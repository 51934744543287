import _ from 'lodash';
import React, { useContext, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { PolicyDocumentService } from 'cnd-capability-quoteandbind';
import { useCallService, useStoredCountry } from 'cnd-common-hooks-platform-react';
import { CountryLayerService, DocumentLinkService, wait } from 'cnd-portals-util-js';
import {
    DOWNLOAD_RETRY_TIMES,
    DOWNLOAD_RETRY_DELAY_IN_SECONDS,
    DOWNLOAD_DELAY_FACTOR
} from '../../constants/Commons';
import useProductLinks from './hooks/useProductLinks';
import useQuoteLinks from './hooks/useQuoteLinks';
import DocumentLinks from '../DocumentLinks/DocumentLinks';
import metadata from './ProductLinks.metadata.json5';
import messages from './ProductLinks.messages';

const fetchDocumentWithRetry = (url) => {
    let retryCount = 0;
    const fetchData = async function fetchData() {
        try {
            retryCount += 1;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Error downloading document');
            }
            return response;
        } catch (error) {
            if (retryCount < DOWNLOAD_RETRY_TIMES) {
                const delay = DOWNLOAD_RETRY_DELAY_IN_SECONDS * (DOWNLOAD_DELAY_FACTOR ** retryCount);
                await wait(delay);
                await fetchData();
            }
            throw error;
        }
    };
    return fetchData();
};

function ProductLinks(props) {
    const {
        staticOnly,
        showQuote,
        title,
        showTitle,
        waitForDocumentsReady,
        filterDocuments,
        model: submissionVM
    } = props;
    const translator = useContext(TranslatorContext);
    const [isDownloading, setIsDownloading] = useState(false);

    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    const quoteID = _.get(submissionVM, 'quoteID.value');
    const sessionUUID = _.get(submissionVM, 'sessionUUID.value');
    const offeringCode = _.get(submissionVM, 'baseData.offering_Cnd.value.code');
    const virtualProductCode_Cnd = _.get(submissionVM, 'baseData.virtualProductCode_Cnd.value');
    const mayDelayPolicyProposalDocument = !waitForDocumentsReady && CountryLayerService.mayDelayPolicyProposalDocument(appCountry);

    const staticLinks = useProductLinks(appCountry, offeringCode, staticOnly, virtualProductCode_Cnd);
    const {
        loading: isLoadingQuote,
        data: quoteLinks
    } = useQuoteLinks(sessionUUID, quoteID, offeringCode, showQuote && mayDelayPolicyProposalDocument);

    const openDocument = useCallback(async (event) => {
        event.preventDefault();
        const target = event.currentTarget || event.target;

        setIsDownloading(true);
        try {
            const response = await fetchDocumentWithRetry(target.href);
            const href = window.URL.createObjectURL(await response.blob());
            window.open(href, '_blank');
        } catch (error) {
            // noop
        } finally {
            setIsDownloading(false);
        }
    }, []);

    const downloadDocument = useCallback(async (event) => {
        event.preventDefault();
        const target = event.currentTarget || event.target;

        setIsDownloading(true);
        try {
            const response = await fetchDocumentWithRetry(target.href);
            const blob = await response.blob();
            const href = window.URL.createObjectURL(blob.slice(0, blob.size, 'application/octet-stream'));
            const anchor = document.createElement('a');
            anchor.href = href;
            anchor.download = target.text;
            document.body.appendChild(anchor);
            anchor.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(href);
                document.body.removeChild(anchor);
            }, 100);
        } catch (error) {
            // noop
        } finally {
            setIsDownloading(false);
        }
    }, []);

    const fetchDocuments = useCallback(async () => {
        if (staticOnly) return [];

        const docs = await PolicyDocumentService.getPolicyDocuments(
            sessionUUID,
            quoteID
        );
        if (waitForDocumentsReady) {
            const someDocsNotReady = _.some(docs, (doc) => doc.isReady === false);
            if (someDocsNotReady) {
                throw new Error('Some documents are not ready');
            }
        }
        const filteredDocs = filterDocuments(docs);
        const baseUrl = getProxiedServiceUrl('downloadDocument');
        const openInTab = DocumentLinkService.openInTab(appCountry);
        return _.reduce(filteredDocs, (memo, doc) => {
            const hiddenDocument = (!showQuote || mayDelayPolicyProposalDocument) && doc.documentType === 'quote';
            if (!doc.isReady || hiddenDocument) return memo;
            const link = {
                url: `${baseUrl}/policyDocument/${doc.publicID}/?token=${doc.sessionID}`,
                name: doc.name,
                onClick: openInTab ? openDocument : downloadDocument,
            };
            memo.push(link);
            return memo;
        }, []);
    }, [
        staticOnly,
        waitForDocumentsReady,
        showQuote,
        mayDelayPolicyProposalDocument,
        quoteID,
        sessionUUID,
        appCountry,
        filterDocuments,
        openDocument,
        downloadDocument
    ]);

    const {
        loading: isLoading,
        error,
        data: pcLinks,
        retry
    } = useCallService(
        fetchDocuments,
        {
            retryTimes: DOWNLOAD_RETRY_TIMES,
            retryDelayFactor: DOWNLOAD_DELAY_FACTOR,
            retryDelayInSeconds: DOWNLOAD_RETRY_DELAY_IN_SECONDS,
            initialIsLoading: !staticOnly
        },
        []
    );

    const links = useMemo(() => {
        return [...quoteLinks, ...staticLinks, ...(pcLinks ?? [])];
    }, [quoteLinks, staticLinks, pcLinks]);

    const overrideProps = {
        productLinksComponentTitle: {
            visible: showTitle && !isLoadingQuote && !isLoading,
            content: title ?? translator(messages.productLinksTitle)
        },
        productLinksComponentProductLinks: {
            isLoading: isLoadingQuote || isLoading || isDownloading,
            error,
            onRetry: retry,
            links
        },
    };

    const resolvers = {
        resolveComponentMap: {
            productlinks: DocumentLinks
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

ProductLinks.propTypes = {
    staticOnly: PropTypes.bool,
    showQuote: PropTypes.bool,
    showTitle: PropTypes.bool,
    waitForDocumentsReady: PropTypes.bool,
    filterDocuments: PropTypes.func,
    title: PropTypes.string,
    model: PropTypes.shape({
        sessionUUID: PropTypes.shape({
            value: PropTypes.string.isRequired
        }).isRequired,
        quoteID: PropTypes.shape({
            value: PropTypes.string.isRequired
        }).isRequired,
    }).isRequired
};

ProductLinks.defaultProps = {
    staticOnly: false,
    showQuote: false,
    showTitle: true,
    waitForDocumentsReady: false,
    filterDocuments: _.nthArg(0),
    title: null
};

export default ProductLinks;
