import StaticInformation from './StaticInformation/StaticInformation';
import MultiSelectCnd from './MultiSelectCnd/MultiSelectCnd';

const platformComponentMap = {
    StaticInformation: { component: 'StaticInformation' },
    MultiSelectCnd: { component: 'MultiSelectCnd' }
};

const platformComponents = {
    StaticInformation,
    MultiSelectCnd
};

export {
    StaticInformation,
    MultiSelectCnd,
    platformComponentMap,
    platformComponents
};
