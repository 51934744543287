import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteAddressCountry: {
        id: 'quoteandbind.components.address.Country',
        defaultMessage: 'Country'
    },
    quoteAddressStreet: {
        id: 'quoteandbind.components.address.Street',
        defaultMessage: 'Street'
    },
    quoteAddressStreetNumber: {
        id: 'quoteandbind.components.address.Street number',
        defaultMessage: 'Street number'
    },
    quoteAddressApartmentNumber: {
        id: 'quoteandbind.components.address.Apartment number',
        defaultMessage: 'Apartment number'
    },
    quoteAddressCity: {
        id: 'quoteandbind.components.address.City',
        defaultMessage: 'City'
    },
    quoteAddressPostcode: {
        id: 'quoteandbind.components.address.Postcode',
        defaultMessage: 'Postcode'
    },
    quoteAddressVoivodeship: {
        id: 'quoteandbind.components.address.Voivodeship',
        defaultMessage: 'Voivodeship'
    },
    quoteAddressCounty: {
        id: 'quoteandbind.components.address.County',
        defaultMessage: 'County'
    },
    quoteAddressState: {
        id: 'quoteandbind.components.address.State',
        defaultMessage: 'State'
    },
    quoteAddressProvince: {
        id: 'quoteandbind.components.address.Province',
        defaultMessage: 'Province'
    },
    quoteAddressPrefecture: {
        id: 'quoteandbind.components.address.Prefecture',
        defaultMessage: 'Prefecture'
    },
    quoteAddressGps: {
        id: 'quoteandbind.components.address.GPS Location',
        defaultMessage: 'GPS Location'
    }
});
