import { defineMessages } from 'react-intl';

export default defineMessages({
    newQuote: {
        id: 'quoteandbind.tvl.wizard.New quote',
        defaultMessage: 'New quote'
    },
    tripDetailsStep: {
        id: 'quoteandbind.tvl.wizard.step.Trip details',
        defaultMessage: 'Trip details'
    },
    quoteStep: {
        id: 'quoteandbind.tvl.wizard.step.Quote',
        defaultMessage: 'Quote'
    },
    personalInformationStep: {
        id: 'quoteandbind.tvl.wizard.step.Personal information',
        defaultMessage: 'Personal information'
    },
    cancelQuote: {
        id: 'quoteandbind.tvl.wizard.cancel Quote',
        defaultMessage: 'Entered data will be lost and you will start the quote process from the beginning.'
    },
    insuranceSummaryStep: {
        id: 'quoteandbind.tvl.wizard.step.Insurance summary',
        defaultMessage: 'Insurance summary'
    },
    paymentStep: {
        id: 'quoteandbind.tvl.wizard.step.Payment',
        defaultMessage: 'Payment'
    },
    retrieveByTokenErrorTitle: {
        id: 'quoteandbind.tvl.wizard.Quote not found',
        defaultMessage: 'Quote not found'
    },
    retrieveByTokenErrorMessage: {
        id: 'quoteandbind.tvl.wizard.Your quote cannot be found, please contact your agent.',
        defaultMessage: 'Your quote cannot be found, please contact your agent.'
    }
});
