import _ from 'lodash';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@jutro/components';
import defaultStyles from './Collapsible.module.scss';

function Collapsible(props) {
    const {
        children,
        collapseId,
        collapsed,
        noToggle,
        autoMeasure,
        onToggle,
        onHeaderRender,
        onTransitionEnd,
        bigButtonCnd,
        styles
    } = props;
    const rootRef = useRef(null);
    const collapsibleRef = useRef(null);

    const [height, setHeight] = useState(
        collapsed ? 0 : undefined
    );

    useEffect(() => {
        if (!collapsed) setHeight(collapsibleRef.current?.getBoundingClientRect().height);
        else setHeight(0);
    }, [collapsed, setHeight]);

    useEffect(() => {
        if (height > 0) {
            setTimeout(() => {
                const rect = rootRef.current.getBoundingClientRect();
                if (rect.top < 0) {
                    window.scrollBy(0, rect.top);
                }
            }, 200);
        }
    }, [height]);

    const collapseButtonStyle = classNames(defaultStyles.collapseButton, {
        [defaultStyles.collapseButtonBig]: bigButtonCnd,
        [defaultStyles.collapsed]: collapsed,
        [defaultStyles.hidden]: noToggle
    });

    const handleToggle = useCallback(() => {
        if (autoMeasure && !collapsed && height === 'auto') {
            setHeight(collapsibleRef.current?.getBoundingClientRect().height);
        }

        onToggle(!collapsed, collapseId);
    }, [collapseId, autoMeasure, height, collapsed, setHeight, onToggle]);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onToggle(!collapsed);
        }
    }, [collapsed, onToggle]);

    const handleTransitionEnd = useCallback(() => {
        if (autoMeasure && !collapsed) setHeight('auto');
        onTransitionEnd(collapsed);
    }, [autoMeasure, collapsed, setHeight, onTransitionEnd]);

    return (
        <div className={styles.root} ref={rootRef}>
            <div className={styles.collapsible} role="menuitem" onClick={handleToggle} onKeyDown={handleKeyDown} tabIndex={0}>
                <div className={classNames(defaultStyles.collapsibleHeader, {
                    [defaultStyles.collapsibleHeaderBig]: bigButtonCnd
                })}>
                    <Button
                        tabIndex={-1}
                        className={collapseButtonStyle}
                        icon="mi-expand-less"
                        type="tertiary"
                    />
                    {onHeaderRender(props)}
                </div>
            </div>
            <div
                style={{ height }}
                className={defaultStyles.collapsible}
                onTransitionEnd={handleTransitionEnd}
            >
                <div ref={collapsibleRef}>
                    {children}
                </div>
            </div>
        </div>
    );
}

Collapsible.propTypes = {
    collapseId: PropTypes.bool.isRequired,
    collapsed: PropTypes.bool,
    noToggle: PropTypes.bool,
    children: PropTypes.element.isRequired,
    autoMeasure: PropTypes.bool,
    styles: PropTypes.shape({
        root: PropTypes.string,
        collapsible: PropTypes.string
    }),
    onToggle: PropTypes.func,
    onHeaderRender: PropTypes.func,
    onTransitionEnd: PropTypes.func,
    bigButtonCnd: PropTypes.bool,
};

Collapsible.defaultProps = {
    collapsed: true,
    noToggle: false,
    autoMeasure: false,
    styles: {},
    onToggle: _.noop,
    onHeaderRender: _.noop,
    onTransitionEnd: _.noop,
    bigButtonCnd: false,
};

export default Collapsible;
