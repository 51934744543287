import { setComponentMapOverrides } from '@jutro/uiconfig';

import QuoteWizardPageTemplate from './components/QuoteWizardPageTemplate';
import LineOfBusiness from './components/LineOfBusiness/LineOfBusiness';
import RetrieveQuote from './components/RetrieveQuote/RetrieveQuote';

export { default as commonMessages } from './quoteandbind.messages';
export { default as commonQuoteStyles } from './quoteandbind-common-styles.module.scss';
export { default as PaymentDetailsPage } from './pages/PaymentDetails/PaymentDetailsPage';
export { default as QualificationPage } from './pages/Qualification/QualificationPage';
export { default as QuoteInfo } from './components/CommonQuoteInfo/CommonQuoteInfo';
export { default as QuickQuoteWizardPageTemplate } from './components/QuickQuoteWizardPageTemplate';
export { QuoteInfoComponentContext, withQuoteInfoComponent } from './components/QuoteInfoComponentContext';
export { QuoteWizardPageTemplate };

setComponentMapOverrides(
    {
        QuoteWizardPageTemplate: { component: 'QuoteWizardPageTemplate' },
        LineOfBusiness: { component: 'LineOfBusiness' },
        RetrieveQuote: { component: 'RetrieveQuote' }
    },
    {
        QuoteWizardPageTemplate,
        LineOfBusiness,
        RetrieveQuote
    }
);
