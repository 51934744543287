import { defineMessages } from 'react-intl';

export default defineMessages({
    insuranceSummaryPageHeader: {
        id: 'quoteandbind.tvl.step.insurance-summary.Insurance summary',
        defaultMessage: 'Insurance summary'
    },
    policyholderSummaryFormHeader: {
        id: 'quoteandbind.tvl.step.insurance-summary.Policy Holder',
        defaultMessage: 'Policy Holder'
    },
    proceedWithPayment: {
        id: 'quoteandbind.tvl.step.insurance-summary.Proceed with payment',
        defaultMessage: 'Proceed with payment'
    },
    isInvoiceNeeded: {
        id: 'quoteandbind.tvl.step.insurance-summary.Is invoice needed?',
        defaultMessage: 'Is invoice needed?'
    },
    paymentError: {
        id: 'quoteandbind.tvl.step.insurance-summary.The payment transaction is not approved. Please try again later.',
        defaultMessage: 'The payment transaction is not approved. Please try again later.'
    },
    licensePlate: {
        id: 'quoteandbind.tvl.step.insurance-summary.License Plate',
        defaultMessage: 'License Plate'
    },
    firstRegistrationDate: {
        id: 'quoteandbind.tvl.step.insurance-summary.First Registration Date',
        defaultMessage: 'First Registration Date'
    },
    insuranceSummaryPersonalDataConsentHeader: {
        id: 'quoteandbind.tvl.step.insurance-summary.Additional Consents',
        defaultMessage: 'Additional Consents'
    },
    discountCodeExpiredTitle: {
        id: 'quoteandbind.tvl.step.insurance-summary.Discount Code expired',
        defaultMessage: 'Discount Code expired'
    },
    discountCodeExpiredMessage: {
        id: 'quoteandbind.tvl.step.insurance-summary.The discount code you entered is already expired. Please start a new quote',
        defaultMessage: 'The discount code you entered is already expired. Please start a new quote'
    },
    startNewQuote: {
        id: 'quoteandbind.tvl.step.insurance-summary.Start a New Quote',
        defaultMessage: 'Start a New Quote'
    }
});
