import React, {
    useContext,
    useCallback,
    useState,
    useEffect,
    useMemo
} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides, renderContentFromMetadata } from '@jutro/uiconfig';
import {
    Main,
    Icon,
    Chevron,
    ModalNextProvider
} from '@jutro/components';
import { Table, TableColumn } from '@jutro/datatable';
import { TranslatorContext } from '@jutro/locale';
import { ServiceManager } from '@jutro/services';
import { BreakpointTrackerContext } from '@jutro/layout';
import { routeConfirmationModal } from '@jutro/router';
import vmTranslator, { messages as platformMessages } from 'gw-platform-translations';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import { brandingData, setBranding } from 'gw-portals-branding-js';
import { RoutingTracking } from 'gw-portals-tracking-react';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';

import 'gw-capability-quoteandbind-react';
import { commonMessages } from 'gw-capability-quoteandbind-common-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import {
    platformComponents,
    platformComponentMap,
    ImageComponent,
} from 'gw-components-platform-react';

import {
    platformComponentMap as customPlatformComponentMap,
    platformComponents as customPlatformComponents
} from 'cnd-components-platform-react';

import {
    platformComponentMap as customCommonPlatformComponentMap,
    platformComponents as customCommonPlatformComponents,
    TelemetryProvider,
    SeverityLevel
} from 'cnd-common-components-platform-react';

import { ErrorBoundary } from 'gw-portals-error-react';
import { AddressLookupComponentMap, AddressLookupComponents } from 'gw-capability-address-react';
import { DependencyProvider } from 'gw-portals-dependency-react';
import { CustomQuoteService } from 'gw-capability-quoteandbind';
import { LoadSaveService } from 'cnd-capability-quoteandbind';
import { PropertyCodeLookupService } from 'gw-capability-propertycode';
import { ContactInfoService, TagManagerService } from 'cnd-portals-util-js';

import { TVLRouteConfig, TVLWizard } from 'cnd-capability-quoteandbind-tvl-react';
import ContactUs from '../pages/ContactUs/ContactUs';
import UnsuccessfulPayment from '../pages/UnsuccessfulPayment/UnsuccessfulPayment';
import ProducerPage from '../pages/ProducerPage/ProducerPage';
import PaymentResult from '../pages/PaymentResult/PaymentResult';
import ProducerCodeHandler from '../pages/ProducerCodeHandler/ProducerCodeHandler';
import metadata from './App.metadata.json5';
import './App.messages';


setComponentMapOverrides({
    ...platformComponentMap,
    ...customPlatformComponentMap,
    ...customCommonPlatformComponentMap,
    ...AddressLookupComponentMap,
    Icon: { component: 'Icon' },
    Table: { component: 'Table' },
    TableColumn: { component: 'TableColumn' },
    Chevron: { component: 'Chevron' },
    // replace the native IMG component with a proxied version
    img: { component: 'img' }
}, {
    ...platformComponents,
    ...customPlatformComponents,
    ...customCommonPlatformComponents,
    ...AddressLookupComponents,
    Icon,
    Table,
    TableColumn,
    Chevron,
    img: ImageComponent
});


let appInsights;

export default function App() {
    const localeService = ServiceManager.getService('locale-service');
    const countryService = ServiceManager.getService('country-service-cnd');
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const [viewModelService, setViewModelService] = useState(undefined);
    const [showPhoneMenu, setShowPhoneMenu] = useState(false);
    const [phoneMenuRef, setPhoneMenuRef] = useState(false);
    const { hostname } = window.location;
    const tvlWizardPath = TVLRouteConfig[hostname] || '/quote-tvl';
    const country = countryService.getStoredCountry();
    const countryCode = country?.toUpperCase();

    const togglePhoneMenu = useCallback(() => {
        setShowPhoneMenu(!showPhoneMenu);
    }, [showPhoneMenu]);

    const shouldTogglePhoneMenu = useCallback((e) => {
        if (phoneMenuRef === e.target) {
            togglePhoneMenu();
        }
    }, [phoneMenuRef, togglePhoneMenu]);

    const clickOnFAQ = useCallback((event) => {
        event.preventDefault();
        window.open(
            // after translation, the link will be replaced dynamically based on Clients location
            ContactInfoService.getFaqLink(countryCode),
            '_blank'
        );
    }, [countryCode]);

    const clickOnPhoneNumber = useCallback((event) => {
        event.preventDefault();
        window.location = `tel:${ContactInfoService.getContactPhoneNumber(countryCode)}`;
    }, [countryCode]);
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(
                    result,
                    translatorFn
                )
            );
        });

        if (brandingData.BRANDING) {
            setBranding();
        }

        if (breakpoint === 'phone' || breakpoint === 'tablet') {
            // a listener so we to the phone menu so we can
            // close the menu if there's a click outside the menu
            document.addEventListener('mousedown', shouldTogglePhoneMenu, false);
            // If a function is returned from a useEffect,
            // it will be used to clean up when component is unmounted
            return () => document.removeEventListener('mousedown', shouldTogglePhoneMenu, false);
        }
    }, [breakpoint, shouldTogglePhoneMenu, togglePhoneMenu, translator]);

    const getMenuRef = useCallback((node) => {
        setPhoneMenuRef(node);
    }, []);

    const metadataToRender = useMemo(() => {
        if (breakpoint === 'phone' || breakpoint === 'tablet') {
            return metadata.pageContent.content.filter((item) => item.id === 'phoneHeader');
        }
        return metadata.pageContent.content.filter((item) => item.id === 'desktopHeader');
    }, [breakpoint]);

    const handleError = useCallback((error = {}) => {
        const errorMsg = _.isObject(error) ? JSON.stringify(error) : error;
        appInsights.trackException({
            error: errorMsg,
            severityLevel: SeverityLevel.Error
        });

        const state = {
            pathname: '/quote-tvl',
            state: error
        };

        return ModalNextProvider.showAlert({
            title: commonMessages.unableToGenerateAQuote,
            message: error.baseError && error.baseError.includes('UWIssue')
                ? commonMessages.unableToGenerateAQuoteUWIssue
                : commonMessages.somethingWentWrong,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: platformMessages.ok
        }).then(
            () => <Redirect to={state} />,
            _.noop
        );
    }, []);

    const handleLanguageValueChange = useCallback((language) => {
        localeService.saveLanguage(language);
        const languageCountry = (language === 'cs' || language === 'en-US') ? 'cz' : language;
        countryService.saveCountry(languageCountry);
    }, [localeService, countryService]);

    useEffect(() => {
        TagManagerService.init(countryCode);
    }, [countryCode]);

    const overrideProps = {
        // Cnd Customization, Task: #62058
        mobileHome: {
            visible: false
        },
        globalizationChooserGridItem: {
            visible: process.env.REACT_APP_SITE_LANG_SELECTOR_ENABLED === 'true'
        },
        globalizationChooserField: {
            onLanguageValueChange: handleLanguageValueChange,
            languageValue: localeService.getStoredLanguage()
        },
        phoneMenu: {
            // Cnd Customization
            className: classNames('gw-phone-header', {
                'gw-phone-header-open': showPhoneMenu
            })
        },
        // Cnd Customization
        phoneMenuBgWithOpacityCnd: {
            ref: getMenuRef
        },
        mobilePhoneNumber: {
            content: ContactInfoService.getContactPhoneNumber(countryCode)
        },
        furtherquestions: {
            title: ContactInfoService.getContactPhoneNumber(countryCode)
        },
        checkanswers: {
            link: ContactInfoService.getFaqLink(countryCode)
        },
        mobileFaq: {
            href: ContactInfoService.getFaqLink(countryCode),
            target: '_blank'
        },
        mobilePrivacyPolicy: {
            href: ContactInfoService.getPrivacyPolicyLink(countryCode),
            target: '_blank'
        },
        footerNavPrivacyPolicy: {
            href: ContactInfoService.getPrivacyPolicyLink(countryCode),
            target: '_blank'
        },
        mobileTermsOfUse: {
            href: ContactInfoService.getTermsOfUseLink(countryCode),
            target: '_blank'
        },
        footerNavTermsOfUse: {
            href: ContactInfoService.getTermsOfUseLink(countryCode),
            target: '_blank'
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            togglePhoneMenu: togglePhoneMenu,
            clickOnPhoneNumber: clickOnPhoneNumber,
            clickOnFAQ: clickOnFAQ
        },
    };

    const footerMetadata = metadata.pageContent.content.filter((item) => item.id === 'footer');

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider value={{
                LoadSaveService,
                CustomQuoteService,
                PropertyCodeLookupService
            }}
            >
                <ViewModelServiceContext.Provider value={viewModelService}>
                    <Router
                        basename={process.env.PUBLIC_PATH}
                        getUserConfirmation={routeConfirmationModal}
                    >
                        <TelemetryProvider
                            disableTelemetry={process.env.REACT_APP_DISABLE_TELEMETRY === 'true'}
                            connectionString={process.env.REACT_APP_TELEMETRY_CONNECTION_STRING}
                            after={(ai) => { appInsights = ai; }}
                        >
                            <Main className="qnbMain" contentClassName="qnbMainContent" fluid>
                                <ModalNextProvider />
                                {renderContentFromMetadata(
                                    metadataToRender, overrideProps, resolvers
                                )}
                                <RoutingTracking />
                                <div className="qnbContent">
                                    <ErrorBoundary onError={handleError}>
                                        <Switch>
                                            <Route path="/contact-us" component={ContactUs} />
                                            <Route path="/partner-tvl/:producer" render={() => <ProducerPage tvlWizardPath={tvlWizardPath} />} />
                                            <Route path={tvlWizardPath} component={TVLWizard} />
                                            <Route path="/r/:token" component={TVLWizard} />
                                            <Route path="/payment-result" component={PaymentResult} />
                                            <Route path="/unsuccessful-payment" component={UnsuccessfulPayment} />
                                            <Route path="/" render={() => <ProducerCodeHandler tvlWizardPath={tvlWizardPath} />} />
                                        </Switch>
                                    </ErrorBoundary>
                                </div>
                                {renderContentFromMetadata(footerMetadata, overrideProps)}
                            </Main>
                        </TelemetryProvider>
                    </Router>
                </ViewModelServiceContext.Provider>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}
