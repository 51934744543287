import React, {
    useCallback,
    useMemo,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { LOBContext } from 'cnd-common-components-platform-react';
import metadata from './AgeBands.metadata.json5';

function AgeBands(props) {
    const {
        value: ageBandsVM,
        path,
        onValueChange,
        onValidate,
        readOnly
    } = props;
    const translator = useContext(TranslatorContext);
    const {
        isComponentValid,
        registerComponentValidation,
        onValidate: setComponentValidation
    } = useValidation('AgeBands');

    const { availableAgeBands } = useContext(LOBContext);

    const getFormValidity = useCallback(() => {
        return ageBandsVM.aspects.valid && ageBandsVM.aspects.subtreeValid;
    }, [ageBandsVM]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [getFormValidity, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, 'AgeBands');
        }
    }, [isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.children.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const restoreZeroIfNeeded = useCallback(
        (ageBand, index) => {
            if (ageBand.numberOfTravellers === '') {
                handleValueChange('0', `${index}.numberOfTravellers`);
            }
        },
        [handleValueChange]
    );

    const generateAgeBandsOverrides = useMemo(() => {
        if (ageBandsVM?.children && ageBandsVM.children.length > 0) {
            return ageBandsVM.value.reduce((all, band, index) => {
                const ageBandConf = availableAgeBands.find(
                    (bandConfig) => bandConfig.ageBand === band.ageBand
                );
                const label = `${translator({ id: `typekey.AgeBand_Cnd.${band.ageBand}`, defaultMessage: band.ageBand })} (${[ageBandConf?.ageRangeDisplayName]})`;
                return {
                    ...all,
                    [`ageBandRow${index}`]: {
                        label,
                        onBlur: () => restoreZeroIfNeeded(band, index)
                    }
                };
            }, {});
        }

        return null;
    }, [ageBandsVM.children, ageBandsVM.value, translator, restoreZeroIfNeeded, availableAgeBands]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left',
            readOnly
        },
        ageBandsRows: {
            data: ageBandsVM?.children
        },
        ...generateAgeBandsOverrides
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={ageBandsVM.children}
            overrideProps={overrideProps}
            onValueChange={handleValueChange}
            onValidationChange={setComponentValidation}
        />
    );
}

AgeBands.propTypes = {
    path: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired
};

export default AgeBands;
