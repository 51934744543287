import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import messages from './Price.messages';
import defaultStyles from './Price.module.scss';

function Unlimited(props) {
    const { styles } = props;
    const translator = useContext(TranslatorContext);

    return (
        <div>
            <span className={styles.amount ?? defaultStyles.amount}>{translator(messages.unlimited)}</span>
        </div>
    );
}

Unlimited.propTypes = {
    styles: PropTypes.shape({
        amount: PropTypes.string,
    })
};

Unlimited.defaultProps = {
    styles: {}
};

export default Unlimited;
