import React, {
    useMemo,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { LOBContext } from 'cnd-common-components-platform-react';
import metadata from './Travellers.metadata.json5';
import styles from './Travellers.module.scss';

function Travellers(props) {
    const {
        model: ageBandsVM,
    } = props;
    const translator = useContext(TranslatorContext);
    const { availableAgeBands } = useContext(LOBContext);
    const travellers = useMemo(() => {
        return ageBandsVM?.children?.filter((band) => band?.numberOfTravellers?.value > 0);
    }, [ageBandsVM]);

    const generateAgeBandsOverrides = useMemo(() => {
        if (travellers?.length > 0) {
            return travellers.reduce((all, bandVM, index) => {
                const band = bandVM.value;
                const ageBandConf = availableAgeBands.find(
                    (bandConfig) => bandConfig.ageBand === band.ageBand
                );
                const label = `${translator({ id: `typekey.AgeBand_Cnd.${band.ageBand}`, defaultMessage: band.ageBand })} (${[ageBandConf?.ageRangeDisplayName]})`;
                return {
                    ...all,
                    [`travellersLabel${index}`]: {
                        content: label
                    },
                    [`travellersCount${index}`]: {
                        content: band.numberOfTravellers
                    },
                };
            }, {});
        }

        return null;
    }, [travellers, translator, availableAgeBands]);

    const overrideProps = {
        travellersRows: {
            data: travellers
        },
        ...generateAgeBandsOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={ageBandsVM.children}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

Travellers.propTypes = {
    model: PropTypes.shape({}).isRequired
};

export default Travellers;
