import { defineMessages } from 'react-intl';

export default defineMessages({
    productLinksQuote: {
        id: 'quoteandbind.productLinksComponent.Quote',
        defaultMessage: 'Quote'
    },
    productLinksQuoteDocumentSuffix: {
        id: 'quoteandbind.productLinksComponent.Quote Document Suffix',
        defaultMessage: 'Quote Document Suffix'
    },
    productLinksTitle: {
        id: 'quoteandbind.productLinksComponent.Insurance product information documents',
        defaultMessage: 'Insurance product information documents'
    },
    productLinksDownloadError: {
        id: 'quoteandbind.productLinksComponent.Download was not completed, try again please!',
        defaultMessage: 'Download was not completed, try again please!'
    },
    tripDetailsTravelTripCancellationDocument: {
        id: 'quoteandbind.tvl.step.trip-details.Travel Trip Cancellation Direct',
        defaultMessage: 'Travel Trip Cancellation Direct',
    },
    tripDetailsTravelTripCancellationDocumentPrefix: {
        id: 'quoteandbind.tvl.step.trip-details.Quote_Travel_Cancellation',
        defaultMessage: 'Quote_Travel_Cancellation',
    },
    tripDetailsTravelTripCancellationDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of Travel Trip Cancellation Direct document',
        defaultMessage: 'http://colonnade.sk/',
    },
    tripDetailsTravelTripCancellationIPIDDocument: {
        id: 'quoteandbind.tvl.step.trip-details.IPID Travel Trip Cancellation Direct Bulgaria',
        defaultMessage: 'IPID Travel Trip Cancellation Direct Bulgaria',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelTripCancellationIPIDDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of IPID Travel Trip Cancellation Direct Bulgaria document',
        defaultMessage: 'http://colonnade.bg/',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelTripCancellationGTCDocument: {
        id: 'quoteandbind.tvl.step.trip-details.GTC Travel Trip Cancellation Direct Bulgaria',
        defaultMessage: 'GTC Travel Trip Cancellation Direct Bulgaria',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelTripCancellationGTCDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of GTC Travel Trip Cancellation Direct Bulgaria document',
        defaultMessage: 'http://colonnade.bg/',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelDomesticTripDocument: {
        id: 'quoteandbind.tvl.step.trip-details.Travel Domestic Trip Direct',
        defaultMessage: 'Travel Domestic Trip Direct',
    },
    tripDetailsTravelDomesticTripDocumentPrefix: {
        id: 'quoteandbind.tvl.step.trip-details.Quote_Travel_Domestic',
        defaultMessage: 'Quote_Travel_Domestic',
    },
    tripDetailsTravelDomesticTripDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of Travel Domestic Trip Direct document',
        defaultMessage: 'http://colonnade.sk/',
    },
    tripDetailsTravelDomesticTripIPIDDocument: {
        id: 'quoteandbind.tvl.step.trip-details.IPID Travel Domestic Trip Direct Bulgaria',
        defaultMessage: 'IPID Travel Domestic Trip Direct Bulgaria',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelDomesticTripIPIDDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of IPID Travel Domestic Trip Direct Bulgaria document',
        defaultMessage: 'http://colonnade.bg/',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelDomesticTripGTCDocument: {
        id: 'quoteandbind.tvl.step.trip-details.GTC Travel Domestic Trip Direct Bulgaria',
        defaultMessage: 'GTC Travel Domestic Trip Direct Bulgaria',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelDomesticTripGTCDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of GTC Travel Domestic Trip Direct Bulgaria document',
        defaultMessage: 'http://colonnade.bg/',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelInternationalAnnualTripDocument: {
        id: 'quoteandbind.tvl.step.trip-details.Travel International Annual Trip Direct',
        defaultMessage: 'Travel International Annual Trip Direct',
    },
    tripDetailsTravelInternationalAnnualTripDocumentPrefix: {
        id: 'quoteandbind.tvl.step.trip-details.Quote_Travel_Annual',
        defaultMessage: 'Quote_Travel_Annual',
    },
    tripDetailsTravelInternationalAnnualTripDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of Travel International Annual Trip Direct document',
        defaultMessage: 'http://colonnade.sk/',
    },
    tripDetailsTravelInternationalAnnualTripIPIDDocument: {
        id: 'quoteandbind.tvl.step.trip-details.IPID Travel International Annual Trip Direct Bulgaria',
        defaultMessage: 'IPID Travel International Annual Trip Direct Bulgaria',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelInternationalAnnualTripIPIDDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of IPID Travel International Annual Trip Direct Bulgaria document',
        defaultMessage: 'http://colonnade.bg/',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelInternationalAnnualTripGTCDocument: {
        id: 'quoteandbind.tvl.step.trip-details.GTC Travel International Annual Trip Direct Bulgaria',
        defaultMessage: 'GTC Travel International Annual Trip Direct Bulgaria',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelInternationalAnnualTripGTCDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of GTC Travel International Annual Trip Direct Bulgaria document',
        defaultMessage: 'http://colonnade.bg/',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelInternationalSingleTripDocument: {
        id: 'quoteandbind.tvl.step.trip-details.Travel International Single Trip Direct',
        defaultMessage: 'Travel International Single Trip Direct',
    },
    tripDetailsTravelInternationalSingleTripDocumentPrefix: {
        id: 'quoteandbind.tvl.step.trip-details.Quote_Travel_Single_Trip',
        defaultMessage: 'Quote_Travel_Single_Trip',
    },
    tripDetailsTravelInternationalSingleTripDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of Travel International Single Trip Direct document',
        defaultMessage: 'http://colonnade.sk/',
    },
    tripDetailsTravelInternationalSingleTripIPIDDocument: {
        id: 'quoteandbind.tvl.step.trip-details.IPID Travel International Single Trip Direct Bulgaria',
        defaultMessage: 'IPID Travel International Single Trip Direct Bulgaria',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelInternationalSingleTripIPIDDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of IPID Travel International Single Trip Direct Bulgaria document',
        defaultMessage: 'http://colonnade.bg/',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelInternationalSingleTripGTCDocument: {
        id: 'quoteandbind.tvl.step.trip-details.GTC Travel International Single Trip Direct Bulgaria',
        defaultMessage: 'GTC Travel International Single Trip Direct Bulgaria',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTravelInternationalSingleTripGTCDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of GTC Travel International Single Trip Direct Bulgaria document',
        defaultMessage: 'http://colonnade.bg/',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsTolicyConcerningProvisionsAndRegulationsDocument: {
        id: 'quoteandbind.tvl.step.trip-details.Policy concerning provision of electronic services / Regulations for concluding distance contracts',
        defaultMessage: 'Policy concerning provision of electronic services / Regulations for concluding distance contracts',
        description: 'Apply for Poland only'
    },
    tripDetailsTolicyConcerningProvisionsAndRegulationsDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of Policy concerning provision of electronic services / Regulations for concluding distance contracts document',
        defaultMessage: 'http://colonnade.pl/',
        description: 'Apply for Poland only'
    },
    tripDetailsBeforeConcludingInsuranceContractInformationDocument: {
        id: 'quoteandbind.tvl.step.trip-details.Information before concluding insurance contract as per Insurance Code requirement  - clause 324/326',
        defaultMessage: 'Information before concluding insurance contract as per Insurance Code requirement  - clause 324/326',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsBeforeConcludingInsuranceContractInformationDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of Information before concluding insurance contract as per Insurance Code requirement  - clause 324/326 document',
        defaultMessage: 'http://colonnade.bg/',
        description: 'Apply for Bulgaria only'
    },
    tripDetailsPrecontractualInformationDocument: {
        id: 'quoteandbind.tvl.step.trip-details.Precontractual Information',
        defaultMessage: 'Precontractual Information',
    },
    tripDetailsPrecontractualInformationDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of Precontractual Information document',
        defaultMessage: 'http://colonnade.sk/',
    },
    tripDetailsCancellationPrecontractualInformationDocumentSK: {
        id: 'quoteandbind.tvl.step.trip-details.Cancellation Precontractual Information Slovakia document',
        defaultMessage: 'Cancellation Precontractual Information Slovakia document',
        description: 'Apply for Slovakia only'
    },
    tripDetailsCancellationPrecontractualInformationDocumentUrlSK: {
        id: 'quoteandbind.tvl.step.trip-details.URL of Cancellation Precontractual Information Slovakia document',
        defaultMessage: 'http://colonnade.sk/',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalSingleTripGTCDocumentSK: {
        id: 'quoteandbind.tvl.step.trip-details.GTC Travel International Single Trip Direct Slovakia',
        defaultMessage: 'GTC Travel International Single Trip Direct Slovakia',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalSingleTripGTCDocumentUrlSK: {
        id: 'quoteandbind.tvl.step.trip-details.URL of GTC Travel International Single Trip Direct Slovakia document',
        defaultMessage: 'http://colonnade.sk/',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalSingleTripIPIDDocumentSK: {
        id: 'quoteandbind.tvl.step.trip-details.IPID Travel International Single Trip Direct Slovakia',
        defaultMessage: 'IPID Travel International Single Trip Direct Slovakia',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalSingleTripIPIDDocumentUrlSK: {
        id: 'quoteandbind.tvl.step.trip-details.URL of IPID Travel International Single Trip Direct Slovakia document',
        defaultMessage: 'http://colonnade.sk/',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalAnnualTripGTCDocumentSK: {
        id: 'quoteandbind.tvl.step.trip-details.GTC Travel International Annual Trip Direct Slovakia',
        defaultMessage: 'GTC Travel International Annual Trip Direct Slovakia',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalAnnualTripGTCDocumentUrlSK: {
        id: 'quoteandbind.tvl.step.trip-details.URL of GTC Travel International Annual Trip Direct Slovakia document',
        defaultMessage: 'http://colonnade.sk/',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalAnnualTripIPIDDocumentSK: {
        id: 'quoteandbind.tvl.step.trip-details.IPID Travel International Annual Trip Direct Slovakia',
        defaultMessage: 'IPID Travel International Annual Trip Direct Slovakia',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalAnnualTripIPIDDocumentUrlSK: {
        id: 'quoteandbind.tvl.step.trip-details.URL of IPID Travel International Annual Trip Direct Slovakia document',
        defaultMessage: 'http://colonnade.sk/',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalCancellationTripGTCDocumentSK: {
        id: 'quoteandbind.tvl.step.trip-details.GTC Travel International Cancellation Trip Direct Slovakia',
        defaultMessage: 'GTC Travel International Cancellation Trip Direct Slovakia',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalCancellationTripGTCDocumentUrlSK: {
        id: 'quoteandbind.tvl.step.trip-details.URL of GTC Travel International Cancellation Trip Direct Slovakia document',
        defaultMessage: 'http://colonnade.sk/',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalCancellationTripIPIDDocumentSK: {
        id: 'quoteandbind.tvl.step.trip-details.IPID Travel International Cancellation Trip Direct Slovakia',
        defaultMessage: 'IPID Travel International Cancellation Trip Direct Slovakia',
        description: 'Apply for Slovakia only'
    },
    tripDetailsTravelInternationalCancellationTripIPIDDocumentUrlSK: {
        id: 'quoteandbind.tvl.step.trip-details.URL of IPID Travel International Cancellation Trip Direct Slovakia document',
        defaultMessage: 'http://colonnade.sk/',
        description: 'Apply for Slovakia only'
    },

    tripDetailsTravelTripCancellationSimplifiedIPIDDocument: {
        id: 'quoteandbind.tvl.step.trip-details.GTC Travel Cancellation Simplified',
        defaultMessage: 'GTC Travel Cancellation Simplified',
    },
    tripDetailsTravelTripCancellationSimplifiedIPIDDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of GTC Travel Cancellation Simplified document',
        defaultMessage: 'http://colonnade.bg/',
    },
    tripDetailsTravelTripCancellationSimplifiedGTCDocument: {
        id: 'quoteandbind.tvl.step.trip-details.IPID Travel Cancellation Simplified',
        defaultMessage: 'IPID Travel Cancellation Simplified',
    },
    tripDetailsTravelTripCancellationSimplifiedGTCDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of IPID Travel Cancellation Simplified document',
        defaultMessage: 'http://colonnade.bg/',
    },

    tripDetailsTravelInternationalSingleTripSimplifiedIPIDDocument: {
        id: 'quoteandbind.tvl.step.trip-details.GTC Travel International Single Trip Simplified',
        defaultMessage: 'GTC Travel International Single Trip Simplified',
    },
    tripDetailsTravelInternationalSingleTripSimplifiedIPIDDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of GTC Travel International Single Trip Simplified document',
        defaultMessage: 'http://colonnade.bg/',
    },
    tripDetailsTravelInternationalSingleTripSimplifiedGTCDocument: {
        id: 'quoteandbind.tvl.step.trip-details.IPID Travel International Single Trip Simplified',
        defaultMessage: 'IPID Travel International Single Trip Simplified',
    },
    tripDetailsTravelInternationalSingleTripSimplifiedGTCDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of IPID Travel International Single Trip Simplified document',
        defaultMessage: 'http://colonnade.bg/',
    },

    tripDetailsTravelInternationalSingleTripBuboIPIDDocument: {
        id: 'quoteandbind.tvl.step.trip-details.GTC Travel International Single Trip Bubo',
        defaultMessage: 'GTC Travel International Single Trip Bubo',
    },
    tripDetailsTravelInternationalSingleTripBuboIPIDDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of GTC Travel International Single Trip Bubo document',
        defaultMessage: 'http://colonnade.sk/',
    },
    tripDetailsTravelInternationalSingleTripBuboGTCDocument: {
        id: 'quoteandbind.tvl.step.trip-details.IPID Travel International Single Trip Bubo',
        defaultMessage: 'IPID Travel International Single Trip Bubo',
    },
    tripDetailsTravelInternationalSingleTripBuboGTCDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of IPID Travel International Single Trip Bubo document',
        defaultMessage: 'http://colonnade.sk/',
    },
    tripDetailsTravelInternationalSingleTripBuboPrecontractualDocument: {
        id: 'quoteandbind.tvl.step.trip-details.Precontractual Travel International Single Trip Bubo',
        defaultMessage: 'Precontractual Travel International Single Trip Bubo',
    },
    tripDetailsTravelInternationalSingleTripBuboPrecontractualDocumentUrl: {
        id: 'quoteandbind.tvl.step.trip-details.URL of Precontractual Travel International Single Trip Bubo document',
        defaultMessage: 'http://colonnade.sk/',
    }
});
