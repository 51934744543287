import _ from 'lodash';
import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { Button, Link, Loader } from '@jutro/components';
import { ContactInfoService } from 'cnd-portals-util-js';
import { QuoteService } from 'cnd-capability-quoteandbind';
import { useCallService, useStoredCountry } from 'cnd-common-hooks-platform-react';
import styles from './YourPass.module.scss';
import messages from './YourPass.messages';

function YourPass(props) {
    const { model: submissionVM } = props;

    const quoteID = _.get(submissionVM, 'quoteID.value');
    const sessionUUID = _.get(submissionVM, 'sessionUUID.value');

    const translator = useContext(TranslatorContext);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    const yourPassInfoLink = ContactInfoService.getYourPassInfoLink(appCountry);

    const fetchYourPass = useCallback(async () => {
        const data = await QuoteService.retrieveYourPassCodes(
            sessionUUID,
            quoteID
        );
        if (_.isEmpty(_.trim(data?.[0]?.link))) {
            throw new Error('YourPassInfoLink is null or empty');
        }
        return data;
    }, [quoteID, sessionUUID]);

    const {
        loading,
        error,
        data,
        retry
    } = useCallService(
        fetchYourPass,
        {
            retryTimes: 3,
            retryDelayFactor: 2,
            retryDelayInSeconds: 5,
            initialIsLoading: true
        },
        []
    );

    const link = data?.[0]?.link;

    return (
        <Loader loaded={!loading}>
            {_.isNil(error) ? (
                <>
                    <div>
                        <div className={styles.yourPassContainer}>
                            {data && data.map((yourPassData) => (
                                <div className={styles.yourPassSection}>
                                    <div className={styles.yourPassQrContainer}>
                                        <div className={styles.yourPassCol}>
                                            <div>
                                                <Link
                                                    className={styles.yourPassQrCode}
                                                    target="_blank"
                                                    href={link}
                                                >
                                                    <img
                                                        height={150}
                                                        width={150}
                                                        src={`${link}?format=qrcode`}
                                                        alt={translator(messages.yourPassQrCodeAlt)}
                                                    />
                                                </Link>
                                            </div>
                                            <div className={styles.yourPassScanAndShare}>
                                                {translator(messages.yourPassScanAndShare)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.yourPassNameLinkSection}>
                                        <div className={styles.yourPassInsuredName}>
                                            {yourPassData.insuredName}
                                        </div>
                                        <div>
                                            <Link
                                                className={styles.yourPassDownload}
                                                target="_blank"
                                                href={yourPassData.link}
                                            >
                                                {translator(messages.yourPassDownload)}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Link
                        className={styles.yourPassInfoLink}
                        icon="mi-help"
                        iconPosition="right"
                        target="_blank"
                        href={yourPassInfoLink}
                    >
                        {translator(messages.yourPassInfo)}
                    </Link>
                </>
            ) : (
                <>
                    <p className={styles.yourPassError}>
                        {translator(messages.yourPassErrorMessage)}
                    </p>
                    <Button type="tertiary" onClick={retry}>
                        {translator(messages.yourPassRetryButtonLabel)}
                    </Button>
                </>
            )}
        </Loader>
    );
}

YourPass.propTypes = {
    model: PropTypes.shape({
        sessionUUID: PropTypes.shape({
            value: PropTypes.string.isRequired
        }).isRequired,
        quoteID: PropTypes.shape({
            value: PropTypes.string.isRequired
        }).isRequired,
    }).isRequired
};

export default YourPass;
