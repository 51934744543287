import {
    useState,
    useContext,
    useCallback,
    useMemo
} from 'react';
import { TranslatorContext } from '@jutro/locale';
import { ModalNextProvider } from '@jutro/components';
import { messages as commonMessages } from 'gw-platform-translations';
import { QuoteService } from 'cnd-capability-quoteandbind';
import { SeverityLevel, getAppInsights } from 'cnd-common-components-platform-react';
import messages from '../ProductLinks.messages';

const quoteDocumentPrefixMap = {
    TVITripCancellation: messages.tripDetailsTravelTripCancellationDocumentPrefix,
    TVIDomesticTrip: messages.tripDetailsTravelDomesticTripDocumentPrefix,
    TVIInternationalAnnualTrip: messages.tripDetailsTravelInternationalAnnualTripDocumentPrefix,
    TVIInternationalSingleTrip: messages.tripDetailsTravelInternationalSingleTripDocumentPrefix
};

function useQuoteLinks(sessionUUID, quoteID, offeringCode, showQuote) {
    const translator = useContext(TranslatorContext);
    const appInsights = getAppInsights();
    const [state, setState] = useState({
        loading: false,
        error: null
    });

    const setResponse = useCallback((loading, error = null) => {
        setState({ loading, error });
    }, []);

    const fetchQuoteDocument = useCallback(async (event) => {
        event.preventDefault();
        setResponse(true);
        try {
            const { document: doc, result } = await QuoteService.createAndDownloadQuoteDocument(
                sessionUUID,
                quoteID
            );
            setResponse(false);
            const blob = await result.blob();
            const url = window.URL.createObjectURL(blob.slice(0, blob.size, 'application/octet-stream'));
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = doc.name;
            document.body.appendChild(anchor);
            anchor.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(anchor);
            }, 100);
        } catch (error) {
            setResponse(false, error);
            const errorMsg = _.isObject(error) ? JSON.stringify(error) : error;
            appInsights.trackException({
                error: errorMsg,
                severityLevel: SeverityLevel.Error
            });
            return ModalNextProvider.showAlert({
                title: commonMessages.genericError,
                message: messages.productLinksDownloadError,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }
    }, [quoteID, sessionUUID, setResponse]);

    const data = useMemo(() => {
        const prefix = translator(quoteDocumentPrefixMap[offeringCode]);
        const suffix = translator(messages.productLinksQuoteDocumentSuffix);
        return [{
            name: `${prefix}_${quoteID}_${suffix}.pdf`,
            onClick: fetchQuoteDocument
        }];
    }, [quoteID, offeringCode, translator, fetchQuoteDocument]);

    if (!showQuote) {
        return { ...state, data: [] };
    }

    return { ...state, data };
}

export default useQuoteLinks;
