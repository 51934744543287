import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import _ from 'lodash';

const ProducerCodeHandler = (props) => {
    const { tvlWizardPath } = props;
    const history = useHistory();
    const producer = _.get(history, 'location.state.producer');

    return (
        <Redirect
            to={{
                pathname: tvlWizardPath,
                state: {
                    producer
                },
            }}
        />
    );
};

export default ProducerCodeHandler;
