import { defineMessages } from 'react-intl';

export default defineMessages({
    frequentlyAskedQuestions: {
        id: 'portal.index.Frequently Asked Questions',
        defaultMessage: 'Frequently Asked Questions',
    },
    faq: {
        id: 'portal.index.FAQ',
        defaultMessage: 'FAQ',
    },
    checkAnswers: {
        id: 'portal.index.Check Answers',
        defaultMessage: 'Check Answers',
    },
    toolTipTextCnd: {
        id: 'portal.index.Tool tip text',
        defaultMessage: 'Check Answers'
    },
    needAssistance: {
        id: 'portal.index.Need Assistance?',
        defaultMessage: 'Need Assistance?',
    },
    liveChatNow: {
        id: 'portal.index.Live Chat Now',
        defaultMessage: 'Live Chat Now',
    },
    furtherQuestions: {
        id: 'portal.index.Further Questions?',
        defaultMessage: 'Further Questions?',
    },
    privacyPolicy: {
        id: 'footer.left_items.Privacy Policy',
        defaultMessage: 'Privacy Policy',
    },
    termsOfUse: {
        id: 'footer.left_items.Legal Notes',
        defaultMessage: 'Legal Notes',
    },
    home: {
        id: 'portal.index.Home',
        defaultMessage: 'Home'
    }
});
