import _ from 'lodash';
import PolicyContact from './PolicyContact';
import PolicyHolder from './PolicyHolder';

function QuoteBaseData(data) {
    if (data) {
        _.extend(this, data);
    }
    this.policyContacts_Cnd = this.policyContacts_Cnd || [];
}


QuoteBaseData.prototype.createInsuredPersons = function createInsuredPersons(policyContactsToAdd, numberOfContacts) {
    this.policyContacts_Cnd = this.policyContacts_Cnd || [];
    const numberOfContactsToAdd = numberOfContacts || policyContactsToAdd.length;
    for (let i = 0; i < numberOfContactsToAdd; i++) {
        this.policyContacts_Cnd.push(
            new PolicyContact(policyContactsToAdd[i], 'PolicyAddlNamedInsured')
        );
    }
};

QuoteBaseData.prototype.createAccountHolder = function createAccountHolder(contact) {
    this.accountHolder = PolicyHolder.createPerson(contact);
};


export default QuoteBaseData;
