import messages from './messages/StaticInformation.messages';

export const StaticInformationData = {
    successfulPaymentPage: {
        BG: [
            {
                id: messages.successfulPaymentPageStepMessage1TextBG
            }
        ],
        CZ: [
            {
                id: messages.successfulPaymentPageStepMessage1TextCZ
            }
        ],
        HU: [
            {
                id: messages.successfulPaymentPageStepMessage1TextHU
            },
            {
                id: messages.successfulPaymentPageStepMessage2TextHU
            },
            {
                id: messages.successfulPaymentPageStepMessage3TextHU
            },
            {
                id: messages.successfulPaymentPageStepMessage4TextHU
            }
        ],
        PL: [
            {
                id: messages.successfulPaymentPageStepMessage1TextPL
            }
        ],
        RO: [
            {
                id: messages.successfulPaymentPageStepMessage1TextRO
            }
        ],
        SK: [
            {
                id: messages.successfulPaymentPageStepMessage1TextSK
            }
        ]
    }
};

export default StaticInformationData;
