import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import defaultStyles from './Price.module.scss';

function Price(props) {
    const {
        value,
        formatter: {
            formatPrice,
            country
        },
        currency,
        styles
    } = props;
    const translator = useContext(TranslatorContext);
    const localCurrency = currency ? translator({
        id: `typekey.Currency.${currency?.toLowerCase()}`,
        defaultMessage: currency?.toUpperCase()
    }) : '';

    return (
        <div className={styles.container ?? defaultStyles.container}>
            <span className={styles.amount ?? defaultStyles.price}>
                {formatPrice ? formatPrice(value, country) : value}
            </span>
            <span className={styles.currency ?? defaultStyles.currency}>
                {localCurrency}
            </span>
        </div>
    );
}

Price.propTypes = {
    value: PropTypes.number.isRequired,
    formatter: PropTypes.shape({
        formatPrice: PropTypes.func.isRequired,
        country: PropTypes.string.isRequired
    }),
    currency: PropTypes.string.isRequired,
    styles: PropTypes.shape({
        container: PropTypes.string,
        amount: PropTypes.string,
        currency: PropTypes.string,
    }),
};

Price.defaultProps = {
    formatter: PropTypes.shape({
        formatPrice: undefined,
        country: undefined
    }),
    styles: {}
};

export default Price;
