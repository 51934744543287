import _ from 'lodash';
import React, {
    useState,
    useMemo,
    useContext,
    useCallback,
    useEffect
} from 'react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { LOBContext } from 'cnd-common-components-platform-react';
import {
    TagManagerService,
    TrackingConstants,
    StaticInformationService
} from 'cnd-portals-util-js';
import { useHistory } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from 'gw-platform-translations';
import { ModalNextProvider } from '@jutro/components';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { useSelectedVariant } from '../../hooks/useSubmission';
import Consents from '../../components/Consents/Consents';
import useErrorHandler from '../../hooks/useErrorHandler';
import useNewQuoteMapper from '../../hooks/useNewQuoteMapper';
import metadata from './QuotePage.metadata.json5';
import QuoteTable from '../../components/QuoteTable/QuoteTable';
import QuoteTableMobile from '../../components/QuoteTableMobile/QuoteTable';
import OfferingTypes from '../../constants/OfferingTypes';
import useAddons from './hooks/useAddons';
import StaticInformationData from '../../constants/StaticInformationData';
import { CANCELLATION_ADDON_PUBLIC_ID, CAR_ASSISTANCE_ADDON_PUBLIC_ID } from '../../constants/QuoteConstants';
import styles from './QuotePage.module.scss';
import messages from './QuotePage.messages';
import usePreselectedAddOns from '../../hooks/usePreselectedAddOns';

function QuotePage(props) {
    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const { wizardData, updateWizardData, jumpTo } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const { isComponentValid, registerComponentValidation, onValidate } = useValidation('QuotePage');
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const {
        isFromQuoteRetrieval,
        setIsFromQuoteRetrieval,
        embeddedTripCancellation,
        setIsNewQuoteWithPrefilledData,
        tripPurpose,
        transportation
    } = useContext(LOBContext);
    const { mapQuoteReferenceWithTripAndClientDetails } = useNewQuoteMapper();
    const ErrorHandler = useErrorHandler();
    const history = useHistory();
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    const submissionVM = useAddons(wizardData, embeddedTripCancellation?.selected, country);
    const selectedVariant = useSelectedVariant(submissionVM);
    const [flavours, setFlavours] = useState([]);
    const vehicleData = useMemo(() => ({
        isAssistanceNeeded: _.get(submissionVM.value, "lobData.travel.isCarAssistanceNeeded"),
        productionYear: _.get(submissionVM.value, "lobData.travel.vehicleProductionYear")
    }), [submissionVM.value]);
    const addonsToPreselect = usePreselectedAddOns(appCountry, {tripPurpose, transportation, vehicleData, embeddedTripCancellation});

    const selectedVariantBranchName = selectedVariant?.branchName;

    const discountWarnings = useMemo(() => {
        const issues = _.get(submissionVM.errorsAndWarnings, 'warnings_Cnd.issues.value', []);
        return issues.filter((issue) => /Discount could not be applied/.test(issue.reason));
    }, [submissionVM.errorsAndWarnings]);

    const getFormValidity = useCallback(() => {
        return !_.isNil(selectedVariant);
    }, [selectedVariant]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [
        getFormValidity,
        registerComponentValidation
    ]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, `${changedPath}.value`, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const onVariantSelection = useCallback(
        (branchName) => {
            const newSubmissionVM = _.clone(submissionVM);
            const submissionVariants = _.get(submissionVM, 'lobData.travel.variants.value', []);
            const newVariants = _.map(submissionVariants, (variant) => ({
                ...variant,
                selected: variant.branchName === branchName
            }));
            _.set(submissionVM, 'lobData.travel.variants.value', newVariants);
            _.unset(submissionVM.value, 'bindData.chosenQuote');
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const structureCustomQuote = useCallback((subVM) => {
        const {
            quoteID,
            sessionUUID,
            quoteData,
            baseData,
            lobData,
        } = subVM.value;
        const travelData = lobData.travel;
        const selectedTravelVariant = travelData.variants.find(
            (x) => x.branchName === selectedVariantBranchName
        );
        let periodStart = baseData.periodStartDate;
        if (embeddedTripCancellation?.selected) {
            const embeddedTripCancellationCoverage = _.find(
                selectedTravelVariant?.coverages?.addonCoverages,
                (cov) => cov.publicID === CANCELLATION_ADDON_PUBLIC_ID
            );
            if (embeddedTripCancellationCoverage?.selected) {
                // eslint-disable-next-line prefer-destructuring
                periodStart = embeddedTripCancellation.periodStart;
            } else {
                periodStart = lobData.travel.departureDate_Cnd;
            }
            _.set(subVM, 'baseData.periodStartDate.value', periodStart);
        }
        return {
            quote: quoteData.offeredQuotes
                .find((x) => x.branchName === selectedVariantBranchName),
            quoteID,
            sessionUUID,
            periodStartDate_Cnd: periodStart,
            periodStart: baseData.periodStartDate,
            periodEnd: baseData.periodEndDate,
            isEmbeddedTripCancellationSelected: embeddedTripCancellation?.selected,
            coverages: {
                travel: selectedTravelVariant?.coverages
            }
        };
    }, [selectedVariantBranchName, embeddedTripCancellation]);

    const setCoveragesAndRequote = useCallback(
        (customQuote) => {
            return CustomQuoteService.updateCustomQuote(customQuote, authHeader).then(
                (response) => {
                    const { quote: { branchName } } = response;

                    const newSubmissionVM = _.clone(submissionVM);
                    const quoteOffer = newSubmissionVM.value.quoteData.offeredQuotes
                        .find((x) => x.branchName === branchName);

                    const status = _.get(response, 'quote.status', 'Draft');
                    quoteOffer.status = status;
                    _.set(newSubmissionVM, 'baseData.periodStatus', status);
                    _.set(newSubmissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
                    quoteOffer.premium = response.quote.premium;

                    const variant = _.get(newSubmissionVM, 'lobData.travel.variants.value')
                        .find((x) => x.branchName === branchName);
                    variant.coverages = response.coverages.travel;
                    if (embeddedTripCancellation?.selected) {
                        const embeddedTripCancellationCoverage = _.find(
                            variant?.coverages?.addonCoverages,
                            (cov) => cov.publicID === CANCELLATION_ADDON_PUBLIC_ID
                        );
                        if (embeddedTripCancellationCoverage?.selected) {
                            const { ticketPurchaseDate, totalTripCost } = embeddedTripCancellation;
                            _.set(newSubmissionVM, 'lobData.travel.ticketPurchaseDate.value', ticketPurchaseDate);
                            _.set(newSubmissionVM, 'lobData.travel.totalTripCost.value', totalTripCost);
                        } else {
                            _.unset(newSubmissionVM.value, 'lobData.travel.ticketPurchaseDate');
                            _.unset(newSubmissionVM.value, 'lobData.travel.ticketPurchaseDate');
                            _.unset(newSubmissionVM.value, 'lobData.travel.totalTripCost');
                        }
                        _.set(newSubmissionVM,
                            'lobData.travel.isEmbeddedTripCancellationSelected.value',
                            !!embeddedTripCancellationCoverage?.selected);
                    }
                    updateWizardData(newSubmissionVM);
                }
            );
        },
        [
            submissionVM,
            embeddedTripCancellation,
            CustomQuoteService,
            authHeader,
            updateWizardData
        ]
    );

    const onNextOnQuoteRetrieval = useCallback(async () => {
        const chosenQuoteSub = _.get(submissionVM, 'bindData.chosenQuote.value');
        const offeredQuotes = _.get(submissionVM, 'quoteData.offeredQuotes.value', []);
        const boundQuote = _.find(offeredQuotes,
            (offeredQuote) => offeredQuote.publicID === chosenQuoteSub);
        if (selectedVariantBranchName === boundQuote?.branchName) {
            return submissionVM;
        }
        const customQuote = structureCustomQuote(submissionVM);
        await CustomQuoteService.setSelectedVersionOnSubmission(
            customQuote.quoteID,
            customQuote.quote.branchName,
            customQuote.sessionUUID
        );
        _.set(submissionVM, 'bindData.chosenQuote.value', customQuote.quote.publicID);
        updateWizardData(submissionVM);
        return submissionVM;
    }, [
        submissionVM,
        updateWizardData,
        selectedVariantBranchName,
        CustomQuoteService,
        structureCustomQuote
    ]);

    const onNext = useCallback(async () => {
        TagManagerService.pushNextButtonClick(TrackingConstants.STEPS.PACKAGE_SELECTION);
        if (isFromQuoteRetrieval) {
            return onNextOnQuoteRetrieval();
        }

        const customQuote = structureCustomQuote(submissionVM);
        try {
            await setCoveragesAndRequote(customQuote);
            _.set(submissionVM, 'bindData.chosenQuote.value', customQuote.quote.publicID);
            updateWizardData(submissionVM);
            return submissionVM;
        } catch (error) {
            ErrorHandler.handleError(error);
            return false;
        }
    }, [
        isFromQuoteRetrieval,
        onNextOnQuoteRetrieval,
        setCoveragesAndRequote,
        submissionVM,
        updateWizardData,
        structureCustomQuote,
        ErrorHandler
    ]);

    const runAddonActionOnAllVariants = useCallback((addonCode, callback) => {
        const variants = _.get(submissionVM.value, 'lobData.travel.variants', []);
            variants.forEach((variant) => {
                const addonCoverages = _.get(variant, 'coverages.addonCoverages');
                const addonCoverage = addonCoverages?.find((addon) => addon.publicID === addonCode);
                callback(addonCoverage);
            });
    }, [submissionVM.value]);

    useEffect(() => {
        if (appCountry === 'HU' && !_.get(submissionVM.value, 'lobData.travel.vehicleProductionYear')) {
            runAddonActionOnAllVariants(CAR_ASSISTANCE_ADDON_PUBLIC_ID, (addonCoverage) => {
                _.unset(addonCoverage, 'amount');
                _.set(addonCoverage, 'terms', []);
                _.set(addonCoverage, 'hasTerms', false);
            });
        }
        if (!addonsToPreselect) {
            return;
        }
        addonsToPreselect.forEach((addonCode) => {
            runAddonActionOnAllVariants(addonCode, (addonCoverage) => {
                if (!!addonCoverage?.amount) {
                    _.set(addonCoverage, 'selected', true);
                }
            });
        });
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFlavours(_.get(submissionVM.value, 'lobData.travel.variants'));
    }, [submissionVM.value]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            quotetable: breakpoint === 'desktop' ? QuoteTable : QuoteTableMobile,
            consents: Consents
        }
    };

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        quotePageWarning: {
            visible: discountWarnings.length > 0,
            content: translator(messages.quotePageDiscountNotAppliedWarning)
        },
        quotePageQuoteTable: {
            flavours,
            selectedVariant: selectedVariantBranchName,
            onVariantSelection,
            coversInitiallyExpanded: _.get(submissionVM.value, 'baseData.offering_Cnd') === OfferingTypes.CANCELLATION,
            additionalCoversHidden: _.get(submissionVM.value, 'baseData.offering_Cnd') === OfferingTypes.CANCELLATION,
            onValueChange: handleValueChange,
            isFromQuoteRetrieval
        },
        quotePageDocumentLinks: {
            model: submissionVM
        },
        quotePagePersonalDataConsentHeader: {
            visible: StaticInformationService.isVisibleOnQuotePage(country?.toUpperCase())
        },
        quoteDetailsStaticInformation: {
            step: 'quotePageStep',
            country: country,
            staticInformationData: StaticInformationData
        },
        quoteDetailsConsents: {
            visible: _.get(submissionVM, 'baseData.consents_Cnd') !== undefined,
            value: _.get(submissionVM, 'baseData.consents_Cnd'),
            onValidate,
            path: 'baseData.consents_Cnd',
            readOnly: false,
            locationFilter: 'qb_step_2'
        }
    };

    const onEditQuote = useCallback(() => {
        return ModalNextProvider.showConfirm({
            title: translator(messages.quoteEditQuotePopupTitle),
            message: translator(messages.quoteEditQuotePopupMessage),
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.yes,
            cancelButtonText: commonMessages.close
        }).then((results) => {
            if (results === 'cancel') {
                return _.noop();
            }
            const newSubmissionVM = mapQuoteReferenceWithTripAndClientDetails(submissionVM);
            updateWizardData(newSubmissionVM);
            setIsFromQuoteRetrieval(false);
            setIsNewQuoteWithPrefilledData(true);
            jumpTo(0);
            history.push({
                pathname: '/quote-tvl',
                state: {
                    allowWizardExit: true
                }
            });
            return newSubmissionVM;
        }, _.noop);
    }, [
        submissionVM,
        history,
        jumpTo,
        setIsFromQuoteRetrieval,
        setIsNewQuoteWithPrefilledData,
        updateWizardData,
        mapQuoteReferenceWithTripAndClientDetails,
        translator
    ]);

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            onEditQuote={onEditQuote}
            showEditQuote
            editQuoteLabel={messages.quoteEditQuote}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
