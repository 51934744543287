import { defineMessages } from 'react-intl';

export default defineMessages({
    informMessage: {
        id: 'quoteandbind.tvl.pages.payment-result.We are waiting for payment status from payment provider. Please wait, it can take up to 60 seconds. Do not leave the page.',
        defaultMessage: 'We are waiting for payment status from payment provider. Please wait, it can take up to 60 seconds. Do not leave the page.',
    },
    titleMessage: {
        id: 'quoteandbind.tvl.pages.payment-result.Payment being processed',
        defaultMessage: 'Payment being processed'
    }
});
