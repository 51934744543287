import { CountrySpecificUtil } from 'cnd-common-portals-util-js';

export default {
    isStateVisible: (country) => {
        if (country === undefined) {
            return false;
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => false
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.PL,
                    CountrySpecificUtil.Countries.RO,
                    CountrySpecificUtil.Countries.JP,
                    CountrySpecificUtil.Countries.CA,
                    CountrySpecificUtil.Countries.US,
                    CountrySpecificUtil.Countries.AU
                ],
                action: () => true
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    },
    getStateLabelKey: (country) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => 'State'
            },
            {
                countries: [CountrySpecificUtil.Countries.JP],
                action: () => 'Prefecture'
            },
            {
                countries: [CountrySpecificUtil.Countries.CA],
                action: () => 'Province'
            },
            {
                countries: [CountrySpecificUtil.Countries.RO],
                action: () => 'County'
            },
            {
                countries: [CountrySpecificUtil.Countries.PL],
                action: () => 'Voivodeship'
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    },
    getPostalCodeMask: (country) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => ''
            },
            {
                countries: [CountrySpecificUtil.Countries.PL],
                action: () => '99-999'
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.CZ,
                    CountrySpecificUtil.Countries.SK
                ],
                action: () => '999 99'
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.HU,
                    CountrySpecificUtil.Countries.BG,
                    CountrySpecificUtil.Countries.AU
                ],
                action: () => '9999'
            },
            {
                countries: [CountrySpecificUtil.Countries.RO],
                action: () => '999999'
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.DE,
                    CountrySpecificUtil.Countries.FR
                ],
                action: () => '99999'
            },
            {
                countries: [CountrySpecificUtil.Countries.CA],
                action: () => 'c9c 9c9'
            },
            {
                countries: [CountrySpecificUtil.Countries.JP],
                action: () => '999-9999'
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    }
};
