import { defineMessages } from 'react-intl';

export default defineMessages({
    yourPassDownload: {
        id: 'quoteandbind.tvl.components.your-pass.Download my card',
        defaultMessage: 'Download my card',
    },
    yourPassQrCodeAlt: {
        id: 'quoteandbind.tvl.components.your-pass.QR code containing link to download your card',
        defaultMessage: 'QR code containing link to download your card'
    },
    yourPassScanAndShare: {
        id: 'quoteandbind.tvl.components.your-pass.Scan and share',
        defaultMessage: 'Scan and share'
    },
    yourPassInfo: {
        id: 'quoteandbind.tvl.components.your-pass.Get more info about your Assistance Card',
        defaultMessage: 'Get more info about your Assistance Card'
    },
    yourPassErrorMessage: {
        id: 'quoteandbind.tvl.components.your-pass.Error occured while downloading Your Pass',
        defaultMessage: 'Error occured while downloading Your Pass'
    },
    yourPassRetryButtonLabel: {
        id: 'quoteandbind.tvl.components.your-pass.Retry',
        defaultMessage: 'Retry'
    }
});
