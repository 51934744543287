import _ from 'lodash';
import React, {
    useContext,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import IndividualPerson from '../Person/Person';
import Company from '../Company/Company';
import PolicyHolder, { PolicyHolderType } from '../../models/PolicyHolder';
import metadata from './PolicyHolder.metadata.json5';
import messages from './PolicyHolder.messages';
import SummaryContext from '../summary/SummaryContext';

const ContactType = {
    Person: 'Person',
    Company: 'Company',
    Enterpreneur: 'IndividualEntrepreneur'
};

const getContactComponent = (policyHolderType) => {
    switch (policyHolderType) {
        case ContactType.Person:
            return IndividualPerson;
        case ContactType.Company:
        case ContactType.Enterpreneur:
            return Company;
        default:
            return null;
    }
};

function PolicyHolderComponent(props) {
    const {
        value: accountHolderVM,
        path,
        onValidate,
        onValueChange,
        isResidentVisible,
        isPersonalIDVisible,
        isNationalIDVisible,
        filterValidationErrors
    } = props;
    const translator = useContext(TranslatorContext);
    const accountHolder = _.get(accountHolderVM, 'value');
    const accountHolderStore = useRef({
        [ContactType.Person]: null,
        [ContactType.Company]: null,
        [ContactType.Enterpreneur]: null,
    });
    const { readOnly } = useContext(SummaryContext);

    const policyHolderType = useMemo(() => {
        const { subtype, isEntrepreneur_Cnd: isEntrepreneur } = accountHolder ?? {};
        switch (subtype) {
            case PolicyHolderType.Person:
                return ContactType.Person;
            case PolicyHolderType.Company:
                return isEntrepreneur ? ContactType.Enterpreneur : ContactType.Company;
            default:
                return subtype;
        }
    }, [accountHolder]);

    useEffect(() => {
        accountHolderStore.current[policyHolderType] = accountHolder;
    }, [accountHolder, policyHolderType]);

    const availablePersonValues = useMemo(() => {
        return [
            {
                name: translator(messages.personalInformationPageIndividualPerson),
                code: ContactType.Person
            },
            {
                name: translator(messages.personalInformationPageIndividualEnterpreneur),
                code: ContactType.Enterpreneur
            },
            {
                name: translator(messages.personalInformationPageCompany),
                code: ContactType.Company
            }
        ];
    }, [translator]);

    const handleContactTypeChange = useCallback((value) => {
        let policyHolder;
        const holderData = accountHolderStore.current[value];
        switch (value) {
            case ContactType.Person:
                policyHolder = PolicyHolder.createPerson(holderData);
                break;
            case ContactType.Company:
                policyHolder = PolicyHolder.createCompany(holderData);
                break;
            case ContactType.Enterpreneur:
                policyHolder = PolicyHolder.createEnterpreneur(holderData);
                break;
            default:
                return;
        }

        if (onValueChange) onValueChange(policyHolder, path);
    }, [onValueChange, path, accountHolderStore]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            readOnly
        },
        policyHolderComponentContact: {
            value: accountHolderVM,
            isResidentVisible,
            isPersonalIDVisible,
            isNationalIDVisible,
            filterValidationErrors
        },
        policyHolderComponentPolicyHolderType: {
            availableValues: availablePersonValues,
            value: policyHolderType,
            onValueChange: handleContactTypeChange,
            readOnly: readOnly
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: onValidate
        },
        resolveComponentMap: {
            contact: getContactComponent(policyHolderType)
        },
    };

    if (!accountHolder) return null;

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={accountHolderVM}
                overrideProps={overrideProps}
                onValueChange={onValueChange}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

PolicyHolderComponent.propTypes = {
    path: PropTypes.shape({}).isRequired,
    value: PropTypes.shape({}).isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    isResidentVisible: PropTypes.bool,
    isPersonalIDVisible: PropTypes.bool,
    isNationalIDVisible: PropTypes.bool,
    filterValidationErrors: PropTypes.func
};

PolicyHolderComponent.defaultProps = {
    isResidentVisible: false,
    isPersonalIDVisible: false,
    isNationalIDVisible: false,
    filterValidationErrors: undefined
};

export default PolicyHolderComponent;
