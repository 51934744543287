import { useCallback, useContext } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { CountryLayerService } from 'cnd-portals-util-js';
import Travellers from './components/Travellers/Travellers';
import metadata from './QuoteInfoBox.metadata.json5';
import styles from './QuoteInfoBox.module.scss';
import OfferingTypes from '../../constants/OfferingTypes';
import LOBConstants from '../../constants/LOBConstants';
import { useSelectedVariant } from '../../hooks/useSubmission';
import './QuoteInfoBox.messages';

const { formatDate } = CountryLayerService;

function QuoteInfoBox(props) {
    const { submissionVM, currentStepIndex } = props;
    const translator = useContext(TranslatorContext);
    const selectedVariant = useSelectedVariant(submissionVM);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    const getPeriodDates = useCallback(() => {
        const start = formatDate(_.get(submissionVM.value, 'lobData.travel.departureDate_Cnd'), appCountry);
        const end = formatDate(_.get(submissionVM.value, 'lobData.travel.returnDate_Cnd'), appCountry);
        return `${start} - ${end}`;
    }, [submissionVM.value, appCountry]);

    const getCountries = useCallback(() => {
        const countries = _.get(submissionVM.value, 'lobData.travel.countries') || [];
        const translatedCountries = countries
            .map((countryKey) => translator({
                id: `typekey.Country.${countryKey}`,
                defaultMessage: countryKey
            }));

        translatedCountries.sort();
        return translatedCountries.join(', ');
    }, [submissionVM.value, translator]);

    const getTripZone = useCallback(() => {
        const tripZone = _.get(submissionVM.value, 'lobData.travel.tripZone');
        return translator({
            id: `typekey.TripZone_Cnd.${tripZone}`,
            defaultMessage: tripZone
        });
    }, [submissionVM.value, translator]);

    const getAddonCovers = useCallback(() => {
        const covers = selectedVariant?.coverages.addonCoverages
            .reduce((result, coverage) => {
                if (coverage.selected === true) {
                    return [...result, coverage.name];
                }
                return result;
            }, []) || [];
        covers.sort();
        return covers.join(', ');
    }, [selectedVariant]);

    const getSelectedOffering = () => _.get(submissionVM.value, 'baseData.offering_Cnd');

    const overrides = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        quoteInfoBoxContainer: {
            visible: currentStepIndex > 0
        },
        quoteInfoBoxOffering: {
            value: translator({
                id: `typekey.Offering_Cnd.${getSelectedOffering()}`,
                defaultMessage: getSelectedOffering()
            }),
            visible: getSelectedOffering()
        },
        quoteInfoBoxDestination: {
            value: getCountries(),
            visible: (_.get(submissionVM.value, 'lobData.travel.countries') || []).length > 0 && getSelectedOffering() !== OfferingTypes.DOMESTIC
        },
        quoteInfoBoxTripZone: {
            value: getTripZone(),
            visible: getSelectedOffering() === OfferingTypes.ANNUAL
        },
        quoteInfoBoxDates: {
            value: getPeriodDates(),
            visible: getSelectedOffering() !== OfferingTypes.ANNUAL
        },
        quoteInfoBoxCoverageStartDate: {
            value: formatDate(_.get(submissionVM.value, 'baseData.periodStartDate'), appCountry),
            visible: getSelectedOffering() === OfferingTypes.ANNUAL
        },
        quoteInfoBoxTravellers: {
            model: _.get(submissionVM, 'lobData.travel.ageBands')
        },
        quoteInfoInsuranceProduct: {
            value: _.get(selectedVariant, 'branchName'),
            visible: selectedVariant !== undefined
                && currentStepIndex > 1
                && getSelectedOffering() !== OfferingTypes.CANCELLATION
                && _.get(submissionVM.value, 'baseData.virtualProductCode_Cnd') !== LOBConstants.DOMESTIC_TRIP_DIRECT_CZECH
        },
        quoteInfoInsurancePremium: {
            value: _.get(selectedVariant, 'premium.total'),
            visible: currentStepIndex > 1
                && _.get(selectedVariant, 'premium.total') !== undefined,
            showFractions: _.get(selectedVariant, 'premium.total.amount') % 1 !== 0
        },
        quoteInfoInsuranceAdditionalCovers: {
            value: getAddonCovers(),
            visible: selectedVariant !== undefined && currentStepIndex > 1 && getSelectedOffering() !== OfferingTypes.CANCELLATION && _.get(getAddonCovers(), 'length') > 0
        },
        quoteInfoBoxTripPurchaseDate: {
            value: formatDate(_.get(submissionVM.value, 'lobData.travel.ticketPurchaseDate'), appCountry),
            visible: _.get(submissionVM.value, 'lobData.travel.ticketPurchaseDate') !== undefined
        },
        quoteInfoBoxTripTicketCost: {
            value: _.get(submissionVM.value, 'lobData.travel.totalTripCost'),
            visible: _.get(submissionVM.value, 'lobData.travel.totalTripCost') !== undefined,
            showFractions: _.get(submissionVM.value, 'lobData.travel.totalTripCost.amount') % 1 !== 0
        },
        quoteInfoInsuranceVariant: {
            value: _.get(selectedVariant, 'branchName'),
            visible: getSelectedOffering() === OfferingTypes.CANCELLATION && currentStepIndex > 1
        }
    };

    const resolvers = {
        resolveComponentMap: {
            travellers: Travellers
        },
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.componentContent, overrides, resolvers);
}

export default QuoteInfoBox;
