import { CountrySpecificUtil } from 'cnd-common-portals-util-js';

export default {
    getRegistrationIDMask: (country) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => ''
            },
            {
                countries: [CountrySpecificUtil.Countries.HU],
                action: () => '99-99-999999'
            },
            {
                countries: [CountrySpecificUtil.Countries.RO],
                action: () => 'c99/900000/9999'
            },
            {
                countries: [CountrySpecificUtil.Countries.CZ],
                action: () => '99999999'
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    },
    getTaxIDMask: (country) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => ''
            },
            {
                countries: [CountrySpecificUtil.Countries.HU],
                action: () => '99999999-9-99'
            },
            {
                countries: [CountrySpecificUtil.Countries.PL],
                action: () => '9999999999'
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    },
    getIDLabel: (country) => {
        if (country === undefined) {
            return 'personPassportNumber';
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => 'personPassportNumber'
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.HU
                ],
                action: () => 'personNationalID'
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    },
    getPersonalIDMask: (country) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => ''
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.RO
                ],
                action: () => '9999999999999'
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.PL
                ],
                action: () => '99999999999'
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.CZ,
                    CountrySpecificUtil.Countries.SK
                ],
                action: () => '9999999990'
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.BG
                ],
                action: () => '9999999999'
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    },
    getNationalIDMask: (country) => {
        if (country === undefined) {
            return '';
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => ''
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.HU
                ],
                action: () => '999999cc'
            },
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    },
    getPersonIDVisibility: (country) => {
        if (country === undefined) {
            return { isPersonalIDVisible: true, isNationalIDVisible: true };
        }
        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => ({ isPersonalIDVisible: true, isNationalIDVisible: true })
            },
            {
                countries: [
                    CountrySpecificUtil.Countries.HU
                ],
                action: () => ({ isPersonalIDVisible: false, isNationalIDVisible: true })
            }
        ];

        return CountrySpecificUtil.callCorrectImpl(variants, country);
    }
};
