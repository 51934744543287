import _ from 'lodash';
import Contact from './Contact';

export const PolicyHolderType = {
    Person: 'Person',
    Company: 'Company'
};

function PolicyHolder(data) {
    this.accountHolder = true;

    if (data) {
        _.extend(this, data);
    }
}

PolicyHolder.create = function createPolicyHolder({ subtype, ...data }) {
    const contact = Contact.create(data);
    return new PolicyHolder({ subtype, ...contact });
};

PolicyHolder.createPerson = function createPerson(data) {
    return PolicyHolder.create({
        resident_Cnd: true,
        ...data,
        subtype: PolicyHolderType.Person
    });
};

PolicyHolder.createCompany = function createCompany(data) {
    return PolicyHolder.create({
        vatPayer_Cnd: true,
        ...data,
        subtype: PolicyHolderType.Company,
        isEntrepreneur_Cnd: false,
    });
};

PolicyHolder.createEnterpreneur = function createEnterpreneur(data) {
    return PolicyHolder.create({
        vatPayer_Cnd: true,
        ...data,
        subtype: PolicyHolderType.Company,
        isEntrepreneur_Cnd: true
    });
};

export default PolicyHolder;
