import { Countries, callCorrectImpl } from './CountryLayerCore';

export default {
    isPremiumVisible: (country) => {
        if (country === undefined) {
            return false;
        }
        const variants = [
            {
                countries: [
                    Countries.BG,
                ],
                action: () => true
            },
            {
                countries: [
                    Countries.SK,
                    Countries.PL,
                    Countries.HU,
                    Countries.CZ,
                    Countries.RO
                ],
                action: () => false
            }
        ];

        return callCorrectImpl(variants, country);
    },

    isIPTTaxVisible: (country) => {
        if (country === undefined) {
            return false;
        }
        const variants = [
            {
                countries: [
                    Countries.BG,
                ],
                action: () => true
            },
            {
                countries: [
                    Countries.SK,
                    Countries.PL,
                    Countries.HU,
                    Countries.CZ,
                    Countries.RO
                ],
                action: () => false
            }
        ];

        return callCorrectImpl(variants, country);
    },

    isAfterDiscountAmountVisible: (country) => {
        if (country === undefined) {
            return false;
        }
        const variants = [
            {
                countries: [
                    Countries.BG,
                ],
                action: () => true
            },
            {
                countries: [
                    Countries.SK,
                    Countries.PL,
                    Countries.HU,
                    Countries.CZ,
                    Countries.RO
                ],
                action: () => false
            }
        ];

        return callCorrectImpl(variants, country);
    },

    getPremiumLabelKey: (country) => {
        if (country === undefined) {
            return '';
        }

        const variants = [
            {
                countries: [Countries.SK],
                action: () => 'IncludingIPT'
            },
            {
                countries: [
                    Countries.BG,
                    Countries.PL,
                    Countries.RO,
                    Countries.CZ,
                    Countries.HU,
                ],
                action: () => ''
            }
        ];

        return callCorrectImpl(variants, country);
    },

    getTotalPremiumLabelKey: (country) => {
        if (country === undefined) {
            return '';
        }

        const variants = [
            {
                countries: [Countries.SK],
                action: () => 'IncludingIPT'
            },
            {
                countries: [Countries.BG],
                action: () => 'TotalSumDue'
            },
            {
                countries: [
                    Countries.PL,
                    Countries.RO,
                    Countries.CZ,
                    Countries.HU,
                ],
                action: () => 'ForTheInsurancePeriod'
            }
        ];

        return callCorrectImpl(variants, country);
    }
};
