import _ from 'lodash';
import React, {
    useState,
    useMemo,
    useContext,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { QuoteTableStaticInformationService, CurrencyFormatterService } from 'cnd-portals-util-js';
import { Button } from '@jutro/components';
import Collapsible from '../../../Collapsible/Collapsible';
import Price from '../../../Price/Price';
import StandardCoverage from './components/Coverage/StandardCoverage';
import AddOnCoverage, { TVLAddonCoverageHeader } from './components/Coverage/AddOnCoverage';
import messages from './QuotePackage.messages';
import styles from './QuotePackage.module.scss';

const priceStyles = {
    amount: styles.quotePackagePriceAmount,
    currency: styles.quotePackagePriceCurrency
};

const addOnCollapsibleStyles = {
    collapsible: styles.quotePackageAdditionalCoversContainer
};

export const COVERAGE_TYPE = Object.freeze({
    Addon: 'addonCoverages',
    Standard: 'standardCoverages',
});

function TVLQuotePackage(props) {
    const {
        variant,
        isSelected,
        expandedCoverageID,
        hideSelectVariant,
        additionalCoversHidden,
        onSelect,
        onCoverageCollapseToggle,
        onAddOrRemoveCoverage
    } = props;
    const translator = useContext(TranslatorContext);
    const [isAddonSectionCollapsed, setIsAddonSectionCollapsed] = useState(true);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    const price = useMemo(() => {
        const standardPriceMultiplied = variant.coverages.standardCoverages
            .filter((cov) => cov.selected === true)
            .reduce((acc, cov) => {
                return acc + cov?.amount?.amount;
            }, 0);

        const selectedAddonsPriceMultiplied = variant.coverages.addonCoverages
            .filter((cov) => cov.selected)
            .reduce((acc, cov) => {
                return acc + cov?.amount?.amount;
            }, 0);

        return {
            amount: (
                standardPriceMultiplied + selectedAddonsPriceMultiplied
            ).toFixed(2),
            currency:
                variant.coverages.standardCoverages[0]?.amount?.currency?.toUpperCase(),
        };
    }, [variant]);

    const discount = useMemo(() => {
        const standardDiscountMultiplied = variant.coverages.standardCoverages
            .filter((cov) => cov.selected === true)
            .reduce((acc, cov) => {
                return acc + (cov?.discount_Cnd?.amount || 0);
            }, 0);

        const selectedAddonsDiscountMultiplied = variant.coverages.addonCoverages
            .filter((cov) => cov.selected)
            .reduce((acc, cov) => {
                return acc + (cov?.discount_Cnd?.amount || 0);
            }, 0);

        const sum = standardDiscountMultiplied + selectedAddonsDiscountMultiplied;

        if (sum === 0) {
            return null;
        }

        return {
            amount: (
                standardDiscountMultiplied + selectedAddonsDiscountMultiplied
            ).toFixed(2),
            currency:
                variant.coverages.standardCoverages[0]?.amount?.currency?.toUpperCase(),
        };
    }, [variant]);

    const standardCoverages = _.sortBy(_.get(variant, `coverages.${[COVERAGE_TYPE.Standard]}`), (cov) => cov.order_Cnd);
    const addOnCoverages = _.sortBy(_.get(variant, `coverages.${[COVERAGE_TYPE.Addon]}`), (cov) => cov.order_Cnd);

    const variantStyle = classNames(styles.quotePackageContainer, {
        [styles.selectedVariant]: isSelected
    });

    const handleSelect = useCallback(() => {
        return onSelect(variant.branchName);
    }, [onSelect, variant]);

    const handleAddonSectionToggle = useCallback(
        (collapsed) => setIsAddonSectionCollapsed(collapsed), []
    );

    const handleAddOrRemoveCoverage = useCallback((coveragePublicID) => {
        onAddOrRemoveCoverage(variant.branchName, coveragePublicID);
    }, [variant, onAddOrRemoveCoverage]);

    const isCollapsed = useCallback(
        (collapseId) => expandedCoverageID !== collapseId, [expandedCoverageID]
    );

    const renderAddonHeader = useCallback(() => (
        <React.Fragment>
            <div className={styles.quotePackageAdditionalCoversHeader}>
                {translator(messages.additionalCoverOptions)}
            </div>
            {discount != null && (
                <div className={styles.alreadyAppliedDiscountDisclaimer}>
                    { translator(messages.alreadyAppliedDiscount) }
                </div>
            )
            }
        </React.Fragment>
    ), [translator, discount]);

    return (
        <div className={variantStyle}>
            <div className={styles.quotePackageHeader}>
                <div className={styles.quotePackageName}>{variant.branchName}</div>
                <Price
                    value={price.amount}
                    formatter={{
                        formatPrice: CurrencyFormatterService.formatPrice,
                        country: appCountry
                    }}
                    currency={price.currency}
                    styles={{
                        container: discount != null
                            ? styles.quotePackagePriceContainerAfterDiscount
                            : styles.quotePackagePriceContainer,
                        ...priceStyles
                    }}
                />

            </div>
            <div className={styles.quotePackageBody}>
                <div className={styles.quotePackageCoversContainer}>
                    {
                        _.map(standardCoverages, (coverage) => {
                            const collapseId = `${variant.branchName}.${coverage.publicID}`;
                            return (
                                <StandardCoverage
                                    key={coverage.publicID}
                                    {...coverage}
                                    terms={_.sortBy(coverage.terms, (term) => term.order_Cnd)}
                                    collapseId={collapseId}
                                    collapsed={isCollapsed(collapseId)}
                                    onToggle={onCoverageCollapseToggle}
                                />
                            );
                        })
                    }
                </div>
                {!additionalCoversHidden && addOnCoverages && (
                    <Collapsible
                        autoMeasure
                        styles={addOnCollapsibleStyles}
                        collapsed={isAddonSectionCollapsed}
                        onToggle={handleAddonSectionToggle}
                        onHeaderRender={renderAddonHeader}
                        bigButtonCnd
                    >
                        <div className={styles.quotePackageCoversContainer}>
                            {
                                _.map(addOnCoverages, (coverage) => {
                                    const collapseId = `${variant.branchName}.${coverage.publicID}`;
                                    if (!coverage?.amount?.amount) {
                                        return (
                                            <TVLAddonCoverageHeader
                                                key={coverage.publicID}
                                                {...coverage}
                                            />
                                        );
                                    }
                                    return (
                                        <AddOnCoverage
                                            key={coverage.publicID}
                                            {...coverage}
                                            collapseId={collapseId}
                                            collapsed={isCollapsed(collapseId)}
                                            onToggle={onCoverageCollapseToggle}
                                            onAddOrRemove={handleAddOrRemoveCoverage}
                                        />
                                    );
                                })
                            }
                        </div>
                    </Collapsible>
                )}
            </div>
            <div className={styles.qoutePackageFooter}>
                {translator(messages.totalPriceForPackage)}
                <div className={styles.quotePackageTotalPriceStaticInformation}>
                    {QuoteTableStaticInformationService.isVisibleOnQuotePage(appCountry)
                        ? translator(messages.totalPriceForPackageStaticInfo)
                        : null}
                </div>
                <Price
                    value={price.amount}
                    formatter={{
                        formatPrice: CurrencyFormatterService.formatPrice,
                        country: appCountry
                    }}
                    currency={price.currency}
                    styles={{
                        container: discount != null
                            ? styles.quotePackagePriceContainerAfterDiscount
                            : styles.quotePackagePriceContainer,
                        ...priceStyles
                    }}
                />
                {discount != null && (
                    <Price
                        value={price.amount - discount.amount}
                        formatter={{
                            formatPrice: CurrencyFormatterService.formatPrice,
                            country: appCountry
                        }}
                        currency={price.currency}
                        styles={{
                            container: styles.quotePackagePriceContainerBeforeDiscount,
                            ...priceStyles
                        }}
                    />
                )}
                {!hideSelectVariant && (
                    <div className={styles.quotePackageSelectPlanContainer}>
                        <Button
                            type={isSelected ? 'primary' : 'secondary'}
                            size="small"
                            onClick={handleSelect}
                        >
                            {isSelected
                                ? translator(messages.selected)
                                : translator(messages.selectPlan)}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

TVLQuotePackage.propTypes = {
    variant: PropTypes.shape({
        branchName: PropTypes.string,
        coverages: PropTypes.shape({
            standardCoverages: [],
            addonCoverages: [],
        }),
    }).isRequired,
    isSelected: PropTypes.bool.isRequired,
    expandedCoverageID: PropTypes.string.isRequired,
    hideSelectVariant: PropTypes.bool,
    additionalCoversHidden: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onCoverageCollapseToggle: PropTypes.func.isRequired,
    onAddOrRemoveCoverage: PropTypes.func.isRequired
};

TVLQuotePackage.defaultProps = {
    hideSelectVariant: false,
    additionalCoversHidden: false
};

export default TVLQuotePackage;
