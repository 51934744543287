/* eslint-disable indent */
import React, {
    useState, useEffect, useContext, useCallback
} from 'react';
import { BreakpointTrackerContext } from '@jutro/layout';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { Loader } from '@jutro/components';
import { useDependencies } from 'gw-portals-dependency-react';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import metadata from './TravelPolicySummary.metadata.json5';
import './TravelPolicySummary.messages';
import OfferingTypes from '../../../constants/OfferingTypes';
import LOBConstants from '../../../constants/LOBConstants';
import { useSelectedVariant } from '../../../hooks/useSubmission';

function TravelPolicySummary(props) {
    const { value: submissionVM } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const [offering, setOffering] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const country = useStoredCountry();
    const selectedVariant = useSelectedVariant(submissionVM);
    const agentsProducerCode = _.get(submissionVM.value, 'agentReferenceData_Cnd.producerCode');

    useEffect(() => {
        setIsLoading(true);
        LoadSaveService.getAvailableProducts(
                agentsProducerCode,
                LOBConstants.TRAVEL_INDIVIDUAL_PRODUCT_CODE,
                country
            ).then((result) => {
                const product = result.find((p) => p.virtualProductCode_Ext === _.get(submissionVM.value, 'baseData.virtualProductCode_Cnd'));
                setOffering(product);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [LoadSaveService, agentsProducerCode, country, submissionVM.value]);

    const getCountries = useCallback(() => {
        const countries = _.get(submissionVM.value, 'lobData.travel.countries');
        return countries
            .map((typecode) => translator({
                id: `typekey.Country.${typecode}`,
                defaultMessage: typecode
            })).join(', ');
    }, [submissionVM.value, translator]);

    const getAdditionalCovers = useCallback(() => {
        return selectedVariant.coverages.addonCoverages
            .reduce((result, coverage) => {
                if (coverage.selected === true) {
                    return [...result, coverage.name];
            }
            return result;
        }, []).join(', ');
    }, [selectedVariant]);

    const isAnyAdditionalCov = useCallback(() => {
        return selectedVariant.coverages.addonCoverages.some((cov) => cov.selected);
    }, [selectedVariant]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            readOnly: true
        },
        tvlTravelPolicyComponent: {
            visible: !isLoading
        },
        travelPolicyStartDate: {
            value: _.get(submissionVM.value, 'baseData.periodStartDate')
        },
        travelPolicyEndDate: {
            value: _.get(submissionVM.value, 'baseData.periodEndDate')
        },
        travelDepartureDate: {
            visible: _.get(submissionVM.value, 'baseData.offering_Cnd') === OfferingTypes.CANCELLATION,
            value: _.get(submissionVM.value, 'lobData.travel.departureDate_Cnd')
        },
        travelReturnDate: {
            visible: _.get(submissionVM.value, 'baseData.offering_Cnd') === OfferingTypes.CANCELLATION,
            value: _.get(submissionVM.value, 'lobData.travel.returnDate_Cnd')
        },
        travelPolicyTripZone: {
            visible: _.get(submissionVM.value, 'baseData.offering_Cnd') === OfferingTypes.ANNUAL,
            value: translator({
                id: `typekey.TripZone_Cnd.${_.get(submissionVM.value, 'lobData.travel.tripZone')}`,
                defaultMessage: _.get(submissionVM.value, 'lobData.travel.tripZone')
            })
        },
        travelTicketPurchaseDate: {
            visible: _.get(submissionVM.value, 'baseData.offering_Cnd') === OfferingTypes.CANCELLATION,
            value: _.get(submissionVM.value, 'lobData.travel.ticketPurchaseDate')
        },
        travelTotalCostOfTicket: {
            visible: _.get(submissionVM.value, 'baseData.offering_Cnd') === OfferingTypes.CANCELLATION,
            value: _.get(submissionVM.value, 'lobData.travel.totalTripCost'),
            showFractions: _.get(submissionVM.value, 'lobData.travel.totalTripCost.amount') % 1 !== 0
        },
        travelVariant: {
            visible: _.get(submissionVM.value, 'baseData.offering_Cnd') === OfferingTypes.CANCELLATION,
            value: _.get(selectedVariant, 'branchName')
        },
        travelPolicyDestination: {
            visible: _.get(submissionVM.value, 'baseData.offering_Cnd') !== OfferingTypes.DOMESTIC
            && _.get(submissionVM.value, 'baseData.offering_Cnd') !== OfferingTypes.ANNUAL,
            value: getCountries()
        },
        travelPolicyOffering: {
            value: _.get(offering, 'productName')
        },
        travelPolicySelectedPackage: {
            visible: _.get(submissionVM.value, 'baseData.offering_Cnd') !== OfferingTypes.CANCELLATION,
            value: _.get(selectedVariant, 'branchName')
        },
        travelPolicyAdditionalCovers: {
            visible: _.get(submissionVM.value, 'baseData.offering_Cnd') !== OfferingTypes.CANCELLATION && isAnyAdditionalCov(),
            value: getAdditionalCovers()
        }
    };

    const resolvers = {

    };

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}


export default TravelPolicySummary;
