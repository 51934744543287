import React, {
    useContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
    useRef
} from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import { ModalNextProvider } from '@jutro/components';
import { messages as commonMessages } from 'gw-platform-translations';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { Loader } from '@jutro/components';
import {
    CountrySpecificUtil,
    LocalDateUtil as CustomLocalDateUtil,
    MasksUtil
} from 'cnd-common-portals-util-js';
import { useHistory } from 'react-router-dom';
import { LOBContext } from 'cnd-common-components-platform-react';
import { useStoredCountry, useValidationErrors } from 'cnd-common-hooks-platform-react';
import {
    TagManagerService,
    TrackingConstants,
    StaticInformationService,
    CurrencyFormatterService,
    CountryLayerService
} from 'cnd-portals-util-js';
import { LocalDateUtil } from 'gw-portals-util-js';
import CoverageDataService from '../../services/CoveragesDataService';
import metadata from './TripDetailsPage.metadata.json5';
import messages from './TripDetailsPage.messages';
import AgeBands from '../../components/AgeBands/AgeBands';
import Consents from '../../components/Consents/Consents';
import Submission from '../../models/Submission';
import OfferingTypes from '../../constants/OfferingTypes';
import StaticInformationData from '../../constants/StaticInformationData';
import useErrorHandler from '../../hooks/useErrorHandler';
import useNewQuoteMapper from '../../hooks/useNewQuoteMapper';
import { CND_DTO_VALIDATION_ERROR } from '../../constants/ErrorCodes';
import PreliminaryQuestions, {TRANSPORTATION_CODES} from '../../constants/PreliminaryQuestions'
import styles from './TripDetailsPage.module.scss';
import './TripDetailsPage.overrides.scss';

const MAX_TRAVELERS = 50;
const DISCOUNT_NOT_FOUND_MESSAGE = 'Discount not found';
const DISCOUNT_NOT_ACTIVE_MESSAGE = 'Discount is not active anymore';
const EXPIRED_DISCOUNT_MESSAGE = 'Expired discount';

function TripDetailsPage(props) {
    const { wizardData, updateWizardData, jumpTo } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const submissionVM = viewModelService.create(
        { ...wizardData.value },
        'pc',
        'edge.capabilities.quote.submission.dto.QuoteDataDTO',
        {
            PersonalDataFieldsNotRequiredContext: true
        }
    );
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('TripDetailsPage');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const translator = useContext(TranslatorContext);
    const [isLoading, setIsLoading] = useState(false);
    const country = useStoredCountry();
     const appCountry = country?.toUpperCase();
    const [selectedCountries, setSelectedCountries] = useState(_.get(submissionVM.value, 'lobData.travel.countries'));
    const [isNewQuote] = useState(submissionVM.baseData.value.periodStatus === 'Draft'
        || submissionVM.baseData.value.periodStatus === undefined);
    const offering = _.get(submissionVM.value, 'baseData.offering_Cnd');
    const isDomesticCancellationAllowed = useMemo(() => {
        return CountryLayerService.isDomesticCancellationAllowed(appCountry)
                    && offering === OfferingTypes.CANCELLATION;
    }, [appCountry, offering]);
    const ErrorHandler = useErrorHandler();
    const [
        filterValidationErrors,
        setValidationErrors,
        resetValidationError
    ] = useValidationErrors();
    const history = useHistory();
    const {
        isFromQuoteRetrieval,
        setIsFromQuoteRetrieval,
        producerCodeFromRetrieval,
        producerObjFromCancellation,
        setProducerObjFromCancellation,
        isNewQuoteWithPrefilledData,
        setIsNewQuoteWithPrefilledData,
        embeddedTripCancellation,
        setEmbeddedTripCancellation,
        availableProducts,
        availableCountries,
        setAvailableCountries,
        availableTripZones,
        setAvailableTripZones,
        availableAgeBands,
        partnerDiscountSupplied,
        transportation,
        setTransportation,
        tripPurpose,
        setTripPurpose
    } = useContext(LOBContext);
    const { mapQuoteReferenceWithTripAndClientDetails } = useNewQuoteMapper();
    const isEmbeddedTripCancellationSelected = embeddedTripCancellation?.selected;
    const currency = CountrySpecificUtil.getCurrencyForCountry(
        appCountry, isEmbeddedTripCancellationSelected
    );
    const isCancellation = offering === OfferingTypes.CANCELLATION || isEmbeddedTripCancellationSelected;
    const effectiveProducerCode = useMemo(() => {
        return _.get(submissionVM.value, 'agentReferenceData_Cnd.producerCode') || producerCodeFromRetrieval
    }, [producerCodeFromRetrieval, submissionVM.value]);
    const [assistanceChangedManually, setAssistanceChangedManually] = useState();

    const arePreliminaryQuestionsVisible = useMemo(() => {
        return !isFromQuoteRetrieval 
            && [OfferingTypes.SINGLE].includes(offering)
            && CountryLayerService.arePreliminaryQuestionsVisible(appCountry)
    }, [isFromQuoteRetrieval, offering, appCountry]);

    const ALERT_ERRORS_CONFIG = {
        'LobData.Travel.AgeBands': translator(messages.tripDetailsInvalidTravellersNumberError, { MAX_TRAVELERS })
    };
    const DOMESTIC_CODE = 'domestic';
    const DAYS_UNTIL_TOMORROW = 1;
    const DAYS_IN_WEEK = 7;
    const DAYS_UNTIL_ONE_WEEK_FROM_TOMORROW = DAYS_UNTIL_TOMORROW + DAYS_IN_WEEK;

    const handleSaveSubmissionData = useCallback(async () => {
        if (!isNewQuote) {
            return submissionVM;
        }
        CoverageDataService.clearAddons();
        try {
            const response = await LoadSaveService.createSubmissionAndQuote(submissionVM.value);
            submissionVM.value = new Submission(response);
            const flavours = _.get(submissionVM, 'lobData.travel.variants.value');
            CoverageDataService.saveAddons(flavours);
            if (isEmbeddedTripCancellationSelected) {
                setEmbeddedTripCancellation({
                    selected: isEmbeddedTripCancellationSelected,
                    periodStart: _.get(submissionVM, 'baseData.periodStartDate.value'),
                    ticketPurchaseDate: _.get(submissionVM, 'lobData.travel.ticketPurchaseDate.value'),
                    totalTripCost: _.get(submissionVM, 'lobData.travel.totalTripCost.value')
                });
            }
            updateWizardData(submissionVM);
            return submissionVM;
        } catch (error) {
            if (error?.appErrorCode === CND_DTO_VALIDATION_ERROR) {
                ErrorHandler.updateRootCause(error, ALERT_ERRORS_CONFIG);
                if (error.customErrorMessage) {
                    ErrorHandler.handleError(error);
                } else {
                    setValidationErrors(error.appData);
                }
            } else {
                ErrorHandler.handleError(error);
            }
            return false;
        }
    }, [
        ErrorHandler,
        LoadSaveService,
        isNewQuote,
        submissionVM,
        isEmbeddedTripCancellationSelected,
        setValidationErrors,
        setEmbeddedTripCancellation,
        ALERT_ERRORS_CONFIG
    ]);

    const onNext = useCallback(() => {
        TagManagerService.pushNextButtonClick(TrackingConstants.STEPS.BASIC_INFORMATION);
        return handleSaveSubmissionData();
    }, [handleSaveSubmissionData]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const newSubmissionVM = _.clone(submissionVM);
            _.set(newSubmissionVM, `${changedPath}.value`, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const getFormValidity = useCallback(() => {
        return !isNewQuote 
            || ((submissionVM.aspects.valid && submissionVM.aspects.subtreeValid)
                && (!arePreliminaryQuestionsVisible || (transportation.length && tripPurpose.length)));
    }, [
        isNewQuote,
        submissionVM.aspects.subtreeValid,
        submissionVM.aspects.valid,
        transportation,
        tripPurpose
    ]);
    
    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [getFormValidity, registerComponentValidation]);

    const getDefaultLocalStartEndDates = useCallback(() => {
        return {
            localStartDate: LocalDateUtil.today(DAYS_UNTIL_TOMORROW),
            localEndDate: LocalDateUtil.today(DAYS_UNTIL_ONE_WEEK_FROM_TOMORROW)
        };
    }, [DAYS_UNTIL_TOMORROW, DAYS_UNTIL_ONE_WEEK_FROM_TOMORROW]);

    const setStartEndDates = useCallback(() => {
        const { localStartDate, localEndDate } = getDefaultLocalStartEndDates();
        _.set(submissionVM.value, 'baseData.periodStartDate', localStartDate);
        _.set(submissionVM.value, 'baseData.periodEndDate', localEndDate);
        handleValueChange(submissionVM.value.baseData);
    }, [getDefaultLocalStartEndDates, handleValueChange, submissionVM.value]);

    const setInitialDate = useCallback(() => {
        if (_.get(submissionVM.value, 'baseData.periodStartDate')) {
            return;
        }
        setStartEndDates();
    }, [setStartEndDates, submissionVM.value]);

    const automaticallySetEndDate = useCallback(
        (localStartDate) => {
            if (!localStartDate) return;
            const localEndDate = _.get(submissionVM.value, 'baseData.periodEndDate');
            const startDate = LocalDateUtil.toMidnightDate(localStartDate);
            const localNewEndDate = CustomLocalDateUtil.addDays(startDate, DAYS_IN_WEEK);
            if (localEndDate?.year !== localNewEndDate.year
                || localEndDate?.month !== localNewEndDate.month
                || localEndDate?.day !== localNewEndDate.day) {
                handleValueChange(localNewEndDate, 'baseData.periodEndDate');
                if (isCancellation) {
                    handleValueChange(localNewEndDate, 'lobData.travel.returnDate_Cnd');
                }
            }
        }, [submissionVM.value, isCancellation, handleValueChange]
    );

    const setDatesForCancellation = useCallback(() => {
        _.set(submissionVM.value, 'baseData.periodStartDate', LocalDateUtil.today());
        const { localStartDate, localEndDate } = getDefaultLocalStartEndDates();
        _.set(submissionVM.value, 'lobData.travel.departureDate_Cnd', localStartDate);
        _.set(submissionVM.value, 'lobData.travel.returnDate_Cnd', localEndDate);
        automaticallySetEndDate(localStartDate);
        handleValueChange(submissionVM.value);
    }, [
        automaticallySetEndDate, getDefaultLocalStartEndDates, handleValueChange, submissionVM.value
    ]);

    const getAvailableDestinationCountries = useCallback((date, offering) => {
        LoadSaveService.getAvailableDestinationCountries(effectiveProducerCode, date, country, offering)
            .then(setAvailableCountries);
    }, [LoadSaveService, country, effectiveProducerCode, setAvailableCountries]);

    const getAvailableTripZones = useCallback((date) => {
        LoadSaveService.getAvailableTripZones(effectiveProducerCode, country, date)
            .then(setAvailableTripZones);
    }, [LoadSaveService, country, effectiveProducerCode, setAvailableTripZones]);

    const updateAvailableCountriesOrZones = useCallback((startDateVM, virtualProductCode) => {
        if (!startDateVM
            || !startDateVM.value
            || !startDateVM.aspects.valid
            || !startDateVM.aspects.subtreeValid) {
                return;
        }
        const selectedOffering = _.get(submissionVM.value, 'baseData.offering_Cnd');
        if (selectedOffering === OfferingTypes.ANNUAL) {
            getAvailableTripZones(startDateVM.value);
        } else if ([OfferingTypes.SINGLE, OfferingTypes.CANCELLATION].includes(selectedOffering)) {
            const effectiveCode = virtualProductCode || _.get(submissionVM.value, 'baseData.virtualProductCode_Cnd');
            getAvailableDestinationCountries(startDateVM.value, effectiveCode);
        }
    }, [submissionVM.value, getAvailableTripZones, getAvailableDestinationCountries]);

    useEffect(() => {
        // cancel on current flow, save producer data
        const producerFromHistory = _.get(history, 'location.state.producer');
        if (producerFromHistory) {
            setProducerObjFromCancellation({...producerFromHistory});
        }
    }, [history]);

    const propagateProducerIntoSubmissionVM = (producerObject, subVM) => {
        if (producerObject) {
            if (producerObject.agentID) {
                _.set(subVM.value, 'agentReferenceData_Cnd', {...producerObject});
            } else {
                _.unset(subVM.value, 'agentReferenceData_Cnd');
            }
            if (producerObject.producerCode) {
                _.set(subVM.value, 'baseData.producerCodeOfRecord_Cnd', producerObject.producerCode);
            }
        }
    }

    const handleOffering = useCallback((props) => {
        const { offering, virtualProductCode, periodStartDateVM, departureDateVM } = props;

        _.set(submissionVM.value, 'baseData.offering_Cnd', offering);
        _.set(submissionVM.value, 'baseData.virtualProductCode_Cnd', virtualProductCode);

        switch (offering) {
            case OfferingTypes.SINGLE: 
                _.unset(submissionVM.value, 'lobData.travel.tripZone');
                setInitialDate();
                if (!isEmbeddedTripCancellationSelected) {
                    setStartEndDates();
                }
                updateAvailableCountriesOrZones(periodStartDateVM, virtualProductCode);
                break;
            case OfferingTypes.CANCELLATION: {
                _.unset(submissionVM.value, 'lobData.travel.tripZone');
                setDatesForCancellation();
                updateAvailableCountriesOrZones(departureDateVM, virtualProductCode);
                break;
            }
            case OfferingTypes.ANNUAL: {
                _.unset(submissionVM.value, 'baseData.periodEndDate');
                _.unset(submissionVM.value, 'lobData.travel.countries');
                setStartEndDates();
                updateAvailableCountriesOrZones(periodStartDateVM, virtualProductCode);
                break;
            }
            case OfferingTypes.DOMESTIC:
                _.unset(submissionVM.value, 'lobData.travel.countries');
                setStartEndDates();
                break;
            default:
                throw new Error(`Unknown offering: ${offering}`);
        }
    }, [submissionVM.value]);

    const handleAgeBands = useCallback((newSubmissionVM) => {
        const currentAgeBands = _.get(submissionVM, 'lobData.travel.ageBands.value');
        _.set(newSubmissionVM, 'lobData.travel.ageBands.value',
            availableAgeBands.map((band) => ({
                ...band,
                numberOfTravellers: currentAgeBands
                    ?.find((ab) => ab.ageBand === band.ageBand)?.numberOfTravellers || 0
            })));
    }, [submissionVM]);

    useEffect(() => {
        if (isNewQuote) {
            setIsLoading(true);

            const offeringFromHistory = _.get(history, 'location.state.offering');
            const productFromHistory = availableProducts.find((product) => product.offering_Cnd === offeringFromHistory);
            if (!isNewQuoteWithPrefilledData) {
                if (offeringFromHistory && productFromHistory) {
                    // Setting up product from query param
                    handleOffering({
                        offering: offeringFromHistory, 
                        virtualProductCode: productFromHistory.virtualProductCode_Ext,
                        departureDateVM: _.get(submissionVM, 'lobData.travel.departureDate_Cnd'),
                        periodStartDateVM: _.get(submissionVM, 'baseData.periodStartDate'),
                    });
                } else if (_.get(history, 'location.state.cancellationUpselling')) {
                    // Setting up cancellation from thank you page
                    const cancellationTripProduct = availableProducts
                        .find((product) => product.offering_Cnd === OfferingTypes.CANCELLATION);
    
                    handleOffering({
                        offering: OfferingTypes.CANCELLATION,
                        virtualProductCode: cancellationTripProduct.virtualProductCode_Ext,
                        departureDateVM: _.get(submissionVM, 'lobData.travel.departureDate_Cnd')
                    });
                } else if (!_.get(submissionVM.value, 'baseData.virtualProductCode_Cnd') ||
                        _.get(submissionVM.value, 'baseData.offering_Cnd') === OfferingTypes.SINGLE) {
                    const singleTripProduct = availableProducts
                        .find((product) => product.offering_Cnd === OfferingTypes.SINGLE);
    
                    handleOffering({
                        offering: OfferingTypes.SINGLE,
                        virtualProductCode: singleTripProduct.virtualProductCode_Ext,
                        periodStartDateVM: _.get(submissionVM, 'baseData.periodStartDate')
                    });
                } else if (_.get(submissionVM.value, 'baseData.offering_Cnd') === OfferingTypes.CANCELLATION) {
                    const cancellationTripProduct = availableProducts
                        .find((product) => product.offering_Cnd === OfferingTypes.CANCELLATION);
                    
                    handleOffering({
                        offering: OfferingTypes.CANCELLATION,
                        virtualProductCode: cancellationTripProduct.virtualProductCode_Ext,
                        departureDateVM: _.get(submissionVM, 'lobData.travel.departureDate_Cnd')
                    });
                } else {
                    const offering = _.get(submissionVM.value, 'baseData.offering_Cnd');
                    const virtualProduct = availableProducts
                        .find((product) => product.offering_Cnd === offering);
                    
                    handleOffering({
                        offering: offering,
                        virtualProductCode: virtualProduct.virtualProductCode_Ext,
                        periodStartDateVM: _.get(submissionVM, 'baseData.periodStartDate')
                    });
                }
            }

            const newSubmissionVM = _.clone(submissionVM);
            handleAgeBands(newSubmissionVM);
            propagateProducerIntoSubmissionVM(producerObjFromCancellation, newSubmissionVM);
            
            updateWizardData(newSubmissionVM);
            handleValueChange(submissionVM.value);

            LoadSaveService.getAvailableConsents(appCountry)
                .then((availableConsents) => {
                    const currentConsents = _.get(submissionVM, 'baseData.consents_Cnd.value', []);
                    const processedConsents = availableConsents.map((consent) => {
                        const currentConsent = currentConsents.find((c) => c.consentTypeID === consent.consentTypeID);
                        return {
                            ...consent,
                            selected: _.get(currentConsent, 'selected', false)
                        };
                    });
                    _.set(newSubmissionVM, 'baseData.consents_Cnd.value', processedConsents);
                    updateWizardData(newSubmissionVM);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (_.get(submissionVM.value, 'baseData.country_Cnd')) { return; }
        _.set(submissionVM.value, 'baseData.country_Cnd', country);
        handleValueChange(submissionVM.value);
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const embeddedCancellationValueStore = useRef();
    
    useEffect(() => {
        if (isNewQuoteWithPrefilledData) {
            if (!_.get(submissionVM.value, 'lobData.travel.departureDate_Cnd')) {
                _.set(submissionVM.value, 'lobData.travel.departureDate_Cnd', _.get(submissionVM.value, 'baseData.periodStartDate'))
            }
            if (!_.get(submissionVM.value, 'lobData.travel.returnDate_Cnd')) {
                _.set(submissionVM.value, 'lobData.travel.returnDate_Cnd', _.get(submissionVM.value, 'baseData.periodEndDate'))
            }
        }
    }, []);
    
    const handleEmbeddedCancellationToggle = useCallback((value) => {
        if (!value) {
            embeddedCancellationValueStore.current = {
                ticketPurchaseDate: _.get(submissionVM, 'lobData.travel.ticketPurchaseDate.value'),
                totalTripCost: _.get(submissionVM, 'lobData.travel.totalTripCost.value')
            };
            _.set(submissionVM, 'baseData.periodStartDate', submissionVM.lobData.travel.departureDate_Cnd);
            _.set(submissionVM, 'baseData.periodEndDate', submissionVM.lobData.travel.returnDate_Cnd);
            _.unset(submissionVM.value, 'lobData.travel.departureDate_Cnd');
            _.unset(submissionVM.value, 'lobData.travel.returnDate_Cnd');
            _.unset(submissionVM.value, 'lobData.travel.ticketPurchaseDate');
            _.unset(submissionVM.value, 'lobData.travel.totalTripCost');
        } else {
            if (!_.isNil(embeddedCancellationValueStore.current)) {
                const store = embeddedCancellationValueStore.current;
                _.set(submissionVM, 'lobData.travel.ticketPurchaseDate.value', store.ticketPurchaseDate);
                _.set(submissionVM, 'lobData.travel.totalTripCost.value', store.totalTripCost);
            }

            const { periodStartDate, periodEndDate } = submissionVM.baseData.value;
            _.set(submissionVM, 'lobData.travel.departureDate_Cnd.value', periodStartDate);
            _.set(submissionVM, 'lobData.travel.returnDate_Cnd.value', periodEndDate);
            const localCurrentDate = LocalDateUtil.today();
            _.set(submissionVM.value, 'baseData.periodStartDate', localCurrentDate);
        }
        setEmbeddedTripCancellation({...embeddedTripCancellation, selected: value, });
        _.set(submissionVM, 'lobData.travel.isEmbeddedTripCancellationSelected.value', value);
        updateWizardData(_.clone(submissionVM));
    }, [submissionVM, updateWizardData, setEmbeddedTripCancellation, embeddedTripCancellation]);

    const onOfferingChange = useCallback((virtualProductCode) => {
        const product = availableProducts
            .find((p) => p.virtualProductCode_Ext === virtualProductCode);

        const { offering_Cnd: selectedOffering } = product;
        const periodStartDateVM = _.get(submissionVM, 'baseData.periodStartDate');

        if (selectedOffering === OfferingTypes.SINGLE) {
            const filteredCountries = _.get(submissionVM.value, 'lobData.travel.countries', [])
                .filter((typecode) => typecode !== appCountry);
            _.set(submissionVM.value, 'lobData.travel.countries', filteredCountries);
        } else {
            handleEmbeddedCancellationToggle(false);
        }

        if (selectedOffering === OfferingTypes.CANCELLATION || isEmbeddedTripCancellationSelected) {
            _.unset(submissionVM.value, 'lobData.travel.tripZone');
            setDatesForCancellation();
        } else {
            _.unset(submissionVM.value, 'lobData.travel.totalTripCost');
            _.unset(submissionVM.value, 'lobData.travel.ticketPurchaseDate');
            _.unset(submissionVM.value, 'lobData.travel.departureDate_Cnd');
            _.unset(submissionVM.value, 'lobData.travel.returnDate_Cnd');
            setStartEndDates();
        }

        if (selectedOffering === OfferingTypes.ANNUAL) {
            _.unset(submissionVM.value, 'baseData.periodEndDate');
            _.unset(submissionVM.value, 'lobData.travel.countries');
            setSelectedCountries([]);
        } else {
            _.unset(submissionVM.value, 'lobData.travel.tripZone');
        }

        if (selectedOffering === OfferingTypes.DOMESTIC) {
            _.unset(submissionVM.value, 'lobData.travel.countries');
            setSelectedCountries([]);
        }

        _.set(submissionVM.value, 'baseData.offering_Cnd', selectedOffering);
        _.set(submissionVM.value, 'baseData.virtualProductCode_Cnd', virtualProductCode);
        updateAvailableCountriesOrZones(periodStartDateVM);
        handleValueChange(submissionVM.value);
    }, [
        submissionVM,
        isEmbeddedTripCancellationSelected,
        availableProducts,
        handleValueChange,
        updateAvailableCountriesOrZones,
        setDatesForCancellation,
        setStartEndDates,
        handleEmbeddedCancellationToggle,
        appCountry
    ]);

    const unsetCountriesAndTripZones = useCallback(() => {
        _.unset(submissionVM.value, 'lobData.travel.tripZone');
        _.unset(submissionVM.value, 'lobData.travel.countries');
        setSelectedCountries([]);
        handleValueChange(submissionVM.value);
    }, [handleValueChange, submissionVM.value]);
    
    const onCountryChange = (selectedValue, dispatch) => {
        switch (dispatch.action) {
            case 'clear':
                setSelectedCountries([]);
                handleValueChange(undefined, 'lobData.travel.countries');
                break;
            case 'select-option':
            case 'remove-value':
            case 'deselect-option': {
                if (selectedValue === null) {
                    setSelectedCountries([]);
                    handleValueChange(undefined, 'lobData.travel.countries');
                } else {
                    setSelectedCountries(selectedValue.map(
                        (selectedValueItem) => (selectedValueItem.value)));
                    handleValueChange(selectedValue.map(
                        (selectedValueItem) => (selectedValueItem.value)),
                        'lobData.travel.countries');
                }
                break;
            }
            default:
                console.log(`Missing action: ${dispatch.action}`);
        }
    }    
    const onTransportationChange = (selectedValue, dispatch) => {
        switch (dispatch.action) {
            case 'clear':
                setTransportation([]);
                break;
            case 'select-option':
            case 'remove-value':
            case 'deselect-option': {
                if (selectedValue === null) {
                    setTransportation([]);
                } else {
                    setTransportation(selectedValue.map(
                        (selectedValueItem) => (selectedValueItem.value)));
                }
                break;
            }
            default:
                console.log(`Missing action: ${dispatch.action}`);
        }
    }
    const onTripPurposeChange = (selectedValue, dispatch) => {
        switch (dispatch.action) {
            case 'clear':
                setTripPurpose([]);
                break;
            case 'select-option':
            case 'remove-value':
            case 'deselect-option': {
                if (selectedValue === null) {
                    setTripPurpose([]);
                } else {
                    setTripPurpose(selectedValue.map(
                        (selectedValueItem) => (selectedValueItem.value)));
                }
                break;
            }
            default:
                console.log(`Missing action: ${dispatch.action}`);
        }
    }

    const isAtLeastOneCountrySelected = useMemo(() => {
        const countries = _.get(submissionVM.value, 'lobData.travel.countries');
        return !!countries;
    }, [submissionVM.value]);

    useEffect(() => {
        if (isEmbeddedTripCancellationSelected && isNewQuoteWithPrefilledData) {
            _.set(submissionVM, 'lobData.travel.isEmbeddedTripCancellationSelected.value', embeddedTripCancellation?.selected)
        }
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isNewQuote) {
            return;
        }
        if (arePreliminaryQuestionsVisible) {
            const isCarAssistanceNeeded = transportation.findIndex((t) => t === TRANSPORTATION_CODES.AUTO) > -1;
            if (!assistanceChangedManually) {
                _.set(submissionVM.value, "lobData.travel.isCarAssistanceNeeded", isCarAssistanceNeeded);
                handleValueChange(submissionVM.value.lobData);
            } else if (!isCarAssistanceNeeded) {
                setAssistanceChangedManually(false);
            }
        } else {
            _.unset(submissionVM.value, "lobData.travel.isCarAssistanceNeeded");
            handleValueChange(submissionVM.value.lobData);
        }
    }, [arePreliminaryQuestionsVisible, transportation]);

    const resolvers = {
        resolveComponentMap: {
            agebands: AgeBands,
            consents: Consents
        },
        resolveCallbackMap: {
            onValueChange: handleValueChange
        },
        resolveClassNameMap: styles
    };

    const isTotalTravellersEmpty = () => !(_.get(submissionVM.value, 'lobData.travel.ageBands') || []).reduce((acc, curr) => acc + Number(curr.numberOfTravellers), 0);

    const resetValidationAndHandleChange = useCallback((value, path) => {
        resetValidationError(path);
        handleValueChange(value, path);
        onValidate(submissionVM.value);
    }, [submissionVM, handleValueChange, resetValidationError, onValidate]);

    const getDiscountCodeValidationError = useCallback(() => {
        const validationMessages = filterValidationErrors('baseData.discountCode_Cnd.messages');
        return _.map(validationMessages, (validationMessage) => {
            switch (validationMessage) {
                case DISCOUNT_NOT_FOUND_MESSAGE:
                    return translator(messages.tripDetailsPageInvalidDiscountCode);
                case EXPIRED_DISCOUNT_MESSAGE:
                    return translator(messages.tripDetailsPageExpireddDiscountCode);
                case DISCOUNT_NOT_ACTIVE_MESSAGE:
                    return translator(messages.tripDetailsPageNotActiveDiscountCode);
                default:
                    return validationMessage;
            }
        });
    }, [filterValidationErrors, translator]);

    const getTotalTripCostValidationError = useCallback(() => {
        const errorMessages = filterValidationErrors('lobData.travel.totalTripCost')?.messages;
        if (_.isNil(errorMessages)) return;
        return errorMessages
            .map((msg) => CurrencyFormatterService.formatMessage(msg, appCountry), isEmbeddedTripCancellationSelected);
    }, [appCountry, filterValidationErrors, isEmbeddedTripCancellationSelected]);

    const isCountriesFieldDisabled = useCallback(() => {
        return (isCancellation
            && (
                _.isNil(_.get(submissionVM.value, 'lobData.travel.departureDate_Cnd'))
                || _.isNil(_.get(submissionVM.value, 'lobData.travel.returnDate_Cnd'))
                || !submissionVM.lobData.travel.departureDate_Cnd.aspects.valid
                || !submissionVM.lobData.travel.departureDate_Cnd.aspects.subtreeValid
                || !submissionVM.lobData.travel.returnDate_Cnd.aspects.valid
                || !submissionVM.lobData.travel.returnDate_Cnd.aspects.subtreeValid
            )
        ) || (!isCancellation
            && (
                _.isNil(_.get(submissionVM.value, 'baseData.periodStartDate'))
                || _.isNil(_.get(submissionVM.value, 'baseData.periodEndDate'))
                || !submissionVM.baseData.periodStartDate.aspects.valid
                || !submissionVM.baseData.periodStartDate.aspects.subtreeValid
                || !submissionVM.baseData.periodEndDate.aspects.valid
                || !submissionVM.baseData.periodEndDate.aspects.subtreeValid
            )
        );
    }, [isCancellation,
        submissionVM.baseData.periodEndDate.aspects.subtreeValid,
        submissionVM.baseData.periodEndDate.aspects.valid,
        submissionVM.baseData.periodStartDate.aspects.subtreeValid,
        submissionVM.baseData.periodStartDate.aspects.valid,
        submissionVM.lobData.travel.departureDate_Cnd.aspects.subtreeValid,
        submissionVM.lobData.travel.departureDate_Cnd.aspects.valid,
        submissionVM.lobData.travel.returnDate_Cnd.aspects.subtreeValid,
        submissionVM.lobData.travel.returnDate_Cnd.aspects.valid,
        submissionVM.value
    ]);

    const isZoneFieldDisabled = useMemo(() => {
        return offering === OfferingTypes.ANNUAL
            && (
                _.isNil(_.get(submissionVM.baseData.value, 'periodStartDate'))
                || !submissionVM.baseData.periodStartDate.aspects.valid
                || !submissionVM.baseData.periodStartDate.aspects.subtreeValid
            );
    }, [
        offering,
        submissionVM.baseData.periodStartDate.aspects.subtreeValid,
        submissionVM.baseData.periodStartDate.aspects.valid,
        submissionVM.baseData.value,
    ]);

    const hasErrors = (path) => {
        const validationErrors = filterValidationErrors(path)?.messages;
        if (validationErrors?.length > 0) {
            return true;
        }
        const model = _.get(submissionVM, path);
        if (_.isNil(model.value)) {
            return false;
        }
        return model.aspects.validationMessages.length > 0;
    };

    const getValidationMessages = (path) => {
        const validationErrors = filterValidationErrors(path)?.messages;
        if (validationErrors?.length > 0) {
            return validationErrors;
        }
        return _.get(submissionVM, `${path}.aspects.validationMessages`);
    };

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left',
            readOnly: !isNewQuote,
            showErrors: isNewQuoteWithPrefilledData || !isNewQuote
        },
        tripDetailsPageEffectiveDate: {
            visible: !isCancellation,
            label:
                offering !== OfferingTypes.ANNUAL
                    ? messages.tripDetailsPageStartDayOfTheyJourney
                    : messages.tripDetailsPageStartingDateOfValidity,
            onBlur: () => {
                if (offering !== OfferingTypes.ANNUAL) {
                    automaticallySetEndDate(_.get(submissionVM.value, 'baseData.periodStartDate'));
                }
                updateAvailableCountriesOrZones(_.get(submissionVM, 'baseData.periodStartDate'));
            },
            onValueChange: (date) => {
                unsetCountriesAndTripZones();
                handleValueChange(date, 'baseData.periodStartDate');
            }
        },
        tripDetailsPageDepartureDate: {
            visible: isCancellation,
            label: messages.tripDetailsPageStartDayOfTheyJourney,
            value: _.get(submissionVM.value, 'baseData.departureDate_Cnd'),
            onBlur: () => {
                automaticallySetEndDate(_.get(submissionVM.value, 'lobData.travel.departureDate_Cnd'));
                updateAvailableCountriesOrZones(_.get(submissionVM, 'lobData.travel.departureDate_Cnd'));
            },
            onValueChange: (date) => {
                unsetCountriesAndTripZones();
                handleValueChange(LocalDateUtil.today(), 'lobData.travel.periodStartDate');
                handleValueChange(date, 'lobData.travel.departureDate_Cnd');
            }
        },
        tripDetailsPageReturnDate: {
            value: _.get(submissionVM.value, 'baseData.periodEndDate'),
        },
        tripDetailsPageOffering: {
            availableValues: availableProducts.map((product) => ({
                name: product.productName,
                code: product.virtualProductCode_Ext
            })),
            value: _.get(submissionVM.value, 'baseData.virtualProductCode_Cnd'),
            onValueChange: onOfferingChange
        },
        tripDetailsPageCountries: {
            options: availableCountries
                ?.filter((typecode) => typecode !== appCountry || isDomesticCancellationAllowed)
                .map((typecode) => (
                    {
                        value: typecode,
                        label: translator({
                            id: `typekey.Country.${typecode}`,
                            defaultMessage: typecode
                        })
                    })),
            visible:
                offering !== OfferingTypes.ANNUAL &&
                offering !== OfferingTypes.DOMESTIC,
            tooltip: {
                text: !isNewQuote ? '' : translator(messages.tripCountriesTooltip)
            },
            onlyShowValues: !isNewQuote,
            value: selectedCountries?.map(typecode => ({
                'value': typecode,
                label: translator({
                    id: `typekey.Country.${typecode}`,
                    defaultMessage: typecode
                })
            })),
            defaultValue: _.get(submissionVM.value, 'lobData.travel.countries')?.map((typecode) => (
                    {
                        value: typecode,
                        label: translator({
                            id: `typekey.Country.${typecode}`,
                            defaultMessage: typecode
                        })
                    }
                )),
            onChange: onCountryChange,
            disabled: isCountriesFieldDisabled()
        },
        tripDetailsPageFurtherDetails: {
            visible: arePreliminaryQuestionsVisible
        },
        tripDetailsPageFurtherDetailsInfo: {
            visible: arePreliminaryQuestionsVisible
        },
        tripDetailsPageTripPurpose: {
            options: PreliminaryQuestions.tripPurpose.map(purpose => ({
                value: purpose.code,
                label: translator(purpose.label)
            })),
            visible: arePreliminaryQuestionsVisible,
            onlyShowValues: !isNewQuote,
            value: tripPurpose?.map(tripPurposeCode => {
                const option = PreliminaryQuestions.tripPurpose.find((t) => t.code === tripPurposeCode);
            
                return {
                    value: tripPurposeCode,
                    label: option? translator(option.label) : ''
                }
            }),
            defaultValue: tripPurpose?.map(tripPurposeCode => {
                const option = PreliminaryQuestions.tripPurpose.find((t) => t.code === tripPurposeCode);
            
                return {
                    value: tripPurposeCode,
                    label: option? translator(option.label) : ''
                }
            }),
            onChange: onTripPurposeChange,
            required: arePreliminaryQuestionsVisible
        },
        tripDetailsPageTransportation: {
            options: PreliminaryQuestions.transportation.map(purpose => ({
                value: purpose.code,
                label: translator(purpose.label)
            })),
            visible: arePreliminaryQuestionsVisible,
            onlyShowValues: !isNewQuote,
            value: transportation?.map((transportationCode) => {
                const option = PreliminaryQuestions.transportation.find((t) => t.code === transportationCode);
            
                return {
                    value: transportationCode,
                    label: option? translator(option.label) : ''
                }
            }),
            defaultValue: transportation?.map((transportationCode) => {
                const option = PreliminaryQuestions.transportation.find((t) => t.code === transportationCode);
            
                return {
                    value: transportationCode,
                    label: option? translator(option.label) : ''
                }
            }),
            onChange: onTransportationChange,
            required: arePreliminaryQuestionsVisible
        },
        tripDetailsVehicleData:{
            visible: arePreliminaryQuestionsVisible
                && transportation.findIndex((t) => t === TRANSPORTATION_CODES.AUTO) > -1
        },
        tripDetailsIsAssistanceNeeded:{
            required: arePreliminaryQuestionsVisible
                && transportation.findIndex((t) => t === TRANSPORTATION_CODES.AUTO) > -1,
            onValueChange: (value, path) => {
                setAssistanceChangedManually(true);
                _.unset(submissionVM.value, 'lobData.travel.vehicleProductionYear');
                handleValueChange(value, path);
            }
        },
        tripDetailsVehicleProductionYear:{
            mask: '9999',
            formatChars: MasksUtil.getMaskFormatCharacters(),
            visible: _.get(submissionVM.value, "lobData.travel.isCarAssistanceNeeded"),
        },
        tripDetailsPageAgeBandsInfo: {
            visible: isNewQuote
        },
        tripDetailsPageTripZone: {
            availableValues: availableTripZones
                .filter((typecode) => typecode !== DOMESTIC_CODE)
                .sort()
                .map((typecode) => ({
                    code: typecode,
                    name: translator({
                        id: `typekey.TripZone_Cnd.${typecode}`,
                        defaultMessage: typecode
                    })
                })),
            visible: offering === OfferingTypes.ANNUAL,
            disabled: isZoneFieldDisabled
        },
        tripDetailsPagePurchaseDate: {
            visible: isCancellation,
            value: isNewQuote ? _.get(submissionVM.value, 'lobData.travel.ticketPurchaseDate') : embeddedTripCancellation?.ticketPurchaseDate,
            showErrors: hasErrors('lobData.travel.ticketPurchaseDate'),
            validationMessages: getValidationMessages('lobData.travel.ticketPurchaseDate'),
            onValueChange: resetValidationAndHandleChange
        },
        tripDetailsPageTotalCost: {
            visible: isCancellation,
            value: isNewQuote ? _.get(submissionVM.value, 'lobData.travel.totalTripCost') : embeddedTripCancellation?.totalTripCost,
            defaultCurrency: currency,
            onValueChange: resetValidationAndHandleChange,
            showErrors: hasErrors('lobData.travel.totalTripCost'),
            validationMessages: getTotalTripCostValidationError()
                || submissionVM.lobData.travel.totalTripCost.aspects.validationMessages,
            label: isEmbeddedTripCancellationSelected
                ? translator(messages.tripDetailsPageTotalCostETC)
                : translator(messages.tripDetailsPageTotalCost),
            tooltip: {
                text: isEmbeddedTripCancellationSelected
                    ? translator(messages.tripDetailsPageTotalCostETCTooltip)
                    : translator(messages.tripDetailsPageTotalCostTooltip)
            }
        },
        tripDetailsPageAgeBands: {
            visible: _.get(submissionVM, 'lobData.travel.ageBands') !== undefined,
            value: _.get(submissionVM, 'lobData.travel.ageBands'),
            onValueChange: (value, path) => {
                handleValueChange(value, path);
                resetValidationError('lobData.travel.totalTripCost');
            },
            onValidate,
            path: 'lobData.travel.ageBands',
            readOnly: !isNewQuote
        },
        tripDetailsDocumentLinks: {
            staticOnly: true,
            model: submissionVM
        },
        tripDetailsPageEndDate: {
            visible: ![OfferingTypes.ANNUAL].includes(offering) && !isCancellation,
            onValueChange: (date) => {
                unsetCountriesAndTripZones();
                handleValueChange(date, 'baseData.periodEndDate');
            }
        },
        tripDetailsPageReturnDate: {
            visible: isCancellation,
            onValueChange: (date) => {
                unsetCountriesAndTripZones();
                handleValueChange(date, 'lobData.travel.returnDate_Cnd');
                handleValueChange(date, 'baseData.periodEndDate');
            }
        },
        tripDetailsPageDiscountCodeHeader: {
            visible: !partnerDiscountSupplied,
        },
        tripDetailsPageDiscountCode: {
            visible: !partnerDiscountSupplied,
            showErrors: !_.isNil(filterValidationErrors('baseData.discountCode_Cnd')),
            validationMessages: getDiscountCodeValidationError(),
            onValueChange: resetValidationAndHandleChange
        },
        tripDetailsPageInformationHeader: {
            visible: StaticInformationService.isVisibleOnTripDetailsPage(appCountry)
        },
        tripDetailsPageStaticInformation: {
            step: 'tripDetailsPageStep',
            country: country,
            staticInformationData: StaticInformationData
        },
        tripDetailsPageConsents: {
            visible: _.get(submissionVM, 'baseData.consents_Cnd') !== undefined,
            value: _.get(submissionVM, 'baseData.consents_Cnd'),
            onValidate,
            path: 'baseData.consents_Cnd',
            readOnly: !isNewQuote,
            locationFilter: 'qb_step_1'
        },
        tripDetailsEmbeddedCancellationCover: {
            required: true,
            value: isEmbeddedTripCancellationSelected,
            visible:
                [OfferingTypes.SINGLE].includes(offering)
                && _.get(submissionVM.value, 'baseData.virtualProductCode_Cnd') !== 'tvi_ins_bub_sk'
                && CountryLayerService.isEmbeddedTripCancellationAvailable(appCountry),
            onValueChange: handleEmbeddedCancellationToggle
        }
    };

    const onEditQuote = useCallback(() => {
        return ModalNextProvider.showConfirm({
            title: translator(messages.tripDetailsEditQuotePopupTitle),
            message: translator(messages.tripDetailsEditQuotePopupMessage),
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.yes,
            cancelButtonText: commonMessages.close
        }).then((results) => {
            if (results === 'cancel') {
                return _.noop();
            }
            const newSubmissionVM = mapQuoteReferenceWithTripAndClientDetails(submissionVM);
            updateWizardData(newSubmissionVM);
            setIsNewQuoteWithPrefilledData(true);
            setIsFromQuoteRetrieval(false);
            jumpTo(0);
            history.push({
                pathname: '/quote-tvl',
                state: {
                    allowWizardExit: true
                }
            });
            return newSubmissionVM;
        }, _.noop);
    }, [
        submissionVM,
        history,
        jumpTo,
        setIsNewQuoteWithPrefilledData,
        updateWizardData,
        mapQuoteReferenceWithTripAndClientDetails,
        translator
    ]);
    
    const onNewQuote = useCallback(() => {
        submissionVM.value = new Submission();
        propagateProducerIntoSubmissionVM(producerObjFromCancellation, submissionVM);

        updateWizardData(submissionVM);
        setIsFromQuoteRetrieval(false);
        setEmbeddedTripCancellation({ selected: false });

        return history.push({
            pathname: '/quote-tvl',
            state: {
                allowWizardExit: true,
                producer: producerObjFromCancellation
            }
        });
    }, [
        submissionVM,
        history,
        producerObjFromCancellation,
        setIsFromQuoteRetrieval,
        setEmbeddedTripCancellation,
        updateWizardData
    ]);

    const disableNext = useMemo(() => {
        if (!offering) {
            return true;
        }

        if ([OfferingTypes.SINGLE, OfferingTypes.CANCELLATION].includes(offering)) {
            return !isComponentValid || isTotalTravellersEmpty() || !isAtLeastOneCountrySelected;
        }

        return !isComponentValid || isTotalTravellersEmpty()
    }, [isComponentValid, isTotalTravellersEmpty, isAtLeastOneCountrySelected, offering])

    return isLoading ? (
        <Loader loaded={!isLoading} />
    ) : (
        <WizardPage
            onNext={onNext}
            disableNext={disableNext}
            showPrevious={false}
            showCancel={false}
            showNewQuote={!isNewQuote}
            newQuoteLabel={messages.tripDetailsNewQuote}
            onNewQuote={onNewQuote}
            showEditQuote={!isNewQuote}
            onEditQuote={onEditQuote}
            editQuoteLabel={messages.tripDetailsEditQuote}
            disclaimerTextCnd={
                CountryLayerService.showExtraDisclaimers(country?.toLocaleUpperCase())
                    && translator(messages.tripDetailsDisclaimerText)
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

TripDetailsPage.propTypes = wizardProps;
export default TripDetailsPage;
