import { defineMessages } from 'react-intl';

export default defineMessages({
    tvlSuccessfulPaymentPageTitle: {
        id: 'quoteandbind.tvl.pages.successful-page.Payment Successful',
        defaultMessage: 'Payment Successful',
    },
    tvlSuccessfulPaymentPageSuccessMessage: {
        id: 'quoteandbind.tvl.pages.successful-page.Your new policy has been approved.',
        defaultMessage:
            'Your new policy has been approved.',
    },
    tvlSuccessfulPaymentPagePolicySummary: {
        id: 'quoteandbind.tvl.pages.successful-page.Policy Summary',
        defaultMessage: 'Policy Summary',
    },
    tvlSuccessfulPaymentPagePolicyNumber: {
        id: 'quoteandbind.tvl.pages.successful-page.Policy Number',
        defaultMessage: 'Policy Number',
    },
    tvlSuccessfulPaymentPagePolicyPeriod: {
        id: 'quoteandbind.tvl.pages.successful-page.Policy Period',
        defaultMessage: 'Policy Period',
    },
    tvlSuccessfulPaymentPagePolicyTotalAmount: {
        id: 'quoteandbind.tvl.pages.successful-page.Policy Total Amount',
        defaultMessage: 'Policy Total Amount',
    },
    tvlSuccessfulPaymentPageOffering: {
        id: 'quoteandbind.tvl.pages.successful-page.Offering',
        defaultMessage: 'Offering',
    },
    tvlSuccessfulPaymentPageDestination: {
        id: 'quoteandbind.tvl.pages.successful-page.Destination',
        defaultMessage: 'Destination',
    },
    tvlSuccessfulPaymentPageTripZone: {
        id: 'quoteandbind.tvl.pages.successful-page.Trip Zone',
        defaultMessage: 'Trip Zone',
    },
    tvlSuccessfulPaymentPageDatesOfTravel: {
        id: 'quoteandbind.tvl.pages.successful-page.Dates of travel',
        defaultMessage: 'Dates of travel',
    },
    tvlSuccessfulPaymentPageTripPurchaseDate: {
        id: 'quoteandbind.tvl.pages.successful-page.Ticket / Trip purchase date',
        defaultMessage: 'Ticket / Trip purchase date',
    },
    tvlSuccessfulPaymentPageTripTicketCost: {
        id: 'quoteandbind.tvl.pages.successful-page.Total cost of tickets / trip',
        defaultMessage: 'Total cost of tickets / trip',
    },
    tvlSuccessfulPaymentPageNumberOfTravelers: {
        id: 'quoteandbind.tvl.pages.successful-page.No of travelers',
        defaultMessage: 'No of travelers',
    },
    tvlSuccessfulPaymentPageInsuranceVariant: {
        id: 'quoteandbind.tvl.pages.successful-page.quote-info-box.Variant',
        defaultMessage: 'Variant',
    },
    tvlSuccessfulPaymentPageProductType: {
        id: 'quoteandbind.tvl.pages.successful-page.ProductType',
        defaultMessage: 'Product Type',
    },
    tvlSuccessfulPaymentPageAdditionalCovers: {
        id: 'quoteandbind.tvl.pages.successful-page.AdditionalCovers',
        defaultMessage: 'Additional covers',
    },
    tvlSuccessfulPaymentPageYourPassTitle: {
        id: 'quoteandbind.tvl.pages.successful-page.Digital Assitance Card',
        defaultMessage: 'Digital Assitance Card',
    },
    tvlSuccessfulPaymentPageYourPassMessage: {
        id: 'quoteandbind.tvl.pages.successful-page.We have issued a digital assistance card for your insurance policy, containing all the important information about your insurance, contact details of the Assistance Center and other useful links for travellers.',
        defaultMessage: 'We have issued a digital assistance card for your insurance policy, containing all the important information about your insurance, contact details of the Assistance Center and other useful links for travellers.'
    },
    tvlSuccessfulPaymentPageYourPassCallToDownloadMessage: {
        id: 'quoteandbind.tvl.pages.successful-page.Please download the card to your phone. This way you will always have the most important information at your hand.',
        defaultMessage: 'Please download the card to your phone. This way you will always have the most important information at your hand.'
    },
    tvlSuccessfulPaymentPagePolicyDocumentsTitle: {
        id: 'quoteandbind.tvl.pages.successful-page.Policy Documents',
        defaultMessage: 'Policy Documents',
    },
    tvlSuccessfulPaymentPageBuyAnotherPolicyTitle: {
        id: 'quoteandbind.tvl.pages.successful-page.Buy another policy',
        defaultMessage: 'Buy another policy'
    },
    tvlSuccessfulPaymentBuyAnotherPolicyMessage: {
        id: 'quoteandbind.tvl.pages.successful-page.Buy another policy hint',
        defaultMessage: 'Please, think about adding a travel cancellation to prevent loss in case of trip cancellation due to any unexpected event or situation.'
    },
    tvlSuccessfulPaymentBuyCancellation: {
        id: 'quoteandbind.tvl.pages.successful-page.Click here to buy cancellation policy',
        defaultMessage: 'Click here to buy cancellation policy'
    }
});
