import { defineMessages } from 'react-intl';

export default defineMessages({
    etcComponentFormHeader: {
        id: 'quoteandbind.tvl.components.embeddedTripCancellationHeader.Trip Cancellation Cover',
        defaultMessage: 'Trip Cancellation Cover'
    },
    etcStartDate: {
        id: 'quoteandbind.tvl.components.embeddedTripCancellation.ETC Start Date',
        defaultMessage: 'ETC Start Date'
    },
    etcEndDate: {
        id: 'quoteandbind.tvl.components.embeddedTripCancellation.ETC End Date',
        defaultMessage: 'ETC End Date'
    }
});
