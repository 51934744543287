import { AgeUtil } from 'gw-portals-util-js';
import messages from './messages/PreliminaryQuestions.messages';

export const TRIP_DETAILS_CODES = {
    HOLIDAY_ABROAD: "travel_34",
    BUSINESS_TRIP: "travel_4",
    MANUAL_WORK: "commercial_20",
    WINTER_SPORTS: "sport_5",
    EXTREME_SPORTS: "sport_1",
    OTHER: "other"
}
export const TRANSPORTATION_CODES = {
    AUTO: "transportation_2",
    PLANE: "transportation_6",
    OTHER: "transportation_21"
}

export const isVehicleApplicable = (productionYear) => {
    return AgeUtil.isAgeLessOrEqual(productionYear, 15) && AgeUtil.isAgeNonNegative(productionYear);
}

const PreliminaryQuestions = {
    tripPurpose: [
        {
            label: messages.tripPurposeHolidayAbroad,
            code: TRIP_DETAILS_CODES.HOLIDAY_ABROAD
        },
        {
            label: messages.tripPurposeBusinessTrip,
            code: TRIP_DETAILS_CODES.BUSINESS_TRIP
        },
        {
            label: messages.tripPurposeWinterSports,
            code: TRIP_DETAILS_CODES.WINTER_SPORTS
        },
        {
            label: messages.tripPurposeExtremeSports,
            code: TRIP_DETAILS_CODES.EXTREME_SPORTS
        },
        {
            label: messages.tripPurposeOther,
            code: TRIP_DETAILS_CODES.OTHER
        }
    ],
    transportation: [
        {
            label: messages.transportationCar,
            code: TRANSPORTATION_CODES.AUTO
        },
        {
            label: messages.transportationPlane,
            code: TRANSPORTATION_CODES.PLANE
        },
        {
            label: messages.transportationOther,
            code: TRANSPORTATION_CODES.OTHER
        }
    ]
};

export default PreliminaryQuestions;
