import _ from 'lodash';
import { useContext, useMemo, useCallback } from 'react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { ServiceManager } from '@jutro/services';
import { CountryLayerService } from 'cnd-portals-util-js';
import { valueToDate, formatDate, getFnsLocale } from 'cnd-common-components-platform-react';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';

import metadata from './InsuredPersonsSummary.metadata.json5';
import './InsuredPersonsSummary.messages';
import styles from './InsuredPersonsSummary.module.scss';

function InsuredPersonsSummary(props) {
    const { value } = props;
    const breakpoint = useContext(BreakpointTrackerContext);

    const localeService = ServiceManager.getService('locale');
    const locale = localeService.getStoredLocale();
    const fnsLocale = useMemo(() => getFnsLocale(locale), [locale]);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    const {
        format: countryFormat,
        timeFormat: countryTimeFormat = '',
        separator: timeSeparator = '',
    } = CountryLayerService.getDateFormats(appCountry);

    const dateFormat = `${countryFormat}${timeSeparator}${countryTimeFormat}`;

    const dateOfBirthFormatter = useCallback((dateOfBirth) => {
        const dateValue = valueToDate(dateOfBirth, false);
        return formatDate(dateValue, dateFormat, fnsLocale);
    }, [dateFormat, fnsLocale]);

    const insuredPersons = useMemo(() => {
        return _.map(value, (person) => {
            return {
                ...person,
                contact: {
                    ...person.contact,
                    dateOfBirth: dateOfBirthFormatter(person?.contact?.dateOfBirth)
                }

            };
        });
    }, [value, dateOfBirthFormatter]);

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            readOnly: true
        },
        insuredPersons: {
            data: insuredPersons,
            className: styles.insuredPersonsTable, // Cnd
        }
    };

    const resolvers = {

    };

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

export default InsuredPersonsSummary;
