import { defineMessages } from 'react-intl';

export default defineMessages({
    insuredPersonsFirstName: {
        id: 'quoteandbind.tvl.components.insured-persons.First Name',
        defaultMessage: 'First Name'
    },
    insuredPersonsLastName: {
        id: 'quoteandbind.tvl.components.insured-persons.Last Name',
        defaultMessage: 'Last Name'
    },
    insuredPersonsBirthDate: {
        id: 'quoteandbind.tvl.components.insured-persons.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    insuredPersonsPasteDataInfo: {
        id: 'quoteandbind.tvl.components.insured-persons.If the insurance protects many insureds, you can use the copying functionality to transfer data instead of providing them manually',
        defaultMessage: 'If the insurance protects many insureds, you can use the copying functionality to transfer data instead of providing them manually'
    },
    insuredPersonsPasteFromClipboardButton: {
        id: 'quoteandbind.tvl.components.insured-persons.Copy insureds\' data from the external source (format of the copying table has to reflect below table layout with proper date format)',
        defaultMessage: 'Copy insureds\' data from the external source (format of the copying table has to reflect below table layout with proper date format)'
    },
    insuredPersonsClearInputsButton: {
        id: 'quoteandbind.tvl.components.insured-persons.Clear all inputs',
        defaultMessage: 'Clear all inputs'
    },
    insuredPersonsPasteInvalid: {
        id: 'quoteandbind.tvl.components.insured-persons.You are trying to copy more insureds than available to add to the policy',
        defaultMessage: 'You are trying to copy more insureds than available to add to the policy'
    }

});
