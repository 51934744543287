const GTM_IDS = {
    CZ: 'GTM-TTMP53Z',
    SK: 'GTM-5CFKZ5S',
    PL: 'GTM-P69VXZ2',
    HU: 'GTM-NTPWFSR',
    BG: 'GTM-P96C532',
    RO: 'GTM-MG2J7HM'
};

const STEPS = {
    BASIC_INFORMATION: 'basicInformation',
    PACKAGE_SELECTION: 'packageSelection',
    PERSONAL_DATA: 'personalData',
    QUOTE_CONFIRMATION: 'quoteConfirmation'
};

const BUTTON_ACTION = {
    NEXT: 'next',
    PROCEED_PAYMENT: 'proceed-payment'
};

export default {
    BUTTON_ACTION,
    GTM_IDS,
    STEPS
};
