import _ from 'lodash';
import React, {
    useEffect, useCallback, useContext, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { MasksUtil } from 'cnd-common-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { ContactDataService } from 'cnd-portals-util-js';
import { LOBContext } from 'cnd-common-components-platform-react';
import EditabilityService from '../../services/EditabilityService';
import Address from '../Address/Address';
import metadata from './Company.metadata.json5';
import metadataSummary from '../../pages/InsuranceSummaryPage/policyholder/CompanySummary.metadata.json5';
import SummaryContext from '../summary/SummaryContext';
import messages from './Company.messages';
import { INVALID_PHONE_MESSAGE, INVALID_EMAIL_MESSAGE } from '../../constants/ErrorCodes';

function Company(props) {
    const {
        id,
        onValidate,
        value: companyVM,
        onValueChange,
        path,
        isEmailPhoneInfoMessageVisible,
        isPersonalIDVisible,
        isEmailNotificationInfoVisible,
        showErrors,
        filterValidationErrors
    } = props;

    const translator = useContext(TranslatorContext);
    const { isFromQuoteRetrieval, quoteRetrievalClone } = useContext(LOBContext);
    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);
    const companyClone = _.get(quoteRetrievalClone, 'baseData.accountHolder');
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    const companyID = _.get(companyVM, 'companyID_Cnd.value');
    const registrationIDMask = ContactDataService.getRegistrationIDMask(appCountry);
    const registrationId = useMemo(() => {
        if (!readOnly) {
            const maskSegments = registrationIDMask?.split('/');
            if (maskSegments.length > 0) {
                const valueSegments = companyID?.split('/') ?? [];
                if (valueSegments.length === maskSegments.length) {
                    return valueSegments.map((val, index) => {
                        const mask = maskSegments[index];
                        return _.padEnd(val, mask.length, '_');
                    }).join('/');
                }
            }
        }
        return companyID;
    }, [readOnly, companyID, registrationIDMask]);

    const getFormValidity = useCallback(() => {
        return companyVM.aspects.valid && companyVM.aspects.subtreeValid;
    }, [companyVM]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [getFormValidity, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }

            if (changedPath.startsWith('primaryAddress.country')) {
                if (_.isEmpty(_.get(companyVM, 'phone_Cnd.number.value'))) {
                    const phoneCountryCode = _.get(companyVM, 'phone_Cnd.countryCode.aspects.availableValues')?.find(
                        (o) => o.code === value || o.code === `${value}_Cnd`
                    );
                    if (phoneCountryCode) {
                        _.set(companyVM, 'phone_Cnd.countryCode', phoneCountryCode);
                    }
                }
            }
        },
        [onValueChange, path, companyVM]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        companyName: {
            readOnly: readOnly || EditabilityService.isReadOnly(companyVM, 'contactName', isFromQuoteRetrieval, companyClone)
        },
        companyRegistrationID: {
            mask: registrationIDMask,
            formatChars: MasksUtil.getMaskFormatCharacters(),
            readOnly: readOnly || EditabilityService.isReadOnly(companyVM, 'companyID_Cnd', isFromQuoteRetrieval, companyClone),
            value: registrationId,
            visible: readOnly ? !_.isNil(registrationId) : true,
        },
        companyIsVATPayer: {
            readOnly: readOnly || EditabilityService.isReadOnly(companyVM, 'vatPayer_Cnd', isFromQuoteRetrieval, companyClone)
        },
        companyTaxID: {
            mask: ContactDataService.getTaxIDMask(appCountry),
            formatChars: MasksUtil.getMaskFormatCharacters(),
            readOnly: readOnly || EditabilityService.isReadOnly(companyVM, 'taxID_Cnd', isFromQuoteRetrieval, companyClone),
            visible: !readOnly || (readOnly && _.get(companyVM, 'taxID_Cnd.value') !== undefined),
        },
        companyPhone: {
            visible: !readOnly || (readOnly && _.get(companyVM, 'phone_Cnd.number.value') != null),
            readOnly: readOnly || EditabilityService.isReadOnly(companyVM, 'phone_Cnd.number', isFromQuoteRetrieval, companyClone),
            showErrors: filterValidationErrors && !_.isNil(filterValidationErrors('baseData.accountHolder.phone_Cnd')),
            validationMessages: (filterValidationErrors && filterValidationErrors('baseData.accountHolder.phone_Cnd')?.messages.map((msg) => {
                if (INVALID_PHONE_MESSAGE.test(msg)) {
                    return translator(messages.companyInvalidPhoneNumber);
                }
                return msg;
            })) || companyVM.phone_Cnd.aspects.validationMessages
        },
        companyEmail: {
            readOnly: readOnly || EditabilityService.isReadOnly(companyVM, 'emailAddress1', isFromQuoteRetrieval, companyClone),
            showErrors: filterValidationErrors && !_.isNil(filterValidationErrors('baseData.accountHolder.emailAddress1')),
            validationMessages: (filterValidationErrors && filterValidationErrors('baseData.accountHolder.emailAddress1')?.messages.map((msg) => {
                if (INVALID_EMAIL_MESSAGE.test(msg)) {
                    return translator(messages.companyInvalidEmail);
                }
                return msg;
            })) || companyVM.emailAddress1.aspects.validationMessages

        },
        companyProvideEmailOrMobileInfo: {
            visible: !readOnly && isEmailPhoneInfoMessageVisible
        },
        companyPersonalID: {
            visible: isPersonalIDVisible,
        },
        companyEmailNotificationInfo: {
            visible: isEmailNotificationInfoVisible && !readOnly
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
        resolveComponentMap: {
            address: Address
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={readOnly ? metadataSummary.componentContent : metadata.componentContent}
                model={companyVM}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

Company.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    isEmailPhoneInfoMessageVisible: PropTypes.bool,
    isPersonalIDVisible: PropTypes.bool,
    isEmailNotificationInfoVisible: PropTypes.bool,
    showErrors: PropTypes.bool.isRequired,
    filterValidationErrors: PropTypes.func
};
Company.defaultProps = {
    isEmailPhoneInfoMessageVisible: false,
    isPersonalIDVisible: false,
    isEmailNotificationInfoVisible: false,
    filterValidationErrors: undefined
};

export default Company;
