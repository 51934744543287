import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { valueToDate, dateToValue } from 'cnd-common-components-platform-react';
import metadata from './EmbeddedTripCancellationSummary.metadata.json5';
import './EmbeddedTripCancellationSummary.messages';

function EmbeddedTripCancellationSummary(props) {
    const { model } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const etcEndDate = useMemo(() => {
        const localEtcEndDate = valueToDate(_.get(model, 'etcendDate.value'));
        localEtcEndDate.setSeconds(localEtcEndDate.getSeconds() - 1);
        return dateToValue(localEtcEndDate);
    }, [model]);

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            readOnly: true
        },
        embeddedTripCancellationEndDate: {
            showTime: true,
            value: etcEndDate
        },
        embeddedTripCancellationTotalCostOfTicket: {
            showFractions: _.get(model, 'totalTripCost.amount.value') % 1 !== 0
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={model}
            overrideProps={overrideProps}
        />
    );
}

EmbeddedTripCancellationSummary.propTypes = {
    model: PropTypes.shape({}).isRequired,
};


export default EmbeddedTripCancellationSummary;
