import { CountryLayerCore } from 'cnd-common-portals-util-js';

export default {
    getFaqLink: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => 'https://www.colonnade.pl/faq'
            },
            {
                countries: [CountryLayerCore.Countries.CZ],
                action: () => 'https://www.colonnade.cz/faq'
            },
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => 'https://www.colonnade.hu/faq'
            },
            {
                countries: [CountryLayerCore.Countries.SK],
                action: () => 'https://www.colonnade.sk/faq'
            },
            {
                countries: [CountryLayerCore.Countries.RO],
                action: () => 'https://www.colonnade.ro/faq'
            },
            {
                countries: [CountryLayerCore.Countries.BG],
                action: () => 'https://www.colonnade.bg/faq'
            }
        ];

        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    getContactPhoneNumber: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => '+48 22 276 26 02'
            },
            {
                countries: [CountryLayerCore.Countries.CZ],
                action: () => '+420 234 108 311'
            },
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => '+36 1 460 1400'
            },
            {
                countries: [CountryLayerCore.Countries.SK],
                action: () => '+421 55 6826 222'
            },
            {
                countries: [CountryLayerCore.Countries.RO],
                action: () => '+40 21 300 96 21'
            },
            {
                countries: [CountryLayerCore.Countries.BG],
                action: () => '0700 14 251'
            }
        ];

        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    getContactEmail: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => 'bok@colonnade.pl'
            },
            {
                countries: [CountryLayerCore.Countries.BG],
                action: () => ''
            },
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => ''
            },
            {
                countries: [CountryLayerCore.Countries.CZ],
                action: () => ''
            },
            {
                countries: [CountryLayerCore.Countries.RO],
                action: () => ''
            },
            {
                countries: [CountryLayerCore.Countries.SK],
                action: () => ''
            },
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    getPrivacyPolicyLink: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => 'https://www.colonnade.pl/polityka-prywatnosci'
            },
            {
                countries: [CountryLayerCore.Countries.CZ],
                action: () => 'https://www.colonnade.cz/ochrana-osobnich-udaju/'
            },
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => 'https://www.colonnade.hu/adatvedelem/'
            },
            {
                countries: [CountryLayerCore.Countries.SK],
                action: () => 'https://www.colonnade.sk/ochrana-osobnych-udajov'
            },
            {
                countries: [CountryLayerCore.Countries.RO],
                action: () => 'https://www.colonnade.ro/informare-privind-prelucrarea-datelor-cu-caracter-personal/'
            },
            {
                countries: [CountryLayerCore.Countries.BG],
                action: () => 'https://www.colonnade.bg/data-privacy-policy/'
            }
        ];

        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    getTermsOfUseLink: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => 'https://www.colonnade.pl/zastrzezenia-prawne'
            },
            {
                countries: [CountryLayerCore.Countries.CZ],
                action: () => 'https://www.colonnade.cz/podminky-uzivani/'
            },
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => 'https://www.colonnade.hu/hasznalati-feltetelek'
            },
            {
                countries: [CountryLayerCore.Countries.SK],
                action: () => 'https://www.colonnade.sk/podmienky-pouzivania'
            },
            {
                countries: [CountryLayerCore.Countries.RO],
                action: () => 'https://www.colonnade.ro/termeni-de-utilizare'
            },
            {
                countries: [CountryLayerCore.Countries.BG],
                action: () => 'https://www.colonnade.bg/terms-of-use/'
            }
        ];

        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    getWebsiteLink: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => 'https://www.colonnade.pl'
            },
            {
                countries: [CountryLayerCore.Countries.CZ],
                action: () => 'https://www.colonnade.cz'
            },
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => 'https://www.colonnade.hu'
            },
            {
                countries: [CountryLayerCore.Countries.SK],
                action: () => 'https://www.colonnade.sk'
            },
            {
                countries: [CountryLayerCore.Countries.RO],
                action: () => 'https://www.colonnade.ro'
            },
            {
                countries: [CountryLayerCore.Countries.BG],
                action: () => 'https://www.colonnade.bg'
            }
        ];

        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    getClaimsInfoWebsiteLink: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => 'https://www.colonnade.pl/zglos-szkode'
            },
            {
                countries: [CountryLayerCore.Countries.CZ],
                action: () => 'https://www.colonnade.cz/pojistna-udalost'
            },
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => 'https://www.colonnade.hu/fontos-teendok-kar-eseten/'
            },
            {
                countries: [CountryLayerCore.Countries.SK],
                action: () => 'https://www.colonnade.sk/hlasenie-skod/'
            },
            {
                countries: [CountryLayerCore.Countries.RO],
                action: () => 'https://www.colonnade.ro/daune/'
            },
            {
                countries: [CountryLayerCore.Countries.BG],
                action: () => 'https://www.colonnade.bg/claims/'
            }
        ];

        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    getYourPassInfoLink: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => 'https://www.colonnade.pl/karta-w-podrozy'
            },
            {
                countries: [CountryLayerCore.Countries.CZ],
                action: () => 'https://www.colonnade.cz/kartavmobilu'
            },
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => 'https://www.colonnade.hu/mobilkartya'
            },
            {
                countries: [CountryLayerCore.Countries.SK],
                action: () => 'https://www.colonnade.sk/poistenie-v-mobile'
            },
            {
                countries: [CountryLayerCore.Countries.RO],
                action: () => 'https://www.colonnade.ro/digital-assistance-card'
            },
            {
                countries: [CountryLayerCore.Countries.BG],
                action: () => 'https://www.colonnade.bg/digital-assistance-card'
            }
        ];

        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    isVisiblePhone: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => false
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.SK,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.RO
                ],
                action: () => true
            }
        ];

        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    isVisibleEmail: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [
                    CountryLayerCore.Countries.PL,
                ],
                action: () => true
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.SK,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.RO
                ],
                action: () => false
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    }
};
