import { CountryLayerCore, CountrySpecificUtil } from 'cnd-common-portals-util-js';

export default {
    formatMessage: (message, country, isEmbeddedTripCancellationSelected) => {
        const currency = CountrySpecificUtil.getCurrencyForCountry(country, isEmbeddedTripCancellationSelected);

        if (country === undefined) {
            return message;
        }

        const variants = [
            {
                countries: [
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.SK,
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.RO,
                ],
                action: () => {
                    return message
                        .replace(/,/g, ' ')
                        .replace(/\.0+/, ',00')
                        .replace(currency, currency.toUpperCase());
                }
            },
            {
                countries: [
                    CountryLayerCore.Countries.HU,
                ],
                action: () => {
                    return message
                        .replace(/,/g, ' ')
                        .replace(/\.0+/, '')
                        .replace(currency, currency.toUpperCase());
                }
            }
        ];

        return CountryLayerCore.callCorrectImpl(variants, country);
    },
    formatPrice: (price, country) => {
        if (country === undefined) {
            return price;
        }

        const variants = [
            {
                countries: [
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.SK,
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.HU
                ],
                action: () => {
                    return price % 1 === 0
                        ? Number(price).toFixed(0).toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
                        : Number(price).toFixed(2).toString()
                            .replace(/(\d)(?=((\d{3})|(\d{3}\.\d{2}))+$)/g, '$1 ')
                            .replace('.', ',');
                }
            },
            {
                countries: [
                    CountryLayerCore.Countries.Other
                ],
                action: () => {
                    return price;
                }
            }
        ];

        return CountryLayerCore.callCorrectImpl(variants, country);
    }
};
