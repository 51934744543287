import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import CoverageTerm from './components/CoverageTerm/CoverageTerm';
import styles from './Coverage.module.scss';
import './Coverage.messages';

function TVLCoverageTerms(props) {
    const { terms } = props;

    return (
        <ul className={styles.coverageTermsContainer}>
            {_.map(terms, (term) => (
                <li>
                    <CoverageTerm {...term} key={term.publicID} />
                </li>
            ))}
        </ul>
    );
}

TVLCoverageTerms.propTypes = {
    terms: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            chosenTermValue: PropTypes.string,
            currency_Cnd: PropTypes.string
        })
    ).isRequired
};

export default TVLCoverageTerms;
