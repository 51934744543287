import _ from 'lodash';
import QuoteBaseData from './QuoteBaseData';
import LOBConstants from '../constants/LOBConstants';


function Submission(data) {
    if (data) {
        _.extend(this, data);
    }
    this.baseData = new QuoteBaseData(this.baseData || {
        productCode: LOBConstants.TRAVEL_INDIVIDUAL_PRODUCT_CODE
    });
    this.lobData = this.lobData || {
        travel: {
            ageBands: []
        }
    };
    this.bindData = this.bindData || {};
}

Submission.prototype.withTVL = function withTVL() {
    return new Submission({
        baseData: {
            productCode: LOBConstants.TRAVEL_INDIVIDUAL_PRODUCT_CODE
        },
        lobData: {
            travel: {
                ageBands: []
            }
        }
    });
};

export default Submission;
