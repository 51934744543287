import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@jutro/components';
import styles from './WarningCnd.module.scss';

function WarningCnd(props) {
    const { children } = props;
    return (
        <p className={styles.container}>
            <Icon className={styles.icon} icon="mi-warning" />
            {children}
        </p>
    );
}

WarningCnd.propTypes = {
    children: PropTypes.node.isRequired
};

export default WarningCnd;
