import React, {
    useCallback,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './Consents.metadata.json5';

function Consents(props) {
    const {
        value: consentsVM,
        path,
        onValueChange,
        onValidate,
        readOnly,
        locationFilter,
        disabled
    } = props;

    const {
        isComponentValid,
        registerComponentValidation,
        onValidate: setComponentValidation
    } = useValidation('Consents');

    const getFormValidity = useCallback(() => {
        let areConsentsValid = true;

        if (consentsVM.children !== undefined && consentsVM.children.length > 0) {
            areConsentsValid = !consentsVM.children.some((consent) => {
                const { location, mandatory, selected } = consent.value;
                return location === locationFilter && (mandatory && !selected);
            });
        }

        return areConsentsValid;
    }, [consentsVM.children, locationFilter]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [getFormValidity, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, 'Consents');
        }
    }, [isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.children.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const generateConsentsOverrides = useCallback(() => {
        if (consentsVM.children !== undefined && consentsVM.children.length > 0) {
            return consentsVM.value.reduce((all, consent, index) => {
                return {
                    ...all,
                    [`consentRow${index}`]: {
                        label: consent.description,
                        readOnly,
                        required: consent.mandatory,
                        visible: consent.location === locationFilter,
                        value: consent.selected,
                        showRequired: consent.mandatory,
                        disabled: disabled
                    }
                };
            }, {});
        }

        return null;
    }, [consentsVM.children, consentsVM.value, readOnly, locationFilter, disabled]);

    const overrideProps = {
        '@field': {
            readOnly
        },
        consentsRows: {
            data: consentsVM?.children
        },
        ...generateConsentsOverrides()
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={consentsVM.children}
            overrideProps={overrideProps}
            onValueChange={handleValueChange}
            onValidationChange={setComponentValidation}
        />
    );
}

Consents.propTypes = {
    path: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    locationFilter: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

export default Consents;
