import _ from 'lodash';
import { useMemo, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';

export const useSelectedVariant = (submissionVM) => {
    return useMemo(() => {
        const variants = _.get(
            submissionVM.value,
            'lobData.travel.variants',
            []
        );
        const offeredQuotes = _.get(
            submissionVM.value,
            'quoteData.offeredQuotes',
            []
        );
        const chosenQuoteId = _.get(submissionVM.value, 'bindData.chosenQuote');
        let selectedVariant = variants.find((variant) => variant.publicID === chosenQuoteId);
        if (!selectedVariant) {
            selectedVariant = variants.find((offer) => offer.selected === true);
        }
        if (!selectedVariant) {
            selectedVariant = variants.find((offer) => offer.isDefault === true);
        }
        if (!selectedVariant && variants.length === 1) {
            [selectedVariant] = variants;
        }
        const selectedQuote = offeredQuotes.find(
            (quotes) => quotes.branchName === selectedVariant?.branchName
        );
        return selectedQuote
            ? { ...selectedQuote, coverages: selectedVariant.coverages }
            : null;
    }, [submissionVM]);
};

export const useAddonCovers = (submissionVM) => {
    const variant = useSelectedVariant(submissionVM);
    const addonCoverages = _.get(variant, 'coverages.addonCoverages');
    return useMemo(() => {
        if (!Array.isArray(addonCoverages)) return '';
        return addonCoverages
            .filter((coverage) => coverage.selected)
            .map((coverage) => coverage.name)
            .join(', ');
    }, [addonCoverages]);
};

export const useCountries = (submissionVM) => {
    const translator = useContext(TranslatorContext);
    return useMemo(() => {
        const countries = _.get(
            submissionVM.value,
            'lobData.travel.countries',
            []
        );
        return countries
            .map((country) => translator({
                id: `typekey.Country.${country}`,
                defaultMessage: country,
            }))
            .join(', ');
    }, [submissionVM, translator]);
};

export const useTripZone = (submissionVM) => {
    const translator = useContext(TranslatorContext);
    const typecode = _.get(submissionVM, 'lobData.travel.tripZone.value');
    if (typecode == null) return '';
    return translator({
        id: typecode.name,
        defaultMessage: typecode.name
    });
};
