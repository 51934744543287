import React from 'react';
import PropTypes from 'prop-types';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { CurrencyFormatterService } from 'cnd-portals-util-js';
import CoverageTerms from './CoverageTerms';
import CoverageLimit from './components/CoverageLimit/CoverageLimit';
import withCollapsible from '../../../../../Collapsible/withCollapsible';
import styles from './Coverage.module.scss';

const collapsibleStyles = {
    root: styles.coverageContainer,
    collapsible: styles.collapsible,
};

const coverageLimitStyles = {
    price: {
        amount: styles.coveragePriceAmount,
        currency: styles.coveragePriceCurrency
    },
};

function TVLStandardCoverageHeader(props) {
    const { name, terms } = props;
    const coverageLimit = terms.find((x) => /(Total|Main)Limit/.test(x.patternCode));
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    const value = coverageLimit?.directValue
        ? CurrencyFormatterService.formatPrice(coverageLimit.directValue, appCountry)
        : coverageLimit?.chosenTermValue;

    return (
        <div className={styles.coverageHeader}>
            <div className={styles.coverageName}>{name}</div>
            <div className={styles.coverageValue}>
                <CoverageLimit
                    styles={coverageLimitStyles}
                    value={value}
                    currency={coverageLimit?.currency_Cnd}
                />
            </div>
        </div>
    );
}

TVLStandardCoverageHeader.propTypes = {
    name: PropTypes.string.isRequired,
    terms: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            chosenTermValue: PropTypes.string,
            currency_Cnd: PropTypes.string,
        })
    ).isRequired,
};

export default withCollapsible(CoverageTerms, {
    onHeaderRender: TVLStandardCoverageHeader,
    styles: collapsibleStyles,
});
