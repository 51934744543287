import { defineMessages } from 'react-intl';

export default defineMessages({
    documentLinksErrorMessage: {
        id: 'quoteandbind.tvl.components.document-links.Error occured while downloading list of documents',
        defaultMessage: 'Error occured while downloading list of documents'
    },
    retryButtonLabel: {
        id: 'quoteandbind.tvl.components.document-links.Retry',
        defaultMessage: 'Retry'
    },
});
