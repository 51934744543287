function isReadOnly(model, fieldName, isFromQuoteRetrieval = false, clone = {}) {
    if (model.value
        && model.value[fieldName]
        && isFromQuoteRetrieval
        && clone
        && clone[fieldName]) {
        return true;
    }
    if (!model.value || !model.value.blockedFields_Cnd) {
        return false;
    }
    if (
        model.value.blockedFields_Cnd
            .map((field) => field.toLowerCase()).includes(fieldName.toLowerCase())
        && model.value.publicID
    ) {
        return true;
    }
    return false;
}

export default {
    isReadOnly,
};
