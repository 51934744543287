import React, {
    useState, useCallback, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { ModalNextProvider, Loader } from '@jutro/components';
import { Wizard } from 'gw-portals-wizard-react';
import { ServiceManager } from '@jutro/services';
import { messages as commonMessages } from 'gw-platform-translations';
import { LoadSaveService } from 'cnd-capability-quoteandbind';
import { ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import { LOBContext } from 'cnd-common-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import Submission from './models/Submission';
import wizardConfig from './config/tvl-wizard-config.json5';
import QuoteInfoBox from './components/QuoteInfoBox/QuoteInfoBox';
import messages from './TVLWizard.messages';
import LOBConstants from './constants/LOBConstants';
import OfferingTypes from './constants/OfferingTypes';

const ACTION_MSG = {
    cancelAction: {
        title: commonMessages.wantToCancel,
        message: messages.cancelQuote,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    }
};

function TVLWizard(props) {
    const localeService = ServiceManager.getService('locale-service');
    const countryService = ServiceManager.getService('country-service-cnd');
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { steps, title } = wizardConfig;
    const { search } = useLocation();
    const { history } = props;
    const [initialSubmission, setInitialSubmission] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [shouldSkipValidSteps] = useState(false);
    const [isFromQuoteRetrieval, setIsFromQuoteRetrieval] = useState(false);
    const [quoteRetrievalClone, setQuoteRetrievalClone] = useState({});
    const [producerCodeFromRetrieval, setProducerCodeFromRetrieval] = useState(null);
    const [producerObjFromCancellation, setProducerObjFromCancellation] = useState(null);
    const [isNewQuoteWithPrefilledData, setIsNewQuoteWithPrefilledData] = useState(false);
    const [embeddedTripCancellation, setEmbeddedTripCancellation] = useState({ selected: false });
    const [jumpToStepIndex, setJumpToStepIndex] = useState(null);
    const [availableProducts, setAvailableProducts] = useState([]);
    const [availableCountries, setAvailableCountries] = useState([]);
    const [availableTripZones, setAvailableTripZones] = useState([]);
    const [availableAgeBands, setAvailableAgeBands] = useState([]);
    const [partnerDiscountSupplied, setPartnerDiscountSupplied] = useState(false);
    const [transportation, setTransportation] = useState([]);
    const [tripPurpose, setTripPurpose] = useState([]);

    const searchParams = new URLSearchParams(search);
    const offeringFromParams = searchParams.get('offering');

    const setProperLanguageAndCountry = (modelData) => {
        const storedCountry = countryService.getStoredCountry();
        const country = _.get(modelData, 'baseData.country_Cnd').toLowerCase();
        let language = country;
        if (country === 'cz') {
            language = 'cs';
        }
        if (language !== localeService.getStoredLanguage() || country !== storedCountry) {
            localeService.saveLanguage(language);
            countryService.saveCountry(country);
            window.location.reload();
        }
    };

    useEffect(() => {
        const { submission, producer: producerFromHistory } = _.get(history, 'location.state', {}); // change quote

        async function setSubmission() {
            let producerData = producerFromHistory || producerObjFromCancellation;

            let modelData;
            const {
                match: {
                    params: { token: quoteRetrieveToken },
                },
            } = props;
            if (quoteRetrieveToken) {
                try {
                    setIsLoading(true);
                    const retrievedQuote = await LoadSaveService.retrieveQuoteByToken(quoteRetrieveToken);
                    modelData = new Submission(retrievedQuote);
                    setQuoteRetrievalClone(modelData);
                    setIsFromQuoteRetrieval(true);
                    const selectedOffering = _.get(modelData, 'baseData.offering_Cnd');
                    const periodStartDate = _.get(modelData, 'baseData.periodStartDate');
                    setProducerCodeFromRetrieval(_.get(modelData, 'baseData.producerCodeOfRecord_Cnd'));

                    const agentReferenceDataCnd = _.get(modelData, 'agentReferenceData_Cnd');
                    if (agentReferenceDataCnd) {
                        producerData = agentReferenceDataCnd;
                        setProducerObjFromCancellation(agentReferenceDataCnd);
                    }

                    if (selectedOffering === OfferingTypes.ANNUAL) {
                        LoadSaveService.getAvailableTripZones(
                            producerData?.producerCode || producerCodeFromRetrieval,
                            countryService.getStoredCountry(),
                            periodStartDate
                        ).then((response) => {
                            setAvailableTripZones(response);
                        });
                    } else if (
                        [OfferingTypes.SINGLE, OfferingTypes.CANCELLATION]
                            .includes(selectedOffering)
                    ) {
                        LoadSaveService.getAvailableDestinationCountries(
                            producerData?.producerCode || _.get(modelData, 'baseData.producerCodeOfRecord_Cnd'),
                            periodStartDate,
                            countryService.getStoredCountry(),
                            _.get(modelData, 'baseData.virtualProductCode_Cnd')
                        ).then((response) => {
                            setAvailableCountries(response);
                        });
                    }
                    setJumpToStepIndex(2);
                    if (process.env.NODE_ENV !== 'production') {
                        setProperLanguageAndCountry(modelData);
                    }
                } catch (err) {
                    history.push('/');
                    ModalNextProvider.showAlert({
                        title: translator(messages.retrieveByTokenErrorTitle),
                        message: translator(messages.retrieveByTokenErrorMessage),
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok,
                    }).catch(_.noop);
                } finally {
                    setIsLoading(false);
                }
            } else if (submission) {
                modelData = new Submission(submission);
                setIsFromQuoteRetrieval(false);
            }

            if (offeringFromParams) {
                // Colonnade website link with offering
                _.set(history, 'location.state.offering', offeringFromParams);
            }

            const initialData = await LoadSaveService.getInitialData(
                producerData?.producerCode || _.get(modelData, 'baseData.producerCodeOfRecord_Cnd'),
                LOBConstants.TRAVEL_INDIVIDUAL_PRODUCT_CODE,
                countryService.getStoredCountry()
            );
            localStorage.setItem('currentServerTime', moment(initialData.currentServerTime).toDate());
            setAvailableProducts(initialData.availableProducts);
            setAvailableAgeBands(initialData.ageBands);

            const submissionVM = viewModelService.create(
                modelData || Submission.prototype.withTVL(),
                'pc',
                'edge.capabilities.quote.submission.dto.QuoteDataDTO'
            );
            if (producerData != null) {
                _.set(submissionVM.value, 'agentReferenceData_Cnd', {...producerData});
                if (producerData.virtualProduct) {
                    const offering = initialData.availableProducts.find(
                        (p) => p.virtualProductCode_Ext === producerData.virtualProduct
                    )?.offering_Cnd;
                    _.set(submissionVM.value, 'baseData.offering_Cnd', offering);
                    _.set(
                        submissionVM.value,
                        'baseData.virtualProductCode_Cnd',
                        producerData.virtualProduct
                    );
                }
                if (producerData.discountCode) {
                    _.set(
                        submissionVM.value,
                        'baseData.discountCode_Cnd',
                        producerData.discountCode
                    );
                }
                setPartnerDiscountSupplied(!!producerData.discountCode);
            }
            const isETCSelected = _.get(submissionVM, 'lobData.travel.isEmbeddedTripCancellationSelected.value');
            if (isETCSelected) {
                setEmbeddedTripCancellation({
                    selected: isETCSelected,
                    periodStart: _.get(submissionVM, 'baseData.periodStartDate.value'),
                    ticketPurchaseDate: _.get(submissionVM, 'lobData.travel.ticketPurchaseDate.value'),
                    totalTripCost: _.get(submissionVM, 'lobData.travel.totalTripCost.value')
                });
            }
            setInitialSubmission(submissionVM);
        }

        setSubmission();
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancel = useCallback(
        () => {
            return ModalNextProvider.showConfirm(ACTION_MSG.cancelAction).then((results) => {
                if (results === 'cancel') {
                    return _.noop();
                }

                setIsLoading(true);
                const state = {};
                if (producerObjFromCancellation) {
                    _.set(state, 'producer', producerObjFromCancellation)
                } else if (producerCodeFromRetrieval) {
                    _.set(state, 'producer', { producerCode: producerCodeFromRetrieval })
                }
                    
                history.push({
                    pathname: '/',
                    state
                });

                return undefined;
            }, _.noop);
        },
        [history, producerObjFromCancellation, producerCodeFromRetrieval]
    );

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <LOBContext.Provider
            value={{
                asideComponents: [QuoteInfoBox],
                isFromQuoteRetrieval,
                setIsFromQuoteRetrieval,
                quoteRetrievalClone,
                producerCodeFromRetrieval,
                setProducerCodeFromRetrieval,
                producerObjFromCancellation,
                setProducerObjFromCancellation,
                embeddedTripCancellation,
                setEmbeddedTripCancellation,
                isNewQuoteWithPrefilledData,
                setIsNewQuoteWithPrefilledData,
                availableProducts,
                setAvailableProducts,
                availableCountries,
                setAvailableCountries,
                availableTripZones,
                setAvailableTripZones,
                availableAgeBands,
                setAvailableAgeBands,
                partnerDiscountSupplied,
                transportation,
                setTransportation,
                tripPurpose,
                setTripPurpose
            }}
        >
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                initialData={initialSubmission}
                onCancel={handleCancel}
                skipCompletedSteps={shouldSkipValidSteps}
                initialStepIndex={_.get(history, 'location.state.initialStepIndex')}
                jumpToStepIndex={jumpToStepIndex}
            />
        </LOBContext.Provider>
    );
}

TVLWizard.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            submission: PropTypes.shape({})
        })
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withViewModelService(TVLWizard);
