import messages from './messages/StaticInformation.messages';

const StaticInformationData = {
    tripDetailsPageStep: {
        PL: [
            {
                id: messages.tripDetailsPageStepMessage1TextPL,
                links: [
                    {
                        url: messages.tripDetailsPageStepMessage1Link1PL,
                        description: messages.tripDetailsPageStepMessage1Link1PL
                    }
                ]
            },
            {
                id: messages.tripDetailsPageStepMessage2TextPL,
                rowStyle: ['lineIndentation1', 'paddingTop']
            },
            {
                id: messages.tripDetailsPageStepMessage3TextPL,
                rowStyle: ['lineIndentation2']
            },
            {
                id: messages.tripDetailsPageStepMessage4TextPL,
                rowStyle: ['lineIndentation2']
            },
            {
                id: messages.tripDetailsPageStepMessage5TextPL,
                rowStyle: ['lineIndentation2']
            },
            {
                id: messages.tripDetailsPageStepMessage6TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage7TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage8TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage9TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage10TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage11TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage12TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage13TextPL,
                rowStyle: ['fontBold', 'paddingTop']
            },
            {
                id: messages.tripDetailsPageStepMessage14TextPL,
                rowStyle: ['lineIndentation1', 'paddingTop']
            },
            {
                id: messages.tripDetailsPageStepMessage15TextPL,
                rowStyle: ['lineIndentation2']
            },
            {
                id: messages.tripDetailsPageStepMessage16TextPL,
                rowStyle: ['lineIndentation2']
            },
            {
                id: messages.tripDetailsPageStepMessage17TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage18TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage19TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage20TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage21TextPL,
                rowStyle: ['lineIndentation3']
            },
            {
                id: messages.tripDetailsPageStepMessage22TextPL,
                rowStyle: ['fontBold', 'paddingTop']
            }
        ],
        BG: [
            {
                id: messages.tripDetailsPageStepMessage1TextBG,
                links: [
                    {
                        url: messages.tripDetailsPageStepMessage1Link1BG,
                        description: messages.tripDetailsPageStepMessage1LinkDescription1BG
                    }
                ]
            },
            {
                id: messages.tripDetailsPageStepMessage2TextBG
            },
            {
                id: messages.tripDetailsPageStepMessage3TextBG,
                links: [
                    {
                        url: messages.tripDetailsPageStepMessage3Link1BG,
                        description: messages.tripDetailsPageStepMessage3Link1BG
                    }
                ]
            }
        ],
        RO: [
            {
                id: messages.tripDetailsPageStepMessage1TextRO,
            },
            {
                id: messages.tripDetailsPageStepMessage1HeaderRO,
                rowStyle: ['fontBold', 'lineIndentation1', 'paddingTop', 'paddingBottom'],
            },
            {
                id: messages.tripDetailsPageStepMessage2TextRO,
                rowStyle: ['lineIndentation2', 'unorderedListElement'],
            },
            {
                id: messages.tripDetailsPageStepMessage3TextRO,
                rowStyle: ['lineIndentation2', 'unorderedListElement'],
            },
            {
                id: messages.tripDetailsPageStepMessage2HeaderRO,
                rowStyle: ['fontBold', 'lineIndentation1', 'paddingTop', 'paddingBottom'],
            },
            {
                id: messages.tripDetailsPageStepMessage4TextRO,
                rowStyle: ['lineIndentation2', 'unorderedListElement'],
            },
            {
                id: messages.tripDetailsPageStepMessage3HeaderRO,
                rowStyle: ['fontBold', 'lineIndentation1', 'paddingTop', 'paddingBottom'],
            },
            {
                id: messages.tripDetailsPageStepMessage5TextRO,
                rowStyle: ['lineIndentation2', 'unorderedListElement'],
            },
        ]
    },
    quotePageStep: {
        BG: [
            {
                id: messages.quotePageStepMessage1TextBG,
                links: [
                    {
                        url: messages.quotePageStepMessage1Link1BG,
                        description: messages.quotePageStepMessage1LinkDescription1BG
                    }
                ]
            },
            {
                id: messages.quotePageStepMessage2TextBG
            },
            {
                id: messages.quotePageStepMessage3TextBG,
                links: [
                    {
                        url: messages.quotePageStepMessage3Link1BG,
                        description: messages.quotePageStepMessage3Link1BG
                    }
                ]
            }
        ]
    },
    personalInformationPageStep: {
        BG: [
            {
                id: messages.personalInformationPageStepMessage1TextBG,
                links: [
                    {
                        url: messages.personalInformationPageStepMessage1Link1BG,
                        description: messages.personalInformationPageStepMessage1Link1BG
                    },
                    {
                        url: messages.personalInformationPageStepMessage1Link2BG,
                        description: messages.personalInformationPageStepMessage1LinkDescription2BG
                    },
                ]
            },
            {
                id: messages.personalInformationPageStepMessage2TextBG,
                links: [
                    {
                        url: messages.personalInformationPageStepMessage2Link1BG,
                        description: messages.personalInformationPageStepMessage2LinkDescription1BG
                    }
                ]
            },
            {
                id: messages.personalInformationPageStepMessage3TextBG
            },
            {
                id: messages.personalInformationPageStepMessage4TextBG,
                links: [
                    {
                        url: messages.personalInformationPageStepMessage4Link1BG,
                        description: messages.personalInformationPageStepMessage4Link1BG
                    }
                ]
            }
        ],
        SK: [
            {
                id: messages.personalInformationPageStepMessage1TextSK,
                links: [
                    {
                        url: messages.personalInformationPageStepMessage1Link1SK,
                        description: messages.personalInformationPageStepMessage1LinkDescription1SK
                    }
                ]
            }
        ],
        RO: [
            {
                id: messages.personalInformationPageStepMessage1TextRO,
                links: [
                    {
                        url: messages.personalInformationPageStepMessage1Link1RO,
                        description: messages.personalInformationPageStepMessage1LinkDescription1RO
                    },
                    {
                        url: messages.personalInformationPageStepMessage1Link2RO,
                        description: messages.personalInformationPageStepMessage1LinkDescription2RO
                    }
                ]
            }
        ],
        CZ: [
            {
                id: messages.personalInformationPageStepMessage1TextCZ,
                links: [
                    {
                        url: messages.personalInformationPageStepMessage1Link1CZ,
                        description: messages.personalInformationPageStepMessage1LinkDescription1CZ
                    },
                    {
                        url: messages.personalInformationPageStepMessage1Link2CZ,
                        description: messages.personalInformationPageStepMessage1LinkDescription2CZ
                    }
                ]
            }
        ],
        PL: [
            {
                id: messages.personalInformationPageStepMessage1TextPL,
                links: [
                    {
                        url: messages.personalInformationPageStepMessage1Link1PL,
                        description: messages.personalInformationPageStepMessage1LinkDescription1PL
                    },
                    {
                        url: messages.personalInformationPageStepMessage1Link2PL,
                        description: messages.personalInformationPageStepMessage1LinkDescription2PL
                    }
                ]
            }
        ]
    },
    insuranceSummaryPageStep: {
        BG: [
            {
                id: messages.insuranceSummaryPageStepMessage1TextBG,
                links: [
                    {
                        url: messages.insuranceSummaryPageStepMessage1Link1BG,
                        description: messages.insuranceSummaryPageStepMessage1LinkDescription1BG
                    }
                ]
            },
            {
                id: messages.insuranceSummaryPageStepMessage2TextBG
            },
            {
                id: messages.insuranceSummaryPageStepMessage3TextBG,
                links: [
                    {
                        url: messages.insuranceSummaryPageStepMessage3Link1BG,
                        description: messages.insuranceSummaryPageStepMessage3Link1BG
                    }
                ]
            }
        ]
    }
};

export default StaticInformationData;
