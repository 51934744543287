import _ from 'lodash';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useContext, useCallback } from 'react';
import Submission from '../models/Submission';
import { CANCELLATION_ADDON_PUBLIC_ID } from '../constants/QuoteConstants';
import OfferingTypes from '../constants/OfferingTypes';

export default function useNewQuoteMapper() {
    const viewModelService = useContext(ViewModelServiceContext);

    const mapQuoteReferenceWithTripDetails = useCallback(
        (submissionVM) => {
            const newSubmissionVM = viewModelService.create(new Submission({
                basedOn_Cnd: {
                    quoteID: _.get(submissionVM.value, 'quoteID'),
                    sessionUUID: _.get(submissionVM.value, 'sessionUUID')
                },
                baseData: {
                    virtualProductCode_Cnd: _.get(submissionVM.value, 'baseData.virtualProductCode_Cnd'),
                    periodStartDate: _.get(submissionVM.value, 'baseData.periodStartDate'),
                    periodEndDate: _.get(submissionVM.value, 'baseData.periodEndDate'),
                    discountCode_Cnd: _.get(submissionVM.value, 'baseData.discountCode_Cnd'),
                    offering_Cnd: _.get(submissionVM.value, 'baseData.offering_Cnd'),
                    productCode: _.get(submissionVM.value, 'baseData.productCode')
                },
                lobData: {
                    travel: {
                        countries: _.get(submissionVM.value, 'lobData.travel.countries'),
                        tripZone: _.get(submissionVM.value, 'lobData.travel.tripZone'),
                        ticketPurchaseDate: _.get(submissionVM.value, 'lobData.travel.ticketPurchaseDate'),
                        totalTripCost: _.get(submissionVM.value, 'lobData.travel.totalTripCost'),
                        ageBands: _.get(submissionVM.value, 'lobData.travel.ageBands')
                    }
                }
            }),
            'pc',
            'edge.capabilities.quote.submission.dto.QuoteDataDTO');
            return newSubmissionVM;
        }, [viewModelService]
    );

    const mapQuoteReference = useCallback(
        (submissionVM) => {
            const newSubmissionVM = viewModelService.create(new Submission({
                basedOn_Cnd: {
                    quoteID: _.get(submissionVM.value, 'quoteID'),
                    sessionUUID: _.get(submissionVM.value, 'sessionUUID')
                }
            }),
            'pc',
            'edge.capabilities.quote.submission.dto.QuoteDataDTO');
            return newSubmissionVM;
        }, [viewModelService]
    );

    const mapQuoteReferenceWithTripAndClientDetails = useCallback((submissionVM) => {
        const removePublicIds = (obj) => {
            if (obj == null) return;
            Object.keys(obj).forEach(prop => {
                if (prop === 'publicID')
                    delete obj[prop];
                else if (typeof obj[prop] === 'object')
                    removePublicIds(obj[prop]);
            });
        }
        removePublicIds(submissionVM.value);
        _.unset(submissionVM.value, 'baseData.periodStatus');
        _.unset(submissionVM.value, 'quoteID');
        _.unset(submissionVM.value, 'sessionUUID');
        _.unset(submissionVM.value, 'quoteData');
        _.unset(submissionVM.value, 'bindData');
        _.unset(submissionVM.value, 'baseData.accountNumber');
        _.unset(submissionVM.value, 'lobData.travel.variants');

        if (!_.get(submissionVM.value, 'baseData.accountHolder.lastName')) {
            _.unset(submissionVM.value, 'baseData.accountHolder');
        }

        const newSubmissionVM = viewModelService.create(
            new Submission({...submissionVM.value}),
            'pc',
            'edge.capabilities.quote.submission.dto.QuoteDataDTO'
        );

        return newSubmissionVM;
    }, [viewModelService]);

    return {
        mapQuoteReferenceWithTripDetails,
        mapQuoteReference,
        mapQuoteReferenceWithTripAndClientDetails
    };
}
