export default {
    getMaskFormatCharacters: () => {
        return {
            9: '[0-9]',
            0: '^[0-9]?$',
            c: '[A-Za-z]',
            '*': '[A-Za-z0-9]'
        };
    }
};
