import { defineMessages } from 'react-intl';

export default defineMessages({
    personFirstName: {
        id: 'quoteandbind.components.person.First Name',
        defaultMessage: 'First Name'
    },
    personLastName: {
        id: 'quoteandbind.components.person.Last Name',
        defaultMessage: 'Last Name'
    },
    personIsResident: {
        id: 'quoteandbuy.components.person.Resident?',
        defaultMessage: 'Resident?'
    },
    personIsResidentTooltip: {
        id: 'quoteandbind.components.person.Resident in reference to the country in which the policy is being purchased.',
        defaultMessage: 'Resident in reference to the country in which the policy is being purchased.'
    },
    personDateOfBirth: {
        id: 'quoteandbuy.components.person.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    personPhone: {
        id: 'quoteandbind.components.person.Phone',
        defaultMessage: 'Phone'
    },
    personEmail: {
        id: 'quoteandbind.components.person.Email',
        defaultMessage: 'Email'
    },
    personPersonalID: {
        id: 'quoteandbind.components.person.Birth number',
        defaultMessage: 'Birth number'
    },
    personNationalID: {
        id: 'quoteandbuy.components.person.National ID',
        defaultMessage: 'National ID'
    },
    personPassportNumber: {
        id: 'quoteandbuy.components.person.Passport number',
        defaultMessage: 'Passport number'
    },
    personProvideEmailOrMobileInfo: {
        id: 'quoteandbind.components.person.Please provide an email address or mobile phone, it will be used for the purpose of personal data processing.',
        defaultMessage: 'Please provide an email address or mobile phone, it will be used for the purpose of personal data processing.'
    },
    personEmailNotificationInfo: {
        id: 'quoteandbind.components.person.If the email address is not provided claim-status notifications will not be sent.',
        defaultMessage: 'If the email address is not provided claim-status notifications will not be sent.'
    },
    personInvalidPhoneNumber: {
        id: 'quoteandbind.components.person.The phone number is incorrect for chosen country code.',
        defaultMessage: 'The phone number is incorrect for chosen country code.'
    },
    personInvalidEmail: {
        id: 'quoteandbind.components.person.The email is not valid',
        defaultMessage: 'The email is not valid'
    },
    personNationalIdPassportNumber: {
        id: 'quoteandbuy.components.person.National ID/Passport number',
        defaultMessage: 'National ID/Passport number'
    },
    personSummaryPhone: {
        id: 'quoteandbind.components.person.Phone',
        defaultMessage: 'Phone'
    }
});
