import { defineMessages } from 'react-intl';

export default defineMessages({
    tripDetailsPageHeader: {
        id: 'quoteandbind.tvl.step.trip-details.Trip details',
        defaultMessage: 'Trip details'
    },
    tripDetailsPageOffering: {
        id: 'quoteandbind.tvl.step.trip-details.Offering',
        defaultMessage: 'Offering'
    },
    tripDetailsPageCountry: {
        id: 'quoteandbind.tvl.step.trip-details.Country',
        defaultMessage: 'Country'
    },
    tripDetailsPageStartDayOfTheyJourney: {
        id: 'quoteandbind.tvl.step.trip-details.Start of the journey',
        defaultMessage: 'Start of the journey'
    },
    tripDetailsPageStartingDateOfValidity: {
        id: 'quoteandbind.tvl.step.trip-details.Starting date of validity',
        defaultMessage: 'Starting date of validity'
    },
    tripDetailsPageEndDate: {
        id: 'quoteandbind.tvl.step.trip-details.End of the journey',
        defaultMessage: 'End of the journey'
    },
    tripDetailsPageNumberOfTravellers: {
        id: 'quoteandbind.tvl.step.trip-details.Number of travellers',
        defaultMessage: 'Number of travellers'
    },
    tripDetailsPageDiscountCode: {
        id: 'quoteandbind.tvl.step.trip-details.Discount code',
        defaultMessage: 'Discount code'
    },
    tripDetailsPageAgeBandInfo: {
        id: 'quoteandbind.tvl.step.trip-details.Please give the age group classification in relation to the start date of the trip. The maximum amount of travelers can not exceed 50 people.',
        defaultMessage: 'Please give the age group classification in relation to the start date of the trip. The maximum amount of travelers can not exceed 50 people.'
    },
    tripDetailsPagePurchaseTicketDate: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.Purchase date of ticket/trip',
        defaultMessage: 'Purchase date of ticket/trip'
    },
    tripDetailsPagePurchaseTicketDateTooltip: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.Date of first trip payment – either installment or full amount.',
        defaultMessage: 'Date of first trip payment – either installment or full amount.'
    },
    tripDetailsPageTotalCost: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.Total cost of ticket/trip',
        defaultMessage: 'Total cost of ticket/trip'
    },
    tripDetailsPageTotalCostETC: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.Total cost of ticket/trip for ETC',
        defaultMessage: 'Total cost of ticket/trip for ETC'
    },
    tripDetailsPageTotalCostTooltip: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.Please enter the total cost of ticket/trip, even if you did not pay the full amount yet. For more details related to the maximum limit allowed, please read GTCs.',
        defaultMessage: 'Please enter the total cost of ticket/trip, even if you did not pay the full amount yet. For more details related to the maximum limit allowed, please read GTCs.'
    },
    tripDetailsPageTotalCostETCTooltip: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.Please enter the total cost of ticket/trip for ETC, even if you did not pay the full amount yet. For more details related to the maximum limit allowed, please read GTCs.',
        defaultMessage: 'Please enter the total cost of ticket/trip for ETC, even if you did not pay the full amount yet. For more details related to the maximum limit allowed, please read GTCs.'
    },
    tripDetailsTripZone: {
        id: 'quoteandbind.tvl.step.trip-details.Trip Zone',
        defaultMessage: 'Trip Zone'
    },
    tripDetailsNewQuote: {
        id: 'quoteandbind.tvl.step.trip-details.New quote',
        defaultMessage: 'New quote'
    },
    tripDetailsEditQuote: {
        id: 'quoteandbind.tvl.step.trip-details.Edit quote',
        defaultMessage: 'Edit quote'
    },
    tripDetailsEditQuotePopupTitle: {
        id: 'quoteandbind.tvl.step.trip-details.Price can be changed',
        defaultMessage: 'Price can be changed'
    },
    tripDetailsEditQuotePopupMessage: {
        id: 'quoteandbind.tvl.step.trip-details.Please have in mind that your price can be changed in case you are modifying the information already selected. Do you want to continue?',
        defaultMessage: 'Please have in mind that your price can be changed in case you are modifying the information already selected. Do you want to continue?'
    },
    tripDetailsInvalidTravellersNumberError: {
        id: 'quoteandbind.tvl.step.trip-details.Total number of travelers exceeds',
        defaultMessage: 'Total number of travelers exceeds {MAX_TRAVELERS}. Please modify number of travelers.'
    },
    tripCountriesTooltip: {
        id: 'quoteandbind.tvl.step.trip-details.Please add all the visited countries including the transit ones.',
        defaultMessage: 'Please add all the visited countries including the transit ones.'
    },
    tripDetailsPageInvalidDiscountCode: {
        id: 'quoteandbind.tvl.step.trip-details.The discount code you entered is not valid, please change or delete it.',
        defaultMessage: 'The discount code you entered is not valid, please change or delete it.'
    },
    tripDetailsPageNotActiveDiscountCode: {
        id: 'quoteandbind.tvl.step.trip-details.The discount code you entered is not active, please change or delete it.',
        defaultMessage: 'The discount code you entered is not active, please change or delete it.'
    },
    tripDetailsPageExpireddDiscountCode: {
        id: 'quoteandbind.tvl.step.trip-details.The discount code you entered is already expired, please change or delete it',
        defaultMessage: 'The discount code you entered is already expired, please change or delete it'
    },
    tripDetailsPageInformationHeader: {
        id: 'quoteandbind.tvl.step.trip-details.Information',
        defaultMessage: 'Information'
    },
    tripDetailsDisclaimerText: {
        id: 'quoteandbind.tvl.step.trip-details.By proceeding further I confirm that I read and I am in the target market',
        defaultMessage: 'By proceeding further I confirm that I read and I am in the target market'
    },
    tripDetailsEmbeddedCancellationCover: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.Do you want trip cancellation cover?',
        defaultMessage: 'Do you want trip cancellation cover?'
    },
    tripDetailsEmbeddedCancellationCoverTooltip: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.If you want to calculate also trip-cancelation electable add-on coverage on the next step, please select this check-box and enter purchase date and total costs of trip/ticket.',
        defaultMessage: 'If you want to calculate also trip-cancelation electable add-on coverage on the next step, please select this check-box and enter purchase date and total costs of trip/ticket.'
    },
    tripDetailsTFurtherDetails: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.Further details',
        defaultMessage: 'Further details'
    },
    tripDetailsTFurtherDetailsInfo: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.In the case of the below questions, you can provide more than one answer.',
        defaultMessage: 'In the case of the below questions, you can provide more than one answer.'
    },
    tripDetailsTripPurpose: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.What is the purpose of your trip?',
        defaultMessage: 'What is the purpose of your trip?'
    },
    tripDetailsTransportation: {
        id: 'quoteandbind.tvl.step.trip-details.What kind of transportation will you use?',
        defaultMessage: 'What kind of transportation will you use?'
    },
    tripDetailsTripAssistanceNeeded: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.Do you want car assistance?',
        defaultMessage: 'Do you want car assistance?'
    },
    tripDetailsVehicleProductionYear: {
        id: 'quoteandbind.tvl.step.quoteandbind.tvl.step.trip-details.Production year',
        defaultMessage: 'Production year'
    }
});
