import { defineMessages } from 'react-intl';

export default defineMessages({
    tvlUnsuccessfulPaymentPageHeader: {
        id: 'quoteandbind.tvl.pages.unsuccessful-page.Unsuccessful Payment',
        defaultMessage: 'Unsuccessful Payment',
    },
    tvlUnsuccessfulPaymentPageIntro: {
        id: 'quoteandbind.tvl.pages.unsuccessful-page.We\'re sorry to inform you that your payment hasn\'t been successfully processed. No worries, it happens sometimes. The most common reasons are:',
        defaultMessage: 'We\'re sorry to inform you that your payment hasn\t been successfully processed. No worries, it happens sometimes. The most common reasons are:',
    },
    tvlUnsuccessfulPaymentPageReason1: {
        id: 'quoteandbind.tvl.pages.unsuccessful-page.Insufficient funds on the account',
        defaultMessage: 'Insufficient funds on the account',
    },
    tvlUnsuccessfulPaymentPageReason2: {
        id: 'quoteandbind.tvl.pages.unsuccessful-page.Expired credit card',
        defaultMessage: 'Expired credit card',
    },
    tvlUnsuccessfulPaymentPageReason3: {
        id: 'quoteandbind.tvl.pages.unsuccessful-page.The bank\'s fraud rules blocked the transaction',
        defaultMessage: 'The bank\'s fraud rules blocked the transaction'
    },
    tvlUnsuccessfulPaymentPageInfo1: {
        id: 'quoteandbind.tvl.pages.unsuccessful-page.Please try again with the same credit card or with another one.',
        defaultMessage: 'Please try again with the same credit card or with another one.'
    },
    tvlUnsuccessfulPaymentPageInfo2: {
        id: 'quoteandbind.tvl.pages.unsuccessful-page.If everything looks okay with your payment method, but the payment is still not going through, we suggest you to contact your financial institution for more information.',
        defaultMessage: 'If everything looks okay with your payment method, but the payment is still not going through, we suggest you to contact your financial institution for more information.'
    },
    tvlUnsuccessfulPaymentPageBtn: {
        id: 'quoteandbind.tvl.pages.unsuccessful-page.Try again',
        defaultMessage: 'Try again'
    },
    tvlUnsuccessfulPaymentPageInfo3: {
        id: 'quoteandbind.tvl.pages.unsuccessful-page.If the paid amount is above or below the expected amount or you have other problems with processing the payment or policy issuance (did not receive the email with the documents), please contact us: phone: xxxxxxxxxxxxxxx',
        defaultMessage: 'If the paid amount is above or below the expected amount or you have other problems with processing the payment or policy issuance (did not receive the email with the documents), please contact us: phone: {phoneNumber}'
    }
});
