import _ from 'lodash';
import React, {
    useState,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import QuotePackage from './components/QuotePackage/QuotePackage';
import styles from './QuoteTable.module.scss';

function TVLQuoteTable(props) {
    const {
        flavours,
        selectedVariant,
        onValueChange,
        onVariantSelection,
        additionalCoversHidden,
    } = props;

    const [expandedCoverageID, setExpandedCoverageID] = useState(null);
    const hideSelectVariant = flavours?.length === 1;

    const handleCoverageCollapseToggle = useCallback((collapsed, id) => {
        setExpandedCoverageID(expandedCoverageID !== id ? id : null);
    }, [expandedCoverageID, setExpandedCoverageID]);

    const handleAddOrRemoveCoverage = useCallback((variantBranchName, coveragePublicID) => {
        const newFlavours = _.map(flavours, (variant) => {
            const branchName = _.get(variant, 'branchName');
            if (branchName !== variantBranchName) return variant;

            const newVariant = { ...variant };
            const addonCoverages = _.get(newVariant, 'coverages.addonCoverages');
            const addon = _.find(addonCoverages, (covAddon) => covAddon.publicID === coveragePublicID);
            addon.selected = !addon.selected;

            return newVariant;
        });

        if (onValueChange) {
            onValueChange(newFlavours, 'lobData.travel.variants');
        }
    }, [flavours, onValueChange]);

    return (
        <div className={styles.quoteTable}>
            {_.map(flavours, (variant) => (
                <QuotePackage
                    key={variant.branchName}
                    variant={variant}
                    isSelected={selectedVariant === variant.branchName}
                    expandedCoverageID={expandedCoverageID}
                    hideSelectVariant={hideSelectVariant}
                    additionalCoversHidden={additionalCoversHidden}
                    onSelect={onVariantSelection}
                    onCoverageCollapseToggle={handleCoverageCollapseToggle}
                    onAddOrRemoveCoverage={handleAddOrRemoveCoverage}
                />
            ))}
        </div>
    );
}

TVLQuoteTable.propTypes = {
    flavours: PropTypes.arrayOf(PropTypes.shape({})),
    selectedVariant: PropTypes.string.isRequired,
    additionalCoversHidden: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    onVariantSelection: PropTypes.func.isRequired,
};

TVLQuoteTable.defaultProps = {
    flavours: [],
    additionalCoversHidden: false,
};

export default TVLQuoteTable;
