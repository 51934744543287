import { defineMessages } from 'react-intl';

export default defineMessages({
    requiredField: {
        id: 'jutro-components.widgets.inputs.This is a required field',
        defaultMessage: 'This is a required field'
    },
    optional: {
        id: 'jutro-components.widgets.inputs.Optional',
        defaultMessage: '(Optional)'
    },
    pleaseSelect: {
        id: 'jutro-components.widgets.inputs.DropdownSelectField.placeholder',
        defaultMessage: 'Please Select'
    },
    futureDate: {
        id: 'displaykey.Edge.Web.Api.Model.FutureDate',
        defaultMessage: 'Value must be a date in the future'
    },
    pastDate: {
        id: 'displaykey.Edge.Web.Api.Model.PastDate',
        defaultMessage: 'Value must be a date in the past'
    },
    notPastLocalDate: {
        id: 'displaykey.Edge.Web.Api.Model.NonPastLocalDate_Cnd',
        defaultMessage: 'This field can\'t be a past date'
    },
    maximumValue: {
        id: 'displaykey.Edge.Web.Api.Model.Max',
        defaultMessage: 'Maximum value is {0}'
    },
    minimumValue: {
        id: 'displaykey.Edge.Web.Api.Model.Min',
        defaultMessage: 'Minimum value is {0}'
    },
    policyHolderMinAge: {
        id: 'displaykey.Edge.Web.Api.QuoteHandler.PolicyHolderMinAge',
        defaultMessage: 'Unfortunately, we cannot issue the policy because the policyholder is under {0} years old'
    },
    policyHolderMaxAge: {
        id: 'displaykey.Edge.Web.Api.Model.PolicyHolderMaxAge_Cnd',
        defaultMessage: 'Unfortunately, we cannot issue the policy because the policyholder is over 100 years old'
    },
    incompleteInput: {
        id: 'jutro-components.widgets.inputs.incomplete',
        defaultMessage: 'Incomplete input'
    },
    valueIsInvalid: {
        id: 'displaykey.Edge.Web.Api.Model.Pattern',
        defaultMessage: 'Value entered is invalid'
    },
    validEmail: {
        id: 'displaykey.Edge.Web.Api.Model.Email',
        defaultMessage: 'Value must be valid e-mail address'
    },
    ticketPurchaseNotToday: {
        id: 'displaykey.Web.PolicyLine.Validation.DateOfPurchaseTicketNotToday_Cnd',
        defaultMessage: 'Purchase date of ticket/trip must be today'
    },
    maxCostOfTrip: {
        id: 'displaykey.Edge.Web.Api.Model.TotalTripCostExceedsLimit',
        defaultMessage: 'Unable to generate a quote, the total cost of the ticket / travel exceeds the limit {0} {1}'
    },
    tvlAnnual: {
        id: 'typekey.Offering_Cnd.TVIInternationalAnnualTrip',
        defaultMessage: 'International Annual Trip'
    },
    tvlSingle: {
        id: 'typekey.Offering_Cnd.TVIInternationalSingleTrip',
        defaultMessage: 'International Single Trip'
    },
    tvlCancellation: {
        id: 'typekey.Offering_Cnd.TVITripCancellation',
        defaultMessage: 'Trip Cancellation'
    },
    tvlDomestic: {
        id: 'typekey.Offering_Cnd.TVIDomesticTrip',
        defaultMessage: 'Domestic Trip'
    },
    notNull: {
        id: 'displaykey.Edge.Web.Api.Model.NotNull',
        defaultMessage: 'This is a required field'
    },
    minimum: {
        id: 'jutro-components.widgets.inputs.The minimum allowed value is',
        defaultMessage: 'The minimum allowed value is'
    },
    invalidPhone: {
        id: 'displaykey.Edge.Web.Api.Model.Phone',
        defaultMessage: 'The phone number is incorrect for chosen country code'
    },
    valueBetween: {
        id: 'displaykey.Edge.Web.Api.Model.Size',
        defaultMessage: 'Value must be between {0} and {1} characters'
    }
});
