import React, {
    useContext,
    useState,
    useCallback,
    useLayoutEffect
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { Loader } from '@jutro/components';
import { Redirect, withRouter } from 'react-router-dom';
import { LoadSaveService } from 'cnd-capability-quoteandbind';
import { TranslatorContext } from '@jutro/locale';
import { ContactInfoService } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import metadata from './UnsuccessfulPayment.metadata.json';
import styles from './UnsuccessfulPayment.module.scss';
import messages from './UnsuccessfulPayment.messages';

function UnsuccessfulPayment({ history }) {
    const [isLoading, setIsLoading] = useState(false);
    const translator = useContext(TranslatorContext);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    const { quoteID, sessionUUID } = _.get(history, 'location.state', {});

    useLayoutEffect(() => {
        document.title = translator(messages.tvlUnsuccessfulPaymentPageHeader) + ' | Colonnade Insurance S.A.';
    }, []);

    const onRetryClick = useCallback(() => {
        (async () => {
            try {
                setIsLoading(true);
                const submission = await LoadSaveService.retrieveSubmission(
                    quoteID,
                    sessionUUID
                );

                const response = await LoadSaveService.createElectronicPayment(submission);
                window.location.href = response.redirectUri;
            } catch (err) {
                setIsLoading(false);
            }
        })();
    }, [quoteID, sessionUUID]);

    if (_.isNil(quoteID) || _.isNil(sessionUUID)) {
        return <Redirect to="/" />;
    }

    const overrideProps = {
        tvlUnsuccessfulPaymentPageBtn: {
            onClick: onRetryClick
        },
        tvlUnsuccessfulPaymentPageInfo3: {
            content: translator(messages.tvlUnsuccessfulPaymentPageInfo3, {
                phoneNumber: ContactInfoService.getContactPhoneNumber(appCountry)
            })
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <Loader loaded={!isLoading}>
            {renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers)}
        </Loader>
    );
}

UnsuccessfulPayment.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withRouter(UnsuccessfulPayment);
