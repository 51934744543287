import _ from 'lodash';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { Link, Loader, Button } from '@jutro/components';
import messages from './DocumentLinks.messages';
import styles from './DocumentLinks.module.scss';

function DocumentLinks({ error, links, isLoading, onRetry }) {
    const translator = useContext(TranslatorContext);
    return (
        <Loader loaded={!isLoading}>
            {_.isNil(error) ? (
                <ul className={styles.documentLinkList}>
                    {_.map(links, ({ url, name, onClick }) => {
                        return (
                            <li key={name}>
                                <Link href={url} onClick={onClick} target="_blank">
                                    {name}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <>
                    <p>{translator(messages.documentLinksErrorMessage)}</p>
                    <Button type="tertiary" onClick={onRetry}>
                        {translator(messages.retryButtonLabel)}
                    </Button>
                </>
            )}
        </Loader>
    );
}

DocumentLinks.propTypes = {
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            name: PropTypes.string,
            onClick: PropTypes.func
        })
    ).isRequired,
    onRetry: PropTypes.func,
};

DocumentLinks.defaultProps = {
    error: null,
    isLoading: false,
    onRetry: _.noop,
};

export default DocumentLinks;
