import { useRef, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalNextProvider } from '@jutro/components';
import _ from 'lodash';
import { commonMessages } from 'gw-capability-quoteandbind-common-react';
import authMessages from 'gw-digital-auth-react/LogoutWarning.messages';
import { messages as platformMessages } from 'gw-platform-translations';
import { useAppInsightsContext, SeverityLevel } from 'cnd-common-hooks-platform-react';
import {
    GW_SECURITY_ERROR,
    GW_SESSION_TIMEOUT,
    CND_DTO_VALIDATION_ERROR
} from '../constants/ErrorCodes';

export const REDIRECT_PATH = '/quote-tvl';

function useErrorHandler() {
    const history = useHistory();
    const appInsights = useAppInsightsContext();
    // eslint-disable-next-line no-unused-vars
    const handleError = useCallback(async (error) => {
        const errorMsg = _.isObject(error) ? JSON.stringify(error) : error;
        appInsights.trackException({
            error: errorMsg,
            severityLevel: SeverityLevel.Error
        });
        const appErrorCode = error?.appErrorCode;
        try {
            if (appErrorCode === GW_SECURITY_ERROR || appErrorCode === GW_SESSION_TIMEOUT) {
                try {
                    await ModalNextProvider.showAlert({
                        title: commonMessages.unableToGenerateAQuote,
                        message: authMessages.sessionTimeout,
                        status: 'info',
                        icon: 'mi-error-outline',
                        confirmButtonText: platformMessages.ok
                    });
                } finally {
                    history.replace({
                        pathname: '/',
                        state: {
                            allowWizardExit: true
                        }
                    });
                }
            } else {
                await ModalNextProvider.showAlert({
                    title: commonMessages.unableToGenerateAQuote,
                    message: error.customErrorMessage !== undefined ? error.customErrorMessage : commonMessages.somethingWentWrong,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: platformMessages.ok
                });
                if (appErrorCode !== CND_DTO_VALIDATION_ERROR) {
                    history.replace(REDIRECT_PATH, {});
                }
            }
        } catch (err) {
            // noop
        }
    }, [history, appInsights]);
    const handlers = useRef({ handleError });

    const updateRootCause = useCallback((error, config) => {
        const { appData } = error;
        const field = (appData || [])[0]?.path;
        _.set(error, 'customErrorMessage', config[field]);
    }, []);

    useEffect(() => {
        handlers.current = { handleError, updateRootCause };
    }, [handleError, updateRootCause]);

    return handlers.current;
}

export default useErrorHandler;
