import _ from 'lodash';

function Address(data) {
    this.country = '';
    this.addressType = 'home';

    if (data) {
        _.extend(this, data);
    }
}

export default Address;
