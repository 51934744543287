import { defineMessages } from 'react-intl';

export default defineMessages({
    successfulPaymentPageStepMessage1TextBG: {
        id: 'quoteandbind.staticInformation.successfulPaymentPageStepMessage1BG',
        defaultMessage: 'Всички документи, които са част от вашата застраховка (преддоговорната информация, информационния документ за застрахователния продукт и условията по застраховката) ще ви бъдат изпратени на заявения от вас адрес на електронна поща (email), за което вие предоставяте съгласие.'
    },
    successfulPaymentPageStepMessage1TextCZ: {
        id: 'quoteandbind.staticInformation.successfulPaymentPageStepMessage1CZ',
        defaultMessage: 'Veškeré dokumenty týkající se Vašeho pojištění byly rovněž zaslány na Vámi poskytnutou emailovou adresu.'
    },
    successfulPaymentPageStepMessage1TextHU: {
        id: 'quoteandbind.staticInformation.successfulPaymentPageStepMessage1HU',
        defaultMessage: 'A biztosításkötéssel kapcsolatos valamennyi tudnivalót és további információt az Ön által megadott e-mail címre küldött levelünkben talál. Levelünk mellékleteként megtalálhatja az alábbi dokumentumokat:'
    },
    successfulPaymentPageStepMessage2TextHU: {
        id: 'quoteandbind.staticInformation.successfulPaymentPageStepMessage2HU',
        defaultMessage: 'Biztosítási feltételek és Ügyféltájékoztató; Biztosítási kötvény; Angol nyelvű igazolás a biztosításkötésről'
    },
    successfulPaymentPageStepMessage3TextHU: {
        id: 'quoteandbind.staticInformation.successfulPaymentPageStepMessage3HU',
        defaultMessage: 'Biztosítási termékismertető (IPID)'
    },
    successfulPaymentPageStepMessage4TextHU: {
        id: 'quoteandbind.staticInformation.successfulPaymentPageStepMessage4HU',
        defaultMessage: 'Angol nyelvű igazolás a biztosításkötésről'
    },
    successfulPaymentPageStepMessage1TextPL: {
        id: 'quoteandbind.staticInformation.successfulPaymentPageStepMessage1PL',
        defaultMessage: 'Dokument potwierdzający zawarcie umowy ubezpieczenia zostanie wysłany na adres e-mail Ubezpieczającego.'
    },
    successfulPaymentPageStepMessage1TextRO: {
        id: 'quoteandbind.staticInformation.successfulPaymentPageStepMessage1RO',
        defaultMessage: 'Toate documentele precontractuale și contractuale, inclusiv polița de asigurare în format electronic, vor fi expediate pe adresa de email comunicata de dvs.'
    },
    successfulPaymentPageStepMessage1TextSK: {
        id: 'quoteandbind.staticInformation.successfulPaymentPageStepMessage1SK',
        defaultMessage: 'Všetky dokumenty týkajúce sa poistnej zmluvy Vám boli zaslané na Vašu e-mailovú adresu.'
    }
});
