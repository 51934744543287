import _ from 'lodash';
import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import queryString from 'query-string';
import {
    Redirect,
    withRouter,
    useLocation
} from 'react-router-dom';
import {
    ViewModelServiceContext,
    withViewModelService
} from 'gw-portals-viewmodel-react';
import { useCallService } from 'cnd-common-hooks-platform-react';
import { TagManagerService } from 'cnd-portals-util-js';
import { LoadSaveService } from 'cnd-capability-quoteandbind';
import { Loader, ModalNextProvider } from '@jutro/components';
import { messages as platformMessages } from 'gw-platform-translations';
import NeutralPayment from './components/NeutralPayment/NeutralPayment';
import SuccessfulPayment from './components/SuccessfulPayment/SuccessfulPayment';
import messages from './PaymentResult.messages';

function PaymentResult() {
    const { search } = useLocation();
    const { quoteID, sessionUUID, error } = queryString.parse(search);
    const [retryService, setRetryService] = useState(false);
    const [loading, setLoading] = useState(true);
    const [retry, setRetry] = useState(false);

    const viewModelService = useContext(ViewModelServiceContext);
    const [submissionVM, setSubmissionVM] = useState(
        viewModelService.create(
            {
                sessionUUID,
                quoteID
            },
            'pc',
            'edge.capabilities.quote.submission.dto.QuoteDataDTO'
        )
    );

    const retrieveBoundSubmission = useCallback(async () => {
        if (error) {
            return;
        }
        const submission = await LoadSaveService.retrieveBoundSubmission(
            quoteID,
            sessionUUID
        );
        submissionVM.value = submission;
        setSubmissionVM(_.clone(submissionVM));
        // analytics
        TagManagerService.pushBoundSubmissionEcommerceObject(
            5, // constant wizard step order number
            submissionVM
        );
    }, [quoteID, sessionUUID, submissionVM, error]);

    const {
        loading: loadingSubmission,
        error: loadingSubmissionError,
        done: submissionDone,
        updateOptions: updateService
    } = useCallService(
        retrieveBoundSubmission,
        { retryTimes: 3, retryDelayFactor: 2, retryDelayInSeconds: 5 },
        [retryService]
    );

    useEffect(() => {
        if (submissionDone && !retry) {
            if (loadingSubmissionError) {
                setRetry(true);
                updateService({ retryTimes: 5 });
                setRetryService(true);
                ModalNextProvider.showAlert({
                    title: messages.titleMessage,
                    message: messages.informMessage,
                    status: 'info',
                    icon: 'mi-info-outline',
                    confirmButtonText: platformMessages.ok
                }).then(_.noop);
            } else {
                setLoading(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingSubmission]);

    if (error) {
        return (
            <Redirect to={{
                pathname: '/unsuccessful-payment',
                state: {
                    sessionUUID,
                    quoteID
                }
            }}
            />
        );
    }

    return (
        <Loader loaded={retry ? !loadingSubmission : !loading}>
            {(() => {
                if (loadingSubmissionError) {
                    return <NeutralPayment />;
                }
                return <SuccessfulPayment submissionVM={submissionVM} />;
            })()}
        </Loader>
    );
}

PaymentResult.propTypes = {};

export default withViewModelService(withRouter(PaymentResult));
