import { defineMessages } from 'react-intl';

export default defineMessages({
    personalInformationPageHeader: {
        id: 'quoteandbind.tvl.step.personal-information.Personal information',
        defaultMessage: 'Personal information'
    },
    personalInformationPageInsuredPersonsHeader: {
        id: 'quoteandbind.tvl.step.personal-information.Insured Persons',
        defaultMessage: 'Insured Persons'
    },
    personalInformationPageInsuredPersonsHint: {
        id: 'quoteandbind.tvl.step.personal-information.Please provide the details about the Travelers to be insured in the Policy in the fields below. Please note, you cannot make any changes to the number of Insured persons or to the respective age-band any person. If you want to make any changes to the number of Insured persons or to respective age-band of any person, please start a new quote.',
        defaultMessage: 'Please provide the details about the Travelers to be insured in the Policy in the fields below. Please note, you cannot make any changes to the number of Insured persons or to the respective age-band any person. If you want to make any changes to the number of Insured persons or to respective age-band of any person, please start a new quote.'
    },
    personalInformationPageInsuredPersonsHintAfterRetrieval: {
        id: 'quoteandbind.tvl.step.personal-information.Please provide the details about the Travelers to be insured in the Policy in the fields below. If you want to make any changes to the Policy Holder’s prefilled data, number of Insured persons or to respective age-band of any person, please contact the producer.',
        defaultMessage: 'Please provide the details about the Travelers to be insured in the Policy in the fields below. If you want to make any changes to the Policy Holder’s prefilled data, number of Insured persons or to respective age-band of any person, please contact the producer.'
    },
    personalInformationPageInvalidAgeBandConfig: {
        id: 'quoteandbind.tvl.step.personal-information.Number of additional insured persons does not match with number in age band',
        defaultMessage: 'Number of additional insured persons does not match with number in age band'
    },
    personalInformationPageLicensePlate: {
        id: 'quoteandbind.tvl.step.personal-information.License Plate',
        defaultMessage: 'License Plate'
    },
    personalInformationFirstRegistrationDate: {
        id: 'quoteandbind.tvl.step.personal-information.First Registration Date',
        defaultMessage: 'First Registration Date'
    },
    personalInformationPageConsentHeader: {
        id: 'quoteandbind.tvl.step.personal-information.Personal Data Consent',
        defaultMessage: 'Personal Data Consent'
    },
    personalInformationPagePolicyHolder: {
        id: 'quoteandbind.tvl.step.personal-information.Policy Holder',
        defaultMessage: 'Policy holder'
    },
    personalInformationPageCopyHolderData: {
        id: 'quoteandbind.tvl.step.personal-information.Copy policy holder data',
        defaultMessage: 'Copy policy holder data'
    },
    personalInformationPageCopyHolderDataInfo: {
        id: 'quoteandbind.tvl.step.personal-information.If the Policyholder is one of the insureds, you can copy already provided data',
        defaultMessage: 'If the Policyholder is one of the insureds, you can copy already provided data'
    },
    personalInformationPageAddlInsuredDuplicate: {
        id: 'quoteandbind.tvl.step.personal-information.You have added at least 2 the same insureds. The insureds have to be unique to continue with the process.',
        defaultMessage: 'You have added at least 2 the same insureds. The insureds have to be unique to continue with the process.'
    },
    personalInformationPageAddintionalEmailText: {
        id: 'quoteandbind.tvl.step.personal-information.By providing your e-mail address, you consent to the Insurer communicating with you electronically. Please provide a real e-mail address.',
        defaultMessage: 'By providing your e-mail address, you consent to the Insurer communicating with you electronically. Please provide a real e-mail address.'
    }
});
