import React from 'react';
import PropTypes from 'prop-types';

function Icon(props) {
    const { className } = props;

    return <span className={className} />;
}

Icon.propTypes = {
    className: PropTypes.string.isRequired
};

export default Icon;
