import { defineMessages } from 'react-intl';

export default defineMessages({
    selectPlan: {
        id: 'quoteandbind.tvl.components.quote-table-mobile.Select plan',
        defaultMessage: 'Select plan'
    },
    selected: {
        id: 'quoteandbind.tvl.components.quote-table-mobile.Selected',
        defaultMessage: 'Selected'
    },
    additionalCoverOptions: {
        id: 'quoteandbind.tvl.components.quote-table-mobile.Additional cover options',
        defaultMessage: 'Additional cover options'
    },
    totalPriceForPackage: {
        id: 'quoteandbind.tvl.components.quote-table-mobile.Total price for package',
        defaultMessage: 'Total price for package'
    },
    totalPriceForPackageStaticInfo: {
        id: 'quoteandbind.tvl.components.quote-table-mobile.*Given price does not include taxes. Taxes will be included in the summary on the next step.',
        defaultMessage: '*Given price does not include taxes. Taxes will be included in the summary on the next step.'
    },
    alreadyAppliedDiscount: {
        id: 'quoteandbind.tvl.components.quote-table-mobile.There has been already an applied discount on addons',
        defaultMessage: 'There has been already an applied discount on addons'
    }
});
