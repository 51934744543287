import { CountryLayerCore } from 'cnd-common-portals-util-js';

export default {
    isVisibleOnQuotePage: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.BG],
                action: () => true
            },
            {
                countries: [
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.SK,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.RO
                ],
                action: () => false
            }
        ];

        return CountryLayerCore.callCorrectImpl(variants, country);
    }
};
