import _ from 'lodash';
import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    useContext,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { ModalNextProvider } from '@jutro/components';
import { ServiceManager } from '@jutro/services';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { LOBContext } from 'cnd-common-components-platform-react';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { CountryLayerService } from 'cnd-portals-util-js';
import { messages as platformMessages } from 'gw-platform-translations';
import { commonMessages } from 'gw-capability-quoteandbind-common-react';
import EditabilityService from '../../services/EditabilityService';
import metadata from './InsuredPersons.metadata.json5';
import messages from './InsuredPersons.messages';
import styles from './InsuredPersons.module.scss';

function InsuredPersons(props) {
    const {
        value: policyContactsVM, path, onValueChange, onValidate
    } = props;
    const translator = useContext(TranslatorContext);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { isFromQuoteRetrieval, quoteRetrievalClone } = useContext(LOBContext);
    const policyContactsClone = _.get(quoteRetrievalClone, 'baseData.policyContacts_Cnd');

    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    const localeService = ServiceManager.getService('locale-service');

    const {
        format: countryDateFormat,
    } = CountryLayerService.getDateFormats(appCountry);

    const {
        isComponentValid,
        registerComponentValidation,
        onValidate: setComponentValidation
    } = useValidation('InsuredPersons');

    const getFormValidity = useCallback(() => {
        return policyContactsVM.aspects.valid && policyContactsVM.aspects.subtreeValid;
    }, [policyContactsVM]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [getFormValidity, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, 'InsuredPersons');
        }
    }, [isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.children.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const handleCellData = useCallback((cells, rowIndex) => {
        cells.forEach((cell, cellIndex) => {
            if (cell !== '') {
                switch (cellIndex) {
                    case 0:
                        _.set(policyContactsVM.value, `${rowIndex}.contact.firstName`, cell);
                        break;
                    case 1:
                        _.set(policyContactsVM.value, `${rowIndex}.contact.lastName`, cell);
                        break;
                    case 2: {
                        const parsingLocale = localeService.getStoredLanguage();
                        let dateParse = moment(cell, countryDateFormat?.toUpperCase(), parsingLocale, true);
                        if (!dateParse.isValid()) {
                            dateParse = moment(cell, 'DD MM YYYY');
                        }

                        if (dateParse.isValid()) {
                            var dob = {
                                day: dateParse.date(),
                                month: dateParse.month(),
                                year: dateParse.year()
                            }
                            _.set(policyContactsVM.value, `${rowIndex}.contact.dateOfBirth`, dob);
                        }
                        break;
                    }
                    default:
                        // Is handled before
                        // eslint-disable-next-line no-useless-return
                        return;
                }
            }
        });
    }, [policyContactsVM, countryDateFormat]);

    const handlePaste = useCallback((clipboard) => {
        const contactsNumber = policyContactsVM?.children?.length;
        const clipboardRows = clipboard?.split(/\n|\r/).filter((row) => row !== '');
        if (clipboardRows.length > contactsNumber) {
            ModalNextProvider.showAlert({
                title: commonMessages.error,
                message: messages.insuredPersonsPasteInvalid,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: platformMessages.ok
            }).then(
                _.noop
            );
        } else if (clipboardRows && Array.isArray(clipboardRows)) {
            // eslint-disable-next-line no-unused-expressions
            clipboardRows?.forEach((row, rowIndex) => {
                if (rowIndex < policyContactsVM?.length) {
                    const cells = row.split(/\t/);
                    if (cells.length === 3) {
                        handleCellData(cells, rowIndex);
                    }
                }
            });
            forceUpdate();
        }
    }, [policyContactsVM, handleCellData, forceUpdate]);

    const handlePasteButton = async () => {
        try {
            const clipboard = await navigator.clipboard.readText();
            handlePaste(clipboard);
        } catch (err) {
            _.noop();
        }
    };

    // Check if Firefox
    const isPasteButtonVisible = typeof navigator.clipboard.readText !== 'undefined';

    const handleClearData = () => {
        _.get(policyContactsVM, 'value').forEach((__, index) => {
            _.unset(policyContactsVM.value, `${index}.contact.firstName`);
            _.unset(policyContactsVM.value, `${index}.contact.lastName`);
            _.unset(policyContactsVM.value, `${index}.contact.dateOfBirth`);
        });
        forceUpdate();
    };

    const INSURED_PERSONS_TABLE_FIRST_INPUT_ID = 'insuredPersonRowFirstname0';

    useEffect(() => {
        const handlePasteInFirstCell = (e) => {
            if (e?.target?.id === INSURED_PERSONS_TABLE_FIRST_INPUT_ID) {
                e?.preventDefault();
                const clipboard = e?.clipboardData?.getData('text');
                handlePaste(clipboard);
            }
        };

        window.addEventListener('paste', handlePasteInFirstCell);

        return () => {
            window.removeEventListener('paste', handlePasteInFirstCell);
        };
    }, [handlePaste]);


    const generateOverrides = useMemo(() => {
        const isMobile = breakpoint === 'phone' || breakpoint === 'tablet';
        if (policyContactsVM?.children && policyContactsVM.children.length > 0) {
            return policyContactsVM.value.reduce((all, contact, index) => ({
                ...all,
                [`insuredPersonRow${index}`]: {
                    visible: contact.contactRole === 'PolicyAddlNamedInsured',
                    className: `cndMarginBottomSM ${styles.insuredPersonRowGrid}` // Cnd
                },
                [`insuredPersonRowFirstname${index}`]: {
                    readOnly: EditabilityService.isReadOnly(policyContactsVM, `[${index}]contact.firstName`, isFromQuoteRetrieval, policyContactsClone),
                },
                [`insuredPersonRowLastname${index}`]: {
                    readOnly: EditabilityService.isReadOnly(policyContactsVM, `[${index}]contact.lastName`, isFromQuoteRetrieval, policyContactsClone)
                },
                [`insuredPersonRowDateOfBirth${index}`]: {
                    icon: breakpoint === 'phone' ? null : undefined,
                    popperPlacement: isMobile ? 'left-end' : 'bottom',
                    readOnly: EditabilityService.isReadOnly(policyContactsVM, `[${index}]contact.dateOfBirth`, isFromQuoteRetrieval, policyContactsClone)
                }
            }), {});
        }

        return null;
    }, [breakpoint, policyContactsVM, isFromQuoteRetrieval, policyContactsClone]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top'
        },
        insuredPersonsButtonContainer: {
            className: isPasteButtonVisible
                ? styles.insuredPersonsButtonContainer
                : styles.insuredPersonsButtonContainerSolo
        },
        insuredPersonsPasteDataInfo: {
            visible: isPasteButtonVisible
        },
        insuredPersonsPasteFromClipboardButton: {
            onClick: handlePasteButton,
            visible: isPasteButtonVisible
        },
        insuredPersonsPasteFromClipboardButtonInformation: {
            visible: isPasteButtonVisible
        },
        insuredPersonsRows: {
            data: policyContactsVM?.children,
        },
        insuredPersonsClearContactsValuesLinkWrapper: {
            className: styles.insuredPersonsButtonContainerSolo
        },
        insuredPersonsClearContactsValuesLink: {
            value: translator(messages.insuredPersonsClearInputsButton),
            title: translator(messages.insuredPersonsClearInputsButton),
            onClick: handleClearData
        },
        ...generateOverrides
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyContactsVM.children}
            overrideProps={overrideProps}
            onValueChange={handleValueChange}
            onValidationChange={setComponentValidation}
        />
    );
}

InsuredPersons.propTypes = {
    path: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired
};

export default InsuredPersons;
