import _ from 'lodash';
import React, {
    useEffect,
    useCallback,
    useContext,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { MasksUtil } from 'cnd-common-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { ContactDataService } from 'cnd-portals-util-js';
import { LOBContext } from 'cnd-common-components-platform-react';
import EditabilityService from '../../services/EditabilityService';
import Address from '../Address/Address';
import metadata from './Person.metadata.json5';
import metadataSummary from '../../pages/InsuranceSummaryPage/policyholder/PersonSummary.metadata.json5';
import messages from './Person.messages';
import SummaryContext from '../summary/SummaryContext';
import { INVALID_PHONE_MESSAGE, INVALID_EMAIL_MESSAGE } from '../../constants/ErrorCodes';


function Person(props) {
    const {
        id,
        onValidate,
        value: personVM,
        onValueChange,
        path,
        isEmailPhoneInfoMessageVisible,
        isResidentVisible,
        isPersonalIDVisible,
        isNationalIDVisible,
        isEmailNotificationInfoVisible,
        showErrors,
        filterValidationErrors
    } = props;
    const translator = useContext(TranslatorContext);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    const personalIDMask = useMemo(
        () => ContactDataService.getPersonalIDMask(appCountry), [appCountry]
    );
    const personalIDRequiredMinLength = useMemo(() => {
        return personalIDMask.replace(0, '').length;
    }, [personalIDMask]);

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);
    const { isFromQuoteRetrieval, quoteRetrievalClone } = useContext(LOBContext);
    const personClone = _.get(quoteRetrievalClone, 'baseData.accountHolder');

    const getFormValidity = useCallback(() => {
        const personalID = _.get(personVM, 'personalID_Cnd.value');
        const hasMinPersonalIdLength = !personalID
        || personalID.length >= personalIDRequiredMinLength;

        return hasMinPersonalIdLength && personVM.aspects.valid && personVM.aspects.subtreeValid;
    }, [personVM, personalIDRequiredMinLength]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [getFormValidity, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }

            if (changedPath.startsWith('primaryAddress.country')) {
                if (_.isEmpty(_.get(personVM, 'phone_Cnd.number.value'))) {
                    const phoneCountryCode = _.get(personVM, 'phone_Cnd.countryCode.aspects.availableValues')?.find(
                        (o) => o.code === value || o.code === `${value}_Cnd`
                    );
                    if (phoneCountryCode) {
                        _.set(personVM, 'phone_Cnd.countryCode', phoneCountryCode);
                    }
                }
            }
        },
        [onValueChange, path, personVM]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        personFirstName: {
            readOnly: readOnly || EditabilityService.isReadOnly(personVM, 'firstName', isFromQuoteRetrieval, personClone)
        },
        personLastName: {
            readOnly: readOnly || EditabilityService.isReadOnly(personVM, 'lastName', isFromQuoteRetrieval, personClone)
        },
        personDateOfBirth: {
            readOnly: readOnly || EditabilityService.isReadOnly(personVM, 'dateOfBirth', isFromQuoteRetrieval, personClone)
        },
        personPhone: {
            visible: !readOnly || _.get(personVM, 'phone_Cnd.number.value') != null,
            readOnly: readOnly || EditabilityService.isReadOnly(personVM, 'phone_Cnd.number', isFromQuoteRetrieval, personClone),
            /* eslint-disable camelcase */
            showErrors: filterValidationErrors && !_.isNil(filterValidationErrors('baseData.accountHolder')?.phone_Cnd),
            validationMessages: (filterValidationErrors && filterValidationErrors('baseData.accountHolder')?.phone_Cnd?.messages.map((msg) => {
                if (INVALID_PHONE_MESSAGE.test(msg)) {
                    return translator(messages.personInvalidPhoneNumber);
                }
                return msg;
            })) || personVM.phone_Cnd.aspects.validationMessages
        },
        personEmail: {
            readOnly: readOnly || EditabilityService.isReadOnly(personVM, 'emailAddress1', isFromQuoteRetrieval, personClone),
            showErrors: filterValidationErrors && !_.isNil(filterValidationErrors('baseData.accountHolder.emailAddress1')),
            validationMessages: (filterValidationErrors && filterValidationErrors('baseData.accountHolder.emailAddress1')?.messages.map((msg) => {
                if (INVALID_EMAIL_MESSAGE.test(msg)) {
                    return translator(messages.personInvalidEmail);
                }
                return msg;
            })) || personVM.emailAddress1.aspects.validationMessages
        },
        personProvideEmailOrMobileInfo: {
            visible: !readOnly && isEmailPhoneInfoMessageVisible
        },
        personIsResident: {
            visible: isResidentVisible,
            readOnly: readOnly
        },
        personPersonalID: {
            mask: personalIDMask,
            formatChars: MasksUtil.getMaskFormatCharacters(),
            visible: isPersonalIDVisible && (readOnly || EditabilityService.isReadOnly(personVM, 'personalID_Cnd', isFromQuoteRetrieval, personClone) !== true)
        },
        personPersonalIDReadOnly: { // to avoid InputMask problem with empty data
            visible: isPersonalIDVisible && EditabilityService.isReadOnly(personVM, 'personalID_Cnd', isFromQuoteRetrieval, personClone),
            readOnly: true
        },
        personNationalID: {
            label: translator(messages[ContactDataService.getIDLabel(appCountry)]),
            mask: _.get(personVM.value, 'resident_Cnd') ? ContactDataService.getNationalIDMask(appCountry) : '',
            formatChars: MasksUtil.getMaskFormatCharacters(),
            visible: isNationalIDVisible && (readOnly || EditabilityService.isReadOnly(personVM, 'passportNumber_Cnd', isFromQuoteRetrieval, personClone) !== true)
        },
        personNationalIDReadOnly: { // to avoid InputMask problem with empty data
            visible: isNationalIDVisible && EditabilityService.isReadOnly(personVM, 'passportNumber_Cnd', isFromQuoteRetrieval, personClone),
            readOnly: true
        },
        personEmailNotificationInfo: {
            visible: isEmailNotificationInfoVisible && !readOnly
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
        resolveComponentMap: {
            address: Address
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={readOnly ? metadataSummary.componentContent : metadata.componentContent}
                model={personVM}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

Person.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    isEmailPhoneInfoMessageVisible: PropTypes.bool,
    isResidentVisible: PropTypes.bool,
    isPersonalIDVisible: PropTypes.bool,
    isNationalIDVisible: PropTypes.bool,
    isEmailNotificationInfoVisible: PropTypes.bool,
    showErrors: PropTypes.bool.isRequired,
    filterValidationErrors: PropTypes.func
};
Person.defaultProps = {
    isEmailPhoneInfoMessageVisible: false,
    isResidentVisible: false,
    isPersonalIDVisible: false,
    isNationalIDVisible: false,
    isEmailNotificationInfoVisible: false,
    filterValidationErrors: undefined
};

export default Person;
