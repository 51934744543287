import React, { useContext, useCallback } from 'react';

import { BreakpointTrackerContext } from '@jutro/layout';

import { WizardPageTemplate, pageTemplateProps } from 'gw-portals-wizard-react';
import { MediaSideBar, WizardSingleErrorComponent } from 'gw-components-platform-react';

import WizardSidebarButtons from './WizardSidebarButtons/WizardSidebarButtons';

function QuoteWizardPageTemplate(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phone';
    const {
        children, acknowledgeError, errorsForStep, ...otherProps
    } = props;

    const renderMediaSidebar = useCallback(() => {
        const isMobile = breakpoint === 'tablet' || isPhone;
        return (
            <React.Fragment>
                <WizardSidebarButtons />
                {isMobile ? null : <MediaSideBar />}
            </React.Fragment>
        );
    }, [breakpoint, isPhone]);

    // Cnd custom: OOTB info bar changed and prepared for customization
    const renderQuoteInfoBox = useCallback(
        () => {
            /*
            TODO: Place for Insurance Summary box
            use "submissionVM" in parameter
            */
            return <div />;
        },
        []
    );

    return (
        <WizardPageTemplate
            renderContextComponent={renderQuoteInfoBox}
            renderWidgets={renderMediaSidebar}
            errorsForStep={errorsForStep}
            {...otherProps}
        >
            <WizardSingleErrorComponent
                acknowledgeError={acknowledgeError}
                issuesList={errorsForStep}
            />
            {children}
        </WizardPageTemplate>
    );
}

QuoteWizardPageTemplate.propTypes = pageTemplateProps;
export default QuoteWizardPageTemplate;
