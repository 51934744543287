import { defineMessages } from 'react-intl';

export default defineMessages({
    paymentInfoComponentFormHeader: {
        id: 'quoteandbind.tvl.components.paymentinfo.Payment info',
        defaultMessage: 'Payment info'
    },
    premiumBeforeDiscountIncludingIPT: {
        id: 'quoteandbind.tvl.components.policyholder.Insurance premium incl. IPT (before discount)',
        defaultMessage: 'Insurance premium incl. IPT (before discount)'
    },
    premiumBeforeDiscount: {
        id: 'quoteandbind.tvl.components.policyholder.Insurance premium (before discount)',
        defaultMessage: 'Insurance premium (before discount)'
    },
    discountAmount: {
        id: 'quoteandbind.tvl.components.policyholder.Discount amount',
        defaultMessage: 'Discount amount'
    },
    afterDiscountAmount: {
        id: 'quoteandbind.tvl.components.policyholder.Insurance premium (after discount)',
        defaultMessage: 'Insurance premium (after discount)'
    },
    premiumBeforeTaxes: {
        id: 'quoteandbind.tvl.components.policyholder.Insurance premium',
        defaultMessage: 'Insurance premium'
    },
    iptTax: {
        id: 'quoteandbind.tvl.components.policyholder.IPT Tax',
        defaultMessage: 'IPT Tax'
    },
    totalPremiumIncludingIPT: {
        id: 'quoteandbind.tvl.components.policyholder.Total premium for the insurance period incl. IPT',
        defaultMessage: 'Total premium for the insurance period incl. IPT'
    },
    totalPremiumTotalSumDue: {
        id: 'quoteandbind.tvl.components.policyholder.Total sum due',
        defaultMessage: 'Total sum due'
    },
    totalPremiumForTheInsurancePeriod: {
        id: 'quoteandbind.tvl.components.policyholder.Total premium for the insurance period',
        defaultMessage: 'Total premium for the insurance period'
    },
    premiumToCoverRisk: {
        id: 'quoteandbind.tvl.components.paymentinfo.Premium to cover risk',
        defaultMessage: 'Premium to cover risk'
    },
    distributionExpenses: {
        id: 'quoteandbind.tvl.components.paymentinfo.Distribution expenses',
        defaultMessage: 'Distribution expenses'
    },
    otherExpenses: {
        id: 'quoteandbind.tvl.components.paymentinfo.Other expenses',
        defaultMessage: 'Other expenses'
    },
});
