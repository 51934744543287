/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { CurrencyFormatterService, CountryLayerService } from 'cnd-portals-util-js';
import { isBoolean } from 'lodash';
import CoverageLimit from '../CoverageLimit/CoverageLimit';
import styles from './CoverageTerm.module.scss';

const priceStyles = {
    price: {
        amount: styles.price
    }
};

const { formatDate } = CountryLayerService;

function TVLCoverageTerm(props) {
    const {
        name, chosenTermValue, directValue, currency_Cnd, valueType, directBooleanValue
    } = props;
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    let value;
    if (valueType === 'datetime') {
        value = formatDate(chosenTermValue, appCountry);
    } else if (directValue) {
        value = CurrencyFormatterService.formatPrice(directValue, appCountry);
    } else if (directBooleanValue) {
        value = directBooleanValue;
    } else {
        value = chosenTermValue;
    }

    return (
        <div className={styles.covTermContainer}>
            <div className={styles.covTermNameContainer}>
                {name}
            </div>
            <div className={styles.covTermValueContainer}>
                {
                    isBoolean(value) ? (
                        value === true
                            ? <span className={`mir mi-check-circle ${styles.yesIcon}`} />
                            : <span className={`mir mi-cancel ${styles.noIcon}`} />
                    ) : (
                        <CoverageLimit value={value} currency={currency_Cnd} styles={priceStyles} />
                    )
                }
            </div>
        </div>
    );
}

TVLCoverageTerm.propTypes = {
    name: PropTypes.string.isRequired,
    chosenTermValue: PropTypes.string,
    directValue: PropTypes.number,
    currency_Cnd: PropTypes.string,
    valueType: PropTypes.string,
    directBooleanValue: PropTypes.bool
};

TVLCoverageTerm.defaultProps = {
    chosenTermValue: undefined,
    directValue: undefined,
    currency_Cnd: undefined,
    valueType: undefined,
    directBooleanValue: undefined
};

export default TVLCoverageTerm;
