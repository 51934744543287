import _ from 'lodash';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { PaymentInfoSummaryService } from 'cnd-common-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './PaymentInfoSummary.metadata.json5';
import messages from './PaymentInfoSummary.messages';

function PaymentInfoSummary(props) {
    const {
        quote: offeredQuote,
        isDiscountApplied: isValidDiscountCode,
        isPerson: isPersonHolderType
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    const isNotEmpty = (fieldName) => {
        return _.get(offeredQuote, `premium.${fieldName}.amount`) !== undefined;
    };

    const isFractionsVisible = (fieldName) => {
        return _.get(offeredQuote, `premium.${fieldName}.amount`) % 1 !== 0;
    };

    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            readOnly: true
        },
        premiumBeforeDiscountAndTaxes: {
            // check visibility if discount code is available
            // or quoted country is BG (for BG shows premium before taxes)
            visible: (isValidDiscountCode || PaymentInfoSummaryService.isPremiumVisible(appCountry))
                && isNotEmpty('totalBeforeTaxes'),
            label: isValidDiscountCode
                ? translator(messages[`premiumBeforeDiscount${PaymentInfoSummaryService.getPremiumLabelKey(appCountry)}`])
                : translator(messages.premiumBeforeTaxes),
            showFractions: isFractionsVisible('totalBeforeTaxes')
        },
        discountAmount: {
            /* eslint-disable camelcase */
            visible: isValidDiscountCode
                && isNotEmpty('discountAmount_Cnd'),
            showFractions: isFractionsVisible('discountAmount_Cnd')
        },
        afterDiscountAmount: {
            visible: isValidDiscountCode
                && PaymentInfoSummaryService.isAfterDiscountAmountVisible(appCountry)
                && isNotEmpty('totalAfterDiscount_Cnd'),
            showFractions: isFractionsVisible('totalAfterDiscount_Cnd')
        },
        iptTax: {
            visible: PaymentInfoSummaryService.isIPTTaxVisible(appCountry)
                && isNotEmpty('taxes'),
            showFractions: isFractionsVisible('taxes')
        },
        totalPremium: {
            label: translator(messages[`totalPremium${PaymentInfoSummaryService.getTotalPremiumLabelKey(appCountry)}`]),
            visible: isNotEmpty('total'),
            showFractions: isFractionsVisible('total')
        },
        premiumToCoverRisk: {
            /* eslint-disable camelcase */
            visible: isNotEmpty('premiumToCoverRisk_Cnd') && isPersonHolderType,
            showFractions: isFractionsVisible('premiumToCoverRisk_Cnd')
        },
        distributionExpenses: {
            /* eslint-disable camelcase */
            visible: isNotEmpty('distributionExpenses_Cnd') && isPersonHolderType,
            showFractions: isFractionsVisible('distributionExpenses_Cnd')
        },
        otherExpenses: {
            /* eslint-disable camelcase */
            visible: isNotEmpty('otherExpenses_Cnd') && isPersonHolderType,
            showFractions: isFractionsVisible('otherExpenses_Cnd')
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={offeredQuote}
            overrideProps={overrideProps}
        />
    );
}

PaymentInfoSummary.propTypes = {
    quote: PropTypes.shape({}).isRequired,
    isDiscountApplied: PropTypes.bool.isRequired,
    isPerson: PropTypes.bool.isRequired
};
export default PaymentInfoSummary;
