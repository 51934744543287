import { noop as _noop } from 'lodash';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { ModalNextProvider } from '@jutro/components';
import { messages as commonMessages } from 'gw-platform-translations';
import messages from './ProducerPage.messages';

function decodeBase64(str) {
    return decodeURIComponent(escape(window.atob(str)));
}

function ProducerPage({ tvlWizardPath }) {
    const { producer } = useParams();
    try {
        const { userId, producerCode, offering, discountCode } = JSON.parse(
            decodeBase64(producer)
        );
        return (
            <Redirect
                to={{
                    pathname: tvlWizardPath,
                    state: {
                        producer: {
                            agentID: userId,
                            producerCode,
                            virtualProduct: offering,
                            discountCode,
                        },
                    },
                }}
            />
        );
    } catch (error) {
        ModalNextProvider.showAlert({
            title: messages.error,
            message: messages.invalidProducerCode,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok,
        }).catch(_noop);
        return null;
    }
}

export default ProducerPage;
