import { CountryLayerCore } from 'cnd-common-portals-util-js';
import * as QuoteConstants from '../constants/QuoteConstants';

export default function isValidTermForCountry(term, country) {
    if (!country) {
        return false;
    }

    const validInCountry = [
        {
            term: QuoteConstants.EMERGENCY_MEDICAL_EXPENSES_ALCOHOL_CLAUSE_PUBLIC_ID,
            countries: [CountryLayerCore.Countries.RO]
        },
        {
            term: QuoteConstants.ASSISTANCE_REPATRIATION_OF_VEHICLE_PUBLIC_ID,
            countries: [
                CountryLayerCore.Countries.BG,
                CountryLayerCore.Countries.HU,
                CountryLayerCore.Countries.PL,
                CountryLayerCore.Countries.RO,
                CountryLayerCore.Countries.SK
            ]
        },
        {
            term: QuoteConstants.CAR_ASSISTANCE_LICENSE_PLATE_COV_TERM_PUBLIC_ID,
            countries: []
        },
        {
            term: QuoteConstants.CAR_ASSISTANCE_FIRST_REGISTRATION_COV_TERM_PUBLIC_ID,
            countries: []
        },
        {
            term: QuoteConstants.CANCELLATION_VARIANT_COV_TERM_PUBLIC_ID,
            countries: []
        }

    ];

    const invalidTerm = validInCountry.find((item) => item.term === term.publicID);
    return invalidTerm ? invalidTerm.countries.includes(country.toUpperCase()) : true;
}
