import { defineMessages } from 'react-intl';

export default defineMessages({
    selectPlan: {
        id: 'quoteandbind.tvl.components.quote-table.Select plan',
        defaultMessage: 'Select plan'
    },
    selected: {
        id: 'quoteandbind.tvl.components.quote-table.Selected',
        defaultMessage: 'Selected'
    },
    additionalCoverOptions: {
        id: 'quoteandbind.tvl.components.quote-table.Additional cover options',
        defaultMessage: 'Additional cover options'
    },
    totalPriceForPackage: {
        id: 'quoteandbind.tvl.components.quote-table.Total price for package',
        defaultMessage: 'Total price for package'
    },
    totalPriceForPackageStaticInfo: {
        id: 'quoteandbind.tvl.components.quote-table.*Given price does not include taxes. Taxes will be included in the summary on the next step.',
        defaultMessage: '*Given price does not include taxes. Taxes will be included in the summary on the next step.'
    },
    no: {
        id: 'quoteandbind.tvl.components.quote-table.No',
        defaultMessage: 'No'
    },
    yes: {
        id: 'quoteandbind.tvl.components.quote-table.Yes',
        defaultMessage: 'Yes'
    },
    add: {
        id: 'quoteandbind.tvl.components.quote-table.Add',
        defaultMessage: 'Add'
    },
    remove: {
        id: 'quoteandbind.tvl.components.quote-table.Remove',
        defaultMessage: 'Remove'
    },
    unlimited: {
        id: 'quoteandbind.tvl.components.quote-table.Unlimited',
        defaultMessage: 'Unlimited'
    },
    alreadyAppliedDiscount: {
        id: 'quoteandbind.tvl.components.quote-table.There has been already an applied discount on addons',
        defaultMessage: 'There has been already an applied discount on addons'
    },
    coverageUnavailable: {
        id: 'quoteandbind.tvl.components.quote-table.Coverage is unavailable due to exceeded age limit',
        defaultMessage: 'Coverage { coverage } is unavailable due to exceeded age limit.'
    },
    carAssistanceUnavailable: {
        id: 'quoteandbind.tvl.components.quote-table.Car assistance unavailable description',
        defaultMessage: 'This cover is not available for the countries, where you are travelling.'
    }
});
