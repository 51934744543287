import _ from 'lodash';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import useStaticInformation from './hooks/useStaticInformation';
import styles from './StaticInformation.module.scss';

const linkStyle = {
    display: 'inline',
    verticalAlign: 'baseline'
};

function StaticInformation(props) {
    const { step, country, staticInformationData } = props;

    const information = useStaticInformation(step, country?.toUpperCase(), staticInformationData);
    const translator = useContext(TranslatorContext);

    const createLinks = (links) => {
        const formattedLinks = {};
        _.each(links, (link, i) => {
            formattedLinks[`link${i + 1}`] = (
                <Link
                    href={translator(link.url)}
                    target="_blank"
                    style={linkStyle}
                >
                    {translator(link.description)}
                </Link>
            );
        });
        return formattedLinks;
    };

    return (
        <div>
            {_.map(information, ({
                links, rowStyle, ...message
            }) => {
                const rowClassNames = rowStyle?.map((c) => styles[c]) || [];
                const className = rowClassNames.join(' ') || '';
                return (
                    <p className={className || null}>
                        <FormattedMessage
                            {...message.id}
                            values={createLinks(links)}
                        />
                    </p>
                );
            })}
        </div>
    );
}

StaticInformation.propTypes = {
    country: PropTypes.string.isRequired,
    step: PropTypes.string.isRequired,
    staticInformationData: PropTypes.shape.isRequired
};

export default StaticInformation;
