import _ from 'lodash';
import { useMemo } from 'react';

function useStaticInformation(step, country, staticInformationData) {
    return useMemo(() => {
        if (!(step && country)) return [];

        const information = _.get(staticInformationData, [step, country]);
        if (_.isNil(information)) return [];

        return information;
    }, [step, country, staticInformationData]);
}

export default useStaticInformation;
