import _ from 'lodash';
import React, { useCallback, useContext, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Loader } from '@jutro/components';
import {
    ViewModelForm,
    withViewModelService
} from 'gw-portals-viewmodel-react';
import {
    OfferingTypes,
    LOBConstants,
    useSelectedVariant,
    useAddonCovers,
    useCountries,
    useTripZone,
    Travellers,
    DOWNLOAD_RETRY_TIMES,
    DOWNLOAD_DELAY_FACTOR,
    DOWNLOAD_RETRY_DELAY_IN_SECONDS
} from 'cnd-capability-quoteandbind-tvl-react';
import { CountrySpecificUtil } from 'cnd-common-portals-util-js';
import { LOBContext } from 'cnd-common-components-platform-react';
import { LoadSaveService } from 'cnd-capability-quoteandbind';
import { useCallService, useStoredCountry } from 'cnd-common-hooks-platform-react';
import { TranslatorContext } from '@jutro/locale';
import YourPass from '../YourPass/YourPass';
import metadata from './SuccessfulPayment.metadata.json5';
import styles from './SuccessfulPayment.module.scss';
import { StaticInformationData } from '../../../../constants/StaticInformationData';
import messages from './SuccessfulPayment.messages';

function SuccessfulPayment(props) {
    const { submissionVM } = props;

    const breakpoint = useContext(BreakpointTrackerContext);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    const selectedVariant = useSelectedVariant(submissionVM);
    const addonCovers = useAddonCovers(submissionVM);
    const destination = useCountries(submissionVM);
    const tripZone = useTripZone(submissionVM);
    const translator = useContext(TranslatorContext);

    const selectedOffering = _.get(
        submissionVM,
        'baseData.offering_Cnd.value.code'
    );

    useLayoutEffect(() => {
        document.title = translator(messages.tvlSuccessfulPaymentPageTitle) + ' | Colonnade Insurance S.A.';
    }, []);

    const fetchInitialDataCnd = useCallback(async () => {
        const data = await LoadSaveService.getInitialData(
            null, LOBConstants.TRAVEL_INDIVIDUAL_PRODUCT_CODE, appCountry, {}
        );
        return data;
    }, [appCountry]);

    const { loading, data } = useCallService(fetchInitialDataCnd, {
        retryTimes: DOWNLOAD_RETRY_TIMES,
        retryDelayFactor: DOWNLOAD_DELAY_FACTOR,
        retryDelayInSeconds: DOWNLOAD_RETRY_DELAY_IN_SECONDS,
        initialIsLoading: true
    }, []);

    const virtualProductCode = _.get(
        submissionVM,
        'baseData.virtualProductCode_Cnd.value'
    );

    const producerCode = _.get(submissionVM.value, 'baseData.producerCodeOfRecord_Cnd');
    const producerObjectVM = _.get(submissionVM, 'agentReferenceData_Cnd');

    const filterDocuments = useCallback((docs) => {
        if (appCountry === CountrySpecificUtil.Countries.BG) {
            return _.filter(docs, (doc) => doc?.documentType !== 'gtc_cnd');
        // eslint-disable-next-line no-else-return
        } else if (appCountry === CountrySpecificUtil.Countries.SK) {
            return docs;
        }
        return _.filter(docs, (doc) => doc?.documentType !== 'policy_summary');
    }, [appCountry]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
        },
        tvlSuccessfulPaymentPageNumberOfTravelers: {
            model: _.get(submissionVM, 'lobData.travel.ageBands')
        },
        tvlSuccessfulPaymentPageProductType: {
            value: _.get(selectedVariant, 'branchName'),
            visible:
                [
                    OfferingTypes.ANNUAL,
                    OfferingTypes.SINGLE,
                    OfferingTypes.DOMESTIC,
                ].indexOf(selectedOffering) > -1
                    && virtualProductCode !== LOBConstants.DOMESTIC_TRIP_DIRECT_CZECH
        },
        tvlSuccessfulPaymentPageAdditionalCovers: {
            value: addonCovers,
            visible:
                [
                    OfferingTypes.ANNUAL,
                    OfferingTypes.SINGLE,
                    OfferingTypes.DOMESTIC,
                ].indexOf(selectedOffering) > -1,
        },
        tvlSuccessfulPaymentPageDestination: {
            value: destination,
            visible:
                [OfferingTypes.CANCELLATION, OfferingTypes.SINGLE].indexOf(
                    selectedOffering
                ) > -1,
        },
        tvlSuccessfulPaymentPageTripZone: {
            value: tripZone,
            visible: selectedOffering === OfferingTypes.ANNUAL,
        },
        tvlSuccessfulPaymentPageDatesOfTravel: {
            visible:
                [
                    OfferingTypes.CANCELLATION,
                    OfferingTypes.SINGLE,
                    OfferingTypes.DOMESTIC,
                ].indexOf(selectedOffering) > -1,
        },
        tvlSuccessfulPaymentPageTripPurchaseDate: {
            visible: selectedOffering === OfferingTypes.CANCELLATION,
        },
        tvlSuccessfulPaymentPagePolicyTotalAmount: {
            showFractions: _.get(selectedVariant, 'premium.total.amount') % 1 !== 0
        },
        tvlSuccessfulPaymentPageTripTicketCost: {
            visible: selectedOffering === OfferingTypes.CANCELLATION,
            showFractions: _.get(submissionVM, 'lobData.travel.totalTripCost.amount.value') % 1 !== 0
        },
        tvlSuccessfulPaymentPageInsuranceVariant: {
            value: _.get(selectedVariant, 'branchName'),
            visible: selectedOffering === OfferingTypes.CANCELLATION,
        },
        tvlSuccessfulPaymentPagePolicyDocuments: {
            showTitle: false,
            showQuote: false,
            model: submissionVM,
            filterDocuments,
            waitForDocumentsReady: true
        },
        tvlSuccessfulPaymentPageYourPass: {
            model: submissionVM
        },
        tvlSuccessfulPaymentPageBuyAnotherPolicyContainer: {
            visible: selectedOffering !== OfferingTypes.CANCELLATION,
        },
        tvlSuccessfulPaymentBuyAnotherPolicyLink: {
            to: {
                pathname: '/quote-tvl',
                state: {
                    cancellationUpselling: true,
                    producer: (producerObjectVM ? { ...producerObjectVM.value } : { producerCode }) || null
                }
            }
        },
        tvlSuccessfulPaymentPageStaticInformation: {
            step: 'successfulPaymentPage',
            country: appCountry,
            staticInformationData: StaticInformationData
        }
    };

    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {
            yourpass: YourPass,
            travellers: Travellers
        },
        resolveClassNameMap: styles,
    };


    return (
        <Loader loaded={!loading}>
            <LOBContext.Provider
                value={{
                    availableAgeBands: data?.ageBands
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    classNameMap={styles}
                    componentMap={resolvers.resolveComponentMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </LOBContext.Provider>
        </Loader>
    );
}

SuccessfulPayment.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
};

export default withViewModelService(SuccessfulPayment);
