import _ from 'lodash';
import React, {
    useEffect, useContext, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { AddressService, CountryLayerService } from 'cnd-portals-util-js';
import { MasksUtil } from 'cnd-common-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { useValidation } from 'gw-portals-validation-react';
import { LOBContext } from 'cnd-common-components-platform-react';
import EditabilityService from '../../services/EditabilityService';
import metadata from './Address.metadata.json5';
import messages from './Address.messages';
import SummaryContext from '../summary/SummaryContext';

const KOSOVO_CODE = 'XK';

function Address(props) {
    const translator = useContext(TranslatorContext);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    const {
        id,
        value: addressVM,
        onValueChange,
        onValidate,
        path,
        isApartmentNumberHidden,
        isGpsLocationVisible,
        showErrors
    } = props;

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation(id);

    const { readOnly } = useContext(SummaryContext);
    const { isFromQuoteRetrieval, quoteRetrievalClone } = useContext(LOBContext);
    const addressClone = _.get(quoteRetrievalClone, 'baseData.accountHolder.primaryAddress');
    const availableValues = _.get(addressVM, 'country.aspects.availableValues');

    useEffect(() => {
        if (addressVM.country.value === undefined) {
            addressVM.country.value = appCountry.toUpperCase();
        }
    }, [addressVM.country.value, appCountry]);

    const getFormValidity = useCallback(() => {
        return addressVM.aspects.valid && addressVM.aspects.subtreeValid;
    }, [addressVM]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [getFormValidity, registerComponentValidation]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const availableCountries = useMemo(() => {
        const skipKosovo = !CountryLayerService.isKosovoAvailableForAccountHolder(appCountry);
        const values = skipKosovo ? availableValues.filter((c) => c.code !== KOSOVO_CODE) : availableValues;

        return values && values.map((typecode) => {
            return {
                code: typecode.code,
                name: translator({
                    id: typecode.name,
                    defaultMessage: typecode.name
                })
            };
        });
    }, [availableValues, translator]);

    const getAppCountry = useMemo(() => {
        const defaultCountry = availableCountries.find(
            (countryEl) => countryEl.code === appCountry
        );
        return translator({
            id: `typekey.Country.${defaultCountry.code}`,
            defaultMessage: defaultCountry.code
        });
    }, [appCountry, availableCountries, translator]);

    const cleanVoivodeshipField = useCallback(
        (countryCode) => {
            if (!AddressService.isStateVisible(countryCode)) {
                _.unset(addressVM.value, 'state');
                handleValueChange(addressVM.value.state, 'state');
            }
        },
        [handleValueChange, addressVM]
    );

    const countryChanged = useCallback(
        (value, changedPath) => {
            cleanVoivodeshipField(value);
            handleValueChange(value, changedPath);
        },
        [handleValueChange, cleanVoivodeshipField]
    );

    const isNotEmptyIfInReadOnlyMode = useCallback((fieldName) => {
        return readOnly ? _.get(addressVM.value, fieldName) !== undefined : true;
    }, [addressVM, readOnly]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            showErrors,
            readOnly
        },
        quoteAddressCountry: {
            availableValues: availableCountries,
            onValueChange: countryChanged,
            value: _.get(addressVM.value, 'country') || getAppCountry,
            visible: isNotEmptyIfInReadOnlyMode('country'),
            readOnly: readOnly
        },
        quoteAddressState: {
            visible: AddressService.isStateVisible(_.get(addressVM.value, 'country', isFromQuoteRetrieval, addressClone)) && isNotEmptyIfInReadOnlyMode('state'),
            label: translator(messages[`quoteAddress${AddressService.getStateLabelKey(_.get(addressVM.value, 'country'))}`]),
            readOnly: readOnly || EditabilityService.isReadOnly(addressVM, 'state', addressClone),
            searchable: true
        },
        quoteAddressApartmentNumber: {
            visible: !isApartmentNumberHidden && isNotEmptyIfInReadOnlyMode('addressLine3'),
            readOnly: readOnly || EditabilityService.isReadOnly(addressVM, 'addressLine3', isFromQuoteRetrieval, addressClone)
        },
        quoteAddressGps: {
            visible: isGpsLocationVisible && isNotEmptyIfInReadOnlyMode('gpsCoordinates'),
            readOnly: readOnly || EditabilityService.isReadOnly(addressVM, 'gpsCoordinates', isFromQuoteRetrieval, addressClone)
        },
        quoteAddressPostcode: {
            mask: AddressService.getPostalCodeMask(_.get(addressVM.value, 'country')),
            formatChars: MasksUtil.getMaskFormatCharacters(),
            visible: !readOnly && EditabilityService.isReadOnly(addressVM, 'postalCode', isFromQuoteRetrieval, addressClone) !== true
        },
        quoteAddressPostcodeReadOnly: { // to avoid InputMask problem with empty data
            visible: (readOnly || EditabilityService.isReadOnly(addressVM, 'postalCode', isFromQuoteRetrieval, addressClone)) && isNotEmptyIfInReadOnlyMode('postalCode'),
            readOnly: true
        },
        quoteAddressCity: {
            visible: isNotEmptyIfInReadOnlyMode('city'),
            readOnly: readOnly || EditabilityService.isReadOnly(addressVM, 'city', isFromQuoteRetrieval, addressClone)
        },
        quoteAddressStreet: {
            visible: isNotEmptyIfInReadOnlyMode('addressLine1'),
            readOnly: readOnly || EditabilityService.isReadOnly(addressVM, 'addressLine1', isFromQuoteRetrieval, addressClone)
        },
        quoteAddressStreetNumber: {
            visible: isNotEmptyIfInReadOnlyMode('addressLine2'),
            readOnly: readOnly || EditabilityService.isReadOnly(addressVM, 'addressLine2', isFromQuoteRetrieval, addressClone)
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={addressVM}
                overrideProps={overrideProps}
                onValueChange={handleValueChange}
                onValidationChange={setComponentValidation}
            />
        </div>
    );
}

Address.propTypes = {
    value: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    isApartmentNumberHidden: PropTypes.bool,
    isGpsLocationVisible: PropTypes.bool,
    showErrors: PropTypes.bool.isRequired
};
Address.defaultProps = {
    isApartmentNumberHidden: false,
    isGpsLocationVisible: false
};
export default Address;
