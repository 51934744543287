import { defineMessages } from 'react-intl';

export default defineMessages({
    companyName: {
        id: 'quoteandbind.components.company.Company Name',
        defaultMessage: 'Company Name'
    },
    companyRegistrationID: {
        id: 'quoteandbind.components.company.Registration ID',
        defaultMessage: 'Registration ID'
    },
    companyIsVATPayer: {
        id: 'quoteandbind.components.company.VAT Payer?',
        defaultMessage: 'VAT Payer?'
    },
    companyTaxID: {
        id: 'quoteandbind.components.company.Tax ID',
        defaultMessage: 'Tax ID'
    },
    companyPhone: {
        id: 'quoteandbind.components.company.Phone',
        defaultMessage: 'Phone'
    },
    companyEmail: {
        id: 'quoteandbind.components.company.Email',
        defaultMessage: 'Email'
    },
    companyProvideEmailOrMobileInfo: {
        id: 'quoteandbind.components.company.Please provide an email address or mobile phone, it will be used for the purpose of companyal data processing.',
        defaultMessage: 'Please provide an email address or mobile phone, it will be used for the purpose of companyal data processing.'
    },
    companyEmailNotificationInfo: {
        id: 'quoteandbind.components.company.If the email address is not provided claim-status notifications will not be sent.',
        defaultMessage: 'If the email address is not provided claim-status notifications will not be sent.'
    },
    companySummaryPhone: {
        id: 'quoteandbind.components.company.Phone',
        defaultMessage: 'Phone'
    },
    companyInvalidPhoneNumber: {
        id: 'quoteandbind.components.company.The phone number is incorrect for chosen country code.',
        defaultMessage: 'The phone number is incorrect for chosen country code.'
    },
    companyInvalidEmail: {
        id: 'quoteandbind.components.company.The email is not valid',
        defaultMessage: 'The email is not valid'
    }
});
