import _ from 'lodash';
import Person from './Person';

function PolicyContact(data, role) {
    if (data) {
        _.extend(this, data);
    }
    this.contact = new Person(data?.contact);
    this.contactRole = role || data?.contactRole;
}


export default PolicyContact;
