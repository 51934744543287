import { setComponentMapOverrides } from '@jutro/uiconfig';

import TVLTripDetailsPage from './pages/TripDetailsPage/TripDetailsPage';
import TVLQuotePage from './pages/QuotePage/QuotePage';
import TVLPersonalInformationPage from './pages/PersonalInformationPage/PersonalInformationPage';
import TVLInsuranceSummaryPage from './pages/InsuranceSummaryPage/InsuranceSummaryPage';
import TVLPaymentPage from './pages/PaymentPage/PaymentPage';
import TVLProductLinks from './components/ProductLinks/ProductLinks';


setComponentMapOverrides(
    {
        TVLTripDetailsPage: { component: 'TVLTripDetailsPage' },
        TVLQuotePage: { component: 'TVLQuotePage' },
        TVLPersonalInformationPage: { component: 'TVLPersonalInformationPage' },
        TVLInsuranceSummaryPage: { component: 'TVLInsuranceSummaryPage' },
        TVLPaymentPage: { component: 'TVLPaymentPage' },
        TVLProductLinks: { component: 'TVLProductLinks' }
    },
    {
        TVLTripDetailsPage,
        TVLQuotePage,
        TVLPersonalInformationPage,
        TVLInsuranceSummaryPage,
        TVLPaymentPage,
        TVLProductLinks
    }
);

// eslint-disable-next-line import/prefer-default-export
export { DOWNLOAD_RETRY_TIMES, DOWNLOAD_DELAY_FACTOR, DOWNLOAD_RETRY_DELAY_IN_SECONDS } from './constants/Commons';
export { default as TVLWizard } from './TVLWizard';
export { default as QuoteInfoBox } from './components/QuoteInfoBox/QuoteInfoBox';
export { default as DocumentLinks } from './components/DocumentLinks/DocumentLinks';
export { default as Travellers } from './components/QuoteInfoBox/components/Travellers/Travellers';
export {
    useSelectedVariant,
    useAddonCovers,
    useCountries,
    useTripZone
} from './hooks/useSubmission';
export { default as OfferingTypes } from './constants/OfferingTypes';
export { default as LOBConstants } from './constants/LOBConstants';
export { default as WizardConfig } from './config/tvl-wizard-config.json5';
export { default as TVLRouteConfig } from './config/tvl-route-config.json5';
