import _ from 'lodash';

let addons = null;

function saveAddons(flavours) {
    addons = _.reduce(flavours, (result, flavour) => {
        const addonCoverages = _.get(flavour, 'coverages.addonCoverages');
        // eslint-disable-next-line no-param-reassign
        result[flavour.branchName] = {
            branchName: flavour.branchName,
            addonCoverages: _.keyBy(addonCoverages, 'publicID')
        };
        return result;
    }, {});
}

function getAddons() {
    return addons;
}

function clearAddons() {
    addons = null;
}

export default {
    clearAddons,
    saveAddons,
    getAddons,
};
