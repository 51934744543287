import { defineMessages } from 'react-intl';

export default defineMessages({
    addAddon: {
        id: 'quoteandbind.tvl.components.quote-table-mobile.Add',
        defaultMessage: 'Add'
    },
    removeAddon: {
        id: 'quoteandbind.tvl.components.quote-table-mobile.Remove',
        defaultMessage: 'Remove'
    },
    coverageUnavailable: {
        id: 'quoteandbind.tvl.components.quote-table-mobile.Coverage is unavailable due to exceeded age limit',
        defaultMessage: 'Coverage { coverage } is unavailable due to exceeded age limit.'
    },
    carAssistanceUnavailable: {
        id: 'quoteandbind.tvl.components.quote-table-mobile.Car assistance unavailable description',
        defaultMessage: 'This cover is not available for the countries, where you are travelling.'
    }
});
