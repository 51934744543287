import { defineMessages } from 'react-intl';

export default defineMessages({
    tripPurposeHolidayAbroad: {
        id: 'quoteandbind.tvl.preliminaryQuestions.Holiday abroad',
        defaultMessage: 'Holiday abroad'
    },
    tripPurposeBusinessTrip: {
        id: 'quoteandbind.tvl.preliminaryQuestions.Business trip',
        defaultMessage: 'Business trip'
    },
    tripPurposeManualWork: {
        id: 'quoteandbind.tvl.preliminaryQuestions.Manual work',
        defaultMessage: 'Manual work'
    },
    tripPurposeWinterSports: {
        id: 'quoteandbind.tvl.preliminaryQuestions.Winter sports',
        defaultMessage: 'Winter sports'
    },
    tripPurposeExtremeSports: {
        id: 'quoteandbind.tvl.preliminaryQuestions.Extreme sports',
        defaultMessage: 'Extreme sports'
    },
    tripPurposeOther: {
        id: 'quoteandbind.tvl.preliminaryQuestions.Other trip',
        defaultMessage: 'Other trip'
    },
    transportationCar: {
        id: 'quoteandbind.tvl.preliminaryQuestions.Car/motor',
        defaultMessage: 'Car/motor'
    },
    transportationPlane: {
        id: 'quoteandbind.tvl.preliminaryQuestions.Plane',
        defaultMessage: 'Plane'
    },
    transportationOther: {
        id: 'quoteandbind.tvl.preliminaryQuestions.Other',
        defaultMessage: 'Other (bus, train etc.)'
    }
});
