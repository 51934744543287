/* eslint-disable camelcase */
import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { Button } from '@jutro/components';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { CurrencyFormatterService } from 'cnd-portals-util-js';
import { LOBContext } from 'cnd-common-components-platform-react';
import { CAR_ASSISTANCE_ADDON_PUBLIC_ID } from '../../../../../../constants/QuoteConstants';
import Price from '../../../../../Price/Price';
import CoverageTerms from './CoverageTerms';
import withCollapsible from '../../../../../Collapsible/withCollapsible';
import styles from './Coverage.module.scss';
import messages from './Coverage.messages';

const collapsibleStyles = {
    root: styles.coverageContainer,
    collapsible: styles.collapsible,
};

const priceStyles = {
    amount: styles.coveragePriceAmount,
    currency: styles.coveragePriceCurrency
};

export function TVLAddonCoverageHeader(props) {
    const {
        publicID,
        name,
        selected,
        amount,
        discount_Cnd,
        onAddOrRemove
    } = props;
    const translator = useContext(TranslatorContext);
    const { isFromQuoteRetrieval } = useContext(LOBContext);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    const isDisabled = useMemo(() => {
        return !isFromQuoteRetrieval && !amount?.amount;
    }, [amount, isFromQuoteRetrieval]);

    const handleAddOrRemove = useCallback(
        (event) => {
            event.stopPropagation();
            onAddOrRemove(publicID);
        },
        [publicID, onAddOrRemove]
    );

    return (
        <div className={`${styles.coverageHeader} ${isDisabled ? styles.coverageHeaderDisabled : ''}`}>
            <div className={`${styles.coverageName} ${isDisabled ? styles.coverageNameDisabled : ''}`}>{name}</div>
            <div className={styles.coverageActions}>
                <Button
                    type="secondary"
                    size="small"
                    className={
                        `${selected ? styles.removeAddonButton : styles.addAddonButton} ${isDisabled ? styles.addAddonButtonDisabled : ''}`
                    }
                    disabled={isDisabled || isFromQuoteRetrieval}
                    onClick={handleAddOrRemove}
                >
                    {translator(
                        selected ? messages.removeAddon : messages.addAddon
                    )}
                </Button>
            </div>
            <div className={styles.coverageValue}>
                {!isDisabled && amount?.amount
                    ? (
                        <Price
                            value={amount?.amount}
                            formatter={{
                                formatPrice: CurrencyFormatterService.formatPrice,
                                country: appCountry
                            }}
                            currency={amount?.currency}
                            styles={{
                                container: discount_Cnd
                                    ? styles.coveragePriceAfterDiscountContainer
                                    : styles.coveragePriceContainer,
                                ...priceStyles
                            }}
                        />
                    )
                    : (
                        <span className={`mir mi-cancel ${styles.noIcon}`} />
                    )
                }
                {!isDisabled && discount_Cnd && (
                    <Price
                        value={amount.amount - discount_Cnd.amount}
                        formatter={{
                            formatPrice: CurrencyFormatterService.formatPrice,
                            country: appCountry
                        }}
                        currency={amount.currency}
                        styles={{
                            container: styles.coveragePriceBeforeDiscountContainer,
                            ...priceStyles
                        }}
                    />
                )}
            </div>
            {isDisabled && (
                <div className={styles.coverageUnavailable}>
                    { publicID === CAR_ASSISTANCE_ADDON_PUBLIC_ID ? // create reason mapping in new portal
                        translator(messages.carAssistanceUnavailable, {}) :
                        translator(messages.coverageUnavailable, { coverage: name }) 
                    }
                </div>
            )}
        </div>
    );
}

TVLAddonCoverageHeader.propTypes = {
    publicID: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    amount: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
    }).isRequired,
    discount_Cnd: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
    }),
    onAddOrRemove: PropTypes.func.isRequired,
};

export default withCollapsible(CoverageTerms, {
    onHeaderRender: TVLAddonCoverageHeader,
    styles: collapsibleStyles,
});
