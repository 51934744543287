import _ from 'lodash';
import { useRef } from 'react';
import { CountryLayerService } from 'cnd-portals-util-js';
import {
    CANCELLATION_ADDON_PUBLIC_ID,
    PHYSICAL_WORK_ADDON_PUBLIC_ID,
    WINTER_SPORTS_ADDON_PUBLIC_ID,
    SPORT_EQUIPMENT_ADDON_PUBLIC_ID,
    EXTREME_SPORTS_ADDON_PUBLIC_ID,
    CAR_ASSISTANCE_ADDON_PUBLIC_ID,
    FLIGHT_ADDON_PUBLIC_ID
} from '../constants/QuoteConstants';
import {TRANSPORTATION_CODES, TRIP_DETAILS_CODES, isVehicleApplicable} from '../constants/PreliminaryQuestions'



export default function usePreselectedAddOns(appCountry, addonsFactors) {
    if (!CountryLayerService.arePreliminaryQuestionsVisible(appCountry)) {
        return;
    }

    const {transportation, tripPurpose, embeddedTripCancellation, vehicleData} = addonsFactors;
    const addonsToPreselectRef = useRef([]);

    if (embeddedTripCancellation?.selected) {
        addonsToPreselectRef.current.push(CANCELLATION_ADDON_PUBLIC_ID);
    }

    if (tripPurpose.findIndex(o => o === TRIP_DETAILS_CODES.MANUAL_WORK) > -1) {
        addonsToPreselectRef.current.push(PHYSICAL_WORK_ADDON_PUBLIC_ID);
    }

    if (tripPurpose.findIndex(o => o === TRIP_DETAILS_CODES.WINTER_SPORTS) > -1) {
        addonsToPreselectRef.current.push(SPORT_EQUIPMENT_ADDON_PUBLIC_ID);
        addonsToPreselectRef.current.push(WINTER_SPORTS_ADDON_PUBLIC_ID);
    }

    if (tripPurpose.findIndex(o => o === TRIP_DETAILS_CODES.EXTREME_SPORTS) > -1) {
        addonsToPreselectRef.current.push(SPORT_EQUIPMENT_ADDON_PUBLIC_ID);
        addonsToPreselectRef.current.push(EXTREME_SPORTS_ADDON_PUBLIC_ID);
    }

    if (transportation.findIndex(o => o === TRANSPORTATION_CODES.AUTO) > -1
            && vehicleData.isAssistanceNeeded
            && isVehicleApplicable(vehicleData.productionYear)) {
        addonsToPreselectRef.current.push(CAR_ASSISTANCE_ADDON_PUBLIC_ID);
    }

    if (transportation.findIndex(o => o === TRANSPORTATION_CODES.PLANE) > -1) {
        addonsToPreselectRef.current.push(FLIGHT_ADDON_PUBLIC_ID);
    }

    return addonsToPreselectRef.current;
}
