import { defineMessages } from 'react-intl';

export default defineMessages({
    technicalIssuesPageHeader: {
        id: 'quoteandbind.tvl.step.technical-issues.Dear Customer',
        defaultMessage: 'Dear Customer'
    },
    technicalIssuesPageBody: {
        id: 'quoteandbind.tvl.step.technical-issues.Unfortunately your request cannot be finalized due to technical issues.',
        defaultMessage: 'Unfortunately your request cannot be finalized due to technical issues.'
    },
    technicalIssuesPageContact: {
        id: 'quoteandbind.tvl.step.technical-issues.Please contact us:',
        defaultMessage: 'Please contact us: {email}'
    },
    technicalIssuesPageContactPhone: {
        id: 'quoteandbind.tvl.step.technical-issues.Phone: <xxxxxx>',
        defaultMessage: 'Phone: {phoneNumber}'
    },
    technicalIssuesPageError: {
        id: 'quoteandbind.tvl.step.technical-issues.Error',
        defaultMessage: 'Error'
    }
});
