import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import { uniqueInnerId } from '@jutro/platform';
import { CheckboxField } from '@jutro/components';
import Label from '../Label/Label';

function isChecked(value) {
    return !_.isNil(value) && value.toString() === 'true';
}

class CheckboxWithLink extends CheckboxField {
    get baseProps() {
        return {
            onClick: this.handleClick,
            checked: isChecked(this.props.value),
            onBlur: this.handleBlur,
            onFocus: this.handleFocus,
            onKeyDown: this.handleKeyDown,
            onChange: this.handleCheckChange,
            accessibilityProps: this.generateAccessibilityProperties(),
            renderInlineLabel: this.renderInlineLabelWithUrl
        };
    }

    renderLabelWithUrl(breakpointProps, className, secondaryClassName) {
        const {
            id,
            label,
            labelPosition,
            hideLabel,
            labelClassName,
            secondaryLabel,
            secondaryLabelClassName,
            showInlineLabel,
            showRequired,
            showOptional,
            size,
            readOnly,
            disabled
        } = breakpointProps;

        if (!(label && label !== '' && !hideLabel)) {
            return null;
        }

        const { labelId } = uniqueInnerId(id, 'labelId');
        const layoutComponent = this.getLayoutComponent();
        const layoutComponentClassName = layoutComponent == null ? undefined : layoutComponent.labelClass;
        const combinedClassName = cx(layoutComponentClassName, labelClassName, className);
        const combinedSecondaryClassName = cx(secondaryLabelClassName, secondaryClassName);
        const tooltipIcon = (labelPosition === 'top' || showInlineLabel) && this.renderTooltipIcon(breakpointProps);
        const labelProps = {
            id: labelId,
            htmlFor: id,
            label: label,
            required: this.isRequired(),
            readOnly: readOnly,
            disabled: disabled,
            hideLabel: hideLabel,
            showRequired: showRequired,
            showOptional: showOptional,
            translator: this.translator,
            className: combinedClassName,
            secondaryLabel: secondaryLabel,
            secondaryLabelClassName: combinedSecondaryClassName,
            size: size,
            labelPosition: labelPosition,
            showInlineLabel: showInlineLabel
        };
        return <Label {...labelProps} tooltipIcon={tooltipIcon} />;
    }

    renderInlineLabelWithUrl = (theme, themeStyles) => {
        const { size, showInlineLabel } = this.props;
        const classes = cx(themeStyles.checkboxElementWrapper, {
            [themeStyles.checkboxElementWrapperSmallInline]: showInlineLabel && size === 'small'
        });
        return (
            <div className={classes}>
                {this.renderLabelWithUrl(this.props, themeStyles.inlineLabel)}
            </div>
        );
    }
}

export default CheckboxWithLink;
