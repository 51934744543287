import _ from 'lodash';
import { CommonTagManagerService } from 'cnd-common-portals-util-js';
import TrackingConstants from './TrackingConstants';

const APP_NAME = 'Q&B';
const BRAND_NAME = 'Colonnade';
const QUANTITY_ONE = 1;

const init = (countryCode) => {
    const trackingId = TrackingConstants.GTM_IDS[countryCode];
    CommonTagManagerService.init(trackingId);
};

const pushNextButtonClick = (stepName) => {
    CommonTagManagerService.pushActionButtonClick(stepName, TrackingConstants.BUTTON_ACTION.NEXT);
};

const pushProceedPaymentButtonClick = (stepName) => {
    CommonTagManagerService
        .pushActionButtonClick(stepName, TrackingConstants.BUTTON_ACTION.PROCEED_PAYMENT);
};

const createEcommerceProductDataObject = (submissionVM, name, variant, price) => {
    return {
        name: name,
        id: _.get(submissionVM, 'bindData.policyNumber.value'),
        price: price,
        brand: BRAND_NAME,
        category: _.get(submissionVM, 'baseData.accountHolder.subtype.value'),
        variant: variant,
        quantity: QUANTITY_ONE
    };
};

const createEcommerceMainProductDataObject = (submissionVM, variant, totalPrice) => {
    const productName = _.get(submissionVM, 'baseData.offering_Cnd.value.code');
    return createEcommerceProductDataObject(submissionVM, productName, variant, totalPrice);
};

const createEcommerceAddonProductsDataObjects = (submissionVM, variantName, addon) => {
    const name = _.get(addon, 'name');
    const price = _.get(addon, 'amount.amount');
    return createEcommerceProductDataObject(submissionVM, name, variantName, price);
};

const createEcommerceProductDataObjects = (submissionVM, variant, totalPrice) => {
    const result = [];
    const variantName = _.get(variant, 'branchName');
    result.push(createEcommerceMainProductDataObject(submissionVM, variantName, totalPrice));
    const allAddons = _.get(variant, 'coverages.addonCoverages');
    const selectedAddons = _.filter(allAddons, (addon) => addon.selected === true);
    _.each(selectedAddons, (addon) => result.push(
        createEcommerceAddonProductsDataObjects(submissionVM, variantName, addon)
    ));
    return result;
};

const pushBoundSubmissionEcommerceObject = (stepNumber, submissionVM) => {
    // method is called for bound submission, so there is only single quote present
    const quote = _.get(submissionVM, 'quoteData.value.offeredQuotes[0]');
    const revenue = _.get(quote, 'premium.totalAfterDiscount_Cnd.amount')
        || _.get(quote, 'premium.total.amount');
    const lobData = _.values(_.get(submissionVM, 'lobData.value'));
    const variant = _.get(lobData[0], 'variants[0]');

    const ecommerceEvent = {
        event: 'successful-transaction',
        ecommerce: {
            purchase: {
                actionField: {
                    id: _.get(submissionVM, 'bindData.policyNumber.value'),
                    affiliation: APP_NAME,
                    revenue: revenue,
                    tax: 0,
                    shipping: 0,
                    coupon: _.get(submissionVM, 'baseData.discountCode_Cnd.value'),
                    step: stepNumber
                },
                products: createEcommerceProductDataObjects(submissionVM, variant, revenue)
            }
        }
    };

    CommonTagManagerService.pushToDataLayer(ecommerceEvent);
};

export default {
    init: init,
    pushActionEvent: CommonTagManagerService.pushActionEvent,
    pushNextButtonClick: pushNextButtonClick,
    pushProceedPaymentButtonClick: pushProceedPaymentButtonClick,
    pushBoundSubmissionEcommerceObject: pushBoundSubmissionEcommerceObject
};
