import React, { useContext, useCallback } from 'react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';

import metadata from './PaymentPage.metadata.json5';
import './PaymentPage.messages';

function PaymentPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const { isComponentValid, onValidate } = useValidation('PaymentPage');

    const onNext = useCallback(async () => {
        submissionVM.value = {}; // TODO: updateSubmission should be called here
        return submissionVM;
    }, [submissionVM]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

PaymentPage.propTypes = wizardProps;
export default PaymentPage;
