export const Currency = Object.freeze({
    PLN: 'pln',
    RON: 'ron',
    HUF: 'huf',
    CZK: 'czk',
    BGN: 'bgn',
    EUR: 'eur',
    USD: 'usd'
});

export const Countries = Object.freeze({
    PL: 'PL',
    RO: 'RO',
    BG: 'BG',
    CZ: 'CZ',
    HU: 'HU',
    SK: 'SK',
    JP: 'JP',
    CA: 'CA',
    US: 'US',
    AU: 'AU',
    FR: 'FR',
    DE: 'DE',
    GB: 'GB',
    Other: 'Other'
});

export const Currencies = Object.freeze({
    PL: Currency.PLN,
    RO: Currency.RON,
    HU: Currency.HUF,
    CZ: Currency.CZK,
    BG: Currency.BGN,
    SK: Currency.EUR,
    Other: Currency.USD
});

export function callCorrectImpl(variants, country) {
    let countryToCheck = country;
    const allCountriesFromVariants = variants.flatMap((x) => x.countries);
    const countryExists = allCountriesFromVariants.find((code) => countryToCheck === code);
    if (!countryExists) {
        countryToCheck = Countries.Other;
    }

    let countrySpecificVariant = null;
    variants.forEach((variant) => {
        if (variant.countries.find((countryCode) => countryCode === countryToCheck)) {
            countrySpecificVariant = variant;
        }
    });

    return countrySpecificVariant.action();
}

export function getCurrencyForCountry(country, isEmbeddedTripCancellationSelected = false) {
    switch (country) {
        case Countries.BG:
            return isEmbeddedTripCancellationSelected ? Currency.EUR : Currencies[country];
        default:
            return Currencies[country] || Currencies.Other;
    }
}

export default {
    Countries,
    Currencies,
    callCorrectImpl,
    getCurrencyForCountry
};
