import _ from 'lodash';
import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import {
    InvoiceService,
    TagManagerService,
    DocumentLinkService,
    TrackingConstants
} from 'cnd-portals-util-js';
import { ModalNextProvider } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { useHistory } from 'react-router-dom';
import { useSelectedVariant } from '../../hooks/useSubmission';
import {
    CND_ELECTRONIC_PAYMENT_ERROR,
    CND_DISCOUNT_NOT_ACTIVE_ERROR,
} from '../../constants/ErrorCodes';
import Consents from '../../components/Consents/Consents';
import metadata from './InsuranceSummaryPage.metadata.json5';
import TravelPolicyInformation from './TravelPolicySummary/TravelPolicySummary';
import EmbeddedTripCancellation from './EmbeddedTripCancellationSummary/EmbeddedTripCancellationSummary';
import InsuredPersonsSummary from './InsuredPersonsSummary/InsuredPersonsSummary';
import PaymentInfoSummary from './PaymentInfoSummary/PaymentInfoSummary';
import SummaryDocuments from './SummaryDocuments/SummaryDocuments';
import Policyholder from '../../components/PolicyHolder/PolicyHolder';
import SummaryContext from '../../components/summary/SummaryContext';
import useErrorHandler from '../../hooks/useErrorHandler';
import useProductLinks from '../../components/ProductLinks/hooks/useProductLinks';
import StaticInformationData from '../../constants/StaticInformationData';
import { PolicyHolderType } from '../../models/PolicyHolder';
import messages from './InsuranceSummaryPage.messages';
import styles from './InsuranceSummaryPage.module.scss';

const CONSENT_TYPE_ID_TO_OPEN_GTC_DOCUMENT = 'pcldr:9';

function InsuranceSummaryPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('InsuranceSummaryPage');
    const translator = useContext(TranslatorContext);
    const [documentsReady, setDocumentsReady] = useState(false);
    const ErrorHandler = useErrorHandler();
    const history = useHistory();
    const offeringCode = _.get(submissionVM, 'baseData.offering_Cnd.value.code');
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    const staticLinks = useProductLinks(appCountry, offeringCode, true);

    const getFormValidity = useCallback(() => {
        return submissionVM.baseData.consents_Cnd.aspects.valid
            && submissionVM.baseData.consents_Cnd.aspects.subtreeValid
            && submissionVM.baseData.isInvoiceNeeded_Cnd.aspects.valid
            && submissionVM.baseData.isInvoiceNeeded_Cnd.aspects.subtreeValid;
    }, [submissionVM.baseData]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [
        getFormValidity,
        registerComponentValidation
    ]);

    const onNext = useCallback(async () => {
        try {
            TagManagerService
                .pushProceedPaymentButtonClick(TrackingConstants.STEPS.QUOTE_CONFIRMATION);
            const response = await LoadSaveService.createElectronicPayment(submissionVM.value);
            window.location.href = response.redirectUri;
            return false;
        } catch (error) {
            const appErrorCode = error?.appErrorCode;
            if (appErrorCode === CND_DISCOUNT_NOT_ACTIVE_ERROR) {
                await ModalNextProvider.showAlert({
                    title: translator(messages.discountCodeExpiredTitle),
                    message: messages.discountCodeExpiredMessage,
                    status: 'warning',
                    icon: 'mi-error-outline',
                    confirmButtonText: messages.startNewQuote
                }).catch(_.noop);

                history.replace({
                    pathname: '/',
                    state: {
                        allowWizardExit: true
                    }
                });

                return false;
            } else if (appErrorCode === CND_ELECTRONIC_PAYMENT_ERROR) {
                const message = translator(messages.paymentError);
                _.set(error, 'customErrorMessage', message);
            }
            ErrorHandler.handleError(error);
            return false;
        }
    }, [submissionVM, ErrorHandler, LoadSaveService, translator, history]);

    const handleConsentChange = useCallback((value, path) => {
        const consentTypeID = _.get(submissionVM, `${path}._parent.consentTypeID.value`);
        const openInTab = DocumentLinkService.openInTab(appCountry);
        if (value && openInTab && consentTypeID === CONSENT_TYPE_ID_TO_OPEN_GTC_DOCUMENT) {
            window.open(_.get(staticLinks, '0.url'), '_blank');
        }
        _.set(submissionVM, path, value);
        updateWizardData(submissionVM);
    }, [submissionVM, appCountry, staticLinks, updateWizardData]);

    const selectedVariant = useSelectedVariant(submissionVM);
    const accountHolderVM = _.get(submissionVM, 'baseData.accountHolder');
    const accountHolderCountry = _.get(accountHolderVM, 'primaryAddress.country.value.code');
    const forceInvoiceNeeded = InvoiceService.isInvoiceRequiredForCompany(accountHolderCountry)
        && _.get(accountHolderVM, 'subtype.value') === PolicyHolderType.Company;

    const handleDocumentsReady = useCallback(() => {
        setDocumentsReady(true);
    }, [setDocumentsReady]);

    useEffect(() => {
        _.set(submissionVM.value, 'baseData.isInvoiceNeeded_Cnd', forceInvoiceNeeded);
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrideProps = {
        '@field': {
            readOnly: true,
            showOptional: false,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        insuranceSummaryPageContainer: {
            className: styles.insuranceSummaryPageContainerMobileFlex,
        },
        travelPolicyInformationSummary: {
            value: submissionVM
        },
        policyholderSummary: {
            isNationalIDVisible: _.get(accountHolderVM, 'passportNumber_Cnd.value') != null,
            isPersonalIDVisible: _.get(accountHolderVM, 'personalID_Cnd.value') != null,
            value: accountHolderVM
        },
        insuranceSummaryLicensePlate: {
            value: _.get(submissionVM.value, 'lobData.travel.licensePlate_Cnd'),
            visible: _.get(submissionVM.value, 'lobData.travel.licensePlate_Cnd') !== undefined
        },
        insuranceSummaryRegistrationDate: {
            value: _.get(submissionVM.value, 'lobData.travel.firstRegistrationDate_Cnd'),
            visible: _.get(submissionVM.value, 'lobData.travel.firstRegistrationDate_Cnd') !== undefined
        },
        insuredPersonsSummary: {
            value: _.get(submissionVM.value, 'baseData.policyContacts_Cnd')
        },
        paymentInfo: {
            isDiscountApplied: _.get(submissionVM.value, 'baseData.discountCode_Cnd') !== undefined,
            isPerson: _.get(accountHolderVM.value, 'subtype') === PolicyHolderType.Person,
            quote: selectedVariant,
        },
        insuranceSummaryDocumentLinks: {
            country: appCountry,
            model: submissionVM,
            onReady: handleDocumentsReady
        },
        isInvoiceNeeded: {
            value: _.get(submissionVM.value, 'baseData.isInvoiceNeeded_Cnd'),
            readOnly: forceInvoiceNeeded
        },
        insuranceSummaryStaticInformation: {
            step: 'insuranceSummaryPageStep',
            country: appCountry,
            staticInformationData: StaticInformationData
        },
        insuranceSummaryConsents: {
            visible: _.get(submissionVM, 'baseData.consents_Cnd') !== undefined,
            value: _.get(submissionVM, 'baseData.consents_Cnd'),
            onValidate,
            onValueChange: handleConsentChange,
            path: 'baseData.consents_Cnd',
            readOnly: false,
            locationFilter: 'qb_step_4',
            disabled: !documentsReady
        },
        travelEmbeddedTripCancellationSummaryContainer: {
            visible: _.get(submissionVM, 'lobData.travel.isEmbeddedTripCancellationSelected.value', false),
        },
        travelEmbeddedTripCancellationSummary: {
            model: _.get(submissionVM, 'lobData.travel')
        }
    };

    const resolvers = {
        resolveComponentMap: {
            travelPolicyInformation: TravelPolicyInformation,
            travelEmbeddedTripCancellation: EmbeddedTripCancellation,
            policyHolder: Policyholder,
            insuredPersons: InsuredPersonsSummary,
            paymentInfo: PaymentInfoSummary,
            consents: Consents,
            summaryDocuments: SummaryDocuments
        },
    };

    return (
        <WizardPage
            onNext={onNext}
            nextLabel={messages.proceedWithPayment}
            disableNext={!isComponentValid || !documentsReady}
        >
            <SummaryContext.Provider
                value={{
                    readOnly: true
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    componentMap={resolvers.resolveComponentMap}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                />
            </SummaryContext.Provider>
        </WizardPage>
    );
}

InsuranceSummaryPage.propTypes = wizardProps;
export default InsuranceSummaryPage;
