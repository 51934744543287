import _ from 'lodash';
import Address from './Address';

function Contact(data) {
    this.primaryAddress = this.address || new Address();
    this.additionalAddresses = [];
    this.phone_Cnd = this.phone_Cnd || {};

    if (data) {
        _.extend(this, data);
    }
}

Contact.create = function createContact(data) {
    return new Contact(data);
};

export default Contact;
