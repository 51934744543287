export default {
    name: 'ColonnadeTheme',
    prefix: 'colonnadeTheme',
    rootStyle: {
        '--GW-COLOR-PRIMARY-CONTRAST': 'pink',
        '--GW-COMPONENT-BOX-SHADOW': '`38px` `15px` `5px` `0px` rgba(0, 0, 0, 0.75)',
        '--GW-FONT-FAMILY': 'var(--GW-FONT-MANROPE)',
        '--GW-FONT-MANROPE': '"Open Sans Variable", "Manrope Variable", "Source Sans Variable", Arial, sans-serif',
        '--GW-FONT-FAMILY-FALLBACK': '"Manrope", "Open Sans", "Source Sans", Arial, sans-serif',
        '--GW-COLOR-LIGHT-CONTRAST': '#111111',
        '--GW-COLOR-DARK-CONTRAST': '#ffffff',
        '--GW-COLOR-BRAND-1': '#007aa1',
        '--GW-COLOR-BRAND-1-HOVER': '#006687',
        '--GW-COLOR-BRAND-1-ACTIVE': '#00536e',
        '--GW-COLOR-BRAND-1-GRADIENT': '#2f9fc5',
        '--GW-COLOR-BRAND-1-GRADIENT-HOVER': '#2f9fc5',
        '--GW-COLOR-BRAND-1-GRADIENT-ACTIVE': '#2f9fc5',
        '--GW-COLOR-BRAND-2': '#414243',
        '--GW-COLOR-BRAND-2-HOVER': '#282929',
        '--GW-COLOR-BRAND-2-ACTIVE': '#0f0f0f',
        '--GW-COLOR-BRAND-3': '#f2c4ab',
        '--GW-COLOR-BRAND-3-HOVER': '#d9b099',
        '--GW-COLOR-BRAND-3-ACTIVE': '#bf9b87',
        '--GW-COLOR-BACKGROUND-1': '#ffffff',
        '--GW-COLOR-BACKGROUND-1-HOVER': '#f2f2f2',
        '--GW-COLOR-BACKGROUND-1-ACTIVE': '#e6e6e6',
        '--GW-COLOR-BACKGROUND-2': '#fafafa',
        '--GW-COLOR-BACKGROUND-2-HOVER': '#EDEDED',
        '--GW-COLOR-BACKGROUND-2-ACTIVE': '#E0E0E0',
        '--GW-COLOR-BACKGROUND-3': '#F0F3F6',
        '--GW-COLOR-BACKGROUND-3-HOVER': '#E1E6EC',
        '--GW-COLOR-BACKGROUND-3-ACTIVE': '#D1D9E2',
        '--GW-COLOR-STROKE': '#D1D9E2',
        '--GW-COLOR-STROKE-HOVER': '#C1CCD8',
        '--GW-COLOR-STROKE-ACTIVE': '#B2BFCE',
        '--GW-COLOR-BORDER': '#ACB9CA',
        '--GW-COLOR-BORDER-HOVER': '#9CACC0',
        '--GW-COLOR-BORDER-ACTIVE': '#8D9FB6',
        '--GW-COLOR-TEXT-1': '#414243',
        '--GW-COLOR-TEXT-1-HOVER': '#282929',
        '--GW-COLOR-TEXT-1-ACTIVE': '#0f0f0f',
        '--GW-COLOR-TEXT-2': '#657580',
        '--GW-COLOR-TEXT-2-HOVER': '#5A6872',
        '--GW-COLOR-TEXT-2-ACTIVE': '#4F5B63',
        '--GW-COLOR-TEXT-3': '#ffffff',
        '--GW-COLOR-TEXT-3-HOVER': '#e6e6e6',
        '--GW-COLOR-TEXT-3-ACTIVE': '#cccccc',
        '--GW-COLOR-TEXT-ACCENT': '#e40028',
        '--GW-COLOR-TEXT-ACCENT-HOVER': '#c90023',
        '--GW-COLOR-TEXT-ACCENT-ACTIVE': '#b0001f',
        '--GW-COLOR-LINK-1': '#007aa1',
        '--GW-COLOR-LINK-1-HOVER': '#006687',
        '--GW-COLOR-LINK-1-ACTIVE': '#00536e',
        '--GW-COLOR-LINK-2': '#4aabc9',
        '--GW-COLOR-LINK-2-HOVER': '#4196b0',
        '--GW-COLOR-LINK-2-ACTIVE': '#378096',
        '--GW-COLOR-ALERT-SUCCESS': '#36df00',
        '--GW-COLOR-ALERT-SUCCESS-HOVER': '#2cb800',
        '--GW-COLOR-ALERT-SUCCESS-ACTIVE': '#259900',
        '--GW-COLOR-ALERT-ERROR': '#e40028',
        '--GW-COLOR-ALERT-ERROR-HOVER': '#c90023',
        '--GW-COLOR-ALERT-ERROR-ACTIVE': '#b0001f',
        '--GW-COLOR-ALERT-WARNING': '#fc9c3d',
        '--GW-COLOR-ALERT-WARNING-HOVER': '#e38d37',
        '--GW-COLOR-ALERT-WARNING-ACTIVE': '#c97d31',
        '--GW-COLOR-ALERT-INFO': '#4aabc9',
        '--GW-COLOR-ALERT-INFO-HOVER': '#4196b0',
        '--GW-COLOR-ALERT-INFO-ACTIVE': '#378096',
        '--GW-COLOR-HIGHLIGHT-1': '#F0F3F6',
        '--GW-COLOR-HIGHLIGHT-1-HOVER': '#E1E6EC',
        '--GW-COLOR-HIGHLIGHT-1-ACTIVE': '#D1D9E2',
        '--GW-COLOR-HIGHLIGHT-2': '#f2c4ab',
        '--GW-COLOR-HIGHLIGHT-2-HOVER': '#d9b099',
        '--GW-COLOR-HIGHLIGHT-2-ACTIVE': '#bf9b87',
        '--GW-COLOR-FOCUS-1': '#4aabc9',
        '--GW-COLOR-FOCUS-1-HOVER': '#4196b0',
        '--GW-COLOR-FOCUS-1-ACTIVE': '#378096',
        '--GW-COLOR-FOCUS-2': '#007aa1',
        '--GW-COLOR-FOCUS-2-HOVER': '#006687',
        '--GW-COLOR-FOCUS-2-ACTIVE': '#00536e'
    },
    componentStyles: {

    }
};
