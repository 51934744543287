import moment from 'moment';

function addDays(date, daysToAdd) {
    const newDate = moment(date).add(daysToAdd, 'days');
    return {
        year: newDate.year(),
        month: newDate.month(),
        day: newDate.date()
    };
}

function addMonths(date, monthsToAdd) {
    const newDate = moment(date).add(monthsToAdd, 'month');
    return {
        year: newDate.year(),
        month: newDate.month(),
        day: newDate.date()
    };
}

function toDate(date) {
    return moment(date).toDate();
}

/**
 * This class is used in conjunction with the Expression Language only. Do not call directly.
 */
export default {
    addDays,
    addMonths,
    toDate
};
