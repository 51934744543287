import { CountrySpecificUtil } from 'cnd-common-portals-util-js';

export default {
    isInvoiceRequiredForCompany: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountrySpecificUtil.Countries.HU],
                action: () => true
            },
            {
                countries: [CountrySpecificUtil.Countries.Other],
                action: () => false
            }
        ];
        return CountrySpecificUtil.callCorrectImpl(variants, country);
    }
};
