import { defineMessages } from 'react-intl';

export default defineMessages({
    tvlNeutralPaymentPageHeader: {
        id: 'quoteandbind.tvl.pages.neutral-page.Oops!',
        defaultMessage: 'Oops!',
    },
    tvlNeutralPaymentPageBody: {
        id: 'quoteandbind.tvl.pages.neutral-page.Something went wrong. We have encountered some technical problems. Please contact us to solve this problem <tel number>',
        defaultMessage:
            'Something went wrong. We have encountered some technical problems. Please contact us to solve this problem {phoneNumber}',
    },
    tvlNeutralPaymentPageTitle: {
        id: 'quoteandbind.tvl.pages.neutral-page.Error',
        defaultMessage:
            'Error',
    },
});
