// eslint-disable-next-line import/prefer-default-export
export const GW_SECURITY_ERROR = 600;
export const GW_SESSION_TIMEOUT = 607;
export const CND_DTO_VALIDATION_ERROR = 701;
export const CND_ELECTRONIC_PAYMENT_ERROR = 702;
export const CND_SANCTION_SCREENING_ERROR = 703;
export const CND_DISCOUNT_NOT_ACTIVE_ERROR = 704;
export const INVALID_PHONE_MESSAGE = /Invalid phone number/;
export const INVALID_EMAIL_MESSAGE = /Invalid email/;
export const ADDL_INSURED_MESSAGE = /Additional insured duplicate/;
