import _ from 'lodash';
import React, { useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Loader, Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { CountryLayerService } from 'cnd-portals-util-js';
import { useCallService } from 'cnd-common-hooks-platform-react';
import { QuoteService } from 'cnd-capability-quoteandbind';
import TVLProductLinks from '../../../components/ProductLinks/ProductLinks';
import {
    DOWNLOAD_RETRY_TIMES,
    DOWNLOAD_RETRY_DELAY_IN_SECONDS,
    DOWNLOAD_DELAY_FACTOR
} from '../../../constants/Commons';
import messages from './SummaryDocuments.messages';

function SummaryDocuments(props) {
    const translator = useContext(TranslatorContext);
    const { country, model: submissionVM, onReady } = props;
    const quoteID = _.get(submissionVM, 'quoteID.value');
    const sessionUUID = _.get(submissionVM, 'sessionUUID.value');
    const mayDelayPolicyProposalDocument = CountryLayerService.mayDelayPolicyProposalDocument(country);

    const mayCreateQuoteDocument = useCallback(() => {
        if (!mayDelayPolicyProposalDocument) {
            return QuoteService.createQuoteDocument(
                sessionUUID,
                quoteID
            );
        }
        return true;
    }, [quoteID, sessionUUID, mayDelayPolicyProposalDocument]);

    const {
        loading: isGeneratingQuoteDocument,
        error,
        done,
        retry
    } = useCallService(
        mayCreateQuoteDocument,
        {
            retryTimes: DOWNLOAD_RETRY_TIMES,
            retryDelayFactor: DOWNLOAD_DELAY_FACTOR,
            retryDelayInSeconds: DOWNLOAD_RETRY_DELAY_IN_SECONDS,
            initialIsLoading: !mayDelayPolicyProposalDocument
        },
        []
    );

    useEffect(() => {
        if (done && error === null) {
            onReady();
        }
    }, [done, error, onReady]);

    return (
        <Loader loaded={!isGeneratingQuoteDocument}>
            {_.isNil(error) ? (
                <TVLProductLinks {...props} showQuote />
            ) : (
                <>
                    <p>{translator(messages.quoteDocumentErrorMessage)}</p>
                    <Button type="tertiary" onClick={retry}>
                        {translator(messages.retryButtonLabel)}
                    </Button>
                </>
            )}
        </Loader>
    );
}

SummaryDocuments.propTypes = {
    country: PropTypes.string.isRequired,
    model: PropTypes.shape({}).isRequired,
    onReady: PropTypes.func.isRequired
};

export default SummaryDocuments;
