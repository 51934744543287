import _ from 'lodash';
import { useContext } from 'react';
import { LOBContext } from 'cnd-common-components-platform-react';
import { useComponentWillMount } from 'cnd-common-hooks-platform-react';
import { useSelectedVariant } from '../../../hooks/useSubmission';
import * as QuoteConstants from '../../../constants/QuoteConstants';
import CoverageDataService from '../../../services/CoveragesDataService';
import isValidTermForCountry from '../../../services/CovTermsForCountry';

const filterAndMapTerms = (terms, country, updated = false) => _.reduce(
    terms, (memo, term) => {
        if (!isValidTermForCountry(term, country)) return memo;
        const newTerm = { ...term };
        if (term.publicID === QuoteConstants
            .CANCELLATION_DEDUCTIBLE_COV_TERM_PUBLIC_ID
            && term.chosenTermValue.charAt(term.chosenTermValue.length - 1) !== '%') {
            newTerm.chosenTermValue = `${newTerm.chosenTermValue}%`;
        }
        return [...memo, { ...newTerm, updated }];
    }, []
);

function useAddons(submissionVM, isEmbeddedTripCancellationSelected, country) {
    const {
        isFromQuoteRetrieval,
    } = useContext(LOBContext);

    const selectedVariant = useSelectedVariant(submissionVM);
    const selectedAddonCoverages = _.get(selectedVariant, 'coverages.addonCoverages');
    const chosenQuote = _.get(submissionVM, 'bindData.chosenQuote.value');

    const isAddonInitiallySelected = (publicID) => {
        if (_.isNil(chosenQuote)) {
            return false;
        }
        return _.find(selectedAddonCoverages,
            (cov) => cov.publicID === publicID)?.selected ?? false;
    };

    useComponentWillMount(() => {
        const cachedAddons = CoverageDataService.getAddons();
        const variants = _.get(submissionVM, 'lobData.travel.variants.value', []);
        const sortedVariants = _.orderBy(_.clone(variants), ['priority'], ['asc']);

        _.each(sortedVariants, (variant) => {
            const addonCoverages = _.get(variant, 'coverages.addonCoverages');
            const validAddonCoverages = _.reduce(addonCoverages, (memo, addon) => {
                if (!isEmbeddedTripCancellationSelected
                    && addon.publicID === QuoteConstants.CANCELLATION_ADDON_PUBLIC_ID) {
                    return memo;
                }

                const {
                    publicID,
                    amount,
                    terms,
                } = addon;

                const cachePrefix = `${variant.branchName}.addonCoverages.${publicID}`;
                const selected = isFromQuoteRetrieval ? addon.selected
                    : isAddonInitiallySelected(publicID);

                let newTerms;
                if (!(terms && terms.length)) {
                    const cachedTerms = _.get(cachedAddons, `${cachePrefix}.terms`);
                    newTerms = filterAndMapTerms(cachedTerms, country, true);
                } else {
                    newTerms = filterAndMapTerms(terms, country);
                }

                return [
                    ...memo, {
                        ...addon,
                        selected,
                        amount: amount ?? _.get(cachedAddons, `${cachePrefix}.amount`),
                        terms: newTerms
                    }
                ];
            }, []);
            _.set(variant, 'coverages.addonCoverages', validAddonCoverages);

            const standardCoverages = _.get(variant, 'coverages.standardCoverages');
            _.each(standardCoverages, (cov) => {
                const newCov = cov;
                newCov.terms = filterAndMapTerms(cov.terms, country);
            });
        });
        _.set(submissionVM.value, 'lobData.travel.variants', sortedVariants);
    });

    return submissionVM;
}

export default useAddons;
