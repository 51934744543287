import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';

import messages from './CommonQuoteInfo.messages';
import commonQuoteStyles from '../../quoteandbind-common-styles.module.scss';

function CommonQuoteInfo(props) {
    const { submissionVM } = props;
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const quoteId = _.get(submissionVM, 'quoteID.value');
    const location = _.get(submissionVM, 'baseData.policyAddress.displayName.value');

    if (breakpoint === 'phone') {
        return (
            <div className={commonQuoteStyles.quoteInfoBar}>
                <div className={commonQuoteStyles.infoBlock}>
                    <div className={commonQuoteStyles.infoBlockTitle}>
                        {translator(messages.quote)}
                    </div>
                    <div id="contextSubmissionId" className={commonQuoteStyles.infoBlockContent}>
                        {quoteId}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={commonQuoteStyles.quoteInfoBar}>
            <div className={commonQuoteStyles.infoBlock}>
                <div className={commonQuoteStyles.infoBlockTitle}>{translator(messages.quote)}</div>
                <div id="contextSubmissionId" className={commonQuoteStyles.infoBlockContent}>
                    {quoteId}
                </div>
            </div>
            <div className={commonQuoteStyles.infoBlock}>
                <div className={commonQuoteStyles.infoBlockTitle}>
                    {translator(messages.location)}
                </div>
                <div id="contextLocation" className={commonQuoteStyles.infoBlockContent}>
                    {location}
                </div>
            </div>
        </div>
    );
}

CommonQuoteInfo.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired
};

export default CommonQuoteInfo;
