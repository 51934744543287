import React, { useContext, useEffect, useCallback, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { TagManagerService, ContactInfoService } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { TranslatorContext } from '@jutro/locale';
import messages from './TechnicalIssuesPage.messages';
import wizardMessages from '../../TVLWizard.messages';
import metadata from './TechnicalIssuesPage.metadata.json5';

function TechnicalIssuesPage(props) {
    const { wizardData: submissionVM } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const history = useHistory();
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    useLayoutEffect(() => {        
        document.title = translator(messages.technicalIssuesPageError) + ' | Colonnade Insurance S.A.';
    }, []);

    useEffect(() => {
        TagManagerService.pushActionEvent('technicalIssues-pageview');
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNewQuote = useCallback(() => {
        return history.push({
            pathname: '/',
            state: {
                allowWizardExit: true
            }
        });
    }, [history]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        tvlTechnicalIssuesPageContactAction: {
            content: translator(messages.technicalIssuesPageContact, {
                email: ContactInfoService.isVisibleEmail(appCountry?.toUpperCase())
                    ? ContactInfoService.getContactEmail(appCountry) : ''
            }),
        },
        tvlTechnicalIssuesPageContactPhone: {
            visible: ContactInfoService.isVisiblePhone(appCountry?.toUpperCase()),
            content: translator(messages.technicalIssuesPageContactPhone, {
                phoneNumber: ContactInfoService.getContactPhoneNumber(appCountry)
            })
        }
    };

    return (
        <WizardPage
            showNewQuote
            preventSidebar
            showPrevious={false}
            showCancel={false}
            showNext={false}
            newQuoteLabel={wizardMessages.newQuote}
            onNewQuote={onNewQuote}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
            />
        </WizardPage>
    );
}

TechnicalIssuesPage.propTypes = wizardProps;
export default TechnicalIssuesPage;
