import TagManager from 'react-gtm-module';

const CLICK_ACTION = '-click';

const init = (trackingId) => {
    TagManager.initialize({
        gtmId: trackingId
    });
};

const pushToDataLayer = (dataObj) => {
    TagManager.dataLayer({
        dataLayer: dataObj
    });
};

const pushActionEvent = (actionName) => {
    const eventData = {
        event: actionName
    };
    pushToDataLayer(eventData);
};

const pushActionButtonClick = (stepName, buttonAction) => {
    const actionName = stepName + '-' + buttonAction + CLICK_ACTION;
    pushActionEvent(actionName);
};

export default {
    init: init,
    pushActionEvent: pushActionEvent,
    pushActionButtonClick: pushActionButtonClick,
    pushToDataLayer: pushToDataLayer
};
