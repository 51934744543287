import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../../../Icon/Icon';
import { Price, Unlimited } from '../../../../../../../Price';
import limitStyles from './CoverageLimit.module.scss';

function TVLCoverageLimit(props) {
    const { value, currency, styles } = props;

    switch (true) {
        case _.isNil(value):
            return <Icon className={`mir mi-check ${limitStyles.coverageIcon}`} />;
        case !_.isEmpty(value):
            return <Price value={value} currency={currency} styles={styles.price} />;
        default:
            return <Unlimited styles={styles.price} />;
    }
}

TVLCoverageLimit.propTypes = {
    value: PropTypes.string,
    currency: PropTypes.string,
    styles: PropTypes.shape({
        price: PropTypes.shape({
            amount: PropTypes.string,
            currency: PropTypes.string,
        })
    })
};

TVLCoverageLimit.defaultProps = {
    value: undefined,
    currency: undefined,
    styles: {}
};

export default TVLCoverageLimit;
