import moment from 'moment';
import { CountryLayerCore } from 'cnd-common-portals-util-js';
import messages from './CountryLayerService.messages';

export default {
    getDateFormats: (country, showTime) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => {
                    if (showTime) {
                        return {
                            format: 'yyyy/MM/dd',
                            timeFormat: 'HH:mm',
                            separator: ', ',
                            placeholder: messages.placeholderVariantYearWithTime,
                        };
                    }
                    return {
                        format: 'yyyy/MM/dd',
                        placeholder: messages.placeholderVariantYear,
                    };
                }
            },
            {
                countries: [
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => {
                    if (showTime) {
                        return {
                            format: 'dd.MM.yyyy',
                            timeFormat: 'HH:mm',
                            separator: ', ',
                            placeholder: messages.placeholderWithTime,
                        };
                    }
                    return {
                        format: 'dd.MM.yyyy',
                        placeholder: messages.placeholder,
                    };
                }
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    formatDate: (date, country) => {
        if (date === undefined || country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => moment(date).format('YYYY/MM/DD')
            },
            {
                countries: [
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => moment(date).format('DD.MM.YYYY')
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    displayPrintButtonOnQuotePage: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.RO],
                action: () => false
            },
            {
                countries: [
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.SK
                ],
                action: () => true
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    showExtraDisclaimers: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => true
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => false
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    mayDelayPolicyProposalDocument: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.SK],
                action: () => false
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.PL
                ],
                action: () => true
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    isEmbeddedTripCancellationAvailable: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.PL],
                action: () => false
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => true
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    isKosovoAvailableForAccountHolder: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.HU,
                    CountryLayerCore.Countries.PL
                ],
                action: () => true
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => false
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },
    isDomesticCancellationAllowed: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => false
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => true
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    isFirstRegistrationDateVisible: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => false
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => true
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },

    arePreliminaryQuestionsVisible: (country) => {
        if (country === undefined) {
            return false;
        }

        const variants = [
            {
                countries: [CountryLayerCore.Countries.HU],
                action: () => true
            },
            {
                countries: [
                    CountryLayerCore.Countries.BG,
                    CountryLayerCore.Countries.CZ,
                    CountryLayerCore.Countries.PL,
                    CountryLayerCore.Countries.RO,
                    CountryLayerCore.Countries.SK
                ],
                action: () => false
            }
        ];
        return CountryLayerCore.callCorrectImpl(variants, country);
    },
};
