import React, {
    useContext, useState, useCallback, useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, InlineLoader, Tooltip } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { LOBContext } from 'cnd-common-components-platform-react';
import { pageTemplateProps } from '../prop-types/wizardPropTypes';

import styles from './DigitalWizardPageTemplate.module.scss';
import WizardSidebar from '../components/WizardSidebar';
import MobileWizardSteps from '../components/MobileWizardSteps/MobileWizardSteps';

function renderLoader(isLoading) {
    return isLoading ? <InlineLoader loading={isLoading} className="gw-inline-loader" /> : null;
}

function DigitalWizardPageTemplate(props) {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { asideComponents } = useContext(LOBContext);
    const isPhone = breakpoint === 'phone';
    const [showSidebar, setShowSidebar] = useState(false);
    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        nextButtonTooltip,
        onPrevious,
        isLoadingPrevious,
        disablePrevious,
        showPrevious,
        previousLabel,
        onCancel,
        isLoadingCancel,
        disableCancel,
        showCancel,
        cancelLabel,
        renderContextComponent,
        renderWidgets,
        wizardData,
        wizardSnapshot,
        showContextComponent,
        showWizardHeader,
        preventSidebar,
        customMessageModal,
        currentStepIndex,
        showEditQuote,
        disableEditQuote,
        onEditQuote,
        editQuoteLabel,
        isLoadingEditQuote,
        showNewQuote,
        disableNewQuote,
        onNewQuote,
        newQuoteLabel,
        isLoadingNewQuote,
        disclaimerTextCnd,
    } = props;

    useEffect(() => {
        switch (breakpoint) {
            case 'phone':
                setShowSidebar(false);
                break;
            default:
                setShowSidebar(true);
                break;
        }
    }, [breakpoint]);

    const toggleSideBar = useCallback(() => {
        setShowSidebar(!showSidebar);
    }, [showSidebar]);

    const contextComponent = useCallback(() => {
        return <div className={styles.wizardContext}>{renderContextComponent(wizardSnapshot)}</div>;
    }, [renderContextComponent, wizardSnapshot]);

    const getAsideComponents = useCallback(() => {
        return (
            <>
                {asideComponents.map((Component) => (
                    <Component currentStepIndex={currentStepIndex} submissionVM={wizardData} />
                ))}
            </>
        );
    });

    const shouldShowButtons = useMemo(() => !(
        showNext === false
        && showPrevious === false
        && showCancel === false
        // changed for cnd
        && showNewQuote === false
    ), [showCancel, showNext, showPrevious, showNewQuote]);

    // Cnd custom
    const isNextOnly = useMemo(() => (
        showPrevious === false
        && showCancel === false
        && showNewQuote === false
        && showEditQuote === false
    ), [showCancel, showPrevious, showNewQuote, showEditQuote]);

    const isPerformingTransiton = useMemo(() => (
        isLoadingCancel || isLoadingPrevious || isLoadingNext
    ), [isLoadingCancel, isLoadingNext, isLoadingPrevious]);

    const wizardClassName = classNames(styles.wizardMain, {
        [styles.wizardMainShowSidebar]: showSidebar && isPhone
    });

    const nextButton = useMemo(() => (
        <Button
            id="gw-wizard-Next"
            className={styles.nextbutton}
            disabled={disableNext || isPerformingTransiton}
            onClick={onNext}
        >
            {translator(nextLabel)}
            {renderLoader(isLoadingNext)}
        </Button>
    ), [disableNext, isLoadingNext, isPerformingTransiton, nextLabel, onNext, translator]);

    const nextButtonWithTooltip = useMemo(() => (
        <Tooltip placement="bottom" content={nextButtonTooltip}>
            <span>{nextButton}</span>
        </Tooltip>
    ), [nextButton, nextButtonTooltip]);

    const renderNextButton = nextButtonTooltip ? nextButtonWithTooltip : nextButton;
    const buttonContainerClassName = classNames(styles.navTransitionButtons, {
        [styles.navTransitionButtonOnlyNext]: !showPrevious && isPhone
    });

    return (
        <div className={styles.digitalWizard}>
            <div className={wizardClassName}>
                {!preventSidebar && (!isPhone || showSidebar) ? (
                    // Cnd custom: place of contextComponent changed, style added
                    <div>
                        <div className={styles.wizardNavigation}>
                            <WizardSidebar customMessageModal={customMessageModal} />
                            {getAsideComponents()}
                            <div className={styles.wizardWidgets}>
                                {renderWidgets(wizardData)}
                            </div>
                        </div>
                        {showContextComponent && contextComponent()}
                    </div>
                ) : null}
                <div className={styles.wizardContent}>
                    {/* Cnd customization */}
                    {!preventSidebar && isPhone ? <MobileWizardSteps /> : null}
                    {showWizardHeader && contextComponent()}
                    {content}
                    <div className={classNames({
                        [styles.navButtons]: shouldShowButtons,
                        [styles.nextOnly]: isNextOnly
                    })}
                    >
                        <div className={buttonContainerClassName}>
                            {showCancel && (
                                <Button
                                    id="gw-wizard-cancel"
                                    disabled={disableCancel || isPerformingTransiton}
                                    type="tertiary"
                                    onClick={onCancel}
                                >
                                    {translator(cancelLabel)}
                                    {renderLoader(isLoadingCancel)}
                                </Button>
                            )}
                            {showNewQuote && (
                                <Button
                                    id="gw-wizard-new-quote"
                                    disabled={disableNewQuote || isPerformingTransiton}
                                    type="tertiary"
                                    onClick={onNewQuote}
                                >
                                    {translator(newQuoteLabel)}
                                    {renderLoader(isLoadingNewQuote)}
                                </Button>
                            )}
                            {showEditQuote && (
                                <Button
                                    id="gw-wizard-edit-quote"
                                    disabled={disableEditQuote || isPerformingTransiton}
                                    type="secondary"
                                    onClick={onEditQuote}
                                >
                                    {translator(editQuoteLabel)}
                                    {renderLoader(isLoadingEditQuote)}
                                </Button>
                            )}
                        </div>
                        <div className={buttonContainerClassName}>
                            {showPrevious && (
                                <Button
                                    id="gw-wizard-previous"
                                    disabled={disablePrevious || isPerformingTransiton}
                                    type="secondary"
                                    onClick={onPrevious}
                                >
                                    {translator(previousLabel)}
                                    {renderLoader(isLoadingPrevious)}
                                </Button>
                            )}
                            {showNext && (
                                /* changed for CND */
                                <>
                                    {disclaimerTextCnd && (
                                        <div className={styles.cndDisclaimer}>
                                            {disclaimerTextCnd}
                                        </div>
                                    )}
                                    {renderNextButton}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

DigitalWizardPageTemplate.propTypes = {
    ...pageTemplateProps,
    /** a function that accepts wizardData to render a React component */
    renderContextComponent: PropTypes.func,
    /** a function that accepts wizardData to render a React component */
    renderWidgets: PropTypes.func,
    showContextComponent: PropTypes.bool,
    showWizardHeader: PropTypes.bool,
    preventSidebar: PropTypes.bool,
    nextButtonTooltip: PropTypes.string,
    disclaimerTextCnd: PropTypes.string
};

DigitalWizardPageTemplate.defaultProps = {
    renderContextComponent: () => null,
    renderWidgets: () => null,
    showContextComponent: true,
    showWizardHeader: false,
    preventSidebar: false,
    nextButtonTooltip: '',
    disclaimerTextCnd: ''
};

export default DigitalWizardPageTemplate;
