/* Custom CND module handling special requirement to check age basing on year only */

function calculateAge(year) {
    const yearNumber = Number(year);
    const currentYear = new Date().getFullYear();
    return (currentYear - yearNumber);
}

function isAgeNonNegative(year) {
    return calculateAge(year) >= 0;
}

function isAgeLessOrEqual(year, age) {
    return calculateAge(year) <= age;
}

export default {
    isAgeNonNegative,
    isAgeLessOrEqual
}