import React, { useCallback } from 'react';
import Collapsible from './Collapsible';

function withCollapsible(Component, collapsibleProps) {
    function CollapsibleComponent(props) {
        const { styles, onHeaderRender } = collapsibleProps;
        const {
            autoMeasure,
            collapseId,
            collapsed,
            noToggle,
            onToggle,
            onTransitionEnd,
            ...componentProps
        } = props;
        const renderHeader = useCallback(() => onHeaderRender(componentProps), [componentProps, onHeaderRender]);

        return (
            <Collapsible
                styles={styles}
                autoMeasure={autoMeasure}
                collapseId={collapseId}
                collapsed={collapsed}
                noToggle={noToggle}
                onToggle={onToggle}
                onTransitionEnd={onTransitionEnd}
                onHeaderRender={renderHeader}
            >
                <Component {...componentProps} />
            </Collapsible>
        );
    }

    CollapsibleComponent.propTypes = Collapsible.propTypes;
    CollapsibleComponent.defaultProps = Collapsible.defaultProps;

    return CollapsibleComponent;
}

export default withCollapsible;
