import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class PolicyDocumentService {
    /**
     * Fetch all documents related to the quote
     * @param {string} sessionUUID id of a session
     * @param {string} quoteID id of a quote
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getPolicyDocuments(sessionUUID, quoteID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policydocuments'),
            'getPolicyDocuments',
            [sessionUUID, quoteID],
            additionalHeaders
        );
    }
}
