import _ from 'lodash';
import { start } from '@jutro/app';
import { initDefaultGA, initDefaultMixpanel } from '@jutro/events';
import { LocaleService } from 'gw-portals-i18n-react';
import { CountryService } from 'cnd-common-portals-util-js';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import colonnadeThemeConfig from './themes/colonnadeTheme/config';

import 'regenerator-runtime';

import App from './app/App';

const { trackingConfig = {} } = appConfig;

const gaTrackingId = trackingConfig.GA_TRACKING_ID;
const mixpanelTrackingId = trackingConfig.MIXPANEL_TRACKING_ID;

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}
if (mixpanelTrackingId) {
    initDefaultMixpanel(trackingConfig);
}

const detectSiteLanguage = () => {
    if (process.env.REACT_APP_SITE_LANG_SELECTOR_ENABLED === 'true') return null;
    const SITE_LANG_KEY = 'REACT_APP_SITE_LANG';
    const { href } = window.location;
    const siteLang = _.find(_.toPairs(process.env), ([key, value]) => {
        if (_.startsWith(key, SITE_LANG_KEY)) {
            const urlsForLang = JSON.parse(value);
            return _.find(urlsForLang, (urlForLang) => {
                const urlRegExp = new RegExp(urlForLang);
                return urlRegExp.test(href);
            });
        }
        return false;
    });
    if (siteLang) {
        const [siteKey] = siteLang;
        const lang = _.lowerCase(siteKey.substring(SITE_LANG_KEY.length + 1));
        return lang;
    }
    return null;
};

start(App, {
    rootId: 'root',
    themeConfig: colonnadeThemeConfig,
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    refreshOnLocaleChange: true,
    onInit: () => {
        const localeService = new LocaleService();
        const countryService = new CountryService();
        const languageFromUrl = detectSiteLanguage();
        if (languageFromUrl) {
            const country = languageFromUrl === 'cs' ? 'cz' : languageFromUrl;
            localeService.saveLanguage(languageFromUrl);
            countryService.saveCountry(country);
        } else {
            const language = localeService.getStoredLanguage();
            const country = (language === 'cs' || language === 'en-US') ? 'cz' : language;
            countryService.saveCountry(country);
        }
        LocaleService.register();
        CountryService.register();
    },
    appName: {
        id: 'digital.appName',
        defaultMessage: 'Colonnade Insurance S.A.'
    },
    appDescription: 'Colonnade Insurance S.A.',
    modalProvider: 'default',
});
