import _ from 'lodash';
import { InputMaskField } from '@jutro/components';

class VariableLenghtInputMask extends InputMaskField {
    isComplete(value) {
        const { mask } = this.props;
        const { patternMap } = this.state;

        return !mask || (value && _.every(patternMap, (pattern, index) => {
            const char = (value[index] ?? '').replace('_', '');
            return char.match(pattern);
        }));
    }
}

export default VariableLenghtInputMask;
