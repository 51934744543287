import { defineMessages } from 'react-intl';

export default defineMessages({
    travelPolicyComponentFormHeader: {
        id: 'quoteandbind.tvl.components.travelPolicyComponentHeader.Travel Policy information',
        defaultMessage: 'Travel Policy information'
    },
    travelPolicyStartDate: {
        id: 'quoteandbind.tvl.components.travelPolicy.Policy Start Date',
        defaultMessage: 'Policy Start Date'
    },
    travelPolicyEndDate: {
        id: 'quoteandbind.tvl.components.travelPolicy.Policy End Date',
        defaultMessage: 'Policy End Date'
    },
    travelPolicyTripZone: {
        id: 'quoteandbind.tvl.components.travelPolicy.Trip zone',
        defaultMessage: 'Trip zone'
    },
    travelDepartureDate: {
        id: 'quoteandbind.tvl.components.travelPolicy.Departure Date',
        defaultMessage: 'Departure Date'
    },
    travelReturnDate: {
        id: 'quoteandbind.tvl.components.travelPolicy.Return Date',
        defaultMessage: 'Return Date'
    },
    travelPolicyDestination: {
        id: 'quoteandbind.tvl.components.travelPolicy.Destination',
        defaultMessage: 'Destination'
    },
    travelPolicyOffering: {
        id: 'quoteandbind.tvl.components.travelPolicy.Offering',
        defaultMessage: 'Offering'
    },
    travelTicketPurchaseDate: {
        id: 'quoteandbind.tvl.components.travelPolicy.Ticket/ trip purchase date',
        defaultMessage: 'Ticket/ trip purchase date'
    },
    travelTotalCostOfTicket: {
        id: 'quoteandbind.tvl.components.travelPolicy.Total cost of ticket/ trip',
        defaultMessage: 'Total cost of ticket/ trip'
    },
    travelVariant: {
        id: 'quoteandbind.tvl.components.travelPolicy.Variant',
        defaultMessage: 'Variant'
    },
    travelPolicySelectedPackage: {
        id: 'quoteandbind.tvl.components.travelPolicy.Selected package',
        defaultMessage: 'Selected package'
    },
    travelPolicyAdditionalCovers: {
        id: 'quoteandbind.tvl.components.policyholder.Additional covers',
        defaultMessage: 'Additional covers'
    }
});
