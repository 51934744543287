import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteDocumentErrorMessage: {
        id: 'quoteandbind.tvl.components.summary-documents.Error occured while generating quote document',
        defaultMessage: 'Error occured while generating quote document'
    },
    retryButtonLabel: {
        id: 'quoteandbind.tvl.components.summary-documents.Retry',
        defaultMessage: 'Retry'
    },
});