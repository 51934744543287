import _ from 'lodash';
import { PolicyHolderType } from './PolicyHolder';

function Person(data) {
    if (data) {
        _.extend(this, data);
    }

    this.dateOfBirth = this.dateOfBirth && new Date(this.dateOfBirth);
    this.subtype = PolicyHolderType.Person;
}


export default Person;
