import { defineMessages } from 'react-intl';

export default defineMessages({
    personalInformationPageIndividualPerson: {
        id: 'quoteandbind.tvl.step.personal-information.Individual Person',
        defaultMessage: 'Individual Person'
    },
    personalInformationPageCompany: {
        id: 'quoteandbind.tvl.step.personal-information.Company',
        defaultMessage: 'Company'
    },
    personalInformationPageIndividualEnterpreneur: {
        id: 'quoteandbind.tvl.step.personal-information.Individual Enterpreneur',
        defaultMessage: 'Individual Enterpreneur'
    },
    personalInformationPageContactType: {
        id: 'quoteandbind.tvl.step.personal-information.Contact Type',
        defaultMessage: 'Contact Type'
    }
});
