import _ from 'lodash';
import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useMemo,
    useRef,
} from 'react';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import {
    ContactDataService,
    TrackingConstants,
    TagManagerService,
    CountryLayerService
} from 'cnd-portals-util-js';
import { LOBContext } from 'cnd-common-components-platform-react';
import { useStoredCountry, useValidationErrors } from 'cnd-common-hooks-platform-react';
import Consents from '../../components/Consents/Consents';
import TechnicalIssuesPage from '../TechnicalIssuesPage/TechnicalIssuesPage';
import InsuredPersons from '../../components/InsuredPersons/InsuredPersons';
import PolicyHolder from '../../components/PolicyHolder/PolicyHolder';
import SummaryContext from '../../components/summary/SummaryContext';
import StaticInformationData from '../../constants/StaticInformationData';
import Submission from '../../models/Submission';
import metadata from './PersonalInformationPage.metadata.json5';
import messages from './PersonalInformationPage.messages';
import styles from './PersonalInformationPage.module.scss';
import { useSelectedVariant } from '../../hooks/useSubmission';
import useErrorHandler from '../../hooks/useErrorHandler';
import {
    CND_DTO_VALIDATION_ERROR,
    CND_SANCTION_SCREENING_ERROR
} from '../../constants/ErrorCodes';
import { CAR_ASSISTANCE_ADDON_PUBLIC_ID } from '../../constants/QuoteConstants'

const ADDL_INSURED_TYPE = 'PolicyAddlNamedInsured';
function PersonalInformationPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);

    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const ErrorHandler = useErrorHandler();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const {
        isComponentValid,
        registerComponentValidation,
        onValidate,
    } = useValidation('PersonalInformationPage');
    const selectedVariant = useSelectedVariant(submissionVM);
    const { authHeader } = useAuthentication();
    const [hasSanctionScreeningError, setHasSanctionScreeningError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [
        filterValidationErrors, setValidationErrors, resetValidationError
    ] = useValidationErrors();

    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), [updateState]);

    const accountHolder = _.get(submissionVM, 'baseData.accountHolder.value');
    const accountHolderVMRef = useRef(viewModelService.create(
        { ...accountHolder },
        'pc',
        'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO',
        {
            PolicyCountry: appCountry,
            PersonalDataFieldsNotRequiredContext: false,
            AccountEmailRequired: true,
            ProductCode: _.get(submissionVM.value, 'baseData.productCode')
        }
    ));

    const accountHolderVM = useMemo(() => {
        accountHolderVMRef.current.value = accountHolder;
        return accountHolderVMRef.current;
    }, [accountHolder]);

    const isResident = _.get(accountHolderVM, 'resident_Cnd.value', true);
    const {
        isFromQuoteRetrieval
    } = useContext(LOBContext);

    const ERRORS_CONFIG = {
        'LobData.Travel.AgeBands': translator(messages.personalInformationPageInvalidAgeBandConfig),
        'PolicyContacts_Cnd': translator(messages.personalInformationPageAddlInsuredDuplicate)
    };

    const getFormValidity = useCallback(() => {
        const isFormValid = submissionVM.baseData.consents_Cnd.aspects.valid && submissionVM.baseData.consents_Cnd.aspects.subtreeValid
            && submissionVM.baseData.policyContacts_Cnd.aspects.valid && submissionVM.baseData.policyContacts_Cnd.aspects.subtreeValid
            && submissionVM.baseData.accountHolder.aspects.valid && submissionVM.baseData.accountHolder.aspects.subtreeValid;
        return isFormValid;
    }, [submissionVM.baseData]);

    useEffect(() => {
        registerComponentValidation(getFormValidity);
    }, [
        getFormValidity,
        registerComponentValidation
    ]);

    const selectedAddonCoverages = useMemo(() => {
        const addons = _.reduce(selectedVariant.coverages.addonCoverages, (result, coverage) => {
            if (coverage.selected) {
                // eslint-disable-next-line no-param-reassign
                result[coverage.publicID] = coverage;
            }
            return result;
        }, {});
        return addons || {};
    }, [selectedVariant]);

    useEffect(() => {
        if (!!selectedAddonCoverages[CAR_ASSISTANCE_ADDON_PUBLIC_ID]
                && _.isNil(_.get(submissionVM.value, 'lobData.travel.firstRegistrationDate_Cnd'))
                && !CountryLayerService.isFirstRegistrationDateVisible(appCountry)) {
            const dateToSet = new Date(_.get(submissionVM.value, 'lobData.travel.vehicleProductionYear'));
            _.set(submissionVM.value, 'lobData.travel.firstRegistrationDate_Cnd',
                {day: dateToSet.getDate(), month: dateToSet.getMonth(), year: dateToSet.getFullYear()});
        }
    }, []);

    const onNext = useCallback(async () => {
        TagManagerService.pushNextButtonClick(TrackingConstants.STEPS.PERSONAL_DATA);
        try {
            const submission = _.cloneDeep(submissionVM.value);
            const phoneNumber = _.get(submission, 'baseData.accountHolder.phone_Cnd.number');
            if (_.size(phoneNumber) === 0) {
                _.unset(submission, 'baseData.accountHolder.phone_Cnd');
            }
            const newSubmission = await LoadSaveService.updateQuotedSubmission(
                submission,
                authHeader
            );
            submissionVM.value = new Submission(newSubmission);
            return submissionVM;
        } catch (error) {
            const appErrorCode = error?.appErrorCode;
            if (appErrorCode === CND_SANCTION_SCREENING_ERROR) {
                setHasSanctionScreeningError(true);
                return false;
            }
            if (appErrorCode === CND_DTO_VALIDATION_ERROR) {
                ErrorHandler.updateRootCause(error, ERRORS_CONFIG);
                if (error.customErrorMessage) {
                    ErrorHandler.handleError(error);
                } else {
                    setValidationErrors(error.appData);
                }
                return false;
            }
            ErrorHandler.handleError(error);
            return false;
        }
    }, [
        LoadSaveService,
        authHeader,
        submissionVM,
        ERRORS_CONFIG,
        ErrorHandler,
        setHasSanctionScreeningError,
        setValidationErrors
    ]);

    const onPrevious = useCallback(() => {
        if (hasSanctionScreeningError) {
            setHasSanctionScreeningError(false);
        }
    }, [hasSanctionScreeningError]);

    useEffect(() => {
        const vm = viewModelService.changeContext(
            submissionVM,
            {
                PersonalDataFieldsNotRequiredContext: false,
            }
        );

        const baseData = _.get(vm.value, 'baseData');
        if (_.isNil(baseData.accountHolder)) {
            baseData.createAccountHolder();
        }
        if (_.isNil(baseData.accountHolder?.subtype)) {
            baseData.createAccountHolder(baseData.accountHolder);
        }

        const policyContacts = _.get(vm.value, 'baseData.policyContacts_Cnd', []);
        const insuredPersons = policyContacts.filter((contact) => contact.contactRole === ADDL_INSURED_TYPE);
        const ageBands = _.get(vm.value, 'lobData.travel.ageBands');
        const totalContactsCount = ageBands
            .reduce((acc, curr) => acc + curr.numberOfTravellers, 0);
        if (insuredPersons.length < totalContactsCount) {
            baseData.createInsuredPersons([], totalContactsCount - insuredPersons.length);
        } else if (insuredPersons.length > totalContactsCount) {
            const otherContacts = policyContacts.filter((contact) => contact.contactRole !== ADDL_INSURED_TYPE);
            _.set(vm.value, 'baseData.policyContacts_Cnd', otherContacts);

            baseData.createInsuredPersons([], totalContactsCount);
        }

        updateWizardData(vm);
        setIsLoading(false);
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!accountHolderVM.value) return;
        if (!isResident) {  
            _.set(accountHolderVM.value, 'personalID_Cnd', null);
        } else if (appCountry !== 'HU') {
            _.set(accountHolderVM.value, 'passportNumber_Cnd', null);
        }
    }, [accountHolderVM.value, appCountry, isResident]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            _.set(submissionVM, `${changedPath}.value`, value);
            if (changedPath.startsWith('baseData.accountHolder.phone_Cnd')) {
                resetValidationError('baseData.accountHolder.phone_Cnd');
            } else if (changedPath.startsWith('baseData.accountHolder.emailAddress1')) {
                resetValidationError('baseData.accountHolder.emailAddress1');
            }

            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData, resetValidationError]
    );

    const getAccountHolderDataForInsured = useCallback(() => {
        if (!_.get(accountHolderVM.value, 'firstName') || !_.get(accountHolderVM.value, 'lastName') || !_.get(accountHolderVM.value, 'dateOfBirth')) {
            return;
        }
        return {
            firstName: accountHolderVM.value.firstName,
            lastName: accountHolderVM.value.lastName,
            dateOfBirth: {...accountHolderVM.value.dateOfBirth}
        }
    }, [accountHolderVM.value]);

    const usePolicyHolderDataAsInsured = useCallback(() => {
        const holderData = getAccountHolderDataForInsured();
        if (!holderData) {
            return;
        }
        const policyContacts = _.get(submissionVM.value, 'baseData.policyContacts_Cnd') || [];
        const insuredPerson = policyContacts.filter((contact) => contact.contactRole === 'PolicyAddlNamedInsured')[0];
        insuredPerson.contact = holderData;
        forceUpdate();
    }, [getAccountHolderDataForInsured, forceUpdate, submissionVM]);

    const {
        isPersonalIDVisible,
        isNationalIDVisible
    } = ContactDataService.getPersonIDVisibility(appCountry);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        tvlPersonalInformationPageLoader: {
            visible: isLoading
        },
        tvlPersonalInformationPageContainer: {
            visible: !isLoading
        },
        personalInformationPagePolicyHolder: {
            value: accountHolderVM,
            onValidate,
            isResidentVisible: true,
            isPersonalIDVisible: isPersonalIDVisible && isResident,
            isNationalIDVisible: (isNationalIDVisible && !isResident) || !isPersonalIDVisible,
            onValueChange: handleValueChange,
            filterValidationErrors
        },
        personalInformationPageAdditionalEmailInformation: {
            className: styles.additionalInfoContainer,
            visible: appCountry === 'HU',
        },
        personalInformationPageAdditionalEmailInformationText: {
            className: styles.additionalInfoContainerWidth
        },
        personalInformationUsePolicyHolderDataButtonRow: {
            className: `cndMarginBottomSM ${styles.personalInformationButtonRow}`
        },
        personalInformationUsePolicyHolderDataButtonInfo: {
            className: `cndMarginBottomSM ${styles.personalInformationButtonRowElement}`
        },
        personalInformationPageInsuredPersons: {
            value: _.get(submissionVM, 'baseData.policyContacts_Cnd'),
            onValidate,
            onValueChange: handleValueChange,
            visible: _.get(submissionVM, 'baseData.policyContacts_Cnd') !== undefined
        },
        personalInformationPageDocumentLinks: {
            model: submissionVM
        },
        personalInformationPageLicensePlate: {
            visible: !!selectedAddonCoverages[CAR_ASSISTANCE_ADDON_PUBLIC_ID],
            required: true
        },
        personalInformationFirstRegistrationDate: {
            visible: !!selectedAddonCoverages[CAR_ASSISTANCE_ADDON_PUBLIC_ID] 
                && CountryLayerService.isFirstRegistrationDateVisible(appCountry),
            required: true
        },
        personalInformationPageInsuredPersonsHint: {
            visible: !isFromQuoteRetrieval
        },
        personalInformationPageInsuredPersonsHintAfterRetrieval: {
            visible: isFromQuoteRetrieval
        },
        personalInformationUsePolicyHolderDataButton: {
            disabled: !getAccountHolderDataForInsured()
        },
        personalInformationPageStaticInformation: {
            step: 'personalInformationPageStep',
            country: appCountry,
            staticInformationData: StaticInformationData
        },
        personalInformationPageConsents: {
            visible: _.get(submissionVM, 'baseData.consents_Cnd') !== undefined,
            value: _.get(submissionVM, 'baseData.consents_Cnd'),
            onValidate,
            path: 'baseData.consents_Cnd',
            readOnly: false,
            locationFilter: 'qb_step_3'
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
            onValueChange: handleValueChange,
            usePolicyHolderDataAsInsured
        },
        resolveComponentMap: {
            insuredpersons: InsuredPersons,
            policyHolder: PolicyHolder,
            consents: Consents
        }
    };

    if (hasSanctionScreeningError) {
        return <TechnicalIssuesPage onPrevious={onPrevious} />;
    }

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
        >
            <SummaryContext.Provider
                value={{
                    readOnly: false
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                    onValueChange={handleValueChange}
                />
            </SummaryContext.Provider>
        </WizardPage>
    );
}

PersonalInformationPage.propTypes = wizardProps;
export default PersonalInformationPage;
