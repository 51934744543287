import React from 'react';
import _ from 'lodash';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import metadata from './ContactUs.metadata.json5';
import styles from './ContactUs.module.scss';
import './ContactUs.messages';

function ContactUs(props) {
    const { location } = props;

    const quoteIDFromWizard = _.get(location, 'state.gwInfo.params[0].quoteID');
    const quoteIDFromServerCall = _.get(location, 'state.error.gwInfo.params[0]');

    const quoteID = quoteIDFromWizard || quoteIDFromServerCall;

    const overrideProps = {
        getAssistanceText: {
            visible: quoteID === undefined
        },
        quoteContainer: {
            visible: quoteID !== undefined
        },
        quoteID: {
            value: quoteID
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <div className={styles.contactUsContainer}>
            {renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers)}
        </div>
    );
}

ContactUs.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            error: PropTypes.shape({
                gwInfo: PropTypes.shape({})
            })
        })
    })
};
ContactUs.defaultProps = {
    location: undefined
};
export default withRouter(ContactUs);
