/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import React, {
    useCallback,
    useEffect,
    useState,
    useContext
} from 'react';
import _, { isBoolean } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import { QuoteTableStaticInformationService, CurrencyFormatterService, CountryLayerService } from 'cnd-portals-util-js';
import classNames from 'classnames';
import { CAR_ASSISTANCE_ADDON_PUBLIC_ID } from '../../constants/QuoteConstants';
import Price from '../Price/Price';
import messages from './QuoteTable.messages';
import styles from './QuoteTable.module.scss';

const { formatDate } = CountryLayerService;

function TVLQuoteTable(props) {
    const translator = useContext(TranslatorContext);
    const {
        flavours,
        selectedVariant,
        onVariantSelection,
        coversInitiallyExpanded,
        additionalCoversHidden,
        onValueChange,
        isFromQuoteRetrieval
    } = props;
    const [standardCoveragesRowData, setStandardCoveragesRowData] = useState();
    const [addonCoveragesRowData, setAddonCoveragesRowData] = useState();
    const [headerFooterData, setHeaderFooterData] = useState();
    const coveragesType = Object.freeze({
        ADDON_COVERS: 'addonCoverages',
        STANDARD_COVERS: 'standardCoverages'
    });
    const [allVariants, setAllVariants] = useState();
    const canChangeVariant = allVariants?.length > 1;
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    const handleVariantsChange = useCallback(
        (value) => {
            if (onValueChange) {
                onValueChange(value, 'lobData.travel.variants');
            }
        },
        [onValueChange]
    );

    useEffect(() => {
        setAllVariants(_.cloneDeep(flavours));
    }, [flavours]);

    const centerCameraOnExtendedCovTerm = (componentId) => {
        const extendedItemElement = document.getElementById(`${componentId}`);
        if (extendedItemElement) {
            // Calculate the position of the extended item
            const itemPosition = extendedItemElement.getBoundingClientRect();
            const scrollX = itemPosition.left + window.scrollX - window.innerWidth / 2;
            const scrollY = itemPosition.top + window.scrollY - window.innerHeight / 4;
            // Scroll to center the extended item
            window.scrollTo(scrollX, scrollY);
        }
    };

    const toggleCovTermsSectionVisibility = useCallback((publicID) => {
        const newVariants = allVariants.map((variant) => {
            const { coverages: { addonCoverages, standardCoverages } } = variant;
            const coverages = addonCoverages.concat(standardCoverages);
            coverages.forEach((cov) => {
                const newCov = cov;
                if (cov.publicID === publicID) {
                    newCov.isExpanded = !newCov.isExpanded;
                } else {
                    newCov.isExpanded = false;
                }
            });
            return variant;
        });
        setAllVariants(newVariants);
        setTimeout(() => centerCameraOnExtendedCovTerm(publicID), 100);
    }, [allVariants]);

    const addAddon = useCallback((covPublicID) => {
        const newVariants = allVariants.map((variant) => {
            const { coverages: { addonCoverages } } = variant;
            addonCoverages.find((addon) => addon.publicID === covPublicID).selected = true;
            return variant;
        });
        setAllVariants(newVariants);
        handleVariantsChange(newVariants);
    }, [allVariants, handleVariantsChange]);

    const removeAddon = useCallback((covPublicID) => {
        const newVariants = allVariants.map((variant) => {
            const { coverages: { addonCoverages } } = variant;
            addonCoverages.find((addon) => addon.publicID === covPublicID).selected = false;
            return variant;
        });
        setAllVariants(newVariants);
        handleVariantsChange(newVariants);
    }, [allVariants, handleVariantsChange]);

    const changeSelectedVariant = useCallback((branchName) => {
        const newVariants = allVariants.map((variant) => {
            const newVariant = variant;
            const isSelected = variant.branchName === branchName;
            newVariant.selected = isSelected;
            if (isSelected) {
                onVariantSelection(branchName);
            }
            return newVariant;
        });
        setAllVariants(newVariants);
        handleVariantsChange(newVariants);
    }, [allVariants, onVariantSelection, handleVariantsChange]);

    const generateCoveragesRowData = useCallback((offers, resolveCoverages) => {
        const allCoverages = offers.flatMap((variant) => resolveCoverages(variant.coverages));
        const uniqueCoverages = [...new Map(allCoverages
            .map((item) => [item.name, item])).values()];
        const uniqueCovTerms = uniqueCoverages.map((coverage) => {
            const allTerms = allCoverages
                .filter((cov) => cov.name === coverage.name)
                .map((cov) => cov.terms)
                .flat();
            const uniqueTerms = [...new Map(allTerms.map((item) => [item.name, item])).values()];
            return {
                coveragePublicID: coverage.publicID,
                terms: uniqueTerms
            };
        });
        const coverages = _.sortBy(uniqueCoverages, (cov) => cov.order_Cnd)
            .map((coverage) => {
                return {
                    name: coverage.name,
                    publicID: coverage.publicID,
                    variants: offers.map((variant) => {
                        const data = resolveCoverages(variant.coverages)
                            .find((cov) => cov.publicID === coverage.publicID);
                        if (data) {
                            data.isExpanded = _.isNil(data.isExpanded) ? coversInitiallyExpanded : data.isExpanded;
                        }
                        return {
                            data,
                            branchName: variant.branchName
                        };
                    }).flat(),
                    terms: _.sortBy(
                        uniqueCovTerms.find(
                            (termData) => termData.coveragePublicID === coverage.publicID
                        )?.terms,
                        (term) => term.order_Cnd
                    ).map((covTerm) => {
                        return {
                            name: covTerm.name,
                            publicID: covTerm.publicID,
                            variants: offers.map((variant) => {
                                const data = resolveCoverages(variant.coverages)
                                    .find((c) => c.publicID === coverage.publicID)?.terms
                                    .find((term) => term.publicID === covTerm.publicID);
                                return {
                                    data,
                                    branchName: variant.branchName
                                };
                            }).flat(),
                        };
                    }),
                    isExpanded: coverage.isExpanded,
                    isSelected: !allCoverages
                        .find((x) => x.publicID === coverage.publicID && x.selected === false)
                };
            });
        return coverages;
    }, [coversInitiallyExpanded]);

    const generateHeaderFooterRowData = useCallback((offers) => {
        return offers.map((variant) => {
            const standardPriceMultiplied = variant.coverages.standardCoverages
                .filter((cov) => cov.selected === true)
                .reduce((acc, cov) => {
                    return acc + cov?.amount?.amount;
                }, 0);
            const selectedAddonsPriceMultiplied = variant.coverages.addonCoverages
                .filter((cov) => cov.selected)
                .reduce((acc, cov) => {
                    return acc + cov?.amount?.amount;
                }, 0);
            const standardDiscountMultiplied = variant.coverages.standardCoverages
                .filter((cov) => cov.selected === true)
                .reduce((acc, cov) => {
                    return acc + (cov?.discount_Cnd?.amount || 0);
                }, 0);
            const selectedAddonsDiscountMultiplied = variant.coverages.addonCoverages
                .filter((cov) => cov.selected)
                .reduce((acc, cov) => {
                    return acc + (cov?.discount_Cnd?.amount || 0);
                }, 0);
            const discountSum = standardDiscountMultiplied + selectedAddonsDiscountMultiplied;
            return {
                branchName: variant.branchName,
                price: (standardPriceMultiplied + selectedAddonsPriceMultiplied).toFixed(2),
                discount: discountSum !== 0 ? discountSum.toFixed(2) : undefined,
                currency: variant.coverages.standardCoverages[0]?.amount?.currency?.toUpperCase()
            };
        });
    }, []);


    const generateRowData = useCallback(() => {
        if (allVariants === undefined) {
            return;
        }
        const standardCoverages = generateCoveragesRowData(allVariants,
            (obj) => obj.standardCoverages);
        setStandardCoveragesRowData(standardCoverages);

        const addonCoverages = generateCoveragesRowData(allVariants,
            (obj) => obj.addonCoverages);
        setAddonCoveragesRowData(addonCoverages);

        const header = generateHeaderFooterRowData(allVariants);
        setHeaderFooterData(header);
    }, [
        allVariants,
        generateCoveragesRowData,
        generateHeaderFooterRowData,
    ]);

    const generateCovTermContainer = useCallback((term) => {
        return (
            <div className={styles.covTermContainer}>
                <div className={styles.covTermNameContainer}>
                    {term.name}
                </div>
                <div className={styles.covTermVariantsContainer}>
                    { term.variants.map((variant) => {
                        let value;
                        if (variant?.data?.valueType === 'datetime') {
                            value = formatDate(variant?.data?.chosenTermValue, appCountry);
                        } else if (variant?.data?.directValue) {
                            value = CurrencyFormatterService.formatPrice(variant?.data?.directValue, appCountry);
                        } else if (variant?.data?.directBooleanValue) {
                            value = variant?.data?.directBooleanValue;
                        } else {
                            value = variant?.data?.chosenTermValue;
                        }
                        const variantStyle = classNames(styles.covTermVariantDataContainer, {
                            [styles.selectedVariant]: selectedVariant === variant.branchName
                        });
                        return (
                            <div className={variantStyle}>
                                { variant.data
                                    && (
                                        <div>
                                            {value === '' ? (
                                                translator(messages.unlimited)
                                            ) : isBoolean(value) ? (
                                                value === true
                                                    ? <span className={`mir mi-check-circle ${styles.yesIcon}`} />
                                                    : <span className={`mir mi-cancel ${styles.noIcon}`} />
                                            ) : (
                                                <Price
                                                    value={value}
                                                    currency={variant?.data?.currency_Cnd}
                                                    styles={{ amount: styles.variantLimitAmount, currency: styles.variantLimitCurrency }}
                                                />
                                            )}
                                        </div>
                                    )
                                }
                                { variant.data === undefined && '-' }
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }, [appCountry, selectedVariant, translator]);

    const generateVariantInfo = useCallback((variant) => {
        if (variant.data === undefined || variant?.data?.selected === false) {
            return (<span className={`mir mi-cancel ${styles.noIcon}`} />);
        }
        const limitCovTerm = variant?.data?.terms.find((x) => /(Total|Main)Limit/.test(x.patternCode));
        const value = limitCovTerm?.directValue
            ? CurrencyFormatterService.formatPrice(limitCovTerm.directValue, appCountry)
            : limitCovTerm?.chosenTermValue;
        if (variant?.data?.terms && limitCovTerm) {
            return (
                <div>
                    { value === '' ? translator(messages.unlimited)
                        : (
                            <Price
                                value={value}
                                currency={limitCovTerm.currency_Cnd}
                                styles={{ amount: styles.variantLimitAmount, currency: styles.variantLimitCurrency }}
                            />
                        )
                    }
                </div>
            );
        }
        return (<span className={`mir mi-check-circle ${styles.yesIcon}`} />);
    }, [appCountry, translator]);

    const generateCoverageContainer = useCallback((row, sectionType) => {
        const isAddonsSection = sectionType === coveragesType.ADDON_COVERS;
        const coverageRowStyle = classNames(styles.coverageRowContainer, {
            [styles.sectionOpened]: row.isExpanded
        });
        const expandButtonStyle = classNames(styles.expandButton, {
            [styles.expandButtonHidden]: row.terms.length === 0
        });

        const isDisabledAddon = !isFromQuoteRetrieval
                            && isAddonsSection
                            && !row?.variants?.[0].data?.amount?.amount;

        return (
            <div className={coverageRowStyle}>
                <div className={styles.coverageNameContainer}>
                    { (!isFromQuoteRetrieval || row.isSelected === true)
                        && (
                            <div className={expandButtonStyle}>
                                <Button
                                    icon="mi-expand-more"
                                    type="tertiary"
                                />
                            </div>
                        )
                    }
                    <div className={styles.coverageNameWrapper}>
                        <div className={`${styles.coverageName} ${isDisabledAddon ? styles.coverageNameDisabled : ''}`}>{row.name}</div>
                        {isDisabledAddon && (
                            <div className={styles.coverageUnavailable}>
                                { row.publicID === CAR_ASSISTANCE_ADDON_PUBLIC_ID ? // create reason mapping in new portal
                                    translator(messages.carAssistanceUnavailable, {}) :
                                    translator(messages.coverageUnavailable, { coverage: row?.name }) 
                                }
                            </div>
                        )}
                    </div>
                    { isAddonsSection && !isFromQuoteRetrieval
                        && (
                            row.isSelected === false
                                ? (
                                    <div className={styles.addButton}>
                                        <Button
                                            type="secondary"
                                            size="xsmall"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                addAddon(row.publicID);
                                            }}
                                            disabled={isDisabledAddon}
                                            className={isDisabledAddon ? styles.addButtonDisabled : ''}
                                        >
                                            { translator(messages.add) }
                                        </Button>
                                    </div>
                                )
                                : (
                                    <div className={styles.removeButton}>
                                        <Button type="neutral" size="xsmall" onClick={(e) => {
                                                e.stopPropagation();
                                                removeAddon(row.publicID);
                                            }}>
                                            { translator(messages.remove) }
                                        </Button>
                                    </div>
                                )
                        )
                    }
                </div>
                <div className={styles.variantsContainer}>
                    { row.variants.map((variant) => {
                        const variantStyle = classNames(styles.variantDataContainer, {
                            [styles.selectedVariant]: selectedVariant === variant.branchName
                        });

                        return (
                            <div className={variantStyle}>
                                { isAddonsSection === false && generateVariantInfo(variant)}
                                { isAddonsSection === true
                                    && (
                                        <div>
                                            {!isDisabledAddon && variant.data?.amount?.amount
                                                ? (
                                                    <>
                                                        <Price
                                                            value={variant?.data?.amount?.amount}
                                                            formatter={{
                                                                formatPrice: CurrencyFormatterService.formatPrice,
                                                                country: appCountry
                                                            }}
                                                            currency={variant?.data?.amount?.currency}
                                                            styles={{
                                                                container: styles.addonVariantContainer,
                                                                amount: styles.addonVariantPrice,
                                                                currency: styles.addonVariantCurrency
                                                            }}
                                                        />
                                                    </>
                                                )
                                                : (<span className={`mir mi-cancel ${styles.noIcon}`} />) }
                                        </div>
                                    )
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }, [
        generateVariantInfo,
        isFromQuoteRetrieval,
        toggleCovTermsSectionVisibility,
        coveragesType,
        selectedVariant,
        translator,
        addAddon,
        removeAddon,
        appCountry
    ]);

    const generateCoveragesSection = useCallback((sectionType) => {
        const isAddonsSection = sectionType === coveragesType.ADDON_COVERS;
        const coveragesRowData = isAddonsSection ? addonCoveragesRowData : standardCoveragesRowData;
        return (
            <>
                { coveragesRowData && coveragesRowData.map((row) => {
                    const sectionContainerStyle = classNames(styles.sectionContainer, {
                        [styles.standardCoveragesSection]: !isAddonsSection,
                        [styles.addonCoveragesSection]: isAddonsSection,
                    });

                    return (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <div
                            className={sectionContainerStyle}
                            onClick={() => {
                                return toggleCovTermsSectionVisibility(row.publicID);
                            }}
                            id={row.publicID}
                        >
                            { generateCoverageContainer(row, sectionType) }
                            <div className={!row.isExpanded && styles.sectionHidden}>
                                { row.terms.map((term) => {
                                    return generateCovTermContainer(term);
                                })}
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }, [
        addonCoveragesRowData,
        standardCoveragesRowData,
        coveragesType,
        generateCovTermContainer,
        generateCoverageContainer
    ]);

    const generateHeaderSection = useCallback(() => {
        return (
            <>
                <div className={styles.headerContainer}>
                    <div className={styles.headerText} />
                    <div className={styles.variantsContainer}>
                        { headerFooterData && headerFooterData.map((column) => {
                            const variantStyle = classNames(styles.variantDataContainer, {
                                [styles.selectedVariant]: selectedVariant === column.branchName
                            });
                            return (
                                <div className={variantStyle}>
                                    <div className={styles.headerItem}>
                                        <div className={styles.branchNameContainer}>
                                            {column.branchName}
                                        </div>
                                        { !column.discount ? (
                                            <Price
                                                value={column.price}
                                                formatter={{
                                                    formatPrice: CurrencyFormatterService.formatPrice,
                                                    country: appCountry
                                                }}
                                                currency={column.currency}
                                                styles={{ container: styles.priceContainer, amount: styles.price, currency: styles.currency }}
                                            />
                                        ) : (
                                            <>
                                                <Price
                                                    value={column.price}
                                                    formatter={{
                                                        formatPrice: CurrencyFormatterService.formatPrice,
                                                        country: appCountry
                                                    }}
                                                    currency={column.currency}
                                                    styles={{ container: styles.priceAfterDiscountContainer, amount: styles.price, currency: styles.currency }}
                                                />
                                                <Price
                                                    value={column.price - column.discount}
                                                    formatter={{
                                                        formatPrice: CurrencyFormatterService.formatPrice,
                                                        country: appCountry
                                                    }}
                                                    currency={column.currency}
                                                    styles={{ container: styles.priceBeforeDiscountContainer, amount: styles.price, currency: styles.currency }}
                                                />
                                            </>
                                        )}

                                        {canChangeVariant && (
                                            <div className={styles.changeVariantButton}>
                                                <Button
                                                    type={selectedVariant === column.branchName ? 'primary' : 'secondary'}
                                                    size="small"
                                                    onClick={() => {
                                                        return changeSelectedVariant(column.branchName);
                                                    }}
                                                >
                                                    { selectedVariant === column.branchName
                                                        ? translator(messages.selected)
                                                        : translator(messages.selectPlan)
                                                    }
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }, [headerFooterData,
        selectedVariant,
        appCountry,
        canChangeVariant,
        translator,
        changeSelectedVariant]);

    const generateFooterSection = useCallback(() => {
        return (
            <div className={styles.footerContainer}>
                <div className={styles.footerText}>
                    { translator(messages.totalPriceForPackage) }
                    <div className={styles.footerStaticInfoText}>
                        {QuoteTableStaticInformationService.isVisibleOnQuotePage(appCountry)
                            ? translator(messages.totalPriceForPackageStaticInfo)
                            : null}
                    </div>
                </div>
                <div className={styles.variantsContainer}>
                    { headerFooterData && headerFooterData.map((column) => {
                        const variantStyle = classNames(styles.variantDataContainer, {
                            [styles.selectedVariant]: selectedVariant === column.branchName
                        });
                        return (
                            <div className={variantStyle}>
                                <div className={styles.footerItem}>
                                    { !column.discount ? (
                                        <Price
                                            value={column.price}
                                            formatter={{
                                                formatPrice: CurrencyFormatterService.formatPrice,
                                                country: appCountry
                                            }}
                                            currency={column.currency}
                                            styles={{ container: styles.priceContainer, amount: styles.price, currency: styles.currency }}
                                        />
                                    ) : (
                                        <>
                                            <Price
                                                value={column.price}
                                                formatter={{
                                                    formatPrice: CurrencyFormatterService.formatPrice,
                                                    country: appCountry
                                                }}
                                                currency={column.currency}
                                                styles={{
                                                    container: styles.priceAfterDiscountContainer,
                                                    amount: styles.price,
                                                    currency: styles.currency
                                                }}
                                            />
                                            <Price
                                                value={column.price - column.discount}
                                                formatter={{
                                                    formatPrice: CurrencyFormatterService.formatPrice,
                                                    country: appCountry
                                                }}
                                                currency={column.currency}
                                                styles={{ container: styles.priceBeforeDiscountContainer, amount: styles.price, currency: styles.currency }}
                                            />
                                        </>
                                    )}
                                    {canChangeVariant && (
                                        <div className={styles.changeVariantButton}>
                                            <Button
                                                type={selectedVariant === column.branchName ? 'primary' : 'secondary'}
                                                size="small"
                                                onClick={
                                                    () => changeSelectedVariant(column.branchName)
                                                }
                                            >
                                                { selectedVariant === column.branchName
                                                    ? translator(messages.selected)
                                                    : translator(messages.selectPlan)
                                                }
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }, [
        headerFooterData,
        canChangeVariant,
        changeSelectedVariant,
        selectedVariant,
        translator,
        appCountry
    ]);

    useEffect(() => {
        generateRowData();
    }, [generateRowData]);

    const generateQuoteTable = useCallback(() => {
        let isAtLeastOneDiscount;
        if (standardCoveragesRowData) {
            isAtLeastOneDiscount = standardCoveragesRowData.some((row) => {
                return row?.variants.some((variant) => {
                    return !_.isNil(variant?.data?.discount_Cnd);
                });
            });
        }

        return (
            <div className={styles.quoteTableWrapper}>
                { generateHeaderSection() }
                { generateCoveragesSection(coveragesType.STANDARD_COVERS) }
                {
                    !additionalCoversHidden && isAtLeastOneDiscount && (
                        <div className={styles.alreadyAppliedDiscountDisclaimer}>
                            { translator(messages.alreadyAppliedDiscount) }
                        </div>
                    )
                }
                { !additionalCoversHidden
                    && addonCoveragesRowData?.length > 0
                    && (
                        <div className={styles.addonCoveragesTitle}>
                            { translator(messages.additionalCoverOptions) }
                        </div>
                    )
                }
                { !additionalCoversHidden
                    && addonCoveragesRowData?.length > 0
                    && generateCoveragesSection(coveragesType.ADDON_COVERS) }
                { generateFooterSection() }
            </div>
        );
    }, [
        generateHeaderSection,
        generateCoveragesSection,
        generateFooterSection,
        coveragesType,
        translator,
        additionalCoversHidden,
        addonCoveragesRowData,
        standardCoveragesRowData
    ]);

    return (
        <>
            { generateQuoteTable() }
        </>
    );
}

TVLQuoteTable.propTypes = {
    selectedVariant: PropTypes.string.isRequired,
    flavours: PropTypes.arrayOf(PropTypes.shape({})),
    onVariantSelection: PropTypes.func.isRequired,
    coversInitiallyExpanded: PropTypes.bool,
    additionalCoversHidden: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    isFromQuoteRetrieval: PropTypes.bool
};
TVLQuoteTable.defaultProps = {
    flavours: [],
    coversInitiallyExpanded: false,
    additionalCoversHidden: false,
    isFromQuoteRetrieval: false
};
export default TVLQuoteTable;
