import { defineMessages } from 'react-intl';

export default defineMessages({
    quotePageHeader: {
        id: 'quoteandbind.tvl.step.quote.Pick the package which is right for you',
        defaultMessage: 'Pick the package which is right for you'
    },
    quoteEditQuote: {
        id: 'quoteandbind.tvl.step.quote.Edit quote',
        defaultMessage: 'Edit quote'
    },
    quoteCancel: {
        id: 'quoteandbind.tvl.step.quote.Cancel',
        defaultMessage: 'Cancel'
    },
    quoteEditQuotePopupTitle: {
        id: 'quoteandbind.tvl.step.quote.Price can be changed',
        defaultMessage: 'Price can be changed'
    },
    quoteEditQuotePopupMessage: {
        id: 'quoteandbind.tvl.step.quote.Please have in mind that your price can be changed in case you are modifying the information already selected. Do you want to continue?',
        defaultMessage: 'Please have in mind that your price can be changed in case you are modifying the information already selected. Do you want to continue?'
    },
    quotePagePersonalDataConsentHeader: {
        id: 'quoteandbind.tvl.step.quote.Information',
        defaultMessage: 'Information'
    },
    quotePageDiscountNotAppliedWarning: {
        id: 'quoteandbind.tvl.step.quote.Discount was not applied to all the packages',
        defaultMessage: 'Discount was not applied to all the packages'
    },
    quotePageDisclaimerText: {
        id: 'quoteandbind.tvl.step.quote.For more details about the covers and limits, please read GTCs',
        defaultMessage: 'For more details about the covers and limits, please read GTCs'
    }
});
