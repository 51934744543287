import React, { useContext, useLayoutEffect } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { ContactInfoService } from 'cnd-portals-util-js';
import { useStoredCountry } from 'cnd-common-hooks-platform-react';
import metadata from './NeutralPayment.metadata.json';
import styles from './NeutralPayment.module.scss';
import messages from './NeutralPayment.messages';

function NeutralPayment() {
    const translator = useContext(TranslatorContext);
    const country = useStoredCountry();
    const appCountry = country?.toUpperCase();

    useLayoutEffect(() => {
        document.title = translator(messages.tvlNeutralPaymentPageTitle) + ' | Colonnade Insurance S.A.';
    }, []);

    const overrideProps = {
        tvlNeutralPaymentPageBody: {
            content: translator(messages.tvlNeutralPaymentPageBody, {
                phoneNumber: ContactInfoService.getContactPhoneNumber(appCountry)
            })
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

export default NeutralPayment;
