import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { TranslatorContext } from '@jutro/locale';
import { TooltipIcon } from '@jutro/components';
import s from './MultiSelectCnd.module.scss';

const MultiSelectCnd = ({
    name,
    label,
    tooltip,
    onChange,
    options,
    className,
    visible,
    value,
    defaultValue,
    onlyShowValues,
}) => {
    const translator = useContext(TranslatorContext);
    const animatedComponents = makeAnimated();

    if (!visible) {
        return null;
    }

    const colorStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused
                ? 'var(--GW-COLOR-FOCUS-1)'
                : state.isMenuOpen
                ? 'var(--GW-COLOR-FOCUS-1)'
                : 'var(--GW-COLOR-BORDER)',
        }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? 'var(--GW-COLOR-BRAND-1)'
                    : isFocused
                    ? 'var(--GW-COLOR-BACKGROUND-3)'
                    : undefined,
                color: isSelected ? 'var(--GW-COLOR-TEXT-3)' : undefined,
            };
        },
    };

    if (!onlyShowValues) {
        return (
            <div className={s.multiSelectContainer}>
                <label htmlFor={name} className={s.multiSelectLabelWrapper}>
                    <span className={s.multiSelectLabel}>
                        {translator({
                            id: label.id,
                            defaultMessage: label.defaultMessage,
                        })}
                    </span>
                    {tooltip && <TooltipIcon text={tooltip.text} />}
                </label>
                <Select
                    isMulti
                    name={name}
                    placeholder=""
                    components={animatedComponents}
                    onChange={onChange}
                    options={options}
                    value={value}
                    className={`${s.multiSelectInput} ${className}`}
                    classNamePrefix="select"
                    styles={colorStyles}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: 'var(--GW-COLOR-FOCUS-1)',
                            neutral10: 'var(--GW-COLOR-BACKGROUND-3)',
                            neutral20: 'var(--GW-COLOR-BRAND-2)',
                            neutral30: 'var(--GW-COLOR-BORDER)',
                        },
                    })}
                />
            </div>
        );
    }

    const itemList = (list) => {
        const returnList = new Intl.ListFormat(
            'en', { style: 'narrow' }
        )
            .format(list.map(
                (listItem) => (listItem.label)
            ));
        return returnList;
    };

    return (
        <div className={s.multiSelectContainer}>
            <label htmlFor={name} className={s.multiSelectLabelWrapper}>
                <span className={s.multiSelectLabel}>
                    {translator({
                        id: label.id,
                        defaultMessage: label.defaultMessage,
                    })}
                </span>
                {tooltip && <TooltipIcon text={tooltip.text} />}
            </label>
            <div className={s.multiSelectDefaultValue}>
                {Array.isArray(defaultValue)
                    ? itemList(defaultValue)
                    : defaultValue
                }
            </div>
        </div>
    );
};

MultiSelectCnd.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    tooltip: PropTypes.shape({
        text: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array,
    className: PropTypes.string,
    visible: PropTypes.boolean,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onlyShowValues: PropTypes.boolean,
};

export default MultiSelectCnd;
