import { JsonRPCService } from 'gw-portals-transport-js';
import { getProxiedServiceUrl } from 'gw-portals-url-js';

export default class QuoteService {
    /**
     * Create a quote document
     * @param {string} sessionUUID id of a session
     * @param {String} quoteID the quote ID
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static createQuoteDocument(sessionUUID, quoteID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'createQuoteDocument',
            [sessionUUID, quoteID],
            additionalHeaders
        );
    }

    /**
     * Create and download a quote document
     * @param {string} sessionUUID id of a session
     * @param {String} quoteID the quote ID
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static async createAndDownloadQuoteDocument(sessionUUID, quoteID, additionalHeaders = {}) {
        const document = await QuoteService.createQuoteDocument(
            sessionUUID,
            quoteID,
            additionalHeaders
        );

        const baseUrl = getProxiedServiceUrl('downloadDocument');
        const result = await fetch(`${baseUrl}/policyDocument/${document.publicID}/?token=${document.sessionID}`);
        return { document, result };
    }

    static retrieveYourPassCodes(sessionUUID, quoteID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('quote'),
            'retrieveYourPassCodes',
            [quoteID, sessionUUID],
            additionalHeaders
        );
    }
}
