import _ from 'lodash';
import { useMemo, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import messages from '../ProductLinks.messages';
import OfferingTypes from '../../../constants/OfferingTypes';

const productsLinks = {
    [OfferingTypes.CANCELLATION]: {
        '*': [
            {
                name: messages.tripDetailsTravelTripCancellationDocument,
                url: messages.tripDetailsTravelTripCancellationDocumentUrl
            },
            {
                name: messages.tripDetailsPrecontractualInformationDocument,
                url: messages.tripDetailsPrecontractualInformationDocumentUrl,
                isStatic: true
            }
        ],
        sk: [
            {
                name: messages.tripDetailsTravelInternationalCancellationTripGTCDocumentSK,
                url: messages.tripDetailsTravelInternationalCancellationTripGTCDocumentUrlSK
            },
            {
                name: messages.tripDetailsTravelInternationalCancellationTripIPIDDocumentSK,
                url: messages.tripDetailsTravelInternationalCancellationTripIPIDDocumentUrlSK
            },
            {
                name: messages.tripDetailsCancellationPrecontractualInformationDocumentSK,
                url: messages.tripDetailsCancellationPrecontractualInformationDocumentUrlSK,
                isStatic: true
            }
        ],
        cz: [
            {
                name: messages.tripDetailsTravelTripCancellationDocument,
                url: messages.tripDetailsTravelTripCancellationDocumentUrl
            }
        ],
        hu: [
            {
                name: messages.tripDetailsTravelTripCancellationDocument,
                url: messages.tripDetailsTravelTripCancellationDocumentUrl
            }
        ],
        ro: { ref: '*' },
        pl: [
            {
                name: messages.tripDetailsTravelTripCancellationDocument,
                url: messages.tripDetailsTravelTripCancellationDocumentUrl
            },
            {
                name: messages.tripDetailsTolicyConcerningProvisionsAndRegulationsDocument,
                url: messages.tripDetailsTolicyConcerningProvisionsAndRegulationsDocumentUrl,
                isStatic: true
            }
        ],
        bg: {
            'default': [
                {
                    name: messages.tripDetailsTravelTripCancellationIPIDDocument,
                    url: messages.tripDetailsTravelTripCancellationIPIDDocumentUrl
                },
                {
                    name: messages.tripDetailsTravelTripCancellationGTCDocument,
                    url: messages.tripDetailsTravelTripCancellationGTCDocumentUrl
                },
                {
                    name: messages.tripDetailsBeforeConcludingInsuranceContractInformationDocument,
                    url: messages.tripDetailsBeforeConcludingInsuranceContractInformationDocumentUrl,
                    isStatic: true
                }
            ],
            'tvi_can_sim_bg': [
                {
                    name: messages.tripDetailsTravelTripCancellationSimplifiedIPIDDocument,
                    url: messages.tripDetailsTravelTripCancellationSimplifiedIPIDDocumentUrl
                },
                {
                    name: messages.tripDetailsTravelTripCancellationSimplifiedGTCDocument,
                    url: messages.tripDetailsTravelTripCancellationSimplifiedGTCDocumentUrl
                }
            ]
        }
    },
    [OfferingTypes.DOMESTIC]: {
        '*': [
            {
                name: messages.tripDetailsTravelDomesticTripDocument,
                url: messages.tripDetailsTravelDomesticTripDocumentUrl
            },
            {
                name: messages.tripDetailsPrecontractualInformationDocument,
                url: messages.tripDetailsPrecontractualInformationDocumentUrl,
                isStatic: true
            }
        ],
        sk: { ref: '*' },
        cz: [
            {
                name: messages.tripDetailsTravelDomesticTripDocument,
                url: messages.tripDetailsTravelDomesticTripDocumentUrl
            }
        ],
        hu: [
            {
                name: messages.tripDetailsTravelDomesticTripDocument,
                url: messages.tripDetailsTravelDomesticTripDocumentUrl
            }
        ],
        ro: { ref: '*' },
        pl: [
            {
                name: messages.tripDetailsTravelDomesticTripDocument,
                url: messages.tripDetailsTravelDomesticTripDocumentUrl
            },
            {
                name: messages.tripDetailsTolicyConcerningProvisionsAndRegulationsDocument,
                url: messages.tripDetailsTolicyConcerningProvisionsAndRegulationsDocumentUrl,
                isStatic: true
            }
        ],
        bg: [
            {
                name: messages.tripDetailsTravelDomesticTripIPIDDocument,
                url: messages.tripDetailsTravelDomesticTripIPIDDocumentUrl
            },
            {
                name: messages.tripDetailsTravelDomesticTripGTCDocument,
                url: messages.tripDetailsTravelDomesticTripGTCDocumentUrl
            },
            {
                name: messages.tripDetailsBeforeConcludingInsuranceContractInformationDocument,
                url: messages.tripDetailsBeforeConcludingInsuranceContractInformationDocumentUrl,
                isStatic: true
            }
        ]
    },
    [OfferingTypes.ANNUAL]: {
        '*': [
            {
                name: messages.tripDetailsTravelInternationalAnnualTripDocument,
                url: messages.tripDetailsTravelInternationalAnnualTripDocumentUrl
            },
            {
                name: messages.tripDetailsPrecontractualInformationDocument,
                url: messages.tripDetailsPrecontractualInformationDocumentUrl,
                isStatic: true
            }
        ],
        sk: [
            {
                name: messages.tripDetailsTravelInternationalAnnualTripGTCDocumentSK,
                url: messages.tripDetailsTravelInternationalAnnualTripGTCDocumentUrlSK
            },
            {
                name: messages.tripDetailsTravelInternationalAnnualTripIPIDDocumentSK,
                url: messages.tripDetailsTravelInternationalAnnualTripIPIDDocumentUrlSK
            },
            {
                name: messages.tripDetailsPrecontractualInformationDocument,
                url: messages.tripDetailsPrecontractualInformationDocumentUrl,
                isStatic: true
            }
        ],
        cz: [
            {
                name: messages.tripDetailsTravelInternationalAnnualTripDocument,
                url: messages.tripDetailsTravelInternationalAnnualTripDocumentUrl
            }
        ],
        hu: [
            {
                name: messages.tripDetailsTravelInternationalAnnualTripDocument,
                url: messages.tripDetailsTravelInternationalAnnualTripDocumentUrl
            }
        ],
        ro: { ref: '*' },
        pl: [
            {
                name: messages.tripDetailsTravelInternationalAnnualTripDocument,
                url: messages.tripDetailsTravelInternationalAnnualTripDocumentUrl
            },
            {
                name: messages.tripDetailsTolicyConcerningProvisionsAndRegulationsDocument,
                url: messages.tripDetailsTolicyConcerningProvisionsAndRegulationsDocumentUrl,
                isStatic: true
            }
        ],
        bg: [
            {
                name: messages.tripDetailsTravelInternationalAnnualTripIPIDDocument,
                url: messages.tripDetailsTravelInternationalAnnualTripIPIDDocumentUrl
            },
            {
                name: messages.tripDetailsTravelInternationalAnnualTripGTCDocument,
                url: messages.tripDetailsTravelInternationalAnnualTripGTCDocumentUrl
            },
            {
                name: messages.tripDetailsBeforeConcludingInsuranceContractInformationDocument,
                url: messages.tripDetailsBeforeConcludingInsuranceContractInformationDocumentUrl,
                isStatic: true
            }
        ]
    },
    [OfferingTypes.SINGLE]: {
        '*': [
            {
                name: messages.tripDetailsTravelInternationalSingleTripDocument,
                url: messages.tripDetailsTravelInternationalSingleTripDocumentUrl
            },
            {
                name: messages.tripDetailsPrecontractualInformationDocument,
                url: messages.tripDetailsPrecontractualInformationDocumentUrl,
                isStatic: true
            }
        ],
        sk: {
            'default': [
                {
                    name: messages.tripDetailsTravelInternationalSingleTripGTCDocumentSK,
                    url: messages.tripDetailsTravelInternationalSingleTripGTCDocumentUrlSK
                },
                {
                    name: messages.tripDetailsTravelInternationalSingleTripIPIDDocumentSK,
                    url: messages.tripDetailsTravelInternationalSingleTripIPIDDocumentUrlSK
                },
                {
                    name: messages.tripDetailsPrecontractualInformationDocument,
                    url: messages.tripDetailsPrecontractualInformationDocumentUrl,
                    isStatic: true
                }
            ],
            'tvi_ins_bub_sk': [
                {
                    name: messages.tripDetailsTravelInternationalSingleTripBuboGTCDocument,
                    url: messages.tripDetailsTravelInternationalSingleTripBuboGTCDocumentUrl
                },
                {
                    name: messages.tripDetailsTravelInternationalSingleTripBuboIPIDDocument,
                    url: messages.tripDetailsTravelInternationalSingleTripBuboIPIDDocumentUrl
                },
                {
                    name: messages.tripDetailsTravelInternationalSingleTripBuboPrecontractualDocument,
                    url: messages.tripDetailsTravelInternationalSingleTripBuboPrecontractualDocumentUrl,
                    isStatic: true
                }
            ],
        },
        cz: [
            {
                name: messages.tripDetailsTravelInternationalSingleTripDocument,
                url: messages.tripDetailsTravelInternationalSingleTripDocumentUrl
            }
        ],
        hu: [
            {
                name: messages.tripDetailsTravelInternationalSingleTripDocument,
                url: messages.tripDetailsTravelInternationalSingleTripDocumentUrl
            }
        ],
        ro: { ref: '*' },
        pl: [
            {
                name: messages.tripDetailsTravelInternationalSingleTripDocument,
                url: messages.tripDetailsTravelInternationalSingleTripDocumentUrl
            },
            {
                name: messages.tripDetailsTolicyConcerningProvisionsAndRegulationsDocument,
                url: messages.tripDetailsTolicyConcerningProvisionsAndRegulationsDocumentUrl,
                isStatic: true
            }
        ],
        bg: {
            'default': [
                {
                    name: messages.tripDetailsTravelInternationalSingleTripIPIDDocument,
                    url: messages.tripDetailsTravelInternationalSingleTripIPIDDocumentUrl
                },
                {
                    name: messages.tripDetailsTravelInternationalSingleTripGTCDocument,
                    url: messages.tripDetailsTravelInternationalSingleTripGTCDocumentUrl
                },
                {
                    name: messages.tripDetailsBeforeConcludingInsuranceContractInformationDocument,
                    url: messages.tripDetailsBeforeConcludingInsuranceContractInformationDocumentUrl,
                    isStatic: true
                }
            ],
            'tvi_ins_sim_bg': [
                {
                    name: messages.tripDetailsTravelInternationalSingleTripSimplifiedIPIDDocument,
                    url: messages.tripDetailsTravelInternationalSingleTripSimplifiedIPIDDocumentUrl
                },
                {
                    name: messages.tripDetailsTravelInternationalSingleTripSimplifiedGTCDocument,
                    url: messages.tripDetailsTravelInternationalSingleTripSimplifiedGTCDocumentUrl
                }
            ]
        }
    }
};

function useProductLinks(country, offering, staticOnly, product) {
    const translator = useContext(TranslatorContext);

    return useMemo(() => {
        if (!(country && offering)) return [];
        const cc = country?.toLowerCase();

        let links = _.get(productsLinks, [offering, cc]);
        if (_.isNil(links)) return [];

        if (!Array.isArray(links)) {
            links = links[product] ?? links['default'];
        }

        if (!_.isNil(links.ref)) {
            links = _.get(productsLinks, [offering, links.ref], []);
        }

        return _.reduce(links, (result, { name, url, isStatic }) => {
            if (!staticOnly && isStatic !== true) {
                return result;
            }
            result.push({
                url: translator(url),
                name: translator(name),
            });
            return result;
        }, []);
    }, [staticOnly, country, offering, product, translator]);
}

export default useProductLinks;
