import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import PropTypes from 'prop-types';

import metadata from './RetrieveQuote.metadata.json5';
import styles from './RetrieveQuote.module.scss';
import './RetrieveQuote.messages';

function RetrieveQuote(props) {
    const { onTogglePageState, onRetrieveQuote } = props;
    const [retrieveQuoteVM, updateRetrieveQuoteVM] = useState(undefined);
    const viewModelService = useContext(ViewModelServiceContext);

    useEffect(
        () => {
            const retrieveQuoteViewModel = viewModelService.create(
                {},
                'pc',
                'edge.capabilities.quote.submission.dto.QuoteRetrievalDTO'
            );
            updateRetrieveQuoteVM(retrieveQuoteViewModel);
        },
        // Disabling as this its called when a parent is re rendered
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const writeValue = useCallback(
        (value, path) => {
            const newRetrieveQuoteVM = viewModelService.clone(retrieveQuoteVM);
            _.set(newRetrieveQuoteVM, path, value);
            updateRetrieveQuoteVM(newRetrieveQuoteVM);
        },
        [retrieveQuoteVM, updateRetrieveQuoteVM, viewModelService]
    );

    const retrieveQuote = () => {
        onRetrieveQuote(retrieveQuoteVM.value);
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onTogglePageState: onTogglePageState,
            onRetrieveQuote: retrieveQuote
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={retrieveQuoteVM}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

RetrieveQuote.propTypes = {
    onTogglePageState: PropTypes.func.isRequired,
    onRetrieveQuote: PropTypes.func.isRequired
};
export default RetrieveQuote;
