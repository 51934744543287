import { defineMessages } from 'react-intl';

export default defineMessages({
    tripDetailsPageStepMessage1TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage1TextPL',
        defaultMessage: 'Na tej stronie internetowej możesz zawrzeć umowę ubezpieczenia podróżnego. Jeśli jesteś zainteresowany innym produktem ubezpieczeniowym, prosimy o kontakt z Colonnade: {link1}'
    },
    tripDetailsPageStepMessage1Link1PL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage1Link1PL',
        defaultMessage: 'https://colonnade.pl/kontakt'
    },
    tripDetailsPageStepMessage2TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage2TextPL',
        defaultMessage: 'A. Ubezpieczenie podróżne na wyjazdy zagraniczne dedykowane jest osobom:'
    },
    tripDetailsPageStepMessage3TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage3TextPL',
        defaultMessage: '1. odbywającym podróż w celach wypoczynkowych i/lub służbowych,'
    },
    tripDetailsPageStepMessage4TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage4TextPL',
        defaultMessage: '2. podróżującym poza granicę kraju ich stałego zamieszkania lub kraju, w którym podlegają obowiązkowemu ubezpieczeniu zdrowotnemu,'
    },
    tripDetailsPageStepMessage5TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage5TextPL',
        defaultMessage: '3. poszukującym w związku z podróżą ubezpieczenia w następujących zakresach:'
    },
    tripDetailsPageStepMessage6TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage6TextPL',
        defaultMessage: 'a. koszty leczenia i assistance'
    },
    tripDetailsPageStepMessage7TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage7TextPL',
        defaultMessage: 'b. następstwa nieszczęśliwych wypadków'
    },
    tripDetailsPageStepMessage8TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage8TextPL',
        defaultMessage: 'c. bagaż podróżny'
    },
    tripDetailsPageStepMessage9TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage9TextPL',
        defaultMessage: 'd. odpowiedzialność cywilna w życiu prywatnym'
    },
    tripDetailsPageStepMessage10TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage10TextPL',
        defaultMessage: 'e. ubezpieczenie gotówki'
    },
    tripDetailsPageStepMessage11TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage11TextPL',
        defaultMessage: 'f. przerwanie podróży'
    },
    tripDetailsPageStepMessage12TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage12TextPL',
        defaultMessage: 'g. ochrona domu podczas podróży'
    },
    tripDetailsPageStepMessage13TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage13TextPL',
        defaultMessage: 'Jeżeli w chwili zawierania umowy ubezpieczenia podróżnego na wyjazdy zagraniczne, ubezpieczony przebywa poza granicami kraju zamieszkania ochrona rozpocznie się po upływie 3 dni.'
    },
    tripDetailsPageStepMessage14TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage14TextPL',
        defaultMessage: 'B. Ubezpieczenie kosztów rezygnacji z podróży dedykowane jest osobom:'
    },
    tripDetailsPageStepMessage15TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage15TextPL',
        defaultMessage: '1. planującym podróż krajową lub zagraniczną w celach wypoczynkowych i/lub służbowych,'
    },
    tripDetailsPageStepMessage16TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage16TextPL',
        defaultMessage: '2. poszukującym w związku z planowaną podróżą ubezpieczenia kosztów:'
    },
    tripDetailsPageStepMessage17TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage17TextPL',
        defaultMessage: 'a. rezygnacji z podróży'
    },
    tripDetailsPageStepMessage18TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage18TextPL',
        defaultMessage: 'b. przerwania podróży '
    },
    tripDetailsPageStepMessage19TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage19TextPL',
        defaultMessage: 'c. zmiany rezerwacji podróży'
    },
    tripDetailsPageStepMessage20TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage20TextPL',
        defaultMessage: 'd. dopłaty za pokój jednoosobowy'
    },
    tripDetailsPageStepMessage21TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage21TextPL',
        defaultMessage: 'e. transportu na miejsce imprezy turystycznej, w razie spóźnienia na transport przewidziany w umowie z biurem podróży'
    },
    tripDetailsPageStepMessage22TextPL: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage22TextPL',
        defaultMessage: 'Oceniając, czy oferowane ubezpieczenie odpowiada potrzebom ubezpieczonego należy przeczytać wskazane powyżej Ogólne warunki ubezpieczenia, zwracając szczególną uwagę na wyłączenia i ograniczenia odpowiedzialności zakładu ubezpieczeń.'
    },
    tripDetailsPageStepMessage1TextBG: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage1TextBG',
        defaultMessage: '1. Информация за Застрахователя и застрахователния договор, в съответствие с чл.324 и чл.326 от КЗ, е предоставена {link1}.'
    },
    tripDetailsPageStepMessage1LinkDescription1BG: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage1LinkDescription1BG',
        defaultMessage: 'тук'
    },
    tripDetailsPageStepMessage1Link1BG: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage1Link1BG',
        defaultMessage: 'https://www.colonnade.bg/media/qb-informatsiya-za-zastrakhovatelya-i-zastrakhovatelniya-dogovor-324-326-pdf'
    },
    tripDetailsPageStepMessage2TextBG: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage2TextBG',
        defaultMessage: '2. В случай, че към датата на закупуване на застраховката Застрахованият не се намира на територията на Република България, покритието по застраховката се предоставя след изтичане на 3 дни от датата на сключване на застраховката.'
    },
    tripDetailsPageStepMessage3TextBG: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage3TextBG',
        defaultMessage: '3. Вие можете да сключите Вашата застраховка за пътуване на този уебсайт. Ако се интересувате от други застрахователни продукти, моля свържете се с нас чрез: {link1}'
    },
    tripDetailsPageStepMessage3Link1BG: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage3Link1BG',
        defaultMessage: 'https://www.colonnade.bg/contact'
    },
    tripDetailsPageStepMessage1TextRO: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage1TextRO',
        defaultMessage: 'In cazul in care ati  ales unul dintre produsele de mai jos, va rugam sa aveti in vedere:',
    },
    tripDetailsPageStepMessage1HeaderRO: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage1HeaderRO',
        defaultMessage: 'Asigurarea Calatoriei Internationala in Strainate:',
    },
    tripDetailsPageStepMessage2TextRO: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage2TextRO',
        defaultMessage: 'Daca la momentul incheierii contractului de asigurare, Asiguratul nu se afla pe teritoriul Romaniei, raspunderea Asiguratorului incepe dupa 3 zile, calculate din ziua urmatoare emiterii contractului de asigurare si efectuarii platii primei de asigurare.',
    },
    tripDetailsPageStepMessage3TextRO: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage3TextRO',
        defaultMessage: 'Daca Asiguratul a optat pentru Acoperirea Aditionala de Anulare a Calatoriei, va rugam sa aveti in vedere ca asigurarea trebuie incheiata in cel mult 14 zile calendaristice de la momentul achizitionarii serviciilor turistice. In cazul in care data de inceput a calatoriei este mai mica de 30 de zile, asigurarea trebuie incheiata la momentul achizitiei serviciilor turistice.',
    },
    tripDetailsPageStepMessage2HeaderRO: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage2HeaderRO',
        defaultMessage: 'Asigurare pentru Anularea Calatoriei:',
    },
    tripDetailsPageStepMessage4TextRO: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage4TextRO',
        defaultMessage: 'Asigurarea trebuie incheiata in cel mult 14 zile calendaristice de la momentul achizitionarii serviciilor turistice. In cazul in care data de inceput a calatoriei este mai mica de 30 de zile, asigurarea trebuie incheiata la momentul achizitiei serviciilor turistice.',
    },
    tripDetailsPageStepMessage3HeaderRO: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage3HeaderRO',
        defaultMessage: 'Asigurare Anuala de Calatorie in Strainate:',
    },
    tripDetailsPageStepMessage5TextRO: {
        id: 'quoteandbind.staticInformation.tripDetailsPageStepMessage5TextRO',
        defaultMessage: 'Daca la momentul incheierii contractului de asigurare, Asiguratul nu se afla pe teritoriul Romaniei, raspunderea Asiguratorului incepe dupa 3 zile, calculate din ziua urmatoare emiterii contractului de asigurare si efectuarii platii primei de asigurare.',
    },
    quotePageStepMessage1TextBG: {
        id: 'quoteandbind.staticInformation.quotePageStepMessage1TextBG',
        defaultMessage: '1. Информация за Застрахователя и застрахователния договор, в съответствие с чл.324 и чл.326 от КЗ, е предоставена {link1}.'
    },
    quotePageStepMessage1Link1BG: {
        id: 'quoteandbind.staticInformation.quotePageStepMessage1Link1BG',
        defaultMessage: 'https://www.colonnade.bg/media/qb-informatsiya-za-zastrakhovatelya-i-zastrakhovatelniya-dogovor-324-326-pdf'
    },
    quotePageStepMessage1LinkDescription1BG: {
        id: 'quoteandbind.staticInformation.quotePageStepMessage1LinkDescription1BG',
        defaultMessage: 'тук'
    },
    quotePageStepMessage2TextBG: {
        id: 'quoteandbind.staticInformation.quotePageStepMessage2TextBG',
        defaultMessage: '2. В случай, че към датата на закупуване на застраховката Застрахованият не се намира на територията на Република България, покритието по застраховката се предоставя след изтичане на 3 дни от датата на сключване на застраховката.'
    },
    quotePageStepMessage3TextBG: {
        id: 'quoteandbind.staticInformation.quotePageStepMessage3TextBG',
        defaultMessage: '3. Вие можете да сключите Вашата застраховка за пътуване на този уебсайт. Ако се интересувате от други застрахователни продукти, моля свържете се с нас чрез: {link1}'
    },
    quotePageStepMessage3Link1BG: {
        id: 'quoteandbind.staticInformation.quotePageStepMessage3Link1BG',
        defaultMessage: 'https://www.colonnade.bg/contact'
    },
    personalInformationPageStepMessage1TextBG: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1TextBG',
        defaultMessage: '1. Подробна информация за обработката и съхранението на лични данни от страна на "Колонад Иншурънс Ес Ей – клон България" може да получите от политиката ни за конфиденциалност, публикувана на: {link1}. Информация за продукта и неговата целева група може да бъде намерена в документа {link2}.'
    },
    personalInformationPageStepMessage1Link1BG: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1Link1BG',
        defaultMessage: 'https://www.colonnade.bg/data-privacy-policy/'
    },
    personalInformationPageStepMessage1Link2BG: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1Link2BG',
        defaultMessage: 'https://www.colonnade.bg/media/qb-informatsiya-za-produkta-i-negovata-tseleva-grupa.pdf'
    },
    personalInformationPageStepMessage1LinkDescription2BG: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1LinkDescription2BG',
        defaultMessage: 'Целеви пазар'
    },
    personalInformationPageStepMessage2TextBG: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage2TextBG',
        defaultMessage: '2. Информация за Застрахователя и застрахователния договор, в съответствие с чл.324 и чл.326 от КЗ, е предоставена {link1}.'
    },
    personalInformationPageStepMessage2Link1BG: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage2Link1BG',
        defaultMessage: 'https://www.colonnade.bg/media/qb-informatsiya-za-zastrakhovatelya-i-zastrakhovatelniya-dogovor-324-326-pdf'
    },
    personalInformationPageStepMessage2LinkDescription1BG: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage2LinkDescription1BG',
        defaultMessage: 'тук'
    },
    personalInformationPageStepMessage3TextBG: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage3TextBG',
        defaultMessage: '3. В случай, че към датата на закупуване на застраховката Застрахованият не се намира на територията на Република България, покритието по застраховката се предоставя след изтичане на 3 дни от датата на сключване на застраховката.'
    },
    personalInformationPageStepMessage4TextBG: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage4TextBG',
        defaultMessage: '4. Вие можете да сключите Вашата застраховка за пътуване на този уебсайт. Ако се интересувате от други застрахователни продукти, моля свържете се с нас чрез: {link1}'
    },
    personalInformationPageStepMessage4Link1BG: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage4Link1BG',
        defaultMessage: 'https://www.colonnade.bg/contact'
    },
    personalInformationPageStepMessage1TextSK: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1TextSK',
        defaultMessage: 'Information on Colonnade data protection and processing principles can be found {link1}.'
    },
    personalInformationPageStepMessage1Link1SK: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1Link1SK',
        defaultMessage: 'https://www.colonnade.sk/ochrana-osobnych-udajov'
    },
    personalInformationPageStepMessage1LinkDescription1SK: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1LinkDescription1SK',
        defaultMessage: 'here'
    },
    personalInformationPageStepMessage1TextRO: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1TextRO',
        defaultMessage: 'Informatii detaliate despre prelucrarea datelor cu caracter personal pot fi gasite in {link1}. Informatiile despre produse si piata tinta pot fi gasite in {link2}.'
    },
    personalInformationPageStepMessage1Link1RO: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1Link1RO',
        defaultMessage: 'https://www.colonnade.ro/informare-privind-prelucrarea-datelor-cu-caracter-personal/'
    },
    personalInformationPageStepMessage1Link2RO: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1Link2RO',
        defaultMessage: ' https://www.colonnade.ro/media/documentul-de-piata-tinta-al-produsului.pdf'
    },
    personalInformationPageStepMessage1LinkDescription1RO: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1LinkDescription1RO',
        defaultMessage: 'Notificarea privind prelucrarea datelor cu caracter personal'
    },
    personalInformationPageStepMessage1LinkDescription2RO: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1LinkDescription2RO',
        defaultMessage: 'Documentul de piata tinta al produsului'
    },
    personalInformationPageStepMessage1TextCZ: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1TextCZ',
        defaultMessage: 'Podrobné informace o zpracování osobních údajů naleznete v {link1}. Informace o produktu a jeho cílové skupině naleznete v dokumentu {link2}'
    },
    personalInformationPageStepMessage1Link1CZ: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1Link1CZ',
        defaultMessage: 'https://www.colonnade.cz/ochrana-osobnich-udaju'
    },
    personalInformationPageStepMessage1LinkDescription1CZ: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1LinkDescription1CZ',
        defaultMessage: 'Pravidlech osobních údajů'
    },
    personalInformationPageStepMessage1Link2CZ: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1Link2CZ',
        defaultMessage: 'https://www.colonnade.cz/ochrana-osobnich-udaju'
    },
    personalInformationPageStepMessage1LinkDescription2CZ: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1LinkDescription2CZ',
        defaultMessage: 'Cílový trh.'
    },
    personalInformationPageStepMessage1TextPL: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1TextPL',
        defaultMessage: 'Szczegółowe informacje dotyczące przetwarzania danych osobowych dostępne są {link1}'
    },
    personalInformationPageStepMessage1Link1PL: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1Link1PL',
        defaultMessage: 'https://colonnade.pl/polityka-prywatnosci'
    },
    personalInformationPageStepMessage1LinkDescription1PL: {
        id: 'quoteandbind.staticInformation.personalInformationPageStepMessage1LinkDescription1PL',
        defaultMessage: 'tutaj.'
    },
    insuranceSummaryPageStepMessage1TextBG: {
        id: 'quoteandbind.staticInformation.insuranceSummaryPageStepMessage1TextBG',
        defaultMessage: '1. Информация за Застрахователя и застрахователния договор, в съответствие с чл.324 и чл.326 от КЗ, е предоставена {link1}.'
    },
    insuranceSummaryPageStepMessage1Link1BG: {
        id: 'quoteandbind.staticInformation.insuranceSummaryPageStepMessage1Link1BG',
        defaultMessage: 'https://www.colonnade.bg/media/qb-informatsiya-za-zastrakhovatelya-i-zastrakhovatelniya-dogovor-324-326.pdf'
    },
    insuranceSummaryPageStepMessage1LinkDescription1BG: {
        id: 'quoteandbind.staticInformation.insuranceSummaryPageStepMessage1LinkDescription1BG',
        defaultMessage: 'тук'
    },
    insuranceSummaryPageStepMessage2TextBG: {
        id: 'quoteandbind.staticInformation.insuranceSummaryPageStepMessage2TextBG',
        defaultMessage: '2. В случай, че към датата на закупуване на застраховката Застрахованият не се намира на територията на Република България, покритието по застраховката се предоставя след изтичане на 3 дни от датата на сключване на застраховката.'
    },
    insuranceSummaryPageStepMessage3TextBG: {
        id: 'quoteandbind.staticInformation.insuranceSummaryPageStepMessage3TextBG',
        defaultMessage: '3. Вие можете да сключите Вашата застраховка за пътуване на този уебсайт. Ако се интересувате от други застрахователни продукти, моля свържете се с нас чрез: {link1}'
    },
    insuranceSummaryPageStepMessage3Link1BG: {
        id: 'quoteandbind.staticInformation.insuranceSummaryPageStepMessage3Link1BG',
        defaultMessage: 'https://www.colonnade.bg/contact'
    }
});
