import { SeverityLevel } from '@microsoft/applicationinsights-web';
import PhoneNumberWithCountryCode from './PhoneNumberWithCountryCode/PhoneNumberWithCountryCode';
import SectionHeader from './SectionHeader/SectionHeader';
import ReCaptcha from './ReCaptcha/ReCaptcha';
import LOBContext from './LOBContext';
import DatePicker from './DatePicker';
import {
    valueToDate,
    dateToValue,
    formatDate,
    getFnsLocale
} from './DatePicker/utils';
import CheckboxWithLink from './CheckboxWithLink';
import ClearableDropdown from './ClearableDropdown';
import VariableLenghtInputMask from './VariableLenghtInputMask';
import WarningCnd from './WarningCnd/WarningCnd';
import TelemetryProvider from './Telemetry/TelemetryProvider';
import { getAppInsights } from './Telemetry/TelemetryService';

const platformComponentMap = {
    PhoneNumberWithCountryCode: { component: 'PhoneNumberWithCountryCode' },
    SectionHeader: { component: 'SectionHeader' },
    ReCaptcha: { component: 'ReCaptcha' },
    CheckboxWithLink: { component: 'CheckboxWithLink' },
    ClearableDropdown: { component: 'ClearableDropdown' },
    VariableLenghtInputMask: { component: 'VariableLenghtInputMask' },
    WarningCnd: { component: 'WarningCnd' },
    date: { component: 'date' }
};

const platformComponents = {
    PhoneNumberWithCountryCode,
    SectionHeader,
    ReCaptcha,
    CheckboxWithLink,
    ClearableDropdown,
    VariableLenghtInputMask,
    WarningCnd,
    date: DatePicker
};

export {
    DatePicker,
    valueToDate,
    dateToValue,
    formatDate,
    getFnsLocale,
    ReCaptcha,
    TelemetryProvider,
    SeverityLevel,
    getAppInsights,
    CheckboxWithLink,
    ClearableDropdown,
    VariableLenghtInputMask,
    WarningCnd,
    SectionHeader,
    PhoneNumberWithCountryCode,
    LOBContext,
    platformComponentMap,
    platformComponents
};
