import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteInfoBoxInsuranceSummary: {
        id: 'quoteandbind.tvl.components.quote-info-box.Insurance summary',
        defaultMessage: 'Insurance summary'
    },
    quoteInfoBoxOffering: {
        id: 'quoteandbind.tvl.components.quote-info-box.Offering',
        defaultMessage: 'Offering'
    },
    quoteInfoBoxDestination: {
        id: 'quoteandbind.tvl.components.quote-info-box.Destination',
        defaultMessage: 'Destination'
    },
    quoteInfoBoxTripZone: {
        id: 'quoteandbind.tvl.components.quote-info-box.Trip zone',
        defaultMessage: 'Trip zone'
    },
    quoteInfoBoxDates: {
        id: 'quoteandbind.tvl.components.quote-info-box.Dates of travel',
        defaultMessage: 'Dates of travel'
    },
    quoteInfoBoxCoverageStartDate: {
        id: 'quoteandbind.tvl.components.quote-info-box.Coverage starting on',
        defaultMessage: 'Coverage starting on'
    },
    quoteInfoBoxTripPurchaseDate: {
        id: 'quoteandbind.tvl.components.quote-info-box.Ticket / trip purchase date',
        defaultMessage: 'Ticket / trip purchase date'
    },
    quoteInfoBoxTripTicketCost: {
        id: 'quoteandbind.tvl.components.quote-info-box.Total cost of ticket / trip',
        defaultMessage: 'Total cost of ticket / trip'
    },
    quoteInfoBoxTravellers: {
        id: 'quoteandbind.tvl.components.quote-info-box.No. of travellers',
        defaultMessage: 'No. of travellers'
    },
    quoteInfoInsuranceProduct: {
        id: 'quoteandbind.tvl.components.quote-info-box.Product Type',
        defaultMessage: 'Product Type'
    },
    quoteInfoInsuranceAdditionalCovers: {
        id: 'quoteandbind.tvl.components.quote-info-box.Additional Covers',
        defaultMessage: 'Additional Covers'
    },
    quoteInfoInsuranceVariant: {
        id: 'quoteandbind.tvl.components.quote-info-box.Variant',
        defaultMessage: 'Variant'
    },
    quoteInfoInsurancePremium: {
        id: 'quoteandbind.tvl.components.quote-info-box.Premium',
        defaultMessage: 'Premium'
    }
});
