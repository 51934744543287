import _ from 'lodash';
import isSameDay from 'date-fns/isSameDay';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import enUS from 'date-fns/locale/en-US';
import pl from 'date-fns/locale/pl';
import bg from 'date-fns/locale/bg';
import ro from 'date-fns/locale/ro';
import cs from 'date-fns/locale/cs';
import hu from 'date-fns/locale/hu';
import sk from 'date-fns/locale/sk';

export const withoutTime = (date) => new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
);

export const parseDate = (dateString, formatString, dateLocale) => {
    return parse(dateString, formatString, new Date(), { locale: dateLocale });
};

export const formatDate = (date, formatString, dateLocale) => {
    return format(date, formatString, { locale: dateLocale });
};

export const adjustDate = (date, minDate, maxDate, showTime) => {
    if (!showTime) {
        return date;
    }
    if (isSameDay(minDate, date) && isAfter(minDate, date)) {
        return minDate;
    }
    if (isSameDay(maxDate, date) && isBefore(maxDate, date)) {
        return maxDate;
    }
    return date;
};

export const valueToDate = (val, showTime) => {
    let dateValue = val;

    if (_.isString(val)) {
        dateValue = new Date(val);
    } else if (_.isNumber(val)) {
        dateValue = new Date(val);
    } else if (val?.year != null) {
        const {
            year,
            month,
            day,
            hour = 0,
            minute = 0
        } = val;
        dateValue = new Date(year, month, day, hour, minute);
    }
    return showTime || _.isNil(dateValue) ? dateValue : withoutTime(dateValue);
};

export const dateToValue = (date) => {
    return {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes()
    };
};

export const getFnsLocale = (locale) => {
    switch (locale) {
        case 'pl':
            return pl;
        case 'bg':
            return bg;
        case 'ro':
            return ro;
        case 'cs':
            return cs;
        case 'hu':
            return hu;
        case 'sk':
            return sk;
        default:
            return enUS;
    }
};
