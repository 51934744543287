export const CANCELLATION_VARIANT_COV_TERM_PUBLIC_ID = 'TVLCancellationVariant';
export const CANCELLATION_DEDUCTIBLE_COV_TERM_PUBLIC_ID = 'TVLCancellationDeductible';
export const CANCELLATION_ADDON_PUBLIC_ID = 'TVLCancellation';
export const WINTER_SPORTS_ADDON_PUBLIC_ID = 'TVLWinterSports';
export const EXTREME_SPORTS_ADDON_PUBLIC_ID = 'TVLExtremeCompetitionSportsPackage';
export const SPORT_EQUIPMENT_ADDON_PUBLIC_ID = 'TVLSportEquipment';
export const FLIGHT_ADDON_PUBLIC_ID = 'TVLFlightPackage';
export const PHYSICAL_WORK_ADDON_PUBLIC_ID = 'TVLPhysicalWork';
export const CAR_ASSISTANCE_ADDON_PUBLIC_ID = 'TVLCarAssistance';
export const CAR_ASSISTANCE_LICENSE_PLATE_COV_TERM_PUBLIC_ID = 'TVLCarAssistanceLicensePlate';
export const CAR_ASSISTANCE_FIRST_REGISTRATION_COV_TERM_PUBLIC_ID = 'TVLCarAssistanceFirstRegistrationDate';
export const EMERGENCY_MEDICAL_EXPENSES_ALCOHOL_CLAUSE_PUBLIC_ID = 'TVLEmergencyMedicalExpensesAlcoholClause';
export const ASSISTANCE_REPATRIATION_OF_VEHICLE_PUBLIC_ID = 'TVLAssistanceRepatriationOfVehicle';
