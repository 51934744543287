import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import { ServiceManager } from '@jutro/services';
import { FieldComponent, FieldIcon } from '@jutro/components';
import { CountryLayerService } from 'cnd-portals-util-js';
import { DATA_TYPE_OBJECT } from '@jutro/prop-types';
import DatePickerInternal from './DatePicker';
import ReadOnlyDateValue from './ReadOnlyDateValue';
import { valueToDate, formatDate, getFnsLocale } from './utils';
import messages from './DatePicker.messages';
import { datePickerStyles, inputFieldStyles } from './styles';
import styles from './DatePicker.module.scss';

const getInputSizeClassnames = (inputStyles, size) => cx({
    [inputStyles.inputSmall]: size === 'small',
    [inputStyles.inputLarge]: size === 'large'
});

class DatePickerComponent extends FieldComponent {
    state = {
        isValid: true,
        isEmpty: false
    }

    constructor(props) {
        super(props);
        const countryService = ServiceManager.getService('country-service-cnd');
        this.localeService = ServiceManager.getService('locale');
        this.country = countryService.getStoredCountry()?.toUpperCase();
    }

    getValidationMessages = () => {
        const validationMessages = super.getValidationMessages();
        const { isValid, isEmpty } = this.state;
        if (!isValid && !isEmpty) {
            return [this.translator(messages.incorrectInput)];
        }
        return validationMessages;
    }

    getFnsLocale = () => {
        const locale = this.localeService.getStoredLocale();
        return getFnsLocale(locale);
    }

    getFormattedValue = (value, showTime) => {
        const {
            format,
            timeFormat = '',
            separator = '',
        } = CountryLayerService.getDateFormats(this.country, showTime);

        const dateValue = valueToDate(value, showTime);
        const useFormat = `${format}${separator}${timeFormat}`;
        return formatDate(dateValue, useFormat, this.getFnsLocale());
    }

    handleChange = (value, isValid, isEmpty) => {
        this.setState({ isValid, isEmpty });
        this.notifyChange(value);
    }

    renderControlReadOnly = (breakpointProps, theme) => {
        const { value, showTime } = breakpointProps;
        if (_.isNil(value)) {
            return '-';
        }
        const formattedValue = this.getFormattedValue(value, showTime);

        return (
            <ReadOnlyDateValue {...breakpointProps} value={formattedValue} />
        );
    }

    renderControl = (breakpointProps, theme, options) => {
        const combinedStyles = { ...datePickerStyles, ...inputFieldStyles };
        const themeStyles = theme.getStyles('DatePickerField', combinedStyles);
        const themeProps = theme.getProps('DatePickerField', breakpointProps);
        const {
            id,
            disabled,
            controlClassName,
            required,
            placeholder,
            minDate,
            maxDate,
            size,
            autoComplete,
            format,
            dataType,
            showTime,
            todayButtonText,
            popperPlacement,
            value,
            intl,
            onBlur
        } = themeProps;

        const inputClasses = cx({
            disabled: disabled,
            invalid: !options.isValid
        }, themeStyles.input,
        themeStyles.datePicker,
        controlClassName,
        getInputSizeClassnames(themeStyles, size));

        const iconClasses = cx({
            [styles.invalid]: !options.isValid
        });

        return (
            <div className={themeStyles.datePicker} ariaExpanded="true" ariaHaspopup="true">
                <FieldIcon
                    {...themeProps}
                    className={iconClasses}
                >
                    <DatePickerInternal
                        id={id}
                        country={this.country}
                        disabled={disabled}
                        className={inputClasses}
                        required={required}
                        placeholder={placeholder}
                        minDate={minDate}
                        maxDate={maxDate}
                        dataType={dataType}
                        autoComplete={autoComplete}
                        format={format}
                        showTime={showTime}
                        todayButtonText={todayButtonText}
                        popperPlacement={popperPlacement}
                        value={value}
                        intl={intl}
                        onValueChange={this.handleChange}
                        onBlur={onBlur}
                    />
                </FieldIcon>
            </div>
        );
    }
}

DatePickerComponent.defaultProps = {
    ...FieldComponent.defaultProps,
    dataType: DATA_TYPE_OBJECT,
    icon: 'mi-calendar-today',
    iconPosition: 'right',
    size: 'medium',
    format: 'long',
    minDate: new Date(1900, 0),
    maxDate: new Date(2100, 0),
    autoComplete: !1,
    showTime: !1
};

export default DatePickerComponent;
