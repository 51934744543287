import _ from 'lodash';
import { useState, useCallback, useEffect, useRef } from 'react';
import useLatest from './useLatest';

const defaultOptions = {
    retryTimes: 0,
    retryDelayFactor: 1,
    retryDelayInSeconds: 0,
    initialIsLoading: false
};

const wait = (seconds) => new Promise((resolve) => setTimeout(resolve, seconds * 1000));

function useCallService(
    serviceCallPromise,
    options = {},
    runIfDependenciesChanged = []
) {
    const [serviceOptions, setServiceOptions] = useState(useRef(Object.assign({}, defaultOptions, options)));
    const latestServiceCallPromise = useLatest(serviceCallPromise);
    const [retryState, setRetryState] = useState({});
    const [state, setState] = useState({
        loading: serviceOptions.current.initialIsLoading,
        data: null,
        error: null,
        done: false
    });

    const setResponse = useCallback((loading, data = null, error = null, done = false) => {
        setState({ loading, data, error, done });
    }, []);

    const retry = useCallback(() => {
        setRetryState({});
    }, []);

    const updateOptions = useCallback((data) => {
        setServiceOptions((prevState) => ({
            ...prevState,
            current: {
                ...prevState.current,
                ...data
            }
        }));
    }, []);

    useEffect(() => {
        let retryCount = 0;
        const fetchData = async function fetchData() {
            try {
                setResponse(true);
                if (!_.isFunction(latestServiceCallPromise.current)) {
                    throw new Error(
                        'First argument of a hook must be async function'
                    );
                }
                retryCount += 1;
                const result = await latestServiceCallPromise.current();
                setResponse(false, result, null, true);
            } catch (err) {
                const { retryTimes, retryDelayFactor, retryDelayInSeconds } = serviceOptions.current;
                if (retryCount < retryTimes) {
                    const delay = retryDelayInSeconds * (retryDelayFactor ** retryCount);
                    await wait(delay);
                    fetchData();
                } else {
                    serviceOptions.current.retryTimes = 0;
                    setResponse(false, null, err, true);
                }
            }
        };
        fetchData();
    }, [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...runIfDependenciesChanged,
        latestServiceCallPromise,
        serviceOptions,
        retryState,
        setResponse,
    ]);

    return {
        ...state,
        retry,
        updateOptions,
    };
}

export default useCallService;
