import _ from 'lodash';
import traverse from 'traverse';
import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';
/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('quote'), method, data, additionalHeaders);
}


function generateTempID(data) {
    const tempIDPaths = traverse(data).paths()
        .filter((pathArray) => _.last(pathArray) === 'tempId')
        .map((pathArray) => pathArray.join('.'));

    return tempIDPaths.reduce((acc, path) => {
        acc[path] = _.get(data, path);
        return acc;
    }, {});
}

function setTempIDs(tempIDMap, data) {
    Object.entries(tempIDMap).forEach(([tempIDPath, tempIDValue]) => {
        _.set(data, tempIDPath, tempIDValue);
    });
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class LoadSaveService
 */
export default class LoadSaveService {
    static getAvailableProducts(producerCode, productCode, country, additionalHeaders = {}) {
        return processSubmission('getAvailableProducts', [producerCode, productCode, country], additionalHeaders);
    }

    static getInitialData(producerCode, productCode, country, additionalHeaders = {}) {
        return processSubmission('getInitialData', [producerCode, productCode, country], additionalHeaders);
    }

    static getAvailableConsents(data, additionalHeaders = {}) {
        return processSubmission('getAvailableConsents', [data], additionalHeaders);
    }

    static getAvailableTripZones(producerCode, country, effDate, additionalHeaders = {}) {
        return processSubmission('getAvailableTripZones', [producerCode, country, effDate], additionalHeaders);
    }

    static getAvailableDestinationCountries(producerCode, effDate, country, offering, additionalHeaders = {}) {
        return processSubmission('getAvailableDestinationCountries', [producerCode, effDate, country, offering], additionalHeaders);
    }

    static createSubmissionAndQuote(data, additionalHeaders = {}) {
        _.set(data, 'baseData.entrySource_Cnd', 'portalquotebind');
        return processSubmission('createSubmissionAndQuote', [data], additionalHeaders);
    }

    /**
     * Updates an existing draft submission.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateDraftSubmission(data, additionalHeaders = {}) {
        const tempIDs = generateTempID(data);
        return processSubmission('updateDraftSubmission', [data], additionalHeaders).then((result) => {
            setTempIDs(tempIDs, result);
            return result;
        });
    }

    /**
     * Updates a quoted submission.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateQuotedSubmission(data, additionalHeaders = {}) {
        return processSubmission('updateQuotedSubmission', [data], additionalHeaders);
    }

    static createElectronicPayment(data, additionalHeaders = {}) {
        return processSubmission('createElectronicPayment', [data], additionalHeaders);
    }

    /**
     * Updates a quoted submission.
     *
     * @param {String} quoteId string containing id of an quote
     * @param {String} sessionUUID string containing UUID of a session
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static retrieveBoundSubmission(quoteId, sessionUUID, additionalHeaders = {}) {
        return processSubmission('retrieveBoundSubmission', [quoteId, sessionUUID], additionalHeaders);
    }

    static retrieveSubmission(quoteId, sessionUUID, additionalHeaders = {}) {
        return processSubmission('retrieveSubmission', [quoteId, sessionUUID], additionalHeaders);
    }

    static retrieveQuoteByToken(token, additionalHeaders = {}) {
        return processSubmission('retrieveQuoteByToken', [token], additionalHeaders);
    }
}
